import ButtonNav from "components/ButtonNav"
import { desiredMenuOrder } from "helpers/functions"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"

function MenuNavBar(props) {
  const dispatch = useDispatch()
  const { menuImageList } = useSelector((store) => store.superAdmin)
  const [menuCategoryList, setMenuCategoryList] = useState([])

  function handleButtonNav(item) {
    let itemsLink = {}
    if (props.isEdit) {
      itemsLink = { item: item, category: props.subCategory || "", list: "Detailed View" }
    } else {
      itemsLink = {
        item: item,
        category: props.subCategory || "",
        list: "Summary View"
      }
    }
    dispatch(marketingActions.setMenuLink(itemsLink))
  }

  React.useEffect(() => {
    const newMenuList = [...menuImageList]
      ?.sort((a, b) => desiredMenuOrder.indexOf(a.name) - desiredMenuOrder.indexOf(b.name))
      .map((item) => {
        return {
          name: item.name,
          value: item.name.toLowerCase()
        }
      })

    setMenuCategoryList(newMenuList)
  }, [menuImageList])

  return (
    <>
      {menuCategoryList?.map((item, i) => {
        return (
          <ButtonNav
            style={{ marginRight: 12 }}
            onPress={() => handleButtonNav(item?.value)}
            key={i}
            isActive={props.activeTab === item.value ? true : false}>
            {item.name}
          </ButtonNav>
        )
      })}
    </>
  )
}

export default MenuNavBar
