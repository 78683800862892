import React, { useState } from "react"
import ClearIcon from "@mui/icons-material/Clear"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import { Col, Row } from "react-bootstrap"
import { Grid } from "@mui/material"
import { createDynamicMenuFieldsAction } from "redux/superAdmin/actions"
import { useDispatch } from "react-redux"
import styles from "../menu.module.scss"

const AddNewFields = ({ currentIndex, category, categoryId, setNewField, barCategory }) => {
  const [field, setField] = useState({})
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  const handleRemoveField = () => {
    setField({})
    setNewField(false)
  }

  const handleChange = (event) => {
    const { value } = event.target

    const regex = /^[a-zA-Z0-9 ()']*$/
    const hasMultipleSpaces = /\s{2,}/.test(value)

    if (!regex.test(value)) {
      setError("Please enter valid field name")
    } else if (hasMultipleSpaces) {
      setError("Field name cannot have double spaces")
    } else {
      setError("")
      setField({ fieldName: value })
    }
  }

  const saveField = () => {
    if (field.fieldName.trim() !== "" && field.fieldName !== null) {
      const transformedField = {
        field_name: field.fieldName?.trim().toLowerCase().replace(/\s+/g, "_"),
        order: currentIndex + 1 || 1,
        category: categoryId,
        ...(category === "Bar Menu"
          ? { barmenu_category: barCategory }
          : { barmenu_category: null })
      }
      const onClose = () => {
        setNewField(false)
        setField({})
      }
      dispatch(
        createDynamicMenuFieldsAction({ payload: transformedField, category, onClose, barCategory })
      )
    } else {
      setError("Please enter valid field name")
    }
  }

  return (
    <Grid item container xs={12}>
      <Grid item xs={10} alignItems="center">
        <Row className={styles.text_field}>
          <Col lg="11">
            <AppTextField
              fullWidth
              type="text"
              value={field.fieldName}
              onChange={handleChange}
              placeholder="Enter Field Name"
              variant="outlined"
              error={!!error}
              helperText={error}
              sx={{
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  padding: "16px 0px 0px 0px"
                }
              }}
            />
          </Col>
          <Col lg="1" style={{ display: "flex", justifyContent: "center" }}>
            <span role="button" onClick={handleRemoveField}>
              <ClearIcon sx={{ width: "30px", height: "48px", marginTop: 2 }} />
            </span>
          </Col>
        </Row>
      </Grid>
      <Grid item container xs={2} justifyContent="flex-end" className={styles.action_btn}>
        <AppButton styles={{ padding: "10px 20px", marginTop: "18px" }} onClick={saveField}>
          Save
        </AppButton>
      </Grid>
    </Grid>
  )
}

export default AddNewFields
