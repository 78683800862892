import { Button, styled } from "@mui/material"

const AppButtonFilled = styled(Button)(({ theme }) => ({
  background: `linear-gradient(97.14deg, ${theme.palette.aqua.main} -118.91%, ${theme.palette.secondary.main} 64.39%)`,
  color: theme.palette.text.primary,
  ...theme.typography.body1Regular,
  textTransform: "capitalize",
  padding: "0px 20px",
  height: "100%"
}))

export default AppButtonFilled
