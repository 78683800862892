import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  styled,
  Typography,
  useTheme
} from "@mui/material"
import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 360,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

const SuccessModal = ({ open, onClose }) => {
  const theme = useTheme()
  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    },
    checked: {
      width: "64px",
      height: "64px",
      marginBottom: "20px"
    }
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </Box>
        <Grid display="flex" flexDirection="column" alignItems="center" mt={2}>
          <CheckCircleIcon htmlColor={theme.palette.secondary.main} style={styles.checked} />
          <Typography variant="h2">Invite Sent Successfully</Typography>
        </Grid>
      </DialogContent>
    </StyledDialog>
  )
}

export default SuccessModal
