import { Box, Dialog, DialogContent, styled } from "@mui/material"
import DMVDatePicker from "components/DMVDatePicker"
import React from "react"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: 352,
    overflow: "hidden",
    boxSizing: "border-box",
    display: "flex",
    gap: 1,
    padding: "24px 13px 20px",
    "& .innerCtr": {
      width: "100%",
      boxSizing: "border-box"
    }
  }
}))

const HolidayCalendar = ({ open, onClose }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <Box className="innerCtr">
          <DMVDatePicker title="Holiday Calendar" readOnly={true} />
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default HolidayCalendar
