import React, { useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Typography } from "@mui/material"
import styles from "./PendingQuestionTable.module.scss"
import { styled } from "@mui/system"
import QuestionRow from "./components/QuestionRow"
import { useDispatch, useSelector } from "react-redux"
import { getPendingQuestionsAction } from "redux/L&D/actions"
import TableNoRecords from "components/TableNoRecords"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "14px 24px 14px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid ${theme.palette.background.default}`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const PendingQuestionTable = () => {
  const dispatch = useDispatch()
  const pendingQuestions = useSelector((store) => store?.learningDevelopment?.pendingQuestions)

  useEffect(() => {
    dispatch(getPendingQuestionsAction())
  }, [])

  return (
    <Paper className={styles.container}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <StyledTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {PendingQuestionColumns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography className={styles.header} variant="body2Regular">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingQuestions?.length ? (
              <>
                {pendingQuestions?.map((row, index) => {
                  return <QuestionRow key={row.id} row={row} index={index} />
                })}
              </>
            ) : (
              <TableNoRecords colSpan={5} label="No Pending Questions Found" />
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Paper>
  )
}

export default PendingQuestionTable

const PendingQuestionColumns = [
  { id: "S.No", label: "S.No", minWidth: 35 },
  { id: "Questions", label: "Questions", minWidth: 50 },
  {
    id: "Suggested By",
    label: "Suggested By",
    align: "center",
    minWidth: 100
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 120
  },
  {
    id: "Suggested For",
    label: "Suggested For",
    align: "center",
    minWidth: "fit-content"
  }
]
