import React, { Fragment } from "react"
import { ListItem, Typography } from "@mui/material"
import Divider from "components/StyledComponents/Divider"

function ListHeaderFor2Items({ heading }) {
  return (
    <Fragment>
      <ListItem sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
        <Typography variant="tag1" sx={{ opacity: 0.6 }}>
          {heading.key}
        </Typography>
        <Typography variant="tag1" sx={{ opacity: 0.6 }}>
          {heading.field}
        </Typography>
      </ListItem>
      <Divider />
    </Fragment>
  )
}

export default ListHeaderFor2Items
