import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import AppTextArea from "components/StyledComponents/AppTextArea"
import { useDispatch } from "react-redux"
import { uploadMenuItemAction } from "redux/marketing/actions"
import { marketingActions } from "redux/marketing/slice/user"
import AddIngrediant from "../AddIngrediant"
import AddStep from "../AddStep"
import AddUniqueFact from "../AddUniqueFact"

const buttonText = {
  EDIT: "Update Item",
  SAVE: "Save Item"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 1000,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

function BeverageItemForm({ formik, open = false, onClose = () => {}, isEdit = false, title }) {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    formik.resetForm({
      cocktailName: "",
      uploadThumbnail: "",
      description: "",
      ingrediants: [{ name: "", measurement: "" }],
      methodology: [{ name: "", measurement: "" }],
      taste: ""
    })
    dispatch(marketingActions.setMenuImageId(""))
  }, [open])

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  const handleImageDelete = (formik) => {
    formik.setFieldValue("uploadThumbnail", null)
  }
  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("uploadThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "uploadThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("uploadThumbnail", image)
      dispatch(uploadMenuItemAction(data))
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "uploadThumbnail" && 1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        {showCropperModal(imageObjectURL, fileDetails, formik)}
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2">
            {" "}
            {isEdit ? "Edit" : "Add New"} {title}
          </Typography>
        </Box>

        <Box flexDirection="column" className="mt-4">
          <React.Fragment>
            <Box className=" w-100 py-2">
              <Row className="w-100 m-0">
                <Col lg="6">
                  <AppTextField
                    id="cocktailName"
                    name="cocktailName"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Cocktail Name"
                    isRequired={true}
                    placeholder="Enter Cocktail Name"
                    value={formik.values.cocktailName}
                    onChange={formik.handleChange}
                    error={formik.touched.cocktailName && Boolean(formik.errors.cocktailName)}
                    helperText={formik.errors.cocktailName}
                  />
                </Col>
                <Col lg="6">
                  <ImageUploadField
                    label="Upload Thumbnail"
                    isVariant={true}
                    placeholder="Upload Image"
                    value={formik.values.uploadThumbnail}
                    onChange={(e) => onImageUpload(e)}
                    onDelete={() => handleImageDelete(formik)}
                  />
                </Col>
              </Row>

              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="description"
                    name="description"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="description"
                    placeholder="Enter description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.errors.description}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="origin_name"
                    name="origin_name"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Region Name"
                    isRequired={true}
                    placeholder="Enter Region Name"
                    value={formik.values.origin_name}
                    onChange={formik.handleChange}
                    error={formik.touched.origin_name && Boolean(formik.errors.origin_name)}
                    helperText={formik.errors.origin_name}
                  />
                </Col>
              </Row>
              {/* <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="unique_fact"
                    placeholder="Enter Unique Fact"
                    label="Unique Fact"
                    fullWidth
                    isUpperCase={true}
                    value={formik.values.unique_fact}
                    onChange={formik.handleChange}
                    error={formik.touched.unique_fact && Boolean(formik.errors.unique_fact)}
                    helperText={formik.errors.unique_fact}></AppTextField>
                </Col>
              </Row> */}

              <AddUniqueFact
                formik={formik}
                values={formik.values.unique_fact}
                name="unique_fact"
                buttonText="+ Add Unique Fact"
                label="Unique Fact"
              />

              <AddIngrediant
                formik={formik}
                values={formik.values.ingrediants}
                name="ingrediants"
                buttonText="+ Add Ingredient"
                label="Ingredients"
              />

              <AddStep
                formik={formik}
                values={formik.values.methodology}
                name="methodology"
                buttonText="+ Add Step"
                label="Methodology"
                showSteps={true}
                stepLabel="Step"
              />

              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextArea
                    label="How It's made"
                    minRows={5}
                    maxRows={15}
                    placeholder="How It's made"
                    value={formik.values.how_its_made}
                    onChange={formik.handleChange("how_its_made")}
                    error={formik.errors.how_its_made}
                  />
                </Col>
              </Row>

              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextArea
                    label="Taste Profile"
                    minRows={5}
                    maxRows={15}
                    placeholder="Enter Taste Profile"
                    value={formik.values.taste}
                    onChange={formik.handleChange("taste")}
                    error={formik.errors.taste}
                  />
                </Col>
              </Row>
            </Box>
          </React.Fragment>
        </Box>
        <Box className="mt-5 d-flex justify-content-around">
          <AppButton
            onClick={formik.handleSubmit}
            sx={() => ({
              height: "48px",
              padding: "10px 50px"
            })}>
            {isEdit ? buttonText.EDIT : buttonText.SAVE}
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default BeverageItemForm
