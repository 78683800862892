import { Box, Grid } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { superAdminAction } from "redux/superAdmin/slice/user"
import { appActions } from "redux/app/slice/app"
import AdminUserListTable from "./adminUserListTable/index"
import { getFrontlineUsersListAction, sendInviteAction } from "redux/superAdmin/actions"
import { useSelector } from "react-redux"
import UploadAddUserModalAdmin from "./components/UploadAddUserModalAdmin"
import SuccessModal from "../frontLineUser/components/SuccessModal"
import { useDebounce } from "hooks/utils"
import SearchComponent from "components/SearchComponent"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"

const AdminUser = () => {
  const history = useHistory()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { frontlineUserList } = useSelector((store) => store?.superAdmin?.frontlineUser || [])
  const { totalActiveUser } = useSelector((store) => store?.superAdmin || 0)
  const { searchField, userStatus, orderBy } = useSelector((store) => store?.app?.filters || "")
  const [selectAll, setSelectAll] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [exceptUsers, setExceptUsers] = useState([])
  const [openModal, setopenModal] = useState(false)
  const debouncedSearchTerm = useDebounce(searchField, 500)
  const [sendInvite, setSendInvite] = useState(false)

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleSendInvite = () => {
    if (selectedUsers?.length || exceptUsers?.length || selectAll === true) {
      const payload = {
        send_to_all: selectAll,
        selected_ids: selectedUsers,
        exclude_ids: exceptUsers,
        user_type: "ADMIN"
      }
      const handleSuccessModal = () => {
        setopenModal(true)
        setSelectAll(false)
        setSelectedUsers([])
        setExceptUsers([])
      }
      dispatch(sendInviteAction({ payload, showSuccessModal: handleSuccessModal }))
    }
  }

  useEffect(() => {}, [frontlineUserList])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(superAdminAction.setFrontlineUserData({}))
    dispatch(appActions.initFilters())
    dispatch(appActions.setInitDate(""))
    dispatch(appActions.setOrderBy("full_name"))
  }, [])

  useEffect(() => {
    // dispatch(
    //   getAdminUsersListAction({
    //     page: currentPage,
    //     search: debouncedSearchTerm,
    //     // role: employeeClass,
    //     status: userStatus?.toLowerCase()
    //     // restaurant: restaurant,
    //     // hire_date: date,
    //     // orderBy: orderBy
    //   })
    // )
    dispatch(
      getFrontlineUsersListAction({
        page: currentPage,
        search: debouncedSearchTerm,
        status: userStatus?.toLowerCase(),
        user_type: "ADMIN",
        orderBy: orderBy
      })
    )
  }, [currentPage, debouncedSearchTerm, userStatus, orderBy])

  function handleSend() {
    setSendInvite(true)
  }

  const users =
    selectedUsers && selectedUsers?.length > 0
      ? selectedUsers.length > 1
        ? `${selectedUsers.length} users`
        : "this user"
      : selectAll
      ? exceptUsers?.length === 0
        ? "all active users"
        : `${totalActiveUser - exceptUsers.length} users`
      : "this user"

  useEffect(() => {
    setCurrentPage(1)
  }, [debouncedSearchTerm, userStatus, orderBy])

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to send invite to ${users}?`}
        description="  "
        style={{ textAlign: "center", width: "80%" }}
        btnText="Send"
        open={sendInvite}
        onClose={() => setSendInvite(false)}
        onDelete={() => handleSendInvite()}
      />
      <UploadAddUserModalAdmin open={modalOpen} onClose={handleClose}></UploadAddUserModalAdmin>
      <Grid container>
        <Grid item xs={12}>
          <Header title="User List" breadCrumbs={breadCrumbs}>
            <Box sx={{ marginRight: "20px" }}>
              <SearchComponent />
            </Box>
            {(selectedUsers?.length || exceptUsers?.length || selectAll === true) && (
              <AppButton
                onClick={() => handleSend()}
                sx={{ padding: "10px 37px", marginRight: "20px" }}>
                Send Invite
              </AppButton>
            )}
            <AppButton
              onClick={() => setModalOpen(true)}
              sx={{
                cursor: "pointer",
                padding: "10px 20px",
                marginRight: "20px"
              }}>
              + Add Bulk User
            </AppButton>
            <AppButton
              onClick={() => history.push("/super-admin/dashboard/admin-user-list/add-admin-user")}
              sx={{ padding: "10px 20px" }}>
              + Add New User
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Container style={{ minWidth: "100%" }}>
        {/* <Row>
          <Col lg="12" className="d-flex justify-content-end mb-4 mt-2 p-0">
            <AppButton style={{ padding: "14px 20px", backgroundColor: theme.palette.secondary.main }}>
              Send Email
            </AppButton>
          </Col>
        </Row> */}

        <Row>
          <Col lg="12" className="p-0">
            <AdminUserListTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={frontlineUserList?.totalPages}
              rows={frontlineUserList?.data || []}
              columns={columns}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              selectedQuestions={selectedUsers}
              setSelectedQuestions={setSelectedUsers}
              exceptQuestions={exceptUsers}
              setExceptQuestions={setExceptUsers}
            />
          </Col>
        </Row>
      </Container>
      <SuccessModal open={openModal} onClose={() => setopenModal(false)} />
    </>
  )
}

export default AdminUser
const columns = [
  {
    id: "select_all",
    label: "",
    minWidth: 70,
    align: "center"
  },
  {
    id: "id",
    label: "S.No",
    minWidth: 100,
    align: "center"
  },
  {
    id: "Name",
    label: "Name",
    isSorting: true,
    sortKey: "full_name",
    minWidth: 210
  },
  {
    id: "Email",
    label: "Email",
    align: "center",
    minWidth: 170
  },
  {
    id: "Role",
    label: "Role",
    align: "center",
    minWidth: "170"
  },
  {
    id: "Invitation email",
    label: "Invitation email",
    align: "center",
    minWidth: 170
  },
  {
    id: "Invitation email Opened",
    label: "Invitation email Opened",
    align: "center",
    minWidth: 200
  },
  {
    id: "User Logged in",
    label: "User Logged in",
    align: "center",
    minWidth: 170
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 170
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 100
  }
]
