import React, { useState } from "react"

import UserMetricsNavBar from "../userMetricsNavBar"
import { useEffect } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import Card from "components/Card/Card"
import { Col, Container, Row } from "react-bootstrap"
import { Typography } from "@mui/material"
import FrontlineUserTable from "./frontlineUserTable"
import TopGmManageUsers from "./topGM-ManageUsers"
import TopCorporateUsers from "./topCorporatUsers"
import TopLocationUsers from "./topLocationUsers"

function TopUsers() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  // const history = useHistory();
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Top Users"
  }, [])

  return (
    <>
      <UserMetricsNavBar breadCrumbs={breadCrumbs} isTopUserActive={true}></UserMetricsNavBar>
      <Card
        style={{
          borderTopLeftRadius: "0px",
          marginTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
        <Container fluid className="mt-3">
          <Row className="m-0">
            <Col
              lg="12"
              className="d-flex align-items-center justify-content-between"
              style={{ height: "50px" }}>
              <Typography variant="h2">Frontline Users</Typography>
            </Col>
          </Row>
          <Row className="m-0 mt-3">
            <Col lg="12">
              <FrontlineUserTable
                rows={frontLineRows}
                columns={frontlineColumns}></FrontlineUserTable>
            </Col>
          </Row>
        </Container>
      </Card>
      <Container fluid className="mt-5">
        <Row className="m-0">
          <Col
            lg="12"
            className="d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}>
            <Typography variant="h2">Top GM/ Manager Users</Typography>
          </Col>
        </Row>
        <Row className="m-0 mt-3">
          <Col lg="12">
            <TopGmManageUsers rows={topGMRows} columns={topGMColumns}></TopGmManageUsers>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5">
        <Row className="m-0">
          <Col
            lg="12"
            className="d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}>
            <Typography variant="h2">Top Corporate User</Typography>
          </Col>
        </Row>
        <Row className="m-0 mt-3">
          <Col lg="12">
            <TopCorporateUsers
              rows={topCorporateRows}
              columns={topCorporateColumns}></TopCorporateUsers>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5">
        <Row className="m-0">
          <Col
            lg="12"
            className="d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}>
            <Typography variant="h2">Top Location users</Typography>
          </Col>
        </Row>
        <Row className="m-0 mt-5">
          <Col lg="12">
            <TopLocationUsers
              rows={topLocationRows}
              columns={topLocationColumns}></TopLocationUsers>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default TopUsers

const frontlineColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 220
  },
  {
    id: "Employee Class",
    label: "Employee Class",
    align: "center",
    minWidth: 170
  },
  {
    id: "Restaurant Name ",
    label: "Restaurant Name",
    align: "center",
    minWidth: 170
  },
  {
    id: "Mobile Views",
    label: "Mobile Views",
    align: "center",
    minWidth: 170
  }
]

const frontLineRows = [
  {
    id: 1,
    rank: 1,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    employeeClass: "Server ",
    restaurantName: "Tempa",
    mobileViews: 1000
  },
  {
    id: 2,
    rank: 2,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    employeeClass: "Bartender ",
    restaurantName: "Coconut Creek ",
    mobileViews: 988
  },
  {
    id: 3,
    rank: 3,
    name: "Robert Fox",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    employeeClass: "TR ",
    restaurantName: "Doral",
    mobileViews: 980
  },
  {
    id: 4,
    rank: 4,
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    employeeClass: "Server ",
    restaurantName: "Virginia Beach",
    mobileViews: 956
  },
  {
    id: 5,
    rank: 5,
    name: "Marvin McKinney",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    employeeClass: "TR ",
    restaurantName: "Naples",
    mobileViews: 1000
  }
]

const topGMColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 220
  },
  {
    id: "Role",
    label: "Role",
    align: "center",
    minWidth: 170
  },
  {
    id: "Restaurant Name ",
    label: "Restaurant Name",
    align: "center",
    minWidth: 170
  },
  {
    id: "Web Views",
    label: "Web Views",
    align: "center",
    minWidth: 170
  }
]

const topGMRows = [
  {
    id: 1,
    rank: 1,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    employeeClass: "Area Director ",
    restaurantName: "Tempa",
    mobileViews: 1000
  },
  {
    id: 2,
    rank: 2,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    employeeClass: "L&D Team ",
    restaurantName: "Coconut Creek ",
    mobileViews: 988
  },
  {
    id: 3,
    rank: 3,
    name: "Robert Fox",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    employeeClass: "General Manager ",
    restaurantName: "Doral",
    mobileViews: 980
  },
  {
    id: 4,
    rank: 4,
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    employeeClass: "Marketing ",
    restaurantName: "Virginia Beach",
    mobileViews: 956
  },
  {
    id: 5,
    rank: 5,
    name: "Marvin McKinney",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    employeeClass: "Area Director",
    restaurantName: "Naples",
    mobileViews: 1000
  }
]

const topCorporateColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 220
  },
  {
    id: "Role",
    label: "Role",
    align: "center",
    minWidth: 170
  },
  {
    id: "Web Views",
    label: "Web Views",
    align: "center",
    minWidth: 170
  }
]

const topCorporateRows = [
  {
    id: 1,
    rank: 1,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    employeeClass: "Area Director ",
    mobileViews: 1000
  },
  {
    id: 2,
    rank: 2,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    employeeClass: "L&D Team ",
    mobileViews: 988
  },
  {
    id: 3,
    rank: 3,
    name: "Robert Fox",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    employeeClass: "General Manager ",
    mobileViews: 980
  },
  {
    id: 4,
    rank: 4,
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    employeeClass: "Marketing ",
    mobileViews: 956
  },
  {
    id: 5,
    rank: 5,
    name: "Marvin McKinney",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    employeeClass: "Area Director",
    mobileViews: 1000
  }
]

const topLocationColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 220
  },
  {
    id: "Active Users",
    label: "Active Users",
    align: "center",
    minWidth: 170
  },
  {
    id: "Mobile & Web Views",
    label: " Mobile & Web Views",
    align: "center",
    minWidth: 170
  }
]

const topLocationRows = [
  {
    id: 1,
    rank: 1,
    employeeClass: "Area Director ",
    activeUsers: 200,
    mobileViews: 1000
  },
  {
    id: 2,
    rank: 2,
    activeUsers: 500,
    employeeClass: "L&D Team ",
    mobileViews: 988
  },
  {
    id: 3,
    rank: 3,
    employeeClass: "General Manager ",
    activeUsers: 340,
    mobileViews: 980
  },
  {
    id: 4,
    rank: 4,
    employeeClass: "Marketing ",
    activeUsers: 100,
    mobileViews: 956
  },
  {
    id: 5,
    rank: 5,
    employeeClass: "Area Director",
    activeUsers: 90,
    mobileViews: 1000
  }
]
