import React, { useState } from "react"
import { Box, Collapse, TableCell, TableRow, Typography, useTheme } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import styles from "../PendingQuestionTable.module.scss"
import Question from "./Question"
import SuggestedBy from "./SuggestedBy"
import { getMonthYearString } from "helpers/functions"

const QuestionRow = ({ row = {}, index }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const theme = useTheme()

  const getStatusColor = () => {
    switch (row?.status?.toLowerCase()) {
      case "pending":
        return { color: theme.palette.orange.main }
      case "approved":
        return { color: theme.palette.green.main }
    }
  }

  return (
    <>
      <TableRow key={row.id} className={isExpanded ? styles.borderBottomNone : ""}>
        <TableCell>
          <div className={styles.serialNo}>{index + 1}</div>
        </TableCell>
        <TableCell>
          <Typography variant="body1Regular">{row.title}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1Regular">{row?.suggested_by?.name ?? "-"}</Typography>
        </TableCell>
        <TableCell align="center">
          <div style={getStatusColor()}>
            <Typography variant="body1Regular">{row?.status}</Typography>
            <span style={{ cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? (
                <KeyboardArrowUpIcon sx={{ width: "17.6px" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ width: "17.6px" }} />
              )}
            </span>
          </div>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1Regular">
            {getMonthYearString(row.trivia?.start_date)}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow className={styles.borderBottomNone}>
        <TableCell className={styles.expandableRow} colSpan={6}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box className={styles.questionCtr}>
              <Question row={row} questionIndex={index} />
              <SuggestedBy row={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default QuestionRow
