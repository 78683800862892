import { Backdrop, Grid, List, Typography } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useTheme } from "@mui/system"
import avatar from "assets/images/user/avatarUser.svg"
import { useDispatch } from "react-redux"
import { getFrontlineUsersDataAction, updateUserStatusAction } from "redux/superAdmin/actions"
import { useSelector } from "react-redux"
import { splitAndMergeString, toTitleCase } from "helpers/functions"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { useHistory } from "react-router-dom"

const userStatus = {
  activated: "Activated",
  deactivated: "Deactivated"
}

const ViewAdminUser = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(false)
  const open = Boolean(anchorEl)
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const { frontlineUserData: adminUserData } = useSelector(
    (store) => store?.superAdmin?.frontlineUser ?? {}
  )
  const [buttonState, setButtonState] = useState("")

  const styles = {
    list: {
      display: "flex",
      flexDirection: "column",
      marginTop: "14px",
      marginBottom: "18px"
    },
    label: {
      color: theme.palette.text.secondary,
      opacity: 0.6,
      marginBottom: "4px"
    },
    userImage: {
      width: "120px",
      height: "120px",
      objectFit: "cover",
      borderRadius: "50%"
    }
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(getFrontlineUsersDataAction())
  }, [])

  function handleEditContest() {
    history.push("/super-admin/dashboard/admin-user-list/edit-admin-user")
  }

  function getButtonStatus(status) {
    if (toTitleCase(status) === userStatus?.activated) {
      return "Deactivate"
    } else {
      return "Activate"
    }
  }

  function handleActivate() {
    const source = "view"
    if (buttonState === userStatus.activated) {
      dispatch(updateUserStatusAction({ activate: false, source }))
      setButtonState("Deactivate")
    } else {
      dispatch(updateUserStatusAction({ activate: true, source }))
      setButtonState("Activated")
    }
  }

  useEffect(() => {
    getButtonStatus()
    if (!adminUserData?.status) return
    setButtonState(toTitleCase(adminUserData?.status))
  }, [buttonState, adminUserData])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const toggleActivePopup = () => {
    handleClose()
    setIsDeletePopupOpen(!isDeletePopupOpen)
  }

  function handleDelete() {
    handleActivate()
  }

  const columns = [
    {
      label: "CONTACT NUMBER",
      content: (
        <Col lg="4" className="ps-0">
          <List sx={styles.list}>
            <Typography sx={styles.label} variant="caption3">
              Contact Number
            </Typography>
            <Typography variant="body1Regular">
              {adminUserData?.phone_number ? `+${adminUserData.phone_number}` : "N/A"}
            </Typography>
          </List>
        </Col>
      ),
      hasData: !!adminUserData?.phone_number
    },
    {
      label: "DATE OF HIRE",
      content: (
        <Col lg="4" className="ps-0">
          <List sx={styles.list}>
            <Typography sx={styles.label} variant="caption3">
              Date Of Hire
            </Typography>
            <Typography variant="body1Regular">
              {adminUserData?.hire_date ? adminUserData.hire_date : "N/A"}
            </Typography>
          </List>
        </Col>
      ),
      hasData: !!adminUserData?.hire_date
    },
    {
      label: "ADDRESS",
      content: (
        <Col lg="4" className="ps-0">
          <List sx={styles.list}>
            <Typography sx={styles.label} variant="caption3">
              Address
            </Typography>
            <Typography variant="body1Regular">
              {adminUserData?.address ? adminUserData.address : "N/A"}
            </Typography>
          </List>
        </Col>
      ),
      hasData: !!adminUserData?.address
    }
  ]

  // Filter columns based on data availability and sort them
  const visibleColumns = columns.filter((column) => column.hasData || column.isSpecial)
  const sortedColumns = visibleColumns
    .filter((column) => !column.isSpecial)
    .sort((a, b) => b.hasData - a.hasData)
    .concat(visibleColumns.filter((column) => column.isSpecial)) // Append special columns last

  const status = adminUserData?.status
  const value =
    status !== undefined && status.toLowerCase() === "activated" ? "deactivate" : "activate"

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={open} onClick={handleClose}></Backdrop>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Change Status"
        description={`Are you sure you want to ${value} this account?`}
        btnText={`${value}`}
        onDelete={handleDelete}
        onClose={toggleActivePopup}
      />
      <Grid container>
        <Grid item xs={12}>
          <Header title="View Admin User" breadCrumbs={breadCrumbs}>
            <AppButton onClick={handleEditContest} styles={{ padding: "14px 70px" }}>
              Edit User
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Container fluid className="mt-4 mb-4 ps-0">
        <Row>
          <Col lg="4">
            <img
              src={adminUserData?.image_url ? adminUserData?.image_url : avatar}
              style={styles.userImage}
              alt=""
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        {/* First row - unchanged */}
        <Row>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                User Status
              </Typography>
              <Typography variant="body1Regular">
                {adminUserData.status ? toTitleCase(adminUserData.status) : "N/A"}
              </Typography>
            </List>
          </Col>
        </Row>
        <Row>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                User Role
              </Typography>
              <Typography variant="body1Regular">
                {adminUserData?.job_class === "PRESIDENT_N_FOUNDER"
                  ? "President & Founder"
                  : adminUserData?.job_class
                  ? splitAndMergeString(adminUserData.job_class)
                  : "N/A"}
              </Typography>
            </List>
          </Col>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Full Name
              </Typography>
              <Typography variant="body1Regular">
                {adminUserData.full_name ? toTitleCase(adminUserData.full_name) : "N/A"}
              </Typography>
            </List>
          </Col>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Email
              </Typography>
              <Typography variant="body1Regular">
                {adminUserData?.email ? adminUserData.email : "N/A"}
              </Typography>
            </List>
          </Col>
        </Row>

        {/* Subsequent rows - reordered based on data availability */}
        {sortedColumns
          .reduce((rows, column, index) => {
            const rowIndex = Math.floor(index / 3)
            if (!rows[rowIndex]) {
              rows[rowIndex] = []
            }
            rows[rowIndex].push(column.content)
            return rows
          }, [])
          .map((rowContent, index) => (
            <Row key={index}>{rowContent}</Row>
          ))}
        <Row>
          <Col lg="12" className="d-flex justify-content-start ps-0 mt-4">
            <AppButton
              onClick={toggleActivePopup}
              styles={{
                padding: "14px 70px",
                backgroundColor: `${
                  toTitleCase(adminUserData?.status) === "Activated"
                    ? "transparent"
                    : theme.palette.secondary.main
                }`,
                borderColor: `${theme.palette.secondary.main}`
              }}>
              {`${getButtonStatus(adminUserData?.status ?? "-") ?? "-"} `}
            </AppButton>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ViewAdminUser
