import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Typography } from "@mui/material"
import styles from "./QuestionTable.module.scss"
import { styled } from "@mui/system"
import TableNoRecords from "components/TableNoRecords"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "& .MuiTableCell-root": {
    padding: "14px 24px 14px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const QuestionTable = ({
  columns = [],
  isGM = false,
  rows = [],
  isCorrectAnswer,
  setModalOpen,
  setModalContent
}) => {
  const populateModal = (row, index) => {
    setModalContent({
      id: index + 1,
      question: row?.questions?.title,
      options: row?.questions?.answer
    })
    setModalOpen(true)
  }

  return (
    <Paper className={styles.container}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <StyledTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography className={styles.header} variant="body2Regular">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length ? (
              <>
                {rows.map((row, index) => {
                  return (
                    <TableRow key={row?.questions?.id}>
                      <TableCell>
                        <div
                          className={`${styles.rank} ${
                            isCorrectAnswer ? styles.green : styles.red
                          }`}>
                          {index + 1}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1Regular">
                          {row?.questions?.title ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell onClick={() => populateModal(row, index)} align="center">
                        <Typography variant="body1Regular" className={styles.correctAnswerText}>
                          Correct Answer
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1Regular">
                          {isGM
                            ? isCorrectAnswer
                              ? row?.correct_count ?? 0
                              : row?.incorrect_count ?? 0
                            : isCorrectAnswer
                            ? row?.questions?.correct_count ?? 0
                            : row?.questions?.incorrect_count ?? 0}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1Regular">{row?.players_answered ?? 0}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1Regular">
                          {isCorrectAnswer
                            ? `${row?.percentage_correct?.toFixed(0) ?? 0}%`
                            : `${row?.percentage_incorrect?.toFixed(0) ?? 0}%`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            ) : (
              <TableNoRecords colSpan={6} label="No Questions Found" />
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Paper>
  )
}

export default QuestionTable
