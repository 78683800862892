import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import AppButton from "components/StyledComponents/AppButton"
import UploadWineOfTheMonthModal from "./components/UploadWineOfTheMonthModal"
import { useDispatch } from "react-redux"
import { getWineMonthAction } from "redux/marketing/actions"
import { useSelector } from "react-redux"
import { appActions } from "redux/app/slice/app"
import { Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import { getLastDayOfMonth } from "helpers/functions"
import WineOfTheMonthScroller from "components/WineOfTheMonthScroller"
import DetailFeatureItemOfTheMonth from "components/DetailFeatureItemOfMonth"
import { marketingActions } from "redux/marketing/slice/user"
import DinnerDiningIcon from "@mui/icons-material/DinnerDining"
import EditWineOfTheMonthModal from "pages/private/marketing/wine-of-the-month/editWineOftheMonth"
import { DMVActions } from "redux/DMV/slice/user"

function WineOfTheMonth() {
  const { listWineMonthData, getSingleWineMonthData } = useSelector(
    (store) => store?.marketing?.wineOfTheMonth || {}
  )

  const divisionNumber = 3
  const theme = useTheme()
  const [index, setIndex] = React.useState(0)
  const [slicedArrays, setSlicedArrays] = useState([])
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const [isEditModal, setIsEditModal] = useState(false)
  const { date } = useSelector((store) => store?.app?.filters ?? {})
  const { featureItemId } = useSelector((store) => store?.marketing?.featureItemMonth || {})
  const alcohol_type = useSelector((store) => store?.app?.filters?.alcohol_type || {})

  const handleClose = () => {
    setModalOpen(false)
    setIsEditModal(false)
  }

  const handleEditModalClose = () => {
    setModalOpen(false)
    setIsEditModal(false)
    dispatch(DMVActions.setAlcoholType(""))
  }

  const styles = {
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    headerBtn: {
      marginRight: "5px",
      marginLeft: "15px"
    },
    navigationBtn: {
      padding: "4px 4px",
      background: theme.palette.secondary.main,
      minWidth: "0px",
      marginRight: 10,
      marginTop: 20
    }
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Featured Items of the Month"
    return () => {
      dispatch(marketingActions.setFeatureItemId(""))
      dispatch(marketingActions.setGetSingleWineMonthData({}))
    }
  }, [])

  function sliceArray() {
    const result = listWineMonthData.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / divisionNumber)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
    setSlicedArrays(result)
  }

  useEffect(() => {
    sliceArray()
  }, [listWineMonthData])

  useEffect(() => {
    const newDate = getLastDayOfMonth(date)
    dispatch(
      getWineMonthAction({
        date: newDate,
        alcohol_type
      })
    )
  }, [date, alcohol_type])

  useEffect(() => {
    dispatch(appActions.initFilters())
    dispatch(appActions.setFilters({ alcohol_type: "All" }))
  }, [])

  const componentArrays = slicedArrays?.map((array, index) => {
    return (
      <WineOfTheMonthScroller
        key={index}
        isBottomDisabled={index > slicedArrays.length - 2}
        index={index}
        isTopDisabled={index === 0}
        onPrev={() => setIndex(index - 1)}
        onNext={() => setIndex(index + 1)}
        details={array}></WineOfTheMonthScroller>
    )
  })

  function handleAddFeatureItemOftheMonth() {
    setModalOpen(true)
    setIsEditModal(false)
    dispatch(marketingActions.setTastingNotesImages([]))
  }

  return (
    <>
      <Container fluid className="ps-2 pe-2 ms-1 me-1">
        <Row>
          <Col lg="12" className="ps-0">
            <Header title="Featured Items Of The Month" breadCrumbs={breadCrumbs}>
              <AppButton onClick={handleAddFeatureItemOftheMonth} styles={styles.headerBtn}>
                + Add Featured Item Of The Month
              </AppButton>
            </Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="mt-4">
            {listWineMonthData.length === 0 ? (
              <Typography variant="body1Regular" sx={{ color: "#FFFFFF" }}>
                No Records Found
              </Typography>
            ) : (
              <>
                <Row>
                  <Col lg="5" md="6" xl="4" xxl="4">
                    {componentArrays[index]}
                  </Col>
                  <Col lg="7" md="6" xl="8" xxl="8" className="ps-5">
                    {featureItemId ? (
                      <DetailFeatureItemOfTheMonth
                        setIsEditModal={setIsEditModal}
                        isEdit={true}
                        data={getSingleWineMonthData}></DetailFeatureItemOfTheMonth>
                    ) : (
                      <div
                        className="text-center d-flex flex-column justify-content-center align-items-center"
                        style={{ marginTop: 160 }}>
                        <DinnerDiningIcon
                          className="mb-4 loadingImage"
                          sx={{
                            color: theme.palette.secondary.main,
                            fontSize: 48
                          }}></DinnerDiningIcon>
                        <Typography variant="body1Regular" sx={{ color: "#FFFFFF" }}>
                          Please select any item
                        </Typography>
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        {/* Modal */}
        {isEditModal ? (
          <EditWineOfTheMonthModal
            isEdit={isEditModal}
            onClose={handleEditModalClose}
            open={isEditModal}></EditWineOfTheMonthModal>
        ) : (
          <UploadWineOfTheMonthModal isEdit={false} open={modalOpen} onClose={handleClose} />
        )}
      </Container>
    </>
  )
}

export default WineOfTheMonth
