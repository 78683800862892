import React from "react"
import { Grid, styled, Typography } from "@mui/material"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./PhoneNumber.scss"
import { FormHelperText } from "@mui/material"

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

const PhoneNumberField = ({
  label,
  isRequired,
  style = {},
  error,
  disabled = false,
  className = "",
  onChange = () => {},
  value = "",
  country = "",
  helperText = "",
  ...props
}) => {
  return (
    <Grid container style={style} className={className}>
      {label ? (
        <StyledLabel variant="body1Regular">
          {(label ?? "-").toUpperCase()}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12}>
        <PhoneInput
          autocompleteSearch={true}
          country={country}
          value={value}
          onlyCountries={["us"]}
          // regions={['north-america', 'south-america','central-america']}
          disabled={disabled}
          onChange={onChange}
          {...props}
        />
      </Grid>
      {error ? <FormHelperText error={true}>{helperText}</FormHelperText> : null}
    </Grid>
  )
}

export default PhoneNumberField
