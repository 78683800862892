import { styled, Switch } from "@mui/material"
import React from "react"

const ToggleSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "41.14px",
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "1.71px",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.palette.text.primary,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "21.47px",
    height: "20.57px"
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: theme.palette.text.secondary,
    opacity: 1
  }
}))

export default ToggleSwitch
