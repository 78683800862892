import React, { useState } from "react"
import { Box, Collapse, TableCell, TableRow, Typography, useTheme } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import styles from "../../PendingQuestionTable/PendingQuestionTable.module.scss"
import Question from "./Question"
import SuggestedBy from "./SuggestedBy"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import { useDispatch } from "react-redux"
import { editTriviaDeleteQuestionAction } from "redux/L&D/actions"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import ImageDisplay from "components/ImageDisplayer"

const QuestionRow = ({ row, index, contestData, isEdit, isIncorrect }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const theme = useTheme()
  const dispatch = useDispatch()
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)

  const getStatusColor = () => {
    switch (row?.status?.toLowerCase()) {
      case "pending":
        return { color: theme.palette.orange.main }
      default:
        return { color: theme.palette.green.main }
    }
  }

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen)
  }

  const handleDeleteQuestion = () => {
    if (row.id) {
      dispatch(editTriviaDeleteQuestionAction(row.id))
    }
  }

  const checkStatus = () => {
    if (contestData?.status?.toLowerCase() === "published") {
      const date = new Date(contestData?.start_date)
      return date > new Date()
    }
    return true
  }

  return (
    <>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Question"
        description="Are you sure you want to delete this question?"
        onDelete={handleDeleteQuestion}
        onClose={toggleDeletePopup}
      />
      <TableRow key={row.id}>
        <TableCell>
          <div className={styles.serialNo}>{index + 1}</div>
        </TableCell>
        <TableCell style={{ cursor: "pointer" }} onClick={() => setIsExpanded(!isExpanded)}>
          <div className="d-flex align-items-center">
            <Typography variant="body1Regular" className="me-2">
              {row?.title ?? "-"}
            </Typography>
            <span>
              {isExpanded ? (
                <KeyboardArrowUpIcon sx={{ width: "17.6px" }} />
              ) : (
                <KeyboardArrowDownIcon
                  className={`${!row?.suggested_by == "-" ? "d-none" : "d-inline-block"}`}
                  sx={{ width: "17.6px" }}
                />
              )}
            </span>
          </div>
        </TableCell>
        <TableCell align="center">
          {row?.suggested_by ? (
            <Typography variant="body1Regular" style={getStatusColor()}>
              {row?.status ?? "-"}
            </Typography>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="center">
          <Typography variant="body1Regular">{row?.category}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography align="center" variant="body1Regular">
            {row?.level}
          </Typography>
        </TableCell>
        {checkStatus() ? (
          <TableCell align="center">
            <span role="button" className="ms-1" onClick={toggleDeletePopup}>
              <DeleteOutlinedIcon
                color="red"
                sx={{
                  height: 30,
                  width: 30
                }}
              />
            </span>
          </TableCell>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell className={styles.expandableRow} colSpan={6}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box className={styles.questionCtr}>
              <Question row={row} index={index} isEdit={isEdit} isIncorrect={isIncorrect} />
              <div className="d-flex">
                <div
                  className="p-2 d-flex align-items-center justify-content-center"
                  style={{ width: "200px", height: "auto" }}>
                  {row?.image ? (
                    <img className="img-fluid" style={{ borderRadius: "6px" }} src={row?.image} />
                  ) : (
                    <div
                      className="p-4"
                      style={{ border: "1px solid", borderRadius: "8px", width: "250px" }}>
                      <ImageDisplay />
                    </div>
                  )}
                </div>
                <SuggestedBy row={row} index={index} />
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default QuestionRow
