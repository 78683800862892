import { Typography } from "@mui/material"
import Card from "components/Card/Card"
import GaugeChart from "components/GaugeChart/gaugeChart"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
// import PerformanceManagementTable from "./components/Table/performanceManagementTable";
import classes from "./percentGoal.module.scss"
import PercentGoalTable from "./PercentGoalTable"

const ExecutivePercentGoal = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Performance Management"
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title="% Of Goal" breadCrumbs={breadCrumbs}></Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className={classes.gaugeBackground}>
              <GaugeChart headingTitle="% of improved" isHeading={false}></GaugeChart>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card className={classes.heading}>
              <Typography variant="h2">% Of Goal Achieved </Typography>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <PercentGoalTable rows={rows} columns={columns} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ExecutivePercentGoal

const columns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Restaurant",
    label: "Restaurant",
    minWidth: 220
  },
  {
    id: "% of Goal Achieved",
    label: "% of Goal Achieved",
    align: "center",
    minWidth: 170
  },
  {
    id: "% Improved",
    label: "% Improved",
    align: "center",
    minWidth: 170
  },
  {
    id: "Improved Candidates",
    label: "Improved Candidates",
    align: "center",
    minWidth: 170
  },
  {
    id: "Total Candidates ",
    label: "Total Candidates ",
    align: "center",
    minWidth: 100
  }
]

const rows = [
  {
    id: 1,
    rank: 1,
    restaurant: "Tampa",
    percentGoal: 133.3,
    percentImproved: 83,
    improvedCandidates: 10,
    totalCandidates: 12
  },
  {
    id: 2,
    rank: 2,
    restaurant: "Waterford Lakes",
    percentGoal: 101,
    percentImproved: 91.6,
    improvedCandidates: 11,
    totalCandidates: 12
  },
  {
    id: 3,
    rank: 3,
    restaurant: "Naples",
    percentGoal: 95,
    percentImproved: 90,
    improvedCandidates: 10,
    totalCandidates: 11
  },
  {
    id: 4,
    rank: 4,
    restaurant: "Sarasota",
    percentGoal: 76,
    percentImproved: 90,
    improvedCandidates: 10,
    totalCandidates: 11
  },
  {
    id: 5,
    rank: 5,
    restaurant: "Doral",
    percentGoal: 60,
    percentImproved: 90,
    improvedCandidates: 9,
    totalCandidates: 10
  },
  {
    id: 6,
    rank: 6,
    restaurant: "Jacksonville",
    percentGoal: 50,
    percentImproved: 40,
    improvedCandidates: 4,
    totalCandidates: 10
  },
  {
    id: 7,
    rank: 7,
    restaurant: "St. Peters",
    percentGoal: 48,
    percentImproved: 40,
    improvedCandidates: 4,
    totalCandidates: 10
  },
  {
    id: 8,
    rank: 8,
    restaurant: "Coconut Creek",
    percentGoal: 45,
    percentImproved: 40,
    improvedCandidates: 4,
    totalCandidates: 10
  },
  {
    id: 9,
    rank: 9,
    restaurant: "Orlando",
    percentGoal: 40,
    percentImproved: 40,
    improvedCandidates: 4,
    totalCandidates: 10
  },
  {
    id: 10,
    rank: 10,
    restaurant: "Indianapolis",
    percentGoal: 38,
    percentImproved: 40,
    improvedCandidates: 4,
    totalCandidates: 10
  }
]
