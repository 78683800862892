import React, { useState } from "react"
import classes from "pages/private/marketing/menu/menu.module.scss"
import { Menu, MenuItem, Typography } from "@mui/material"
import ImageDisplay from "components/ImageDisplayer"
import { useTheme } from "@mui/system"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useDispatch } from "react-redux"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import Paragraph from "components/ReadMoreParagraph"
import { DMVActions } from "redux/DMV/slice/user"
import { deleteDMVBeverageMenuItemAction, getDMVBeverageItemsAction } from "redux/DMV/actions/user"

function DMV_BeverageMenuListItems({
  foodList,
  isEdit,
  categoryIdData,
  setMenuItemsModal = () => {},
  setIsEditModalItem = () => {}
}) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event, id, categoryId) => {
    setAnchorEl(event.currentTarget)
    dispatch(DMVActions.setDMVItemID(id))
    dispatch(DMVActions.setDMVMenuCategoryId(categoryId))
    dispatch(getDMVBeverageItemsAction())
  }

  function handleMenuItemDelete() {
    dispatch(deleteDMVBeverageMenuItemAction())
  }

  function handleMenuDeleteModal() {
    handleClose()
    setDeleteModal(true)
    setAnchorEl(null)
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(DMVActions.setDMVItemID(""))
  }

  function handleEditMenuItemModal() {
    setAnchorEl(null)
    setIsEditModalItem(true)
    setMenuItemsModal(true)
  }

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this beverage ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <div
        className={classes.itemContainer}
        style={{
          border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
          borderRadius: `${isEdit ? "6px" : "0px"}`,
          padding: `${isEdit ? "8px" : "0px"}`
        }}>
        <div className={classes.imageContainer}>
          <ImageDisplay data={foodList?.image_url}></ImageDisplay>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: 375,
            overflowWrap: "break-word"
          }}>
          <div>
            <Typography variant="h4">{foodList?.name ?? "-"}</Typography>
          </div>

          <div>
            <div className="mt-2">
              <Typography variant="tag1">{"Description:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.description ?? "-"}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Region:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.origin_name ?? "-"}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Unique Facts:"}</Typography>
              <br></br>
              {foodList?.unique_facts?.map((item, index) => (
                <Typography
                  style={{ display: item?.fact ? "list-item" : "", marginLeft: 15 }}
                  key={index}
                  variant="tagItalic"
                  sx={{ opacity: 0.6 }}>
                  <Paragraph
                    text={item?.fact ?? ""}
                    maxCharCount={70}
                    key={index}
                    variant="tagItalic"
                    sx={{ opacity: 0.6 }}></Paragraph>
                </Typography>
              ))}
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Ingredients:"}</Typography>
              <br></br>
              {foodList?.ingredient?.map((item, index) => (
                <Typography key={index} variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {`${item?.name ?? ""}`}
                  {item?.measurement
                    ? foodList?.ingredient?.length - 1 > index
                      ? ` (${item?.measurement})`
                      : item?.measurement
                      ? ` (${item?.measurement}) `
                      : ""
                    : foodList?.ingredient?.length - 1 > index
                    ? ", "
                    : ""}
                </Typography>
              ))}
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Methodology:"}</Typography>
              <br></br>
              {foodList?.methodology?.map((item, index) => (
                <Typography key={index} variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {`${item?.name ?? ""}`}
                  {item?.step
                    ? foodList?.methodology?.length - 1 > index
                      ? ` (${item?.step}), `
                      : item?.step
                      ? ` (${item?.step}) `
                      : ""
                    : foodList?.methodology?.length - 1 > index
                    ? ", "
                    : ""}
                </Typography>
              ))}
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"How It's made:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {<Paragraph text={foodList?.how_its_made ?? ""} maxCharCount={70}></Paragraph>}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Taste Profile:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {<Paragraph text={foodList?.taste_profile ?? ""} maxCharCount={70}></Paragraph>}
              </Typography>
            </div>
          </div>
        </div>
        {isEdit && (
          <div>
            <MoreVertIcon
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleClick(e, foodList?.id, categoryIdData)}
              sx={{ cursor: "pointer" }}></MoreVertIcon>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}>
              <MenuItem
                onClick={() => handleEditMenuItemModal()}
                sx={{ width: "150px", color: theme.palette.secondary.main }}>
                Edit
              </MenuItem>
              <MenuItem onClick={handleMenuDeleteModal} sx={{ color: theme.palette.red.main }}>
                Delete
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </>
  )
}

export default DMV_BeverageMenuListItems
