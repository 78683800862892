import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import { LDReducers } from "./L&D/slice"
import { appReducers } from "./app/slice"
import L_D_actionWatcher from "./L&D/sagas"
import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
import "../interceptor"
import { userReducers } from "./user"
import { GMReducers } from "./GM/slice"
import { all } from "redux-saga/effects"
import GMActionWatcher from "./GM/sagas"
import { DMVReducers } from "./DMV/slice"
import DMVActionWatcher from "./DMV/sagas"
import { marketingReducers } from "./marketing/slice"
import marketingWatcher from "./marketing/sagas"
import { superAdminReducers } from "./superAdmin/slice"
import superAdminWatcher from "./superAdmin/sagas"

const combinedReducers = combineReducers({
  ...LDReducers,
  ...appReducers,
  ...userReducers,
  ...GMReducers,
  ...DMVReducers,
  ...marketingReducers,
  ...superAdminReducers
})

const persistConfig = {
  key: `${process.env.REACT_APP_API_MY_URL}`,
  storage
}

function* sagas() {
  yield all([
    L_D_actionWatcher(),
    GMActionWatcher(),
    DMVActionWatcher(),
    marketingWatcher(),
    superAdminWatcher()
  ])
}

const sagaMiddleware = createSagaMiddleware()
const persistedReducer = persistReducer(persistConfig, combinedReducers)

const middleware = [
  ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
  sagaMiddleware
]

const store = configureStore({
  reducer: persistedReducer,
  middleware
})

sagaMiddleware.run(sagas)

export default store
