import { styled } from "@mui/material"
import Card from "components/Card/Card"
import React from "react"

const StyledCard = styled(Card)(() => ({
  padding: 12,
  borderRadius: 6,
  border: "0px",
  borderLeft: "6px",
  borderStyle: "solid",
  flex: "1 1 0px",
  margin: "0px 6px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 12
}))

const InfoCard = ({ color = "transparent", children, style }) => {
  return <StyledCard style={{ borderColor: color, ...style }}>{children}</StyledCard>
}

export default InfoCard
