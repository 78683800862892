import { Grid, Typography } from "@mui/material"
import React from "react"
import styles from "./CategoryStyle.module.scss"
import { useSelector } from "react-redux"

const CategoryCard = ({ title, category, count }) => {
  const categorySelected = useSelector((store) => store?.app?.filters?.category || [])
  const isIncluded = categorySelected.length === 7 ? true : categorySelected.includes(title)
  return (
    <div style={{ position: "relative", display: "flex" }}>
      <Grid item className={`${styles.container} ${isIncluded && styles.withBoxShadow}`}>
        <Typography variant="body1Regular" color="text.secondary" sx={{ padding: "4px 16px" }}>
          {title}
        </Typography>
        {category !== "ALL" && (
          <Grid container className={styles.cat_container}>
            <Typography variant="emphasisText" color="white.main" letterSpacing="normal">
              {category}
            </Typography>
          </Grid>
        )}
        {/* {count ? ( */}
        <Grid item className={styles.ellipse_spirit_gold}>
          {count ?? 0}
        </Grid>
        {/* ) : null} */}
      </Grid>
    </div>
  )
}

export default CategoryCard
