import React from "react"
import { List, ListItem, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Card from "components/Card/Card"
import ListItems from "./components/listItems"

const WineOfTheMonth = ({ tableRowData = [], routeNext }) => {
  const history = useHistory()
  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem sx={{ justifyContent: "space-between", padding: "0 0 12px 0" }}>
          <Typography variant="h2">Wine Of The Month</Typography>
          <span onClick={() => history.push(routeNext)}>
            <IconButtonWithBorder aria-label="Wine Of The Month">
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>
        {}
        <ListItems tableData={tableRowData} />
      </List>
    </Card>
  )
}

export default WineOfTheMonth

// const TABLE_DATA = [
//   {
//     id: 0,
//     key: "April",
//     field: "Abrillante"
//   },
//   {
//     id: 1,
//     key: "March",
//     field: "Red & White Blends"
//   },
//   {
//     id: 2,
//     key: "February",
//     field: "Chilean Cabernet Sauvignon"
//   },
//   {
//     id: 3,
//     key: "January",
//     field: "Rio de ouro"
//   },
//   {
//     id: 3,
//     key: "January",
//     field: "Rio de ouro"
//   }
// ];
