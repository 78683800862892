import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Typography } from "@mui/material"
import styles from "./QuestionTable.module.scss"
import { styled } from "@mui/system"
import TableNoRecords from "components/TableNoRecords"
import AppPagination from "components/StyledComponents/AppPagination"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { useState } from "react"
import { appActions } from "redux/app/slice/app"
import { useDispatch } from "react-redux"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "& .MuiTableCell-root": {
    padding: "14px 24px 14px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const AllTriviaQuestionTable = ({
  columns = [],
  rows = [],
  setModalOpen,
  setModalContent,
  setPage,
  currentPage,
  totalPage,
  itemsPerPage = 12
}) => {
  const dispatch = useDispatch()
  const populateModal = (row, index) => {
    setModalContent({
      id: index + 1,
      question: row?.questions?.title,
      options: row?.questions?.answer
    })
    setModalOpen(true)
  }

  const [sort, setSort] = useState("")
  const [sortDirection, setSortDirection] = useState("")

  const handleSortDirection = (dir, id) => {
    setSortDirection(dir)
    handleSort(dir, id)
  }

  const handleSort = (dir, id) => {
    if (dir === "asc") {
      setSort(id)
      dispatch(appActions.setOrderBy(id))
    } else {
      setSort(`-${id}`)
      dispatch(appActions.setOrderBy(`-${id}`))
    }
  }

  React.useEffect(() => {}, [sort, sortDirection])

  return (
    <Paper className={styles.container}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <StyledTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography className={styles.header} variant="body2Regular">
                    {column.label}
                    {column?.isSorting && (
                      <span style={{ cursor: "pointer" }}>
                        {sortDirection === "desc" && sort === `-${column?.sortId}` ? (
                          <ArrowDownwardIcon
                            onClick={() => handleSortDirection("asc", column?.sortId)}
                            fontSize="small"
                          />
                        ) : (
                          <ArrowUpwardIcon
                            onClick={() => handleSortDirection("desc", column?.sortId)}
                            fontSize="small"
                          />
                        )}
                      </span>
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length ? (
              rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div className={styles.rank}>
                      {currentPage * itemsPerPage - (itemsPerPage - 1) + index}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1Regular">{row?.questions?.title ?? "-"}</Typography>
                  </TableCell>
                  <TableCell onClick={() => populateModal(row, index)} align="center">
                    <Typography variant="body1Regular" className={styles.correctAnswerText}>
                      Correct Answer
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1Regular">{row?.players_answered ?? 0}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1Regular">{row?.number_correct ?? 0}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1Regular">{row?.number_incorrect ?? 0}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1Regular">
                      {row?.percentage_correct?.toFixed(0) ?? 0}%
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1Regular">
                      {row?.percentage_incorrect?.toFixed(0) ?? 0}%
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableNoRecords colSpan={6} label="No Questions Found" />
            )}
          </TableBody>
        </StyledTable>
        {rows?.length ? (
          <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
        ) : (
          ""
        )}
      </TableContainer>
    </Paper>
  )
}

export default AllTriviaQuestionTable
