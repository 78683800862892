import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React from "react"
import styles from "../../Gamification/GamificationSummaryTable/GamificationSummaryTable.module.scss"
import avatar from "assets/images/user/avatarUser.svg"
import AppPagination from "components/StyledComponents/AppPagination"
import TableNoRecords from "components/TableNoRecords"
import { getRankColor } from "helpers/functions"
import ProfileDisplayer from "components/ProfileDisplayer"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid ${theme.palette.background.default}`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    padding: "24px 24px 12px 0px",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const QuizSummaryTable = ({
  columns = [],
  rows = [],
  setPage,
  currentPage,
  totalPage,
  activeToggle
}) => {
  const formatValue = (value) => {
    if (activeToggle === "percentage") {
      return `${value}%`
    } else if (activeToggle === "time") {
      return `${value}s`
    }
    return value
  }

  return (
    <>
      <Paper className={styles.tableContainer}>
        <TableContainer>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!rows?.length ? (
                <TableNoRecords colSpan={9} label="No Data Found" />
              ) : (
                rows?.map((row, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>
                        <div className={styles.rank} style={{ borderColor: getRankColor(row) }}>
                          {row.rank}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={`${styles.tableAvatar}`}>
                          <div
                            className={`${styles.tableAvatar__img} ${
                              row.rank === 1 && styles.borderImg
                            }`}>
                            {row?.image_url ? (
                              <ProfileDisplayer image={row?.image_url} />
                            ) : (
                              <img src={avatar} />
                            )}
                          </div>
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                              <Typography className="me-2" variant="body2Regular">
                                {row?.name}
                              </Typography>
                              {row.isBiggestOpportunityServer ? <span role="button"></span> : null}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{formatValue(row?.Food)}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {formatValue(row?.["Wine Region"])}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{formatValue(row?.Beverage)}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{formatValue(row?.Special)}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{formatValue(row?.over_all)}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {formatValue(row?.cumulative)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
      </Paper>
      <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
    </>
  )
}

export default QuizSummaryTable
