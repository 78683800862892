import React from "react"
import Card from "components/Card/Card"
import { Box, Dialog, styled, IconButton, Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import CloseIcon from "@mui/icons-material/Close"
import UploadMenu from "./UploadMenu"

function UploadAddMenuModal({ open, onClose }) {
  const theme = useTheme()

  const styles = {
    card: {
      margin: 0
    },
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center"
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 3,
      opacity: 1,
      position: "relative",
      top: "12px",
      left: "-23%",
      width: "150%"
    },
    mainDivider: {
      backgroundColor: theme.palette.background.main,
      opacity: 1,
      marginTop: "10px"
    },
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    },
    anchor: {
      color: theme.palette.secondary.main
    }
  }

  const StyledDialog = styled(Dialog)(() => ({
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      maxWidth: "500px"
    }
  }))

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <Card style={styles.card}>
        <Box>
          <Typography variant="h3">Bulk Upload</Typography>
        </Box>
        <IconButton onClick={onClose} sx={styles.closeBtn}>
          <CloseIcon sx={{ height: "14px", width: "14px" }} />
        </IconButton>
        <Box>
          <UploadMenu onClose={onClose}></UploadMenu>
        </Box>
      </Card>
    </StyledDialog>
  )
}

export default UploadAddMenuModal
