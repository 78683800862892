import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { makeStyles } from "@material-ui/core/styles"
import { Tooltip, Typography } from "@mui/material"
import crown from "assets/icons/crown.svg"
import "./LeaderboardTable.scss"
import InfoIcon from "@mui/icons-material/Info"
import avatar from "assets/images/user/avatarUser.svg"
import AppPagination from "components/StyledComponents/AppPagination"
import TableNoRecords from "components/TableNoRecords"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    "& .MuiPaper-root": {
      backgroundColor: "green"
    },
    "& .MuiTableRow-hover": {
      backgroundColor: theme.palette.background.main
    },
    "& .MuiTableCell-root": {
      padding: "16px 16px",
      backgroundColor: theme.palette.background.main,
      borderBottom: "1px solid #353535"
    }
  }
}))

const LeaderBoardTable = ({ columns, rowsData = [], setPage, currentPage, totalPage }) => {
  const classes = useStyles()

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table className={classes.root} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      paddingRight: i === columns.length - 1 ? "0" : "auto"
                    }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}{" "}
                      {column?.tooltip && (
                        <Tooltip title={column.tooltip}>
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsData?.length ? (
                rowsData.map((row, i) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell
                        sx={{
                          paddingRight: i === columns.length - 1 ? "0" : "auto"
                        }}>
                        <span
                          style={{
                            border: "2px solid #3ADB76",
                            borderRadius: 6,
                            padding: "12px 10px"
                          }}>
                          {row.rank}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className="tableAvatar">
                          <div className="tableAvatar__img">
                            {
                              <img
                                className={`${
                                  row.rank === 1 && "borderImg"
                                } tableAvatar__img__profile`}
                                src={row.image ? row.image : avatar}
                              />
                            }
                          </div>
                          <div className="d-flex flex-column">
                            <Typography
                              variant="body2Regular"
                              className="text-ellipsis"
                              sx={{ maxWidth: "550px" }}>
                              {row.name}
                            </Typography>
                            {row.rank === 1 && (
                              <div className="mt-1 d-flex" style={{ color: "#E67C1B" }}>
                                <img width={12} className="me-2" height={12} src={crown} alt="" />
                                <Typography variant="tag1">Highest Ranked</Typography>
                              </div>
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">{row.correctAnswers}</TableCell>
                      {/* <TableCell align="center">{row.signUp_Upgrades}</TableCell> */}
                      <TableCell align="center">{row.coins}</TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableNoRecords colSpan={6} label="No Data Found" />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {rowsData?.length ? (
        <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
      ) : (
        ""
      )}
    </>
  )
}

export default LeaderBoardTable
