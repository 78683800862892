import { Box, Dialog, DialogContent, styled, Typography } from "@mui/material"
import CustomDivider from "components/StyledComponents/Divider"
import moment from "moment"
import React from "react"
import { useSelector } from "react-redux"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 12,
    border: "none",
    width: 500,
    padding: 35,
    boxSizing: "border-box"
  }
}))

function isDecimal(data) {
  const num = Number(data)
  if (isNaN(num)) return
  if (num % 1) {
    return true
  }
  return false
}
function calculateDecimal(num) {
  const value = Number(num)

  if (value % 1 > 0.5) {
    return Math.ceil(value)
  } else {
    return Math.floor(value)
  }
}
const TriviaStatsModal = ({ open, setOpen = () => {}, triviaStats = {} }) => {
  const { date } = useSelector((store) => store?.app?.filters || "")

  return (
    <StyledDialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2">
            {`Trivia Analytics for ${moment(date).format("MMMM")} (Month to Date)`}{" "}
          </Typography>
        </Box>
        <Box flexDirection="column">
          {Object.keys(dataLabels).map((key) => (
            <React.Fragment key={key}>
              <Box className="d-flex justify-content-between w-100 py-2">
                <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
                  {dataLabels[key] || "-"}
                </Typography>
                <Typography variant="body1Regular">
                  {`${
                    isDecimal(triviaStats?.[key])
                      ? calculateDecimal(triviaStats?.[key])
                      : Number(triviaStats?.[key]) || "-"
                  }${
                    typeof triviaStats?.[key] === "string"
                      ? triviaStats?.[key] === "0.00"
                        ? ""
                        : "%"
                      : ""
                  }`}
                </Typography>
              </Box>
              <CustomDivider />
            </React.Fragment>
          ))}
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default TriviaStatsModal

const dataLabels = {
  // questions_attempted: "Questions Attempted",
  total_attempts: "Players Answered",
  total_correct: "Total Correct Answers",
  total_incorrect: "Total Incorrect Answers",
  percentage_correct: "% Correct Answered",
  percentage_incorrect: "% Incorrect Answered"
}
