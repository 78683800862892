import { Box, Grid, InputBase, styled, Typography } from "@mui/material"
import React from "react"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import { FormHelperText } from "@mui/material"

const Container = styled(Box)(({ theme }) => ({
  height: 48,
  width: 202,
  backgroundColor: theme.palette.background.main,
  borderRadius: 6,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .icon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& svg": {
      height: 24,
      width: 24,
      margin: "0px 10px"
    }
  },
  "& .countDisplay": {
    backgroundColor: "#212121",
    width: 88,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5
  }
}))

const StyledInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    textAlign: "center"
  }
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

const IncDecField = ({
  count = 1,
  onChange = () => {},
  label,
  maxLimit,
  error,
  touched,
  isRequired,
  ...props
}) => {
  const onInc = () => {
    if (!count) {
      return onChange(1)
    }
    onChange(parseInt(count) + 1)
  }

  const onDec = () => {
    if (count <= 1) {
      return onChange(1)
    }
    if (count) {
      onChange(count - 1)
    }
  }

  const handleInput = ({ target: { value } }) => {
    if (value < 0) {
      return
    }
    onChange(value)
  }

  const handleBlur = () => {
    if (!count || count < 1) {
      onChange(1)
    }
  }

  return (
    <Grid container {...props}>
      {label ? (
        <StyledLabel variant="body1Regular">
          {label}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12}>
        <Container>
          <span onClick={onDec} className="icon">
            <RemoveIcon />
          </span>
          <div className="countDisplay">
            <StyledInput
              value={count}
              fullWidth
              type="number"
              max={maxLimit}
              onChange={handleInput}
              onBlur={handleBlur}
              inputProps={{ min: 0, max: 10 }}
            />
          </div>

          <span className="icon" onClick={onInc}>
            <AddIcon />
          </span>
        </Container>
        {error && touched ? <FormHelperText error={true}>{error}</FormHelperText> : null}
      </Grid>
    </Grid>
  )
}

export default IncDecField
