import { Box, styled, Typography } from "@mui/material"
import Card from "components/Card/Card"
import React from "react"

const StyledContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  "& .card1": {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginBottom: 2
  },
  "& .card2": {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: 0
  }
}))

const ListContainer = styled(Box)(() => ({
  width: "100%",
  marginTop: 38,
  "& .innerCtr": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 0px",
    borderBottom: "2px solid #3c3c3c",
    "& span": {
      opacity: 0.6
    }
  }
}))

const ListView = ({ list = [] }) => {
  return (
    <ListContainer>
      {list.map((item, index) => (
        <Box className="innerCtr" key={`${item.label + index}`}>
          <Typography variant="body1Regular">{item.label}</Typography>
          <Typography variant="body1Regular">{item.value}</Typography>
        </Box>
      ))}
    </ListContainer>
  )
}

const MembershipsCards = () => {
  return (
    <StyledContainer>
      <Card className="card1">
        <Typography variant="h2">Membership Invite Opportunity</Typography>
        <ListView list={list1} />
      </Card>
      <Card className="card2">
        <Typography variant="h2">Membership Invite Opportunity</Typography>
        <ListView list={list2} />
      </Card>
    </StyledContainer>
  )
}

export default MembershipsCards

const list1 = [
  {
    label: "Monthly Invite Opportunity",
    value: "530"
  },
  {
    label: "Annualized Opportunity	",
    value: "6,360"
  },
  {
    label: "Annual Revenue / Member	",
    value: "$800"
  },
  {
    label: "Lost Revenue Opportunity",
    value: "$5,088,000"
  }
]

const list2 = [
  {
    label: "Monthly Upgrade Opportunity	",
    value: "229"
  },
  {
    label: "Annualized Opportunity	",
    value: "2,753"
  },
  {
    label: "Annual Revenue / Member	",
    value: "$400"
  },
  {
    label: "Lost Revenue Opportunity",
    value: "$1,101,164"
  }
]
