import { Box, Grid, Typography, useTheme } from "@mui/material"
import AppDatePicker from "components/AppDatePicker"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import AppButton from "components/StyledComponents/AppButton"
import AppSelect from "components/StyledComponents/AppSelect"
import AppTextArea from "components/StyledComponents/AppTextArea"
import AppTextField from "components/StyledComponents/AppTextField"
import CustomDivider from "components/StyledComponents/Divider"
import StyledLabel from "components/StyledComponents/StyledLabel"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { uploadNotificationImageAction } from "redux/superAdmin/actions/user"

const EVENTTYPE = [
  {
    label: "Online",
    value: "Online"
  },
  {
    label: "Offline",
    value: "Offline"
  }
]

const EventForm = ({ formik, btnText }) => {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)
  const dispatch = useDispatch()

  const handleImageDelete = (formik) => {
    formik.setFieldValue("uploadThumbnail", null)
  }
  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("uploadThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "uploadThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("uploadThumbnail", image)
      dispatch(uploadNotificationImageAction(data))
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "uploadThumbnail" && 1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <React.Fragment>
      {showCropperModal(imageObjectURL, fileDetails, formik)}
      <Container fluid className="mt-2">
        <Row>
          <Col lg="4">
            <AppTextField
              id="name"
              name="name"
              fullWidth
              type="text"
              label="Event Name"
              isRequired={true}
              isUpperCase={!false}
              placeholder="Enter Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              backgroundColor="transparent"
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4">
            <Grid item sm={12} lg={6} className="datesContainer">
              <StyledLabel variant="body1Regular">
                {"Event Date"}
                <span>*</span>
              </StyledLabel>
              <Box className="dateFieldContainer w-100 mt-2">
                <AppDatePicker
                  minDate={new Date()}
                  maxDate={new Date().setMonth(new Date().getMonth() + 5)}
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  key={"startDate"}
                  value={formik.values.startDate}
                  onChange={(val) => formik.setFieldValue("startDate", val)}
                  error={formik.errors.startDate}
                  touched={formik.touched.startDate}
                />
              </Box>
            </Grid>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="4">
            <AppTextField
              type="time"
              fullWidth
              label="Start Time"
              isRequired={true}
              isUpperCase={true}
              value={formik.values.startTime}
              onChange={formik.handleChange("startTime")}
              backgroundColor="transparent"
              error={formik.touched.startTime && Boolean(formik.errors.startTime)}
              helperText={formik.touched.startTime && formik.errors.startTime}
            />
          </Col>
          <Col lg="4">
            <AppTextField
              type="time"
              fullWidth
              label="End Time"
              isRequired={true}
              isUpperCase={true}
              value={formik.values.endTime}
              onChange={formik.handleChange("endTime")}
              backgroundColor="transparent"
              error={formik.touched.endTime && Boolean(formik.errors.endTime)}
              helperText={formik.touched.endTime && formik.errors.endTime}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="4">
            <Grid item sm={12} lg={6} className="datesContainer">
              <StyledLabel variant="body1Regular">
                {"Deadline for RSVP (Day)"}
                <span>*</span>
              </StyledLabel>
              <Box className="dateFieldContainer w-100 mt-2">
                <AppDatePicker
                  minDate={new Date()}
                  maxDate={new Date().setMonth(new Date().getMonth() + 4)}
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  key={"rsvpDate"}
                  value={formik.values.rsvpDate}
                  onChange={(val) => formik.setFieldValue("rsvpDate", val)}
                  error={formik.errors.rsvpDate}
                  touched={formik.touched.rsvpDate}
                />
              </Box>
            </Grid>
          </Col>
          <Col lg="4">
            <AppTextField
              type="time"
              fullWidth
              label="Deadline for RSVP (Time)"
              isRequired={true}
              isUpperCase={true}
              value={formik.values.rsvpTime}
              onChange={formik.handleChange("rsvpTime")}
              backgroundColor="transparent"
              error={formik.touched.rsvpTime && Boolean(formik.errors.rsvpTime)}
              helperText={formik.touched.rsvpTime && formik.errors.rsvpTime}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="4">
            <AppSelect
              id="eventType"
              name="eventType"
              menuItems={EVENTTYPE}
              label="Event Type"
              fullWidth
              isRequired={true}
              value={formik.values?.eventType}
              onChange={formik.handleChange}
              error={formik.errors.eventType}
              touched={formik.touched.eventType}
            />
          </Col>
          <Col lg="4">
            {formik.values?.eventType !== "Online" ? (
              <AppTextField
                id="address"
                name="address"
                fullWidth
                type="text"
                label="Address"
                isRequired={true}
                isUpperCase={!false}
                placeholder="Enter Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                backgroundColor="transparent"
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            ) : (
              <AppTextField
                id="eventLink"
                name="eventLink"
                fullWidth
                type="text"
                label="Event Link"
                isRequired={true}
                isUpperCase={!false}
                placeholder="Enter Event Link"
                value={formik.values.eventLink}
                onChange={formik.handleChange}
                backgroundColor="transparent"
                error={formik.touched.eventLink && Boolean(formik.errors.eventLink)}
                helperText={formik.touched.eventLink && formik.errors.eventLink}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="8">
            <AppTextArea
              label="Description"
              minRows={3}
              maxRows={15}
              isRequired={true}
              placeholder="Enter Description"
              value={formik.values.description}
              onChange={formik.handleChange("description")}
              error={formik.errors?.description}
              touched={formik.touched?.description}
              charLimit={100}
            />
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <CustomDivider />
        </Row>
        <Row className="mb-4">
          <Col lg="8">
            <Typography variant="caption2" color={theme.palette.secondary.main}>
              Notification
            </Typography>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="4">
            <Grid item sm={12} lg={6} className="datesContainer">
              <StyledLabel variant="body1Regular">
                {"Schedule Notification Date"}
                <span>*</span>
              </StyledLabel>
              <Box className="dateFieldContainer w-100 mt-2">
                <AppDatePicker
                  minDate={new Date()}
                  maxDate={formik.values.startDate}
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  key={"notificationDate"}
                  value={formik.values.notificationDate}
                  onChange={(val) => formik.setFieldValue("notificationDate", val)}
                  error={formik.errors.notificationDate}
                  touched={formik.touched.notificationDate}
                />
              </Box>
            </Grid>
          </Col>
          <Col lg="4">
            <AppTextField
              type="time"
              fullWidth
              label="Schedule Notification Time"
              isRequired={true}
              isUpperCase={true}
              value={formik.values.notificationTime}
              onChange={formik.handleChange("notificationTime")}
              backgroundColor="transparent"
              error={formik.touched.notificationTime && Boolean(formik.errors.notificationTime)}
              helperText={formik.touched.notificationTime && formik.errors.notificationTime}
            />
          </Col>
          <Col lg="4">
            <ImageUploadField
              label="Upload Thumbnail"
              isVariant={true}
              placeholder="Upload Image"
              value={formik.values.uploadThumbnail}
              onChange={(e) => onImageUpload(e)}
              onDelete={() => handleImageDelete(formik)}
              error={formik.errors.uploadThumbnail}
              touched={formik.touched.uploadThumbnail}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="8">
            <AppTextArea
              label="Notification Content"
              minRows={3}
              maxRows={15}
              isRequired={true}
              placeholder="Enter Notification Content"
              value={formik.values.notificationContent}
              onChange={formik.handleChange("notificationContent")}
              error={formik.errors?.notificationContent}
              touched={formik.touched?.notificationContent}
              charLimit={100}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="12">
            <AppButton
              onClick={formik.handleSubmit}
              style={{ padding: "14px 40px", backgroundColor: theme.palette.secondary.main }}>
              {btnText}
            </AppButton>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default EventForm
