import React, { useEffect, useState } from "react"
import Header from "components/Header/Header"
import { Container, Row } from "react-bootstrap"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import ListItems from "components/AdditionalResourcesComponents/ListItems"
import AdditionalResource1 from "assets/images/AdditionalResources/AdditionalResource1.png"
import AdditionalResource2 from "assets/images/AdditionalResources/AdditionalResource2.png"

function AdditionalResourcesPage() {
  const [breadCrumbs, setBreadcrumbs] = useState([])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
  }, [])

  return (
    <Container fluid className="p-0">
      <Row>
        <Header title="Additional Resources" back={true} breadCrumbs={breadCrumbs} />
      </Row>
      <Row className="ps-3 pe-3 ms-1 me-1">
        <ListItems data={RESOURCE_DATA} />
      </Row>
    </Container>
  )
}

export default AdditionalResourcesPage

const RESOURCE_DATA = [
  {
    id: 0,
    icon: AdditionalResource1,
    title: "Video of the award winning wine",
    type: "video"
  },
  {
    id: 1,
    icon: AdditionalResource2,
    title: "Picture of the latest wine club dinner",
    type: "image"
  },
  {
    id: 2,
    icon: AdditionalResource1,
    title: "Video of the award winning wine",
    type: "video"
  },
  {
    id: 3,
    icon: AdditionalResource2,
    title: "Picture of the latest wine club dinner",
    type: "image"
  },
  {
    id: 4,
    icon: AdditionalResource1,
    title: "Video of the award winning wine",
    type: "video"
  },
  {
    id: 5,
    icon: AdditionalResource2,
    title: "Picture of the latest wine club dinner",
    type: "image"
  }
]
