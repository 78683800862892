import { Box, Dialog, DialogContent, IconButton, Grid, Typography, styled } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import styles from "../../learning_development/QuestionBank/EditQuestion/EditQuestion.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import { addToQuestionBankAction } from "redux/L&D/actions/user"
import { useHistory } from "react-router-dom"
import MultipleSelect from "components/StyledComponents/MultipleSelect"

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

const AddQuestion = ({ onClose = () => {}, data = {}, isAutoSuggest = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { newRestaurantList } = useSelector((store) => store?.app || [])
  const [restaurant, setRestaurants] = useState([])

  const handleSubmit = (values) => {
    const payload = {
      ...data,
      restaurant_ids: values?.restaurant
    }

    dispatch(addToQuestionBankAction({ payload: payload, isAutoSuggest: isAutoSuggest }))
    history.goBack()
    onClose()
  }

  useEffect(() => {
    let resData = []
    if (newRestaurantList?.length > 0) {
      for (let i of newRestaurantList) {
        resData.push({
          value: i.id,
          label: i.name
        })
      }
    }
    resData.unshift({ label: "All", value: "All" })
    setRestaurants(resData)
  }, [newRestaurantList])

  return (
    <StyledDialog open={true} maxWidth="">
      <DialogContent id="editQuestionDialog" className={styles.addTriviaDialogBox}>
        <Formik
          initialValues={{ restaurant: ["All"] }}
          enableReinitialize={true}
          onSubmit={handleSubmit}>
          {(formik) => (
            <React.Fragment>
              <Box className="d-flex justify-content-center position-relative">
                <Typography variant="h2" className="my-3">
                  Add Questionnaire
                </Typography>
                <Box className={styles.closeButton}>
                  <span role="button" onClick={onClose}>
                    <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
                      <CloseIcon sx={{ height: "14px", width: "14px" }} />
                    </IconButton>
                  </span>
                </Box>
              </Box>
              <Box height={40} />
              <Box sx={{ padding: "0 11px" }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={6}>
                    <MultipleSelect
                      id="restaurant"
                      name="restaurant"
                      menuItems={restaurant}
                      label="Select Restaurant"
                      isRequired={true}
                      value={formik.values.restaurant}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
                <Box className="d-flex justify-content-center">
                  <AppButton
                    className="mt-3 px-5"
                    variant="outlined"
                    disabled={formik.values.restaurant ? false : true}
                    onClick={formik.handleSubmit}>
                    Save
                  </AppButton>
                </Box>
              </Box>
            </React.Fragment>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  )
}

export default AddQuestion
