import { createSlice } from "@reduxjs/toolkit"
import { revertAllLnD } from "../actions/user"

const initialState = {
  trivia: {
    triviaContest: {
      newContest: {
        questionsPublished: false,
        triviaId: "",
        questionImages: []
      },
      triviaCategories: []
    },
    triviaContestList: {
      totalPages: 1,
      data: []
    },
    incorrectTriviaContestList: {
      totalPages: 1,
      data: []
    },
    viewTrivia: {
      triviaId: "",
      contestData: {},
      questions: []
    },
    editTrivia: {
      triviaId: "",
      contestData: {},
      questions: [],
      isContestEdited: false
    },
    contestAnalytics: {
      triviaId: "",
      restaurantList: [],
      correctAnswers: [],
      incorrectAnswers: [],
      topSuggestors: []
    }
  },
  suggestedQuestion: {
    isEdited: false,
    image: null
  },
  pendingQuestions: [],
  triviaAnalytics: {
    correctAnswers: [],
    incorrectAnswers: []
  },
  leaderBoardUserList: {
    totalPages: 1,
    data: []
  },
  top5RanksTrivia: [],
  topTrivia: [],
  calendarData: [],
  uploadQuestionData: [],
  menuLink: "",
  generate_question_MenuLink: "dinner",
  dinner_Lunch_data: [],
  selectedMenuItems: [],
  wineMenuData: [],
  wineBottleMenuData: [],
  barMenuData: [],
  generatedQuestions: [],
  questionBank: null,
  triviaMonthList: [],
  autoSuggestQuestions: {},
  autoSuggestQuestionId: "",
  generateQuestionSpiritData: [],
  allQuestions: {
    pages: 1,
    data: []
  },
  incorrectTriviaDuration: {},
  categoryCount: {},
  triviaQuestionCount: {},
  reasonCodes: [],
  flaggedQuestions: [],
  questionCategory: "",
  likeDislikeData: {}
}

const learningDevelopment = createSlice({
  name: "learningDevelopment",
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(revertAllLnD, () => initialState),
  reducers: {
    setTriviaId: (state, action) => {
      state.trivia.triviaContest.newContest.triviaId = action.payload
    },
    setQuestionsPublished: (state, action) => {
      state.trivia.triviaContest.newContest.questionsPublished = action.payload
    },
    setTriviaList: (state, action) => {
      state.trivia.triviaContestList = action.payload
    },
    setIncorrectTriviaList: (state, action) => {
      state.trivia.incorrectTriviaContestList = action.payload
    },
    initTriviaContest: (state) => {
      state.trivia.triviaContest.newContest = {
        questionsPublished: false,
        triviaId: "",
        questionImages: []
      }
    },
    setUploadedImages: (state, action) => {
      let newData = [...(state?.trivia?.triviaContest?.newContest?.questionImages ?? [])]
      newData[action.payload.index] = action.payload.data
      state.trivia.triviaContest.newContest.questionImages = newData
    },
    setUploadedImagesArr: (state, action) => {
      state.trivia.triviaContest.newContest.questionImages = action.payload
    },
    setTriviaCategories: (state, action) => {
      state.trivia.triviaContest.triviaCategories = action.payload
    },
    setPendingQuestions: (state, action) => {
      state.pendingQuestions = action.payload
    },
    setTriviaCorrectAnswers: (state, action) => {
      state.triviaAnalytics.correctAnswers = action.payload
    },
    setTriviaIncorrectAnswers: (state, action) => {
      state.triviaAnalytics.incorrectAnswers = action.payload
    },
    setSuggestedQuestionEdited: (state, action) => {
      state.suggestedQuestion.isEdited = action.payload
    },
    setSuggestedQuestionImage: (state, action) => {
      state.suggestedQuestion.image = action.payload
    },
    setViewTriviaId: (state, action) => {
      state.trivia.viewTrivia.triviaId = action.payload
    },
    setQuestionMenuLink: (state, action) => {
      state.menuLink = action.payload
    },
    setGenerate_Question_MenuLink: (state, action) => {
      state.generate_question_MenuLink = action.payload
    },
    setViewTriviaData: (state, action) => {
      state.trivia.viewTrivia.contestData = action.payload
    },
    setViewTriviaQuestions: (state, action) => {
      state.trivia.viewTrivia.questions = action.payload
    },
    setViewTriviaQuestionStatus: (state, action) => {
      state.trivia.viewTrivia.questions[action.payload.index].status = action.payload.status
    },
    setEditTriviaId: (state, action) => {
      state.trivia.editTrivia.triviaId = action.payload
    },
    setEditTriviaData: (state, action) => {
      state.trivia.editTrivia.contestData = action.payload
    },
    setEditTriviaQuestions: (state, action) => {
      state.trivia.editTrivia.questions = action.payload
    },
    setIsContestEdited: (state, action) => {
      state.trivia.editTrivia.isContestEdited = action.payload
    },
    setEditTriviaQuestionStatus: (state, action) => {
      state.trivia.editTrivia.questions[action.payload.index].status = action.payload.status
    },
    setLeaderBoardWinnerList: (state, action) => {
      state.leaderBoardMerchant = action.payload
    },
    setLeaderBoardUserList: (state, action) => {
      state.leaderBoardUserList = action.payload
    },
    setContestAnalyticsId: (state, action) => {
      state.trivia.contestAnalytics.triviaId = action.payload
    },
    setResturantListByTrivia: (state, action) => {
      state.trivia.contestAnalytics.restaurantList = action.payload
    },
    setCorrectAnswersByTrivia: (state, action) => {
      state.trivia.contestAnalytics.correctAnswers = action.payload
    },
    setIncorrectAnswersByTrivia: (state, action) => {
      state.trivia.contestAnalytics.incorrectAnswers = action.payload
    },
    setTopSuggestorsByTrivia: (state, action) => {
      state.trivia.contestAnalytics.topSuggestors = action.payload
    },
    setTopTrivia: (state, action) => {
      state.topTrivia = action.payload
    },
    setCalendarData: (state, action) => {
      state.calendarData = action.payload
    },
    setTop5RanksTrivia: (state, action) => {
      state.top5RanksTrivia = action.payload
    },
    setUploadQuestionsData: (state, action) => {
      state.uploadQuestionData = action.payload
    },
    setDinnerLunchData: (state, action) => {
      state.dinner_Lunch_data = action.payload
    },
    setSelectedMenuItems: (state, action) => {
      state.selectedMenuItems = action.payload
    },
    setWineMenuData: (state, action) => {
      state.wineMenuData = action.payload
    },
    setWineBottleMenuData: (state, action) => {
      state.wineBottleMenuData = action.payload
    },
    setBarMenuData: (state, action) => {
      state.barMenuData = action.payload
    },
    setGeneratedQuestions: (state, action) => {
      state.generatedQuestions = action.payload
    },
    setQuestionBankData: (state, action) => {
      state.questionBank = action.payload
    },
    setTriviaMonthList: (state, action) => {
      state.triviaMonthList = action.payload
    },
    setDMVAutoSuggestQuestions: (state, action) => {
      state.autoSuggestQuestions = action.payload
    },
    setAutoSuggestQuestionId: (state, action) => {
      state.autoSuggestQuestionId = action.payload
    },
    setSpiritsData: (state, action) => {
      state.generateQuestionSpiritData = action.payload
    },
    setAllQuestionsByTrivia: (state, action) => {
      state.allQuestions = action.payload
    },
    setIncorrectTriviaDuration: (state, action) => {
      state.incorrectTriviaDuration = action.payload
    },
    setCategoryCount: (state, action) => {
      state.categoryCount = action.payload
    },
    setTriviaQuestionsCount: (state, action) => {
      state.triviaQuestionCount = action.payload
    },
    setReasonCodes: (state, action) => {
      state.reasonCodes = action.payload
    },
    setFlaggedQuestions: (state, action) => {
      state.flaggedQuestions = action.payload
    },
    setQuestionCategory: (state, action) => {
      state.questionCategory = action.payload
    },
    setLikeDislikeData: (state, action) => {
      state.likeDislikeData = action.payload
    }
  }
})

export default learningDevelopment
export const learningDevelopmentActions = learningDevelopment.actions
