import React from "react"
import { Route, Redirect } from "react-router"
import { useIsLoggedIn } from "hooks/state"
import PrivateLayout from "../layout/learning_development/privateLayout"
// import PrivateLayout from "layout/marketing/privateLayout";
import { useSelector } from "react-redux"

const AuthWrapper = ({ component: Component, ...rest }) => {
  const isLoggedIn = useIsLoggedIn()
  const role = useSelector((res) => res.user?.userData?.role)

  switch (role) {
    case "DMV":
      return (
        <Route
          {...rest}
          render={(props) =>
            isLoggedIn === true ? (
              <PrivateLayout {...props}>
                <Component {...props} />
              </PrivateLayout>
            ) : (
              <Redirect
                to={{
                  pathname: "/dmv/auth/login",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      )
    default:
      return (
        <Route
          {...rest}
          render={(props) =>
            isLoggedIn === true ? (
              <PrivateLayout {...props}>
                <Component {...props} />
              </PrivateLayout>
            ) : (
              <Redirect
                to={{
                  pathname: "/auth/login",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      )
  }
}

export default AuthWrapper
