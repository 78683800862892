import { Grid, Typography, useTheme } from "@mui/material"
import React, { useState } from "react"
import styles from "../menuImage.module.scss"
import AppTextField from "components/StyledComponents/AppTextField"
import { useDispatch } from "react-redux"
import ImageCropper from "components/ImageCropper"
import { uploadMenuImageAction } from "redux/superAdmin/actions"
import UploadMenuImage from "./uploadImage"

const MenuImageForm = ({ formik, index }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const handleImageUpload = (image) => {
    if (image) {
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    let data = new FormData()
    data.append("media", image)
    data.append("media_type", "IMAGE")
    data.append("content_type", image.type)
    dispatch(uploadMenuImageAction({ index, data }))
    formik.setFieldValue(`menuimage[${index}].image`, image)
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={0}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <Grid container item spacing={2} xs={6} mb={12.5} key={`menuimage${index}`}>
      {showCropperModal(imageObjectURL, fileDetails, formik)}
      <Grid item>
        <Grid item className={styles.category} mb={4}>
          <Typography
            variant="body1Regular"
            sx={{ fontWeight: "600" }}
            color={theme.palette.secondary.main}>
            Category {index + 1}
          </Typography>
        </Grid>
        <Grid item mb={4}>
          <UploadMenuImage
            value={formik.values?.menuimage?.[index]?.image}
            onChange={handleImageUpload}
            error={formik.errors?.menuimage?.[index]?.image}
            touched={formik.touched?.menuimage?.[index]?.image}
            isVariant={true}
          />
        </Grid>
        <Grid item mt={1}>
          <AppTextField
            label="Category Name"
            placeholder="Enter Category Name"
            isUpperCase={true}
            fullWidth={true}
            value={formik.values.menuimage[index].name}
            onChange={formik.handleChange(`menuimage[${index}].name`)}
            error={
              formik.touched?.menuimage?.[index]?.name &&
              Boolean(formik.errors?.menuimage?.[index]?.name)
            }
            helperText={
              formik.touched?.menuimage?.[index]?.name && formik.errors?.menuimage?.[index]?.name
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MenuImageForm
