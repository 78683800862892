import React from "react"

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "24px" }}>
      <h1>Privacy Policy</h1>
      <h3>Introduction</h3>
      <p>
        We Vantero Hospitality, value your trust & respect your privacy. We are committed to
        operating with the highest ethical standards and appropriate internal controls. We are
        sensitive to the scenario that we would be dealing with data and information of a personal
        nature. This Privacy Policy provides you with details about the manner in which your data is
        collected, stored & used by us. You are advised to read this Privacy Policy carefully. By
        visiting Vantero Hospitality&apos;s website/applications you expressly give us consent to
        use & disclose your personal information in accordance with this Privacy Policy. If you do
        not agree to the terms of the policy, please do not use or access Vantero Hospitality
        website or mobile applications.
      </p>
      <br />
      <h3>General</h3>
      <p>
        Your privacy is very important to us. We follow systematic and stringent procedures to
        protect the security of the information / data stored on our servers. The information that
        you have shared on our App is stored in secure server and can be accessed only for official
        purposes. Any of our employees who violate our privacy and/or security policies related to
        customer&apos;s data is subject to disciplinary action, including possible termination and
        civil and/or criminal prosecution.
        <br />
        <br></br>
        On signing up on our App, you are required to provide us with certain basic mandatory
        information inter-alia including your e-mail id, name, telephone number and a password. Once
        the registration is completed, the said e-mail id and password can be used to access your
        account every time you visit our App. We will not sell, share or rent your personal
        information to any 3rd party. Any emails and/or SMS sent by Vantero Hospitality will only be
        in connection with the provision of agreed services & products and this Privacy Policy.
        <br />
        <br></br>
        Periodically, we may reveal general statistical information about Vantero Hospitality & its
        users, such as number of visitors, number and type of goods and services purchased, etc.
        <br />
        <br></br>
        We reserve the right to communicate your personal information to any third party that makes
        a legally-compliant request for its disclosure.
        <br />
        <br></br>
        We may contact you via a phone call to discuss the information provided on the app and will
        do so only upon authenticating your identity.
      </p>
      <br />
      <h3>Personal Information</h3>
      <p>
        Personal Information means and includes all information that can be linked to a specific
        individual or to identify any individual, such as name, address, mailing address, telephone
        number, email ID and any details that may have been voluntarily provide by the user in
        connection with availing any of the services on Vantero Hospitality.
        <br /> When you browse through Vantero Hospitality, we may collect information regarding the
        domain and host from which you access the internet, the Internet Protocol address of the
        computer or Internet service provider you are using, and anonymous site statistical data.
      </p>
      <br />
      <h3> Security </h3>
      <p>
        Vantero Hospitality has stringent security measures in place to protect the loss, misuse,
        and alteration of the information under our control. Whenever you change or access your
        account information, we offer the use of a secure server. Any payment details are entered on
        the Payment Gateway&apos;s or Bank&apos;s page on a secured SSL. The data is transferred
        between Bank and gateways in an encrypted manner. Once your information is in our possession
        we adhere to strict security guidelines, protecting it against unauthorized access.
      </p>
      <br /> <h3>Third Party Links</h3>
      <p>
        Vantero Hospitality does not endorse or guarantee any third party links found on its
        App/Website. The Services offered on third party sites are not Services of Vantero
        Hospitality, and Vantero Hospitality cannot attest to the accuracy of information provided
        by the linked sites. You should not consider Vantero Hospitality linking to any website to
        be an endorsement by Vantero Hospitality, or any of its employees, or the sponsors of the
        site or the information or products presented on that site.
      </p>
      <br />
      <h3>Consent</h3>
      <p>
        By using Vantero Hospitality and/or by providing your information, you consent to the
        collection and use of the information you disclose on Vantero Hospitality in accordance with
        this Privacy Policy, including but not limited to your consent for sharing your information
        as per this privacy policy. Additionally, you give permission to call you in connection to
        services rendered. This permission can be revoked by writing to help@Vantero Hospitality.com
      </p>
      <br />
      <h3>Notices and Revisions</h3>
      <p>
        If you choose to visit this site, your visit and any dispute over privacy is subject to this
        Notice, including limitations on damages, resolution of disputes, and application of the
        laws of India. If you have any concern about privacy at our Website/App, please contact us
        with a thorough description, and we will try to resolve it. Our business changes constantly,
        and our Privacy Policy will also change; but you should check our web site frequently to see
        recent changes. Unless stated otherwise, our current Privacy Policy applies to all
        information that we have about you and your account.
      </p>
      <br /> <h3>Reach us</h3>
      <p>
        If you have any questions or comments regarding this privacy policy, please contact us via
        email at
        <a
          target="_blank"
          rel="noreferrer"
          href={"mailto:help@vanterohospitality.com"}
          style={{ textDecoration: "none" }}>
          {" "}
          help@vanterohospitality.com
        </a>
      </p>
    </div>
  )
}

export default PrivacyPolicy
