import React, { useState } from "react"
import { useEffect } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import Card from "components/Card/Card"
import { Col, Container, Row } from "react-bootstrap"
import { Box, Typography } from "@mui/material"
import BasePeriodNav from "../BasePeriodNavBar"
import BaseIncrementalTable from "./BaseIncrementalTable"
import PerformanceIncrementalTable from "./PerformanceIncrementalTable"

function IncrementalMembership() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [activeTab, setActiveTab] = useState("Base Period")
  const tabs = ["Base Period", "Performance Management Period"]
  // const history = useHistory();
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Base Period Graph"
  }, [])

  const styles = {
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      backgroundColor: "transparent",
      padding: "16px 6px",

      border: "1px solid #C4C4C4",
      borderRadius: "6px"
    },
    tabs: {
      backgroundColor: "red"
    }
  }

  return (
    <>
      <BasePeriodNav breadCrumbs={breadCrumbs} isIncrementalActive={true}></BasePeriodNav>
      <Card
        style={{
          borderTopLeftRadius: "0px",
          marginTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
        <Container fluid className="mt-3">
          <Row className="m-0">
            <Col
              lg="12"
              className="d-flex align-items-center justify-content-between"
              style={{ height: "50px" }}>
              <Typography className="ms-3" variant="h2">
                Base Period Statistics
              </Typography>
              <div style={styles.headingContainer}>
                {tabs.map((tab, index) => (
                  <Box sx={styles.tabs} key={index}>
                    <Typography
                      onClick={() => setActiveTab(tab)}
                      variant="body1Regular"
                      sx={[
                        tab === activeTab
                          ? {
                              background:
                                "linear-gradient(97.14deg, #4FACE9 -118.91%, #274C9A 64.39%);"
                            }
                          : {},
                        {
                          cursor: "pointer",
                          padding: "12px 24px",
                          borderRadius: "6px"
                        }
                      ]}>
                      {tab}
                    </Typography>
                  </Box>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
        <Container style={{ minWidth: "100%" }} className="mt-5">
          <Row className="m-0">
            <Col lg="12">
              {activeTab === "Base Period" && (
                <BaseIncrementalTable
                  rows={excludedRows}
                  columns={excludedColumns}></BaseIncrementalTable>
              )}
              {activeTab === "Performance Management Period" && (
                <PerformanceIncrementalTable
                  rows={attendantsRows}
                  columns={attendantsColumns}></PerformanceIncrementalTable>
              )}
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  )
}

export default IncrementalMembership

const excludedColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 200
  },
  {
    id: "Base Period Invite Rate",
    label: "Base Period Invite Rate",
    align: "center",
    minWidth: 150
  },
  {
    id: "Team Invite Rate",
    label: "Team Invite Rate",
    minWidth: 150,
    align: "center"
  },
  {
    id: "Performance Gap",
    label: "Performance Gap",
    align: "center",
    minWidth: 150
  }
]

const excludedRows = [
  {
    id: 16,
    rank: 16,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    inviteRate: 50.0,
    invites: 2,
    nonWCM: 4
  },
  {
    id: 18,
    rank: 18,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    inviteRate: 0.0,
    invites: 0,
    nonWCM: 4
  }
]

const attendantsColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 200
  },
  {
    id: "Non WCM Guests",
    label: "Non WCM Guests",
    align: "center",
    minWidth: 150
  },
  {
    id: "Performance Gap Improvement",
    label: "Performance Gap Improvement",
    align: "center",
    minWidth: 150
  },
  {
    id: "Incremental Membership",
    label: "Incremental Membership",
    align: "center",
    minWidth: 150
  },
  {
    id: "Annualized",
    label: "Annualized",
    align: "center",
    minWidth: 150
  },
  {
    id: "Performance Gap",
    label: "Performance Gap",
    align: "center",
    minWidth: 150
  },
  {
    id: "Invites",
    label: "Invites",
    align: "center",
    minWidth: 150
  },
  {
    id: "% of Goal",
    label: "% of Goal",
    align: "center",
    minWidth: 150
  },
  {
    id: "Invite Rate",
    label: "Invite Rate",
    align: "center",
    minWidth: 150
  },
  {
    id: "New Team Invite Rate",
    label: "New Team Invite Rate",
    align: "center",
    minWidth: 150
  }
]

const attendantsRows = [
  {
    id: 16,
    rank: 16,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    nonWCM: 354,
    performanceGapImprove: 2.5,
    incrementalMembership: 8,
    annualized: 50,
    performanceGap: -0.3,
    inviteRate: 10.0,
    invites: 52,
    percentOfGoal: 230,
    newTeamInvite: 6.7
  },
  {
    id: 17,
    rank: 17,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    nonWCM: 230,
    performanceGapImprove: 3.5,
    incrementalMembership: 7,
    annualized: 10,
    performanceGap: 2.7,
    inviteRate: 60.0,
    invites: 52,
    percentOfGoal: 530,
    newTeamInvite: 6.7
  },
  {
    id: 18,
    rank: 18,
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    nonWCM: 349,
    performanceGapImprove: 5.4,
    incrementalMembership: 3,
    annualized: 17,
    performanceGap: 4.2,
    inviteRate: 10.0,
    invites: 52,
    percentOfGoal: 230,
    newTeamInvite: 6.7
  }
]
