import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import AppButtonGradient from "components/StyledComponents/AppButtonGradient"
import CustomDivider from "components/StyledComponents/Divider"
import { FieldArray, Formik } from "formik"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { validationSchemaWineClubMembership } from "helpers/validationSchema"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Prompt, useHistory } from "react-router-dom"
import {
  createWineClubMembershipsAction,
  getExistingBenefitsAction,
  getMembershipImageURLAction
} from "redux/DMV/actions"
import { DMVActions } from "redux/DMV/slice/user"
import BenefitsForm from "./components/BenefitsForm"
import MembershipsForm from "./components/MembershipsForm"

const DMVWineClubMembership = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [initialValues, setInitialValues] = useState({ benefitsSet: [] })
  const history = useHistory()
  const { benefitsImages, isSubmitted } = useSelector(
    (store) => store?.dmv?.wineClubMembership ?? {}
  )

  const initFormik = () => {
    setInitialValues({
      benefitsSet: [
        {
          name: "",
          description: "",
          icon: null,
          tooltip: "",
          memberships: ""
        }
      ]
    })
  }

  const handleReset = (formik) => {
    dispatch(DMVActions.initWineClubMembership())
    formik.resetForm()
  }

  const addBenefit = (arrayHelper) => {
    arrayHelper.push({
      name: "",
      description: "",
      icon: null,
      tooltip: "",
      memberships: ""
    })
  }

  const handleSubmit = (values) => {
    setIsFormSubmitting(true)
    const payload = {
      benefits: values.benefitsSet.map((benefit, index) => ({
        ...(benefitsImages?.[index] ? { image: benefitsImages[index] } : {}),
        benefit: benefit.name.trim(),
        detail: benefit.description.trim(),
        has_tooltip: benefit.tooltip !== "",
        tooltip: benefit.tooltip.trim(),
        available_with: benefit.memberships
      }))
    }
    dispatch(createWineClubMembershipsAction(payload))
  }

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  }, [])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Wine Of The Month - DMV"
    dispatch(DMVActions.initWineClubMembership())
    dispatch(getExistingBenefitsAction())
    dispatch(getMembershipImageURLAction())
    initFormik()
  }, [])

  useEffect(() => {
    if (isSubmitted) {
      dispatch(DMVActions.setIsWineClubMembershipSubmitted(false))
      history.push("/dmv/dashboard")
    }
  }, [isSubmitted])

  return (
    <Container fluid className="p-0  ">
      <Row>
        <Col lg="12">
          <Header title="Wine Club Memberships" breadCrumbs={breadCrumbs} back={true}>
            <AppButton onClick={() => history.push("/dmv/dashboard/wine-club-memberships/list")}>
              View Wine Club Benefits
            </AppButton>
          </Header>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchemaWineClubMembership}
            onSubmit={handleSubmit}>
            {(formik) => (
              <>
                <Prompt
                  when={formik.dirty && !isFormSubmitting}
                  message={
                    "Changes you made may not be saved. Are you sure you want to leave this page ?"
                  }
                />
                <FieldArray
                  name={"benefitsSet"}
                  render={(arrayHelper) => (
                    <>
                      {formik.values?.benefitsSet?.map((item, index) => (
                        <BenefitsForm
                          key={index}
                          formik={formik}
                          index={index}
                          arrayHelper={arrayHelper}
                        />
                      ))}
                      {formik?.values?.benefitsSet?.length < 6 ? (
                        <AppButtonGradient
                          sx={{
                            height: "48px",
                            width: "200px"
                          }}
                          onClick={() => addBenefit(arrayHelper)}
                          variant="outlined">
                          Add More
                        </AppButtonGradient>
                      ) : null}
                    </>
                  )}
                />
                <Row>
                  <Col lg="2" className="mt-5">
                    <AppButtonGradient
                      onClick={() => handleReset(formik)}
                      sx={{ height: "48px", width: "100%" }}
                      variant="outlined">
                      Reset
                    </AppButtonGradient>
                  </Col>
                  <Col lg="2" className="mt-5">
                    <AppButtonGradient
                      onClick={formik.handleSubmit}
                      sx={{ height: "48px", width: "100%" }}
                      variant="filled">
                      Submit
                    </AppButtonGradient>
                  </Col>
                </Row>
                <CustomDivider sx={{ margin: "34px 0px" }} />
                <MembershipsForm />
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  )
}

export default DMVWineClubMembership
