import { Box, Card, Grid, InputAdornment, Typography, styled, useTheme } from "@mui/material"
import React, { useState } from "react"
import styles from "./restaurant.module.scss"
import SearchTextField from "components/StyledComponents/SearchTextField"
import SearchIcon from "@mui/icons-material/Search"
import VantaMenuLogo from "assets/icons/VantaMenuLogo.svg"
import { getRestaurantsAction } from "redux/L&D/actions/index"
import { useDispatch, useSelector } from "react-redux"
import { useDebounce } from "hooks/utils"
import AppPagination from "components/StyledComponents/AppPagination"
import { userActions } from "redux/user/user"
import { useHistory } from "react-router-dom"
import VantaLogo from "assets/icons/VantaLogo.svg"
import Logout from "assets/icons/logout.svg"
import { revertAllLnD } from "redux/L&D/actions"
import { revertAllMarketing } from "redux/marketing/actions"
import { revertAllGM } from "redux/GM/actions"
import { revertAllDMV } from "redux/DMV/actions"
import { revertAllSuperAdmin } from "redux/superAdmin/actions"
import { appActions } from "redux/app/slice/app"
import { formatDate } from "helpers/functions"

const StyledCard = styled(Card)(() => ({
  color: "#fff",
  border: "2px solid #120E0C",
  borderRadius: "8px",
  textAlign: "center",
  padding: "16px",
  cursor: "pointer",
  "&:hover": {
    border: "2px solid #AD916F",
    boxShadow: "0px 11px 33.1px -10px rgba(173, 145, 111, 0.30)"
  }
}))

const ChooseRestaurant = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState("")
  const [selectedRestaurant, setSelectedRestaurant] = useState({})
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const { restaurantList } = useSelector((store) => store.user)

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  function dispatchLogoutState() {
    dispatch(revertAllLnD())
    dispatch(revertAllMarketing())
    dispatch(revertAllGM())
    dispatch(revertAllDMV())
    dispatch(revertAllSuperAdmin())
    dispatch(userActions.setUserData({}))
    dispatch(userActions.setUserWithData({}))
    dispatch(appActions.setIsLoggedInData(false))
    dispatch(
      appActions.setFilters({
        date: formatDate(new Date())
      })
    )
    dispatch(appActions.setRestaurantList([]))
    dispatch(appActions.setUserTypeList([]))
    dispatch(userActions.setUserRestaurantList([]))
    dispatch(userActions.setUserRestaurant({}))
    dispatch(appActions.setIsMultiRestaurant(false))
  }

  const handleLogOut = () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    dispatchLogoutState()
    history.push("/auth/login")
  }

  const handleRestaurant = (card) => {
    setSelectedRestaurant(card)
    dispatch(userActions.setUserRestaurant(card))
    history.push("/marketing/dashboard")
  }

  function filterResults(searchQuery, results) {
    // Trim search query
    if (searchQuery.trim() === "") {
      return results // Return the original results array
    }

    // Filter the results based on search query
    return results?.filter((item) => {
      const { name, location } = item

      // Helper function to check if a string includes the search query
      const includesSearchQuery = (string) =>
        string.toLowerCase().includes(searchQuery.toLowerCase())

      // Check if restaurant name, or location  matches the search query
      const nameMatch = searchQuery && includesSearchQuery(name)
      const locationMatch = location ? includesSearchQuery(location) : false

      return nameMatch || locationMatch
    })
  }

  const filteredResults = filterResults(debouncedSearchTerm, restaurantList)

  const itemsPerPage = 9
  const totalPages = Math.ceil(filteredResults?.length / itemsPerPage)

  const filterRestaurantList = filteredResults?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  React.useEffect(() => {
    setCurrentPage(1)
  }, [debouncedSearchTerm])

  React.useEffect(() => {
    dispatch(getRestaurantsAction())
  }, [])

  return (
    <section className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <Grid item container mt={6} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography className={styles.heading} variant="h2">
              Select Restaurant
            </Typography>
            <Typography variant="body2Light" color={theme.palette.text.secondary}>
              You can always switch to different restaurant in the dashboard
            </Typography>
          </Grid>
          <Grid item>
            <div className={styles.logout_grid}>
              <img src={Logout} className="cursor-pointer" onClick={handleLogOut} />
            </div>
          </Grid>
        </Grid>
        {restaurantList?.length > 9 && (
          <>
            <Grid item my={4} className={styles.searchContainer}>
              <SearchTextField
                id="search"
                name="search"
                value={searchValue}
                onChange={handleSearchField}
                fullWidth
                type="search"
                placeholder="Search Restaurant or Location..."
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        backgroundColor: theme.palette.background.secondary
                      }}>
                      <SearchIcon htmlColor={theme.palette.text.secondary} />
                    </InputAdornment>
                  )
                }}
                style={{
                  border: `1px solid ${theme.palette.text.secondary}`,
                  backgroundColor: theme.palette.background.secondary,
                  borderRadius: 6
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h2" color={theme.palette.white.main}>
                All Restaurants
              </Typography>
              <Typography
                variant="body2Light"
                sx={{ fontSize: 12 }}
                color={theme.palette.text.secondary}>
                Showing {filterRestaurantList?.length} out of {restaurantList?.length} Results
              </Typography>
            </Grid>
          </>
        )}
        <Box mb={6} mt={restaurantList?.length < 9 ? 10 : 4.8}>
          <Grid container spacing={2}>
            {filterRestaurantList.map((card, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <StyledCard
                  sx={
                    selectedRestaurant?.id === card.id
                      ? {
                          border: "2px solid #AD916F",
                          boxShadow: "0px 11px 33.1px -10px rgba(173, 145, 111, 0.30)",
                          height: "100%",
                          display: "grid",
                          alignItems: "center"
                        }
                      : {
                          height: "100%",
                          display: "grid",
                          alignItems: "center"
                        }
                  }
                  onClick={() => handleRestaurant(card)}>
                  <img
                    src={card?.icon || VantaMenuLogo}
                    style={{
                      width: "100%",
                      height: "25px",
                      objectFit: "contain",
                      marginBottom: "16px"
                    }}
                  />
                  <Grid item xs={12} mb={0.5}>
                    <Typography variant="body1Medium" color={theme.palette.secondary.main}>
                      {card.name}
                    </Typography>
                  </Grid>
                  <Typography
                    variant="tag1"
                    sx={{ fontSize: 11 }}
                    color={theme.palette.text.secondary}>
                    {card.location}
                  </Typography>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
          {restaurantList?.length > 9 && (
            <Grid container justifyContent="flex-start">
              {filterRestaurantList?.length ? (
                <AppPagination
                  onPageChange={setCurrentPage}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              ) : (
                ""
              )}
            </Grid>
          )}
        </Box>
      </div>
      <footer className={styles.footer}>
        <img src={VantaLogo} alt="" />
        <Typography variant="body1Regular">Gamification Platform Powered by DM Ventures</Typography>
      </footer>
    </section>
  )
}

export default ChooseRestaurant
