import React, { useState } from "react"
import Card from "components/Card/Card"
import { Typography } from "@mui/material"
import classes from "./detailWineMonth.module.scss"
import { Col, Row } from "react-bootstrap"
import item1 from "assets/images/wineMonth/items.svg"
import item2 from "assets/images/wineMonth/item2.svg"
import item3 from "assets/images/wineMonth/item3.svg"
import item4 from "assets/images/wineMonth/item4.svg"
import item5 from "assets/images/wineMonth/item5.svg"
import moment from "moment"
import ImageDisplay from "components/ImageDisplayer"
import { useTheme } from "@mui/system"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Menu, MenuItem } from "@mui/material"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { useDispatch } from "react-redux"
import { deleteWineMonthAction } from "redux/marketing/actions"
import { marketingActions } from "redux/marketing/slice/user"
import VideoPlayer from "pages/private/DVM_Console/performanceMgmtContent/components/VideoPlayer"

// function convertVideoUrl(videoURL) {
//   if (videoURL.includes("watch?v=")) {
//     let NewVideoURL = videoURL.replace("watch?v=", "embed/")
//     NewVideoURL =
//       NewVideoURL.indexOf("&") > -1 ? NewVideoURL.slice(0, NewVideoURL.indexOf("&")) : NewVideoURL
//     return NewVideoURL
//   } else {
//     const Url = videoURL.split("/")
//     return `https://www.youtube.com/watch?v=${Url.slice(-1)}`
//   }
// }

function DetailFeatureItemOfTheMonth({ data, isEdit = false, setIsEditModal = () => {} }) {
  // const [videoLoaded, setVideoLoaded] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [modal, setDeleteModal] = useState(false)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  // const url = convertVideoUrl(data?.video_url ?? "") ?? ""
  const theme = useTheme()

  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleFeatureItemDelete(id) {
    dispatch(deleteWineMonthAction(id))
    dispatch(marketingActions.setFeatureItemId(""))
    setDeleteModal(false)
  }

  function handleCloseModal() {
    setDeleteModal(false)
  }

  const handleClick = (event) => {
    dispatch(marketingActions.setTastingNotesImages([]))
    setAnchorEl(event.currentTarget)
  }

  function handleEditMenuItemModal() {
    setAnchorEl(null)
    setIsEditModal(true)
  }

  function handleMenuDeleteModal() {
    setDeleteModal(true)
    handleClose()
    setAnchorEl(null)
  }

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this feature item ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={() => handleCloseModal()}
        onDelete={() => handleFeatureItemDelete(data?.id)}
        open={modal}></DeleteConfirmationDialog>
      <Card>
        <Row>
          <Col lg="12">
            <div className="d-flex justify-content-between" style={{ position: "relative" }}>
              <div>
                {data?.wine_image && (
                  <div className={classes.imgBox}>
                    <img src={data?.wine_image} alt="" />
                  </div>
                )}
                <div
                  className={`${
                    classes.headingContainer + " d-flex justify-content-end flex-column"
                  }`}>
                  <div>
                    <Typography variant="tag1">
                      {data?.month ? moment(data?.month).format("DD MMM YYYY") : "-"} Featured Items
                      Of The Month
                    </Typography>
                  </div>
                  <div className="mt-2">
                    <Typography
                      variant="caption1"
                      sx={(theme) => ({ color: theme.palette.secondary.main })}>
                      {data?.name ?? "-"}
                    </Typography>
                  </div>
                </div>
              </div>
              {isEdit && (
                <div>
                  <MoreVertIcon
                    id="demo-positioned-button"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => handleClick(e)}
                    sx={{ cursor: "pointer" }}></MoreVertIcon>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}>
                    <MenuItem onClick={() => handleEditMenuItemModal()} sx={{ width: "150px" }}>
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuDeleteModal()}
                      sx={{ color: theme.palette.red.main }}>
                      Delete
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className="mt-4">
              <VideoPlayer video={data} />
              {/* {!videoLoaded && (
                <Shimmer type="rounded" height="250px" styles={{ marginLeft: 0, marginRight: 0 }} />
              )}
              <YoutubeFrame
                style={{ width: "100%", marginTop: "10px", height: "300px" }}
                {...{ videoLoaded, setVideoLoaded, url }}
              /> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className={classes.columnGrid}>
            <div>
              <div className="d-flex flex-column mb-4">
                <Typography
                  className="mb-2"
                  sx={(theme) => ({ color: theme.palette.secondary.main })}
                  variant="caption1"
                  style={{ textTransform: "capitalize" }}>
                  <img className={classes.iconImg} src={item1} alt="" />
                  Region
                </Typography>
                <Typography
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                  variant="body1Large">
                  {data?.wine_region ?? "-"}
                </Typography>
              </div>
              <div className="d-flex flex-column mb-4">
                <Typography
                  className="mb-2"
                  sx={(theme) => ({ color: theme.palette.secondary.main })}
                  variant="caption1"
                  style={{ textTransform: "capitalize" }}>
                  <img className={classes.iconImg} src={item2} alt="" />
                  description
                </Typography>
                <Typography
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                  variant="body1Large">
                  {data?.description ?? "-"}
                </Typography>
              </div>
              <div className="d-flex flex-column mb-4">
                <Typography
                  className="mb-2"
                  sx={(theme) => ({ color: theme.palette.secondary.main })}
                  variant="caption1"
                  style={{ textTransform: "capitalize" }}>
                  <img className={classes.iconImg} src={item3} alt="" />
                  {data?.alcohol_type === "wine" ? "Grape Varietals/Ingredients" : "Ingredients"}
                </Typography>
                <Typography
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                  variant="body1Large">
                  {data?.gape_variatals ?? "-"}
                </Typography>
              </div>
              <div className="d-flex flex-column mb-4">
                <Typography
                  className="mb-2"
                  sx={(theme) => ({ color: theme.palette.secondary.main })}
                  variant="caption1"
                  style={{ textTransform: "capitalize" }}>
                  <img className={classes.iconImg} src={item4} alt="" />
                  Tasting Note
                </Typography>
                <ul>
                  {data?.tasting_notes?.length ? (
                    data?.tasting_notes?.map((items, id) => {
                      return (
                        <li key={id}>
                          <Typography
                            sx={(theme) => ({ color: theme.palette.text.secondary })}
                            variant="body1Large">
                            {items.name ?? "-"}
                          </Typography>
                        </li>
                      )
                    })
                  ) : (
                    <p>No items Found</p>
                  )}
                </ul>
              </div>
            </div>
            <div>
              <div className="d-flex flex-column mb-3">
                <Typography
                  sx={(theme) => ({ color: theme.palette.secondary.main })}
                  variant="caption1"
                  style={{ textTransform: "capitalize" }}>
                  <img className={classes.iconImg} src={item5} alt="" />
                  {data?.alcohol_type === "wine" ? "Menu Pairing" : "Classic Cocktails"}
                </Typography>
              </div>
              {data?.menu_pairing?.length && data?.menu_pairing[0]?.name ? (
                data?.menu_pairing?.map((items, id) => {
                  return (
                    <div className="d-flex mb-4" key={id}>
                      <div className={classes.imgContainer}>
                        <ImageDisplay data={items?.image}></ImageDisplay>
                      </div>
                      <div className="d-flex align-items-center">
                        <Typography
                          sx={(theme) => ({ color: theme.palette.text.secondary })}
                          variant="body1Large">
                          {items?.name ?? "-"}
                        </Typography>
                      </div>
                    </div>
                  )
                })
              ) : (
                <p>No Items found</p>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default DetailFeatureItemOfTheMonth
