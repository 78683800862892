import { Typography } from "@mui/material"
import AppTextField from "components/StyledComponents/AppTextField"
import { FieldArray } from "formik"
import React from "react"
import { Col, Row } from "react-bootstrap"
import ClearIcon from "@mui/icons-material/Clear"
import { useTheme } from "@mui/system"
import StyledLabel from "components/StyledComponents/StyledLabel"

const AddIngredient = ({ formik, showSteps, values, stepLabel, name, buttonText, label }) => {
  const theme = useTheme()

  return (
    <Row className="w-100 m-0 mt-4">
      <StyledLabel>{label}</StyledLabel>
      <FieldArray
        name={name}
        render={(arrayHelper) => (
          <>
            {values?.length
              ? values?.map((ingredient, index) => (
                  <Row
                    className="mt-2"
                    style={{ paddingRight: 0 }}
                    id={`${name}-${index}`}
                    key={`${name}-${index}`}>
                    {showSteps && (
                      <Col
                        lg="1"
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                        className="align-items-center">
                        <Typography
                          variant="body1Regular"
                          sx={{
                            color: theme.palette.secondary.main,
                            textUnderlineOffset: "2px"
                          }}>
                          {stepLabel}
                          {` ${index + 1}`}
                        </Typography>
                      </Col>
                    )}
                    <Col lg={showSteps ? "7" : "8"}>
                      <AppTextField
                        id={`${name}[${index}].name`}
                        name={`${name}[${index}].name`}
                        type="text"
                        fullWidth
                        placeholder={`Enter ${
                          label === "Recipe with Measurements" ? "Recipe" : "Ingredient"
                        }`}
                        value={ingredient?.name || ""}
                        onChange={(event) => {
                          formik.setFieldValue(`${name}[${index}].name`, event.target.value)
                        }}
                        onBlur={formik.handleBlur(`${name}[${index}].name`)}
                        error={Boolean(formik.errors?.[name]?.[index]?.name)}
                        helperText={formik.errors?.[name]?.[index]?.name}
                      />
                    </Col>
                    <Col lg="3" className="p-0">
                      <AppTextField
                        id={`${name}[${index}].measurement`}
                        name={`${name}[${index}].measurement`}
                        type="text"
                        fullWidth
                        placeholder="Enter Measurement"
                        value={ingredient?.measurement || ""}
                        onChange={(event) => {
                          formik.setFieldValue(`${name}[${index}].measurement`, event.target.value)
                        }}
                      />
                    </Col>
                    {index > 0 && (
                      <Col lg="1" style={{ display: "flex", justifyContent: "center" }}>
                        <span role="button" onClick={() => arrayHelper.remove(index)}>
                          <ClearIcon sx={{ width: "30px", height: "48px" }} />
                        </span>
                      </Col>
                    )}
                  </Row>
                ))
              : null}
            <Row className="w-100 m-0 mt-2 justify-content-end">
              <Col
                lg="12"
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}>
                <Typography
                  variant="body1Regular"
                  onClick={() =>
                    arrayHelper.push({
                      name: "",
                      measurement: ""
                    })
                  }
                  sx={{
                    color: theme.palette.secondary.main,
                    textDecoration: "underline",
                    cursor: "pointer",
                    textUnderlineOffset: "2px"
                  }}>
                  {buttonText}
                </Typography>
              </Col>
            </Row>
          </>
        )}
      />
    </Row>
  )
}

export default AddIngredient
