import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import classes from "../menu.module.scss"
import MenuListContainer from "../../../../../components/menuListContainer/index"
import {
  getBarMenuPeriodAction,
  getMenuPeriodAction,
  getWineMenuPeriodAction
} from "redux/marketing/actions"
import { Typography, Grid, InputAdornment } from "@mui/material"
import { useHistory } from "react-router-dom"
import { useTheme } from "@mui/system"
import { marketingActions } from "redux/marketing/slice/user"
import CategoryModal from "./components/CategoryModal"
import MenuNavBar from "components/menuNavBar"
import SubMenuBar from "components/subMenuBar"
import WineListItems from "components/WineListItems"
import BarListItems from "components/BarListItem"
import BarMenuCategoryModal from "./components/BarMenuCategoryModal"
import SpiritsMenuListContainer from "components/spiritsMenuListConatiner"
import {
  getMenuSubCategoryAction,
  getNewSpiritCountAction,
  getSpiritCMSAction
} from "redux/marketing/actions/user"
import SpiritsSubMenuBar from "components/SpiritsSubMenuBar"
import DMVAddMenuItems from "pages/private/DVM_Console/DMV_Menu/AddDMVMenuItems"
import AddMenuItems from "../AddMenuItems"
import DMV_SpiritSummary from "components/DMVSpiritSummary"
import { getDynamicMenuFieldsAction } from "redux/superAdmin/actions"
import { toTitleCase } from "helpers/functions"
import WineCategoryModal from "./components/WineCategoryModal"
import { useDebounce } from "hooks/utils"
import SearchTextField from "components/StyledComponents/SearchTextField"
import SearchIcon from "@mui/icons-material/Search"
import WineBottleListItems from "components/WineBottleListItems"

const buttonText = {
  CATEGORY: "+ Add Category",
  ITEM: "+ Add Item",
  IMPORT_SPIRITS: "Import Spirits"
}

const menuText = {
  DINNER: "dinner",
  LUNCH: "lunch",
  BRUNCH: "brunch",
  BREAKFAST: "breakfast",
  BARMENU: "bar menu",
  WINEBAR: "wine by glass",
  SPIRITS: "spirits",
  WINEBOTTLE: "wine by bottle"
}

function EditMenu() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const [categoryModal, setCategoryModal] = useState(false)
  const [isEdit] = useState(true)
  const [currentCategoryData, setCurrentCategoryData] = useState({})
  const [isEditModal, setIsEditModal] = useState(false)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const { linkCategory } = useSelector((state) => state?.marketing?.menu || {})
  const { menuPeriod, barMenuPeriod, wineMenuPeriod, spiritData, menuSubCategory } = useSelector(
    (state) => state?.marketing?.menu || []
  )
  const { menuCategory, SpiritSubMenu } = useSelector((state) => state.dmv)
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const [menuItems, setMenuItems] = useState(menuPeriod)
  const [barItems, setBarItems] = useState(barMenuPeriod)
  const [wineItems, setWineItems] = useState(wineMenuPeriod)
  const [spiritItems, setSpiritItems] = useState(spiritData)

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  const handleRouting = () => {
    const itemsLink = {
      item: linkCategory?.item,
      category: menuSubCategory?.[0]?.name || "",
      list: "Summary View"
    }
    dispatch(marketingActions.setMenuLink(itemsLink))
    history.push("/marketing/dashboard/explore-menu")
  }

  function handleAddCategory(btnValue) {
    if (btnValue?.trim() === buttonText.CATEGORY) {
      setCategoryModal(true)
      setIsEditModal(false)
      setCurrentCategoryData(false)
      setIsEditModalItem(false)
      dispatch(marketingActions.setFoodItemsDetail({}))
    } else if (btnValue?.trim() === buttonText.ITEM) {
      setMenuItemsModal(true)
    } else {
      // setImportSpiritModal(true)
    }
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Menu"
    return () => dispatch(marketingActions.setFoodItemsDetail({}))
  }, [])

  useEffect(() => {
    if (linkCategory?.item === menuText.BARMENU) {
      dispatch(getBarMenuPeriodAction())
    } else if (
      linkCategory?.item === menuText.WINEBAR ||
      linkCategory?.item === menuText.WINEBOTTLE
    ) {
      dispatch(getWineMenuPeriodAction())
    } else if (linkCategory?.item === menuText.SPIRITS) {
      dispatch(getSpiritCMSAction())
      dispatch(getNewSpiritCountAction())
    } else {
      dispatch(getMenuSubCategoryAction())
      dispatch(getMenuPeriodAction())
    }
    setSearchValue("")
    dispatch(getDynamicMenuFieldsAction({ category: toTitleCase(linkCategory?.item) }))
  }, [linkCategory?.item, linkCategory?.category, menuCategory])

  function filterResults(searchQuery, results, menuType) {
    const trimmedQuery = searchQuery.trim().toLowerCase()

    if (!trimmedQuery) {
      return results
    }

    const isSpiritsMenu = menuType === menuText.SPIRITS
    const listKey = isSpiritsMenu ? "spirit_list" : "food_list"
    const categoryKey = isSpiritsMenu ? "spirit_category" : "food_category"

    return results
      ?.map((period) => {
        const category = period[categoryKey]?.toLowerCase()
        const isCategoryMatch = category.includes(trimmedQuery)

        // If the search query partially matches the category, return all items in that category
        if (isCategoryMatch) {
          return period
        }

        // Filter items by name or by ingredients
        const filteredList = period[listKey]?.filter((item) => {
          const nameMatch = item?.name?.toLowerCase().includes(trimmedQuery)

          const ingredientsField = item?.data_to_display?.find(
            (field) => field.filedName === "Ingredients" || field.filedName === "Ingredient"
          )
          let ingredientMatch
          if (isSpiritsMenu) {
            ingredientMatch = item?.ingredient?.some((ingredient) =>
              ingredient?.name?.toLowerCase().includes(trimmedQuery)
            )
          } else {
            ingredientMatch = Array.isArray(ingredientsField?.value)
              ? ingredientsField.value.some((ingredient) =>
                  ingredient.name.toLowerCase().includes(trimmedQuery)
                )
              : ingredientsField?.value.toLowerCase().includes(trimmedQuery)
          }

          return nameMatch || ingredientMatch
        })

        return filteredList?.length > 0 ? { ...period, [listKey]: filteredList } : null
      })
      .filter(Boolean)
      .sort((a, b) => {
        const categoryA = a[categoryKey].toLowerCase()
        const categoryB = b[categoryKey].toLowerCase()

        // Sort based on how closely the category matches the query
        if (categoryA === trimmedQuery) return -1
        if (categoryB === trimmedQuery) return 1

        if (categoryA.startsWith(trimmedQuery) && !categoryB.startsWith(trimmedQuery)) return -1
        if (!categoryA.startsWith(trimmedQuery) && categoryB.startsWith(trimmedQuery)) return 1

        return categoryA.localeCompare(categoryB)
      })
  }

  useEffect(() => {
    const menuMap = {
      [menuText.BARMENU]: {
        data: barMenuPeriod,
        setFunction: setBarItems
      },
      [menuText.WINEBAR]: {
        data: wineMenuPeriod,
        setFunction: setWineItems
      },
      [menuText.WINEBOTTLE]: {
        data: wineMenuPeriod,
        setFunction: setWineItems
      },
      [menuText.SPIRITS]: {
        data: spiritData,
        setFunction: setSpiritItems
      },
      default: {
        data: menuPeriod,
        setFunction: setMenuItems
      }
    }

    const { data, setFunction } = menuMap[linkCategory?.item] || menuMap.default
    const filteredResults = filterResults(debouncedSearchTerm, data, linkCategory?.item)
    setFunction(filteredResults)
  }, [
    barMenuPeriod,
    wineMenuPeriod,
    spiritData,
    menuPeriod,
    linkCategory?.item,
    menuCategory?.name,
    linkCategory?.category,
    debouncedSearchTerm
  ])

  return (
    <Fragment>
      {/* {importSpiritModal && <ImportSpiritsModal onClose={() => setImportSpiritModal(false)} />} */}
      {linkCategory?.item === menuText?.SPIRITS ? (
        <DMVAddMenuItems
          isEdit={isEditModalItem}
          open={menuItemsModal}
          onClose={handleCloseMenuItems}
          isCMS={true}></DMVAddMenuItems>
      ) : (
        <AddMenuItems
          isEdit={isEditModalItem}
          open={menuItemsModal}
          onClose={handleCloseMenuItems}
        />
      )}
      {(linkCategory?.item === menuText.DINNER ||
        linkCategory?.item === menuText.LUNCH ||
        linkCategory?.item === menuText.BRUNCH ||
        linkCategory?.item === menuText.BREAKFAST) && (
        <CategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          onClose={setCategoryModal}></CategoryModal>
      )}
      {linkCategory?.item === menuText.BARMENU && (
        <BarMenuCategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          onClose={setCategoryModal}></BarMenuCategoryModal>
      )}
      {linkCategory?.item === menuText.WINEBAR && (
        <WineCategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          label="Wine"
          onClose={setCategoryModal}
        />
      )}
      {linkCategory?.item === menuText.WINEBOTTLE && (
        <WineCategoryModal
          data={currentCategoryData}
          isEdit={isEditModal}
          open={categoryModal}
          setMenuItemsModal={setMenuItemsModal}
          label="Wine By Bottle"
          onClose={setCategoryModal}
        />
      )}
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title={`Menu`} breadCrumbs={breadCrumbs}>
              <div className="d-flex align-items-center">
                <AppButton
                  styles={{
                    backgroundColor: theme.palette.background.main,
                    padding: "12px 25px"
                  }}
                  variant="filled"
                  onClick={handleRouting}>
                  Done
                </AppButton>
              </div>
            </Header>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="12">
            <MenuNavBar
              isEdit={isEdit}
              activeTab={linkCategory?.item}
              subCategory={menuSubCategory?.[0]?.name}></MenuNavBar>
          </Col>
        </Row>
        {(linkCategory?.item === menuText.DINNER ||
          linkCategory?.item === menuText.LUNCH ||
          linkCategory?.item === menuText.BRUNCH ||
          linkCategory?.item === menuText.BREAKFAST) && (
          <Row className="mt-4">
            <Col lg="12">
              <SubMenuBar
                isEdit={isEdit}
                activeTab={linkCategory?.category}
                menu={menuSubCategory}></SubMenuBar>
            </Col>
          </Row>
        )}
        {linkCategory?.item === menuText.SPIRITS && (
          <Row className="mt-4">
            <Col lg={12}>
              <SpiritsSubMenuBar isCMS={true} activeTab={menuCategory?.name} isEdit={true} />
            </Col>
          </Row>
        )}
      </Container>
      <Container fluid className="p-0">
        <Row className="m-0 mt-2 mb-4 pb-2" style={{ borderBottom: "1px solid gray" }}>
          <Col lg="12" className="p-0 d-flex justify-content-between align-items-center">
            <Typography variant="h2">
              {linkCategory?.item === menuText.DINNER ||
              linkCategory?.item === menuText.LUNCH ||
              linkCategory?.item === menuText.BRUNCH ||
              linkCategory?.item === menuText.BREAKFAST
                ? linkCategory?.category
                : ""}
            </Typography>
            {linkCategory?.item !== menuText.SPIRITS && (
              <Grid sx={{ position: "relative" }}>
                <AppButton
                  onClick={(e) => handleAddCategory(e.target.innerText)}
                  styles={{
                    backgroundColor: theme.palette.background.main,
                    padding: "10px 20px"
                  }}>
                  {linkCategory?.item === menuText.DINNER ||
                  linkCategory?.item === menuText.LUNCH ||
                  linkCategory?.item === menuText.BRUNCH ||
                  linkCategory?.item === menuText.BREAKFAST ||
                  linkCategory?.item === menuText.BARMENU ||
                  linkCategory?.item === menuText.WINEBAR ||
                  linkCategory?.item === menuText.WINEBOTTLE
                    ? buttonText.CATEGORY
                    : linkCategory?.item === menuText.SPIRITS
                    ? buttonText.IMPORT_SPIRITS
                    : buttonText.ITEM}
                </AppButton>
                {/* {linkCategory?.item === menuText.SPIRITS && newSpiritCount?.[0]?.total_amount && (
                <Grid item className={classes.ellipse_gold}>
                  {newSpiritCount?.[0]?.total_amount}
                </Grid>
              )} */}
              </Grid>
            )}
          </Col>
        </Row>
        <Row className={"mb-2"}>
          <Col lg="4">
            <SearchTextField
              id="search"
              name="search"
              value={searchValue}
              onChange={handleSearchField}
              fullWidth
              type="search"
              placeholder="Search menu items or ingredients"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      backgroundColor: theme.palette.primary.main
                    }}>
                    <SearchIcon htmlColor={`${theme.palette.text.secondary}`} />
                  </InputAdornment>
                )
              }}
              style={{
                border: `1px solid ${theme.palette.text.secondary}`,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 6
              }}
            />
          </Col>
        </Row>
        {(linkCategory?.item === menuText.DINNER ||
          linkCategory?.item === menuText.LUNCH ||
          linkCategory?.item === menuText.BRUNCH ||
          linkCategory?.item === menuText.BREAKFAST) && (
          <div className={classes.menuContainer}>
            {menuItems?.length > 0 ? (
              menuItems?.map((menuItem, idx) => {
                return (
                  <MenuListContainer
                    setMenuItemsModal={setMenuItemsModal}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setIsEditModalItem={setIsEditModalItem}
                    setCategoryModal={setCategoryModal}
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}></MenuListContainer>
                )
              })
            ) : (
              <Typography>
                {searchValue ? "No items found matching your search" : "No Data Found"}
              </Typography>
            )}
          </div>
        )}
        {linkCategory?.item === menuText.BARMENU && (
          <div className={classes.menuContainer}>
            {barItems?.length > 0 ? (
              barItems?.map((menuItem, idx) => {
                return (
                  <BarListItems
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}
                    setMenuItemsModal={setMenuItemsModal}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setIsEditModalItem={setIsEditModalItem}
                    setCategoryModal={setCategoryModal}></BarListItems>
                )
              })
            ) : (
              <Typography>
                {searchValue ? "No items found matching your search" : "No Data Found"}
              </Typography>
            )}
          </div>
        )}
        {linkCategory?.item === menuText.WINEBAR && (
          <div className={classes.menuContainer}>
            {wineItems?.length > 0 ? (
              wineItems?.map((menuItem, idx) => {
                return (
                  <WineListItems
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setCategoryModal={setCategoryModal}></WineListItems>
                )
              })
            ) : (
              <Typography>
                {searchValue ? "No items found matching your search" : "No Data Found"}
              </Typography>
            )}
          </div>
        )}
        {linkCategory?.item === menuText.WINEBOTTLE && (
          <div className={classes.menuContainer}>
            {wineItems?.length > 0 ? (
              wineItems?.map((menuItem, idx) => {
                return (
                  <WineBottleListItems
                    isEdit={isEdit}
                    data={menuItem}
                    key={idx}
                    setCurrentCategoryData={setCurrentCategoryData}
                    setIsEdit={setIsEditModal}
                    setCategoryModal={setCategoryModal}></WineBottleListItems>
                )
              })
            ) : (
              <Typography>
                {searchValue ? "No items found matching your search" : "No Data Found"}
              </Typography>
            )}
          </div>
        )}
        {linkCategory?.item === menuText.SPIRITS && (
          <>
            <Grid item sx={{ maxWidth: "50%" }}>
              {SpiritSubMenu?.map(
                (foodList, i) =>
                  foodList?.id === menuCategory?.name &&
                  (foodList?.description ||
                    foodList?.image_url ||
                    foodList?.unique_facts?.length > 0) && (
                    <DMV_SpiritSummary key={i} isEdit={false} foodList={foodList} isCMS={true} />
                  )
              )}
            </Grid>
            <Container fluid className="p-0 mt-4">
              <div className={classes.spiritContainer}>
                {spiritItems?.length > 0 ? (
                  spiritItems?.map((menuItem, idx) => {
                    return (
                      <SpiritsMenuListContainer
                        setMenuItemsModal={setMenuItemsModal}
                        setCurrentCategoryData={setCurrentCategoryData}
                        setIsEdit={setIsEditModal}
                        setIsEditModalItem={setIsEditModalItem}
                        setCategoryModal={setCategoryModal}
                        isEdit={isEdit}
                        data={menuItem}
                        key={idx}></SpiritsMenuListContainer>
                    )
                  })
                ) : (
                  <Typography>
                    {searchValue ? "No items found matching your search" : "No Data Found"}
                  </Typography>
                )}
              </div>
            </Container>
          </>
        )}
      </Container>
    </Fragment>
  )
}

export default EditMenu
