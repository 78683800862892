import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"
import styles from "./adminUserList.module.scss"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import InfoIcon from "@mui/icons-material/Info"
import { useRef } from "react"
import { useSelector } from "react-redux"
import { Backdrop, Menu, MenuItem } from "@mui/material"
import { useTheme } from "@mui/system"
import Chip from "@mui/material/Chip"
import { useHistory } from "react-router-dom"
import AppPagination from "components/StyledComponents/AppPagination"
import { useDispatch } from "react-redux"
import { superAdminAction } from "redux/superAdmin/slice/user"
import TableNoRecords from "components/TableNoRecords"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { appActions } from "redux/app/slice/app"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import ProfileDisplayer from "components/ProfileDisplayer"
import avatar from "assets/images/user/avatarUser.svg"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { deleteUserAction, updateUserStatusAction } from "redux/superAdmin/actions"
import { formatDateDetail } from "helpers/functions"

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}))

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "sticky",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const AdminUserListTable = ({
  columns = [],
  rows = [],
  setPage,
  currentPage,
  totalPage,
  selectAll,
  setSelectAll,
  selectedQuestions,
  setSelectedQuestions,
  exceptQuestions,
  setExceptQuestions,
  itemsPerPage = 12
}) => {
  const [anchorEl, setAnchorEl] = useState(false)
  const rowId = useRef(null)
  const history = useHistory()
  const openMenu = Boolean(anchorEl)
  const [deleteUser, setDeleteUser] = useState(false)
  const [userStatus, setUserStatus] = useState(null)
  const [isStatusOpenPopup, setisStatusOpenPopup] = useState(false)
  const dispatch = useDispatch()
  const themes = useTheme()
  const [order, setOrder] = useState("ASC")
  const [sortKey, setSortKey] = useState("")
  const { totalUserCount } = useSelector((store) => store?.superAdmin || 0)

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const onOptionsClick = (e, id, status) => {
    e.stopPropagation()
    rowId.current = id
    setUserStatus(status)
    setAnchorEl(e.currentTarget)
    dispatch(superAdminAction.setFrontlineUserID(rowId.current))
  }

  const handleDeleteUser = () => {
    if (rows.length > 1) {
      dispatch(deleteUserAction({ user_type: "ADMIN", page: currentPage }))
    } else {
      const page = currentPage - 1
      setPage(page)
      dispatch(deleteUserAction({ user_type: "ADMIN", page }))
    }
  }

  function handleDelete() {
    setDeleteUser(true)
  }

  function handleSort(key) {
    const isSameKey = sortKey === key
    const newOrder = isSameKey && order === "DESC" ? "ASC" : "DESC"
    setSortKey(key)
    setOrder(newOrder)
    dispatch(appActions.setOrderBy(newOrder === "DESC" ? `-${key}` : key))
  }

  const handleQuestionSelect = () => {
    if (selectAll && exceptQuestions?.length > 0) {
      setExceptQuestions([])
      return
    }
    setSelectedQuestions([])
    setExceptQuestions([])
    setSelectAll((prevSelectAll) => !prevSelectAll)
  }

  const handleSelectQuestion = (ID) => {
    let tempUserList = []
    if (selectAll) {
      const isExceptQuestionsAvailable = exceptQuestions.filter((item) => item === ID)
      if (isExceptQuestionsAvailable?.length > 0) {
        const updatedExceptQuestionList = exceptQuestions.filter((item) => item !== ID)
        setExceptQuestions(updatedExceptQuestionList)
      } else {
        setExceptQuestions([...exceptQuestions, ID])
      }
    } else {
      //check that question is already selected or not
      const isAvailable = selectedQuestions.filter((item) => item === ID)
      //if item is already selected remove that item from list
      if (isAvailable?.length > 0) {
        const updatedList = selectedQuestions.filter((item) => item !== ID)
        tempUserList = updatedList
        setSelectedQuestions(updatedList)
      } else {
        //add new item in list
        tempUserList = [...selectedQuestions, ID]
        setSelectedQuestions(tempUserList)
      }
    }
    if (tempUserList?.length === totalUserCount) {
      setSelectAll(true)
    }
  }

  const checkSelected = (ID) => {
    if (selectAll && exceptQuestions?.length === 0) {
      return true
    }
    if (selectAll && exceptQuestions?.length > 0) {
      let exceptIems = exceptQuestions.filter((data) => data === ID)
      if (exceptIems?.length > 0) {
        return false
      } else {
        return true
      }
    } else {
      let isItemSelected = selectedQuestions.filter((item) => item === ID)
      if (isItemSelected?.length > 0) {
        return true
      } else {
        return false
      }
    }
  }

  function getButtonStatus(status) {
    if (!status) return
    if (status == "ACTIVATED") {
      return "Deactivate"
    } else {
      return "Activate"
    }
  }

  function handleStatus() {
    setisStatusOpenPopup(true)
  }

  function handleUserStatus() {
    const source = "list"
    const isActivated = userStatus === "ACTIVATED"
    if (isActivated) {
      dispatch(updateUserStatusAction({ activate: false, source, user_type: "ADMIN", currentPage }))
    } else {
      dispatch(updateUserStatusAction({ activate: true, source, user_type: "ADMIN", currentPage }))
    }
  }

  useEffect(() => {
    setOrder("ASC")
  }, [])

  useEffect(() => {}, [order])

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this user?`}
        description="  "
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteUser(false)}
        onDelete={() => handleDeleteUser()}
        open={deleteUser}
      />
      <DeleteConfirmationDialog
        title="Change Status"
        description={`Are you sure you want to ${
          userStatus === "ACTIVATED" ? "deactivate" : "activate"
        } this account ?`}
        btnText={`${userStatus === "ACTIVATED" ? "Deactivate" : "Activate"}`}
        onDelete={() => handleUserStatus()}
        onClose={() => setisStatusOpenPopup(false)}
        open={isStatusOpenPopup}
      />
      <Paper>
        <TableContainer sx={{ maxHeight: 700 }}>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: index == 0 ? 1 : 0.6 }}>
                      {column.label}{" "}
                      {column?.tooltip && (
                        <Tooltip title={column.tooltip}>
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      )}
                      {column?.isSorting && column?.sortKey && (
                        <span
                          onClick={() => handleSort(column?.sortKey)}
                          style={{ cursor: "pointer" }}>
                          {order === "DESC" ? (
                            <ArrowDownwardIcon fontSize="small" />
                          ) : (
                            <ArrowUpwardIcon fontSize="small" />
                          )}
                        </span>
                      )}
                      {index == 0 && (
                        <span>
                          {selectAll && exceptQuestions?.length === 0 ? (
                            <CheckCircleIcon
                              htmlColor={themes.palette.secondary.main}
                              onClick={handleQuestionSelect}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon onClick={handleQuestionSelect} />
                          )}
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows?.map((row, index) => {
                  return (
                    <TableRow
                      sx={
                        row?.user?.status == "DEACTIVATED" ? { opacity: "0.6" } : { opacity: "1" }
                      }
                      key={row?.user.id}>
                      <TableCell align="center">
                        {row?.user?.status !== "DEACTIVATED" ? (
                          checkSelected(row?.user?.id) ? (
                            <CheckCircleIcon
                              htmlColor={themes.palette.secondary.main}
                              onClick={() => handleSelectQuestion(row?.user?.id)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSelectQuestion(row?.user?.id)}
                            />
                          )
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <div className={styles.rank}>
                          {currentPage * itemsPerPage - (itemsPerPage - 1) + index}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={`${styles.tableAvatar}`}>
                          {row?.user?.image ? (
                            <ProfileDisplayer image={row?.user.image} />
                          ) : (
                            <div className={styles.tableAvatar__img}>
                              <img src={avatar} alt="Fallback Avatar" />
                            </div>
                          )}
                          <div className="d-flex flex-column">
                            <Typography variant="body2Regular">
                              {row?.user?.full_name ?? "-"}
                            </Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {row?.user?.email ?? "-" ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {row?.user?.job_class ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {row?.user?.invitation_email || "NA"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {formatDateDetail(
                            row?.user?.email_opened,
                            row?.user?.opened_on,
                            "Not Opened"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {formatDateDetail(
                            row?.user?.last_login ? "Logged in" : "Not logged in",
                            row?.user?.last_login,
                            "Not logged in"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Chip variant="filled" color="secondary" label={`${row?.user?.status}`} />
                      </TableCell>
                      <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                        <span onClick={(e) => onOptionsClick(e, row?.user?.id, row?.user?.status)}>
                          <MoreHorizIcon
                            sx={() => ({
                              height: "24px",
                              width: "24px"
                            })}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableNoRecords colSpan={8} label="No Users Found" />
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
        <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={openMenu} onClick={handleMenuClose}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{
              sx: {
                marginTop: "15px",
                backgroundColor: themes.palette.background.default,
                borderRadius: "12px",
                "& .MuiList-root": {
                  backgroundColor: themes.palette.background.default,
                  width: "225px",
                  boxSizing: "border-box",
                  padding: "10px 0"
                }
              }
            }}>
            <StyledMenuItem
              onClick={() =>
                history.push("/super-admin/dashboard/admin-user-list/view-admin-user")
              }>
              <Typography variant="body1Regular">View</Typography>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() =>
                history.push("/super-admin/dashboard/admin-user-list/edit-admin-user")
              }>
              <Typography variant="body1Regular">Edit</Typography>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleStatus}>
              <Typography variant="body1Regular"> {`${getButtonStatus(userStatus)}`}</Typography>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleDelete}>
              <Typography sx={{ color: themes.palette.red.main }} variant="body1Regular">
                Delete
              </Typography>
            </StyledMenuItem>
          </Menu>
        </Backdrop>
      </Paper>
      {rows?.length ? (
        <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
      ) : (
        ""
      )}
    </>
  )
}

export default AdminUserListTable
