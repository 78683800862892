import React from "react"
import { Container, Row } from "react-bootstrap"
import Header from "components/Header/Header"
import { Col } from "react-bootstrap"
import ButtonNav from "components/ButtonNav"
import { useHistory } from "react-router-dom"

function UserMetricsNavBar({
  breadCrumbs,
  isUsageActive = false,
  isVideoActive = false,
  isPerformanceActive = false,
  isTopUserActive = false
}) {
  const history = useHistory()
  return (
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <Header title="User Metrics" breadCrumbs={breadCrumbs} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <ButtonNav
            isActive={isUsageActive}
            onPress={() => history.push("/dmv/dashboard/usage-dashboard")}>
            Usage Dashboard
          </ButtonNav>
          <ButtonNav
            isActive={isVideoActive}
            onPress={() => history.push("/dmv/dashboard/video-analytics")}>
            Video Analytics
          </ButtonNav>
          <ButtonNav
            isActive={isPerformanceActive}
            onPress={() => history.push("/dmv/dashboard/performance-management")}>
            Performance Management
          </ButtonNav>
          <ButtonNav
            isActive={isTopUserActive}
            onPress={() => history.push("/dmv/dashboard/top-users")}>
            Top Users
          </ButtonNav>
        </Col>
      </Row>
    </Container>
  )
}

export default UserMetricsNavBar
