import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { Fragment } from "react"
import { Container } from "react-bootstrap"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import Header from "components/Header/Header"
import InviteRateTable from "./inviteRateTable/index"
import TrTrendCardView from "./TrTrendCardView/index"

function InviteRate() {
  const [breadCrumbs, setBreadcrumbs] = useState([])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "4 Month TR Trend"
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title="4 Month TR Trend" breadCrumbs={breadCrumbs} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <InviteRateTable rows={LeaderBoardRows} columns={LeaderBoardColumns}></InviteRateTable>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5 ">
        <Row>
          <Col lg="6">
            <TrTrendCardView heading="Non Membership Opportunity" data={TRData}></TrTrendCardView>
          </Col>
          <Col lg="6">
            <TrTrendCardView heading="Invite Rate" data={inviteRateData}></TrTrendCardView>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <TrTrendCardView heading="Membership Opportunity" data={TRData}></TrTrendCardView>
          </Col>
          <Col lg="6">
            <TrTrendCardView heading="Upgrade Rate" data={inviteRateData}></TrTrendCardView>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default InviteRate

const TRData = {
  fieldData: [
    {
      id: 1,
      name: "Coconut Creek",
      monthData: ["1,459", "1,243", "2,234", "1,234"]
    },
    {
      id: 2,
      name: "Indianapolis",
      monthData: ["1,456", "1,654", "2,234", "1,876"]
    },
    {
      id: 3,
      name: "Tempa",
      monthData: ["1,094", "1,754", "2,575", "1,345"]
    },
    {
      id: 4,
      name: "Virginia Beach",
      monthData: ["1,789", "1,756", "2,756", "1,765"]
    }
  ],
  total: [
    {
      name: "Total",
      monthData: ["1,789", "1,756", "2,756", "1,765"]
    }
  ]
}
const inviteRateData = {
  fieldData: [
    {
      id: 1,
      name: "Coconut Creek",
      monthData: ["5.6%", "6.1%", "4.1%", "4.0%"]
    },
    {
      id: 2,
      name: "Indianapolis",
      monthData: ["15.3%", "15.8%", "6.7%", "12.3%"]
    },
    {
      id: 3,
      name: "Tempa",
      monthData: ["5.6%", "6.1%", "4.1%", "4.0%"]
    },
    {
      id: 4,
      name: "Virginia Beach",
      monthData: ["15.3%", "15.8%", "6.7%", "12.3%"]
    }
  ],
  total: [
    {
      name: "Total",
      monthData: ["11.0%", "10.6%", "6.7%", "8.5%"]
    }
  ]
}

const LeaderBoardColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "employeeName",
    label: "Employee Names ",
    minWidth: 100
  },
  {
    id: "Restaurant Name",
    label: "Employee Class  ",
    align: "center",
    minWidth: 100
  },
  {
    label: "Non-Member Opportunity",
    id: "Non-Member Opportunity",
    align: "center",
    minWidth: 50
  },
  {
    id: "Invite Rate",
    label: "Invite Rate",
    align: "center",
    minWidth: 100
  },
  {
    id: "Membership Opportunity",
    label: "Membership Opportunity",
    align: "center",
    minWidth: 10
  },
  {
    id: "Upgrade Rate",
    label: "Upgrade Rate",
    align: "center",
    minWidth: 50
  }
]

const LeaderBoardRows = [
  {
    id: 1,
    rank: 1,
    name: "Scott Hatteberg",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    restaurantName: "Virginia Beach",
    nonMember: "10",
    inviteRate: "80%",
    membership: "80",
    upgradeRate: "10.0%"
  },
  {
    id: 2,
    rank: 2,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    restaurantName: "Coconut Creek",
    nonMember: "6",
    inviteRate: "40%",
    membership: "72",
    upgradeRate: "30.0%"
  },
  {
    id: 3,
    rank: 3,
    name: "Marcella Ellis",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    restaurantName: "Virginia Beach",
    nonMember: "6",
    inviteRate: "40%",
    membership: "72",
    upgradeRate: "30.0%"
  },
  {
    id: 4,
    rank: 4,
    name: "Albert Flores",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    restaurantName: "",
    nonMember: "8",
    inviteRate: "50%",
    membership: "40",
    upgradeRate: "10.0%"
  },
  {
    id: 5,
    rank: 5,
    name: "Devon Lane",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    restaurantName: "Virginia Beach",
    nonMember: "5",
    inviteRate: "20%",
    membership: "30",
    upgradeRate: "30.0%"
  },
  {
    id: 6,
    rank: 6,
    name: "Scott Hatteberg",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    restaurantName: "Coconut Creek",
    nonMember: "10",
    inviteRate: "80%",
    membership: "80",
    upgradeRate: "10.0%"
  },
  {
    id: 7,
    rank: 7,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    restaurantName: "indianapolis",
    nonMember: "6",
    inviteRate: "40%",
    membership: "72",
    upgradeRate: "30.0%"
  },
  {
    id: 8,
    rank: 8,
    name: "Marcella Ellis",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    restaurantName: "Virginia Beach",
    nonMember: "6",
    inviteRate: "40%",
    membership: "72",
    upgradeRate: "30.0%"
  },
  {
    id: 9,
    rank: 9,
    name: "Albert Flores",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    restaurantName: "Coconut Creek",
    nonMember: "8",
    inviteRate: "50%",
    membership: "40",
    upgradeRate: "10.0%"
  },
  {
    id: 10,
    rank: 10,
    name: "Devon Lane",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    restaurantName: "indianapolis",
    nonMember: "5",
    inviteRate: "20%",
    membership: "30",
    upgradeRate: "30.0%"
  }
]
