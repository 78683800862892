import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { getGamificationLeaderboardDataAction } from "redux/marketing/actions"
import { appActions } from "redux/app/slice/app"
import { marketingActions } from "redux/marketing/slice/user"
import GamificationSummaryTable from "./GamificationSummaryTable"
import Star from "assets/icons/bonusStar.svg"

const GamificationLeaderboard = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [record, setRecord] = useState([])
  const gamificationData = useSelector((state) => state?.marketing?.gamificationData ?? [])
  const dispatch = useDispatch()
  const { date, employeeClass, orderBy } = useSelector((store) => store?.app?.filters || {})

  useEffect(() => {
    setCurrentPage(1)
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Gamification Leader Board"
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [date, employeeClass, orderBy])

  useEffect(() => {
    dispatch(
      getGamificationLeaderboardDataAction({
        date: date,
        page: currentPage,
        employeeClass: employeeClass,
        orderBy: orderBy
      })
    )
  }, [currentPage, date, employeeClass, orderBy])

  useEffect(() => {
    dispatch(appActions.initFilters())
    return () => dispatch(marketingActions.setUserId(""))
  }, [])

  useEffect(() => {
    const itemsPerPage = 12
    const totalPages = Math.ceil(gamificationData.length / itemsPerPage)
    setPages(totalPages)

    const leaderboardList =
      gamificationData?.length &&
      gamificationData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    setRecord(leaderboardList || [])
  }, [gamificationData])

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title="Gamification Leaderboard" breadCrumbs={breadCrumbs}></Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <GamificationSummaryTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={pages}
              rows={record || []}
              columns={columns}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default GamificationLeaderboard

const columns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 60
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 220
  },
  {
    id: "Profile Photo",
    label: "Profile Photo",
    align: "center",
    minWidth: 131,
    isSorting: true,
    sortId: "profile_picture"
  },
  {
    id: "Featured Items",
    label: "Featured Items",
    align: "center",
    minWidth: 146,
    isSorting: true,
    sortId: "featured_item"
  },
  {
    id: "Tasting Notes",
    label: "Tasting Notes",
    align: "center",
    minWidth: 140,
    isSorting: true,
    sortId: "tasting_notes"
  },
  {
    id: "Trivia Award",
    label: "Trivia Award",
    align: "center",
    minWidth: 128,
    isSorting: true,
    sortId: "trivia_award"
  },
  {
    id: "Flash Cards",
    label: "Flash Cards",
    align: "center",
    minWidth: 125,
    isSorting: true,
    sortId: "flash_cards"
  },
  {
    id: "Events RSVP",
    label: "Events RSVP",
    align: "center",
    minWidth: 135,
    isSorting: true,
    sortId: "event_rsvp"
  },
  {
    id: "5-Star Review",
    label: "5-Star Review",
    align: "center",
    minWidth: 155,
    isSorting: true,
    sortId: "review"
  },
  {
    id: "Review with Positive Verbatim",
    label: "Review with Positive Verbatim",
    align: "center",
    minWidth: 175,
    isSorting: true,
    sortId: "positive_review_coins"
  },
  {
    id: "Bonus Coins",
    label: "Bonus Coins",
    align: "center",
    minWidth: 130,
    icon: Star
  },
  {
    id: "Trivia Coins",
    label: "Trivia Coins",
    align: "center",
    minWidth: 110
  },
  {
    id: "Total Coins",
    label: "Total Coins",
    align: "center",
    minWidth: 80
  }
]
