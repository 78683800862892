import { Box, Typography } from "@mui/material"
import React from "react"
import styles from "../PendingQuestionTable.module.scss"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

const Question = ({ row, questionIndex }) => {
  return (
    <Box className={styles.question}>
      <Typography
        sx={{ margin: "0px 0px 4px 52px" }}
        variant="caption3"
        className={styles.questionHeader}>
        {`${row.category} | ${row.level}`}
      </Typography>
      <Box className={styles.questionTitle}>
        <Typography variant="body1Regular">{questionIndex + 1}</Typography>
        <ArrowForwardIcon sx={{ marginLeft: "14px" }} />
        <Typography variant="h4" sx={{ marginLeft: "6px" }}>
          {row.title}
        </Typography>
      </Box>
      {row?.answer?.map((option, index) => (
        <Box
          key={option.id}
          className={`${styles.option} ${option.is_correct ? styles.optionCorrect : ""}`}>
          <div className={styles.optionNumber}>
            <Typography variant="tag1">{(index + 10).toString(36).toUpperCase()}</Typography>
          </div>
          <Typography
            className={styles.optionName}
            sx={{ marginLeft: "10px" }}
            variant="body2Regular">
            {option.answer}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default Question
