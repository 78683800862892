import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import InfoIcon from "@mui/icons-material/Info"
import { useRef } from "react"
import { Backdrop, Menu, MenuItem } from "@mui/material"
import { useTheme } from "@mui/system"
import AppPagination from "components/StyledComponents/AppPagination"
import { useDispatch, useSelector } from "react-redux"
import { superAdminAction } from "redux/superAdmin/slice/user"
import TableNoRecords from "components/TableNoRecords"
import { appActions } from "redux/app/slice/app"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ReleaseForm from "../../addRelease"
import { deleteVersionAction, getVersionDataAction } from "redux/superAdmin/actions"
import { formatDate } from "helpers/functions"
import styles from "./releaseList.module.scss"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}))

const orderStat = {
  ASC: "hire_date",
  DESC: "-hire_date"
}

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "sticky",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const AppReleaseListTable = ({ columns = [], rows = [], setPage, currentPage, totalPage }) => {
  const [anchorEl, setAnchorEl] = useState(false)
  const rowId = useRef(null)
  const openMenu = Boolean(anchorEl)
  const [order, setOrder] = useState(orderStat.DESC)
  const [openForm, setOpenForm] = useState(false)
  const dispatch = useDispatch()
  const themes = useTheme()
  const { versionId } = useSelector((store) => store?.superAdmin || [])
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const onOptionsClick = (e, id) => {
    e.stopPropagation()
    rowId.current = id
    setAnchorEl(e.currentTarget)
    dispatch(superAdminAction.setVersionID(rowId.current))
  }

  function handleSort() {
    if (order === orderStat.DESC) {
      setOrder("hire_date")
      dispatch(appActions.setOrderBy("hire_date"))
    } else {
      setOrder("-hire_date")
      dispatch(appActions.setOrderBy("-hire_date"))
    }
  }

  const handleCloseForm = () => {
    setOpenForm(false)
    dispatch(superAdminAction.setVersionID(null))
  }

  const handleOpenForm = () => {
    setOpenForm(true)
  }

  const handleOpenDelete = () => {
    setIsDeletePopupOpen(true)
  }

  const handleCloseDelete = () => {
    setIsDeletePopupOpen(false)
  }

  useEffect(() => {
    setOrder("-hire_date")
    dispatch(appActions.setOrderBy("-hire_date"))
  }, [])

  useEffect(() => {
    if (versionId) {
      dispatch(getVersionDataAction())
    }
  }, [versionId])

  useEffect(() => {}, [order])

  return (
    <>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        onClose={handleCloseDelete}
        onDelete={() => dispatch(deleteVersionAction())}
        description="Are you sure you want to delete this release version?"
      />
      <ReleaseForm isEdit={true} open={openForm} onClose={handleCloseForm}></ReleaseForm>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 700 }}>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}{" "}
                      {column?.tooltip && (
                        <Tooltip title={column.tooltip}>
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      )}
                      {column?.isSorting && (
                        <span onClick={handleSort} style={{ cursor: "pointer" }}>
                          {order === "-hire_date" ? (
                            <ArrowDownwardIcon fontSize="small"></ArrowDownwardIcon>
                          ) : (
                            <ArrowUpwardIcon fontSize="small"></ArrowUpwardIcon>
                          )}
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows?.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        <div className="d-flex flex-column">
                          <Typography
                            className={row?.device === "IOS" ? styles.capitalize : ""}
                            variant="body2Regular">
                            {row?.device ?? "-"}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.title ?? "-"}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.version ?? "-"}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.status ?? "-"}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {row?.is_mandatory ? "Yes" : "No"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {row?.published_by?.full_name ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {formatDate(row?.created_at) ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                        <span onClick={(e) => onOptionsClick(e, row?.id)}>
                          <MoreHorizIcon
                            sx={() => ({
                              height: "24px",
                              width: "24px"
                            })}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableNoRecords colSpan={8} label="No Release Found" />
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
        <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={openMenu} onClick={handleMenuClose}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{
              sx: {
                marginTop: "15px",
                backgroundColor: themes.palette.background.default,
                borderRadius: "12px",
                "& .MuiList-root": {
                  backgroundColor: themes.palette.background.default,
                  width: "225px",
                  boxSizing: "border-box",
                  padding: "10px 0"
                }
              }
            }}>
            <StyledMenuItem onClick={handleOpenForm}>
              <Typography variant="body1Regular">Edit</Typography>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleOpenDelete}>
              <Typography variant="body1Regular">Delete</Typography>
            </StyledMenuItem>
          </Menu>
        </Backdrop>
      </Paper>
      {rows?.length ? (
        <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
      ) : (
        ""
      )}
    </>
  )
}

export default AppReleaseListTable
