import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton, Grid } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import AppTextArea from "components/StyledComponents/AppTextArea"
import { useDispatch, useSelector } from "react-redux"
import MultiSelect from "components/StyledComponents/MultipleSelect"
import AppDatePicker from "components/AppDatePicker"
import StyledLabel from "components/StyledComponents/StyledLabel"
import { uploadNotificationImageAction } from "redux/superAdmin/actions/user"
import { superAdminAction } from "redux/superAdmin/slice/user"

const buttonText = {
  EDIT: "Update",
  SAVE: "Save"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 1000,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

function NotificationForm({ formik, open = false, onClose = () => {}, isEdit = false }) {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)
  const { userRole } = useSelector((store) => store?.superAdmin ?? [])

  const dispatch = useDispatch()

  useEffect(() => {
    formik.resetForm({
      notificationName: "",
      uploadThumbnail: "",
      scheduleNotificationDate: "",
      scheduleNotificationTime: "",
      employeeClass: ["All"],
      notificationContent: ""
    })
    dispatch(superAdminAction?.setNotificationImageId(""))
    dispatch(superAdminAction?.setNotificationData({}))
  }, [open])

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  const handleImageDelete = (formik) => {
    formik.setFieldValue("uploadThumbnail", null)
  }
  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("uploadThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "uploadThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("uploadThumbnail", image)
      dispatch(uploadNotificationImageAction(data))
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "uploadThumbnail" && 1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        {showCropperModal(imageObjectURL, fileDetails, formik)}
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2"> {isEdit ? "Edit" : "Add New"} Notification</Typography>
        </Box>

        <Box flexDirection="column" className="mt-4">
          <React.Fragment>
            <Box className=" w-100 py-2">
              <Row className="w-100 m-0">
                <Col lg="6">
                  <AppTextField
                    id="notificationName"
                    name="notificationName"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Notification Name"
                    isRequired={true}
                    placeholder="Enter Notification Name"
                    value={formik.values.notificationName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.notificationName && Boolean(formik.errors.notificationName)
                    }
                    helperText={formik.touched.notificationName && formik.errors.notificationName}
                  />
                </Col>
                <Col lg="6">
                  <ImageUploadField
                    label="Upload Thumbnail"
                    // isRequired={true}
                    isVariant={true}
                    placeholder="Upload Image"
                    value={formik.values.uploadThumbnail}
                    onChange={(e) => onImageUpload(e)}
                    onDelete={() => handleImageDelete(formik)}
                    error={formik.errors.uploadThumbnail}
                    touched={formik.touched.uploadThumbnail}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="6">
                  <Grid item sm={12} lg={6} className="datesContainer">
                    <StyledLabel variant="body1Regular">
                      {"Schedule Notification Date"}
                      <span>*</span>
                    </StyledLabel>
                    <Box className="dateFieldContainer w-100 mt-2">
                      <AppDatePicker
                        minDate={new Date()}
                        maxDate={new Date().setMonth(new Date().getMonth() + 5)}
                        inputFormat="dd MMM yyyy"
                        disableMaskedInput
                        key={"scheduleNotificationDate"}
                        value={formik.values.scheduleNotificationDate}
                        onChange={(val) => formik.setFieldValue("scheduleNotificationDate", val)}
                        error={formik.errors.scheduleNotificationDate}
                        touched={formik.touched.scheduleNotificationDate}
                      />
                    </Box>
                  </Grid>
                </Col>
                <Col lg="6">
                  <AppTextField
                    type="time"
                    fullWidth
                    label="Schedule Notification Time"
                    isRequired={true}
                    isUpperCase={true}
                    value={formik.values.scheduleNotificationTime}
                    onChange={formik.handleChange("scheduleNotificationTime")}
                    error={
                      formik.touched.scheduleNotificationTime &&
                      Boolean(formik.errors.scheduleNotificationTime)
                    }
                    helperText={
                      formik.touched.scheduleNotificationTime &&
                      formik.errors.scheduleNotificationTime
                    }
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="6">
                  <MultiSelect
                    id="role"
                    name="role"
                    menuItems={userRole}
                    label="Role"
                    isRequired={true}
                    value={formik.values?.role}
                    onChange={formik.handleChange}
                    error={formik.errors.role}
                    touched={formik.touched.role}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextArea
                    label="Notification Content"
                    minRows={5}
                    maxRows={15}
                    isRequired={true}
                    placeholder="Enter Notification Content"
                    value={formik.values.notificationContent}
                    onChange={formik.handleChange("notificationContent")}
                    error={formik.errors?.notificationContent}
                    touched={formik.touched?.notificationContent}
                    charLimit={100}
                  />
                </Col>
              </Row>
            </Box>
          </React.Fragment>
        </Box>
        <Box className="mt-5 d-flex justify-content-around">
          <AppButton
            onClick={formik.handleSubmit}
            sx={() => ({
              height: "48px",
              padding: "10px 50px"
            })}>
            {isEdit ? buttonText.EDIT : buttonText.SAVE}
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default NotificationForm
