import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@mui/material"
import TableNoRecords from "components/TableNoRecords"
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    "& .MuiPaper-root": {
      backgroundColor: "red"
    },
    "& .MuiTableRow-hover": {
      backgroundColor: "red"
    },
    "& .MuiTableCell-root": {
      padding: "24px 16px",
      backgroundColor: theme.palette.background.main,
      borderBottom: "1px solid #353535"
    }
  }
}))

const TriviaAnalyticsTable = ({ columns, rows, rowsData }) => {
  const classes = useStyles()

  if (rowsData === undefined) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table className={classes.root} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <span
                        style={{
                          border: "2px solid #CC4B37",
                          borderRadius: 6,
                          padding: "12px 10px"
                        }}>
                        {row.rank}
                      </span>
                    </TableCell>
                    <TableCell>{row.questions}</TableCell>
                    <TableCell align="center">{row.triviaCategory}</TableCell>
                    <TableCell align="center">{row.difficultyLevel}</TableCell>
                    <TableCell align="center">{row.incorrect_count}</TableCell>
                    <TableCell align="center">{row.players_answered}</TableCell>
                    <TableCell align="right">
                      <Typography variant="body2Regular" sx={{ paddingRight: "20px" }}>
                        {row.inCorrect}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  } else {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table className={classes.root} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsData?.length ? (
                rowsData?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <span
                          style={{
                            border: "2px solid #CC4B37",
                            borderRadius: 6,
                            padding: "12px 10px"
                          }}>
                          {index + 1}
                        </span>
                      </TableCell>

                      <TableCell>{row?.questions?.title}</TableCell>
                      <TableCell align="center">{row?.questions?.category}</TableCell>
                      <TableCell align="center">{row?.questions?.level}</TableCell>
                      <TableCell align="center">{row?.questions?.incorrect_count}</TableCell>
                      <TableCell align="center">{row?.players_answered}</TableCell>
                      <TableCell align="right">
                        <Typography variant="body2Regular" sx={{ paddingRight: "20px" }}>
                          {row?.percentage_incorrect !== null
                            ? `${parseInt(row?.percentage_incorrect ?? 0).toFixed(0)}%`
                            : "0%"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableNoRecords colSpan={6} label="No Questions Found" />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}

export default TriviaAnalyticsTable
