import React from "react"
import { Box, Dialog, DialogContent, styled, Typography } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import { Formik } from "formik"
import { validationSchemaCommentAdd } from "helpers/validationSchema"
import { useDispatch, useSelector } from "react-redux"
import { addCommentCalenderAction, EditCommentCalenderAction } from "redux/marketing/actions"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 12,
    border: "none",
    width: 500,
    padding: 35,
    boxSizing: "border-box"
  }
}))

function CommentModal({ data, open, onClose = () => {}, onAdd = () => {} }) {
  const { calendarData } = useSelector((store) => store?.learningDevelopment || [])
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    if (data?.notes?.id) {
      dispatch(EditCommentCalenderAction({ id: data?.notes?.id, note: e.comment }))
    } else {
      dispatch(
        addCommentCalenderAction({
          month: calendarData[data?.index - 1]?.start_date,
          note: e.comment
        })
      )
    }
    onAdd()
    onClose(false)
  }

  return (
    <StyledDialog open={open} onClose={() => onClose(false)}>
      <DialogContent>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2">{data?.notes?.id ? "Edit" : "Add"} a Comment</Typography>
        </Box>
        <Formik
          initialValues={{
            comment: data?.notes?.id ? data?.notes?.note : ""
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchemaCommentAdd}>
          {(formik) => (
            <>
              <Box flexDirection="column">
                <React.Fragment>
                  <Box className="d-flex justify-content-between w-100 py-2">
                    <AppTextField
                      id="comment"
                      name="comment"
                      fullWidth
                      label="Comment"
                      isRequired={true}
                      placeholder="Enter Comment"
                      value={formik.values.comment}
                      onChange={formik.handleChange}
                      error={formik.touched.comment && Boolean(formik.errors.comment)}
                      helperText={formik.errors.comment}
                    />
                  </Box>
                </React.Fragment>
              </Box>
              <Box className="mt-5 d-flex justify-content-around">
                <AppButton
                  onClick={() => onClose(false)}
                  sx={{ height: "48px", width: 150 }}
                  variant="outlined">
                  Cancel
                </AppButton>
                <AppButton
                  onClick={formik.handleSubmit}
                  sx={(theme) => ({
                    height: "48px",
                    width: 150,
                    backgroundColor: theme.palette.secondary.main
                  })}>
                  {data?.notes?.id ? "Update" : "Add"}
                </AppButton>
              </Box>
            </>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  )
}

export default CommentModal
