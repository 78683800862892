import { createAction } from "@reduxjs/toolkit"

export const getUserTypeListAction = createAction("getUserTypeListAction")

export const getFrontlineUsersListAction = createAction("getFrontlineUsersListAction")

export const uploadFrontlineUserAction = createAction("uploadFrontlineUserAction")
export const getFrontlineUsersDataAction = createAction("getFrontlineUsersDataAction")
export const deleteUserAction = createAction("deleteUserAction")
export const EditFrontlineUsersDataAction = createAction("EditFrontlineUsersDataAction")

export const updateUserStatusAction = createAction("updateUserStatusAction")
export const uploadFrontlineBulkUserAction = createAction("uploadFrontlineBulkUserAction")
export const getAdminUsersListAction = createAction("getAdminUsersListAction")

export const uploadAdminUserAction = createAction("uploadAdminUserAction")

export const getUserMainListAction = createAction("getUserMainListAction")
export const getRoleUsersListAction = createAction("getRoleUsersListAction")
export const getNewRestaurantListAction = createAction("getNewRestaurantListAction")

export const getAdminUsersDataAction = createAction("getAdminUsersDataAction")
export const EditAdminUsersDataAction = createAction("EditAdminUsersDataAction")

export const updateAdminUserStatusAction = createAction("updateAdminUserStatusAction")
export const uploadFrontlineBulkUserAdminAction = createAction("uploadFrontlineBulkUserAdminAction")

export const getDashboardStatsAction = createAction("getDashboardStatsAction")

export const revertAllSuperAdmin = createAction("revertAllSuperAdmin")

export const getVersionListAction = createAction("getVersionListAction")

export const addVersionAction = createAction("addVersionAction")
export const getVersionDataAction = createAction("getVersionDataAction")
export const updateVersionAction = createAction("updateVersionAction")
export const deleteVersionAction = createAction("deleteVersionAction")
export const addNotificationAction = createAction("addNotificationAction")
export const getNotificationListAction = createAction("getNotificationList")
export const deleteNotificationAction = createAction("deleteNotification")
export const sendNotificationAction = createAction("sendNotification")
export const getNotificationsAction = createAction("getNotifications")
export const EditNotificationAction = createAction("EditNotificationAction")
export const uploadNotificationImageAction = createAction("uploadNotificationImageAction")
export const addGiftCardAction = createAction("addGiftCardAction")
export const getGiftCardListAction = createAction("getGiftCardList")
export const deleteGiftCardAction = createAction("deleteGiftCard")
export const getGiftCardsAction = createAction("getGiftCard")
export const EditGiftCardAction = createAction("EditGiftCardAction")
export const uploadGiftCardImageAction = createAction("uploadGiftCardImageAction")
export const getGamificationDataAction = createAction("getGamificationDataAction")
export const EditGamificationAction = createAction("EditGamificationAction")
export const getEventDataAction = createAction("getEventDataAction")
export const addEventAction = createAction("addEventAction")
export const deleteEventAction = createAction("deleteEventAction")
export const getEventDetailsAction = createAction("getEventDetailsAction")
export const editEventAction = createAction("editEventAction")
export const addMenuImageAction = createAction("addMenuImageAction")
export const getMenuImageAction = createAction("getMenuImageAction")
export const uploadMenuImageAction = createAction("uploadMenuImageAction")
export const downloadMenuTemplateAction = createAction("downloadMenuTemplateAction")
export const getDynamicMenuFieldsAction = createAction("getDynamicMenuFieldsAction")
export const deleteMenuFieldAction = createAction("deleteMenuFieldAction")
export const createDynamicMenuFieldsAction = createAction("createDynamicMenuFieldsAction")
export const editDynamicMenuFieldsAction = createAction("editDynamicMenuFieldsAction")
export const sendInviteAction = createAction("sendInviteAction")
export const getUserRoleAction = createAction("getUserRoleAction")
export const getRoleDetailsAction = createAction("getRoleDetailsAction")
export const editRoleDetailsAction = createAction("editRoleDetailsAction")
export const addRoleDetailsAction = createAction("addRoleDetailsAction")
export const deleteRoleDetailsAction = createAction("deleteRoleDetailsAction")
