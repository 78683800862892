import React from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import styles from "./likeDislike.module.scss"
import likeIcon from "assets/icons/thumbsUp.svg"
import unLikeIcon from "assets/icons/thumbsDown.svg"
import avatar from "assets/images/avatarUser.svg"
import { useTheme } from "@emotion/react"
import { useSelector } from "react-redux"
import ProfileDisplayer from "components/ProfileDisplayer"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 753,
    minHeight: 605,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 34,
    boxSizing: "border-box"
  }
}))
function LikeDislikeDialog({
  open = false,
  onClose = () => {},
  choice,
  setChoice,
  isLike,
  setIsLike
}) {
  const { likeDislikeData } = useSelector((state) => state?.learningDevelopment)
  const theme = useTheme()

  const style = {
    closeBtnContainer: {
      color: theme.palette.text.primary,
      position: "absolute",
      right: "32px",
      top: "32px",
      backgroundColor: theme.palette.background.main
    },
    closeBtn: {
      width: "16px",
      height: "16px"
    }
  }
  const handleClick = (preference) => {
    if (preference === "like") {
      setIsLike(true)
    } else {
      setIsLike(false)
    }
    setChoice(preference)
  }

  const likedData = likeDislikeData.liked_user_data
  const dislikedData = likeDislikeData.disliked_user_data

  return (
    <StyledDialog open={open}>
      <DialogContent>
        <Box className="d-flex justify-content-end">
          <IconButton onClick={onClose} sx={style.closeBtnContainer}>
            <CloseIcon sx={style.closeBtn} />
          </IconButton>
        </Box>
        <Box className="d-flex justify-content-center">
          <Typography variant="h2">Counts</Typography>
        </Box>
        <Box className={styles.likeDislikeContainer}>
          <Box
            className={`${styles.optionBox} ${choice === "like" ? styles.selectedOption : ""}`}
            onClick={() => handleClick("like")}>
            <img src={likeIcon} className={styles.icon} alt="Like Icon" />
            <Typography variant="body2Medium" className={`${styles.count}`}>
              {likeDislikeData?.liked_count < 10
                ? `0${likeDislikeData?.liked_count}`
                : likeDislikeData?.liked_count}
            </Typography>
            <Typography variant="body1small">
              {likeDislikeData?.liked_count > 1 ? "Likes" : "Like"}
            </Typography>
          </Box>
          <Box className={styles.divider} />
          <Box
            className={`${styles.optionBox} ${choice === "dislike" ? styles.selectedOption : ""}`}
            onClick={() => handleClick("dislike")}>
            <img src={unLikeIcon} className={styles.icon} alt="Dislike Icon" />
            <Typography variant="body2Medium" className={`${styles.count}`}>
              {likeDislikeData?.disliked_count < 10
                ? `0${likeDislikeData?.disliked_count}`
                : likeDislikeData?.disliked_count}
            </Typography>
            <Typography variant="body1small">
              {likeDislikeData?.disliked_count > 1 ? "Dislikes" : "Dislike"}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.countsContainer}>
          {(isLike ? likedData : dislikedData).length < 1 ? (
            <Box className={styles.noDataContainer}>
              <Typography variant="h2">No Data Found</Typography>
            </Box>
          ) : (
            (isLike ? likedData : dislikedData).map((user, index) => (
              <Box key={index} className={styles.countItem}>
                <Box className={styles.rank}>{index + 1}</Box>
                {user?.image_url !== null ? (
                  <ProfileDisplayer image={user?.image_url} isPopupAvatar={true} />
                ) : (
                  <img src={avatar} />
                )}
                <Typography>{user.name}</Typography>
              </Box>
            ))
          )}
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default LikeDislikeDialog
