import { Box } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import InfoCard from "../../../../components/PerformanceSummary/InfoCard/infoCard"
import PerformanceSummaryTable from "components/PerformanceSummary/PerformanceSummaryTable"
import { useDispatch, useSelector } from "react-redux"
import { getLeaderboardDataAction } from "redux/marketing/actions"
import { appActions } from "redux/app/slice/app"
import { marketingActions } from "redux/marketing/slice/user"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
import { getUserRoleAction } from "redux/superAdmin/actions"

const GMPerformanceSummary = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const data = useSelector((state) => state?.marketing?.leaderboard?.data ?? [])
  const dispatch = useDispatch()
  const { date, employeeClass, orderBy } = useSelector((store) => store?.app?.filters || {})
  const history = useHistory()

  const handleGamificationLeaderboard = () => {
    history.push("/marketing/dashboard/leaderboard/gamification-leaderboard")
  }

  function handleQuizLeaderBoard() {
    history.push("/marketing/dashboard/leaderboard/quiz-leaderboard")
  }

  useEffect(() => {
    setCurrentPage(1)
    setBreadcrumbs(createBreadcrumbs())
    dispatch(getUserRoleAction())
    document.title = "Leader Board"
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [date, employeeClass, orderBy])

  useEffect(() => {
    dispatch(
      getLeaderboardDataAction({
        date: date,
        page: currentPage,
        employeeClass: employeeClass,
        orderBy: orderBy
      })
    )
  }, [currentPage, date, employeeClass, orderBy])

  useEffect(() => {
    dispatch(appActions.initFilters())
    return () => dispatch(marketingActions.setUserId(""))
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title="Frontline Performance" breadCrumbs={breadCrumbs}>
              <AppButton
                sx={{ marginRight: "32px", padding: "12px 24px" }}
                onClick={() => handleQuizLeaderBoard()}>
                Quiz Leaderboard
              </AppButton>
              <AppButton
                onClick={() => handleGamificationLeaderboard()}
                sx={{ padding: "13px 36px" }}>
                Gamification Leaderboard
              </AppButton>
            </Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Box className="d-flex w-100 flex-wrap">
              <InfoCard color="#3ADB76" style={{ paddingLeft: 0 }}>
                Top Servers
              </InfoCard>
              <InfoCard color="#9CC43B">Doing Great</InfoCard>
              <InfoCard color="#FFAE00">Average</InfoCard>
              <InfoCard color="#E67C1B">Underperforming</InfoCard>
              <InfoCard color="#CC4B37" style={{ paddingRight: 0 }}>
                Poor
              </InfoCard>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <PerformanceSummaryTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={data.pages}
              rows={data.results}
              columns={columns}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default GMPerformanceSummary

const columns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 220
  },
  {
    id: "Has App",
    label: "Has App",
    align: "center",
    minWidth: 90
  },
  {
    id: "Trivia Correct Answers",
    label: "Trivia Correct Answers",
    align: "center",
    minWidth: 90,
    isSorting: true,
    sortId: "correct_answer"
  },
  {
    id: "Trivia Question Played",
    label: "Trivia Question Played",
    align: "center",
    minWidth: 100,
    isSorting: true,
    sortId: "total_attempts"
  },
  {
    id: "Coins Earned",
    label: "Total Coins Earned",
    align: "center",
    minWidth: 100,
    isSorting: true,
    sortId: "cooper_coins"
  },
  {
    id: "Trivia Correct %",
    label: "Trivia Correct %",
    align: "center",
    minWidth: 100,
    isSorting: true,
    sortId: "user_correct_percent"
  }
]
