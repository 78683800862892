import React, { useState } from "react"
import Card from "components/Card/Card"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Typography, useMediaQuery } from "@mui/material"
import MenuListItems from "../menuListItems/index"
import trashIcon from "assets/icons/trash.svg"
import { useTheme } from "@mui/system"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { DeleteMenuCategoryAction } from "redux/marketing/actions"
import editIcon from "assets/icons/edit.svg"
import classes from "../../pages/private/marketing/menu/menu.module.scss"
import AddMenuItemButton from "components/StyledComponents/addNewItemButton"

function MenuListContainer({
  data,
  isEdit = false,
  setCategoryModal = () => {},
  setIsEdit = () => {},
  setIsEditModalItem = () => {},
  setCurrentCategoryData = () => {},
  setMenuItemsModal = () => {}
}) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const categoryLength = data?.food_category?.length || 0
  const isSmall = useMediaQuery("(max-width:1440px)")

  function EditCategoryModal(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    setCategoryModal(true)
    setIsEdit(true)
    setCurrentCategoryData(data)
  }

  function handleMenuPeriodDelete(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    dispatch(DeleteMenuCategoryAction({ mealCategoryId: id }))
  }

  function handleMenuDeleteModal(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    setDeleteModal(true)
  }

  function handleAddItems(id) {
    setIsEditModalItem(false)
    dispatch(marketingActions.setMenuCategoryId(id))
    setMenuItemsModal(true)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete “${data?.food_category ?? ""}”?`}
        description={`"${data?.food_category ?? ""}" has ${data?.food_list?.length ?? ""} ${
          data?.food_list?.length <= 1 ? "dish" : "dishes"
        }`}
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteModal(false)}
        onDelete={() => handleMenuPeriodDelete(data?.food_category_id)}
        open={modal}></DeleteConfirmationDialog>

      <Card style={{ height: "fit-content" }}>
        <Row>
          <Col lg="12">
            <div
              className="d-flex justify-content-between align-item-center"
              style={{
                backgroundColor: isEdit && theme.palette.background.default,
                padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                borderRadius: 6
              }}>
              <div className="d-flex positon-absolute">
                <Typography variant="h2">
                  {data?.food_category}
                  {isEdit && (
                    <img
                      className={classes.edit}
                      src={editIcon}
                      onClick={() => EditCategoryModal(data?.food_category_id)}
                    />
                  )}
                </Typography>
              </div>
              {isEdit && (
                <div className="d-flex align-items-center">
                  <AddMenuItemButton
                    onAddItems={() => handleAddItems(data?.food_category_id)}
                    text="Add Menu Item"
                    typographyClass={classes.addButton}
                    {...(isSmall && categoryLength > 23 ? { isLongCategory: true } : {})}
                  />
                  <img
                    className={classes.trashIcon}
                    onClick={() => handleMenuDeleteModal(data?.food_category_id)}
                    src={trashIcon}
                    alt=""
                  />
                </div>
              )}
            </div>
          </Col>
          <Col lg="12">
            {data?.food_list?.length ? (
              data?.food_list?.map((items, idx) => {
                return (
                  <MenuListItems
                    categoryIdData={data?.food_category_id}
                    setIsEditModalItem={setIsEditModalItem}
                    setMenuItemsModal={setMenuItemsModal}
                    isEdit={isEdit}
                    key={idx}
                    foodList={items}></MenuListItems>
                )
              })
            ) : (
              <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                No Items Found
              </Typography>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default React.memo(MenuListContainer)
