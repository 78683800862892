import Header from "components/Header/Header"
import AppButtonGradient from "components/StyledComponents/AppButtonGradient"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Typography } from "@mui/material"
import PerformanceMgmtVideos from "./components/PerformanceMgmtVideos"
import UploadVideo from "./components/UploadVideo"
import { useDispatch, useSelector } from "react-redux"
import { getVideoGalleryVideosAction } from "redux/DMV/actions"
import { DMVActions } from "redux/DMV/slice/user"

const PerformanceManagementContent = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [isUploadVideoPopupOpen, setIsUploadVideoPopupOpen] = useState(false)
  const [editModeData, setEditModeData] = useState(null)
  const [selectedTab, setSelectedTab] = useState()
  const { isDeleted } = useSelector((store) => store?.dmv?.videoGallery || {})
  const dispatch = useDispatch()

  const onTabChange = (tab) => {
    setSelectedTab(tab)
    fetchData(tab?.value)
  }

  const toggleUploadVideoPopup = () => {
    setIsUploadVideoPopupOpen((isOpen) => {
      if (isOpen) {
        fetchData(selectedTab?.value)
      }
      return !isOpen
    })
  }

  const fetchData = (value) => {
    console.log({ value })
    dispatch(getVideoGalleryVideosAction(value))
  }

  const onUploadClick = () => {
    setEditModeData(null)
    toggleUploadVideoPopup()
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Performance management Content"
  }, [])

  useEffect(() => {
    if (isDeleted) {
      dispatch(DMVActions.setVideoGalleryVideoDeleted(false))
      fetchData(selectedTab?.value)
    }
  }, [isDeleted, selectedTab])

  return (
    <Fragment>
      {isUploadVideoPopupOpen ? (
        <UploadVideo editModeData={editModeData} onClose={toggleUploadVideoPopup} />
      ) : null}
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title="Performance management Content" breadCrumbs={breadCrumbs}>
              <AppButtonGradient
                className="ms-3"
                sx={{ padding: "13px 20px" }}
                onClick={onUploadClick}>
                <Typography variant="body1Regular" sx={{ color: "#FFFFFF" }}>
                  Upload Video
                </Typography>
              </AppButtonGradient>
            </Header>
          </Col>
        </Row>
      </Container>
      <PerformanceMgmtVideos
        {...{
          toggleUploadVideoPopup,
          setEditModeData,
          selectedTab,
          onTabChange,
          fetchData
        }}
      />
    </Fragment>
  )
}

export default PerformanceManagementContent
