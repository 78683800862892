import { FormHelperText, Typography } from "@mui/material"
import { FieldArray } from "formik"
import React, { useRef } from "react"
import OptionTemplate from "./OptionTemplate"

const DraggableOptions = ({ formik, getOptionValues = () => {}, getOptionPath = () => {} }) => {
  const dragItem = useRef()
  const dragOverItem = useRef()
  const draggable = useRef()
  const uid = useRef(1)
  const optionErrors = getOptionValues(formik?.errors) || ""
  const optionTouched = getOptionValues(formik?.touched) || ""

  const onAddChoiceClick = (options) => {
    options.push({
      uid: uid.current++,
      index: options.length - 1,
      name: "",
      isCorrect: false
    })
  }

  const dragStart = (e, position) => {
    dragItem.current = position
    draggable.current = e.currentTarget.closest(".optionItem")
    draggable.current.style.cursor = "no-drop"
  }

  const dragEnter = (e) => {
    draggable.current.style.cursor = "copy"
    let targetChild = e.currentTarget.closest(".optionItem")
    targetChild.parentNode.insertBefore(draggable.current, targetChild?.nextSibling || targetChild)
    let prevDragIndex = dragOverItem.current
    dragOverItem.current = Array.from(targetChild.parentNode.children).indexOf(targetChild)
    if (dragOverItem.current && prevDragIndex && dragOverItem.current > prevDragIndex) {
      --dragOverItem.current
    }
  }

  const drop = (options) => {
    if (dragItem.current === dragOverItem.current) {
      return
    }
    const dragItemContent = getOptionValues(formik.values)?.[dragItem.current]
    options.remove(dragItem.current)
    options.insert(dragOverItem.current, dragItemContent)
    dragItem.current = null
    dragOverItem.current = null
  }

  return (
    <ul className="m-0 p-0">
      <FieldArray
        name={getOptionPath()}
        render={(arrayHelper) => (
          <>
            <li
              key={"draghelper"}
              className="py-3 optionItem"
              style={{ listStyleType: "none", marginTop: 3 }}
              onDragEnter={dragEnter}
              onDragEnd={() => drop(arrayHelper)}
            />
            {getOptionValues(formik.values)?.map((option, index) => (
              <OptionTemplate
                key={`optionItem-${option?.id || option?.uid}-${index}`}
                arrayHelper={arrayHelper}
                index={index}
                formik={formik}
                getOptionValues={getOptionValues}
                getOptionPath={getOptionPath}
                {...{ dragStart, dragEnter, drop }}
              />
            ))}
            {!Array.isArray(optionErrors) && Boolean(optionTouched) && Boolean(optionErrors) ? (
              <FormHelperText error={true}>{optionErrors}</FormHelperText>
            ) : null}
            {getOptionValues(formik.values)?.length < 4 ? (
              <Typography
                variant="caption2"
                role="button"
                onClick={() => onAddChoiceClick(arrayHelper)}
                sx={(theme) => ({
                  color: theme.palette.secondary.main,
                  textDecoration: "underline",
                  textUnderlineOffset: "2px"
                })}>
                Add Choice
              </Typography>
            ) : null}
          </>
        )}
      />
    </ul>
  )
}

export default DraggableOptions
