import React, { useEffect } from "react"
import { Redirect, Route } from "react-router"
import PublicLayout from "../layout/publicLayout"
import { useIsLoggedIn } from "hooks/state"
import { useSelector } from "react-redux"

const PublicWrapper = ({ component: Component, ...rest }) => {
  const isLoggedIn = useIsLoggedIn()
  const { isMultiRestaurant } = useSelector((state) => state?.app)
  const role = useSelector((res) => res.user?.userData?.role)

  useEffect(() => {}, [role])

  switch (role) {
    case "DMV":
      return (
        <Route
          {...rest}
          render={(props) =>
            isLoggedIn !== true ? (
              <PublicLayout {...props}>
                <Component {...props} />
              </PublicLayout>
            ) : (
              <Redirect
                to={{
                  pathname: "/dmv/dashboard",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      )
    default:
      return (
        <Route
          {...rest}
          render={(props) =>
            isLoggedIn !== true ? (
              <PublicLayout {...props}>
                <Component {...props} />
              </PublicLayout>
            ) : (
              <Redirect
                to={{
                  pathname: isMultiRestaurant ? `/auth/choose-restaurant` : "/marketing/dashboard",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      )
  }
}

export default PublicWrapper
