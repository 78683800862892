// Export all the public routes
import DMVLogin from "pages/public/DVMLogin"
import PrivacyPolicy from "pages/public/PrivacyPolicy"
import Login from "pages/public/login"
import SignUp from "pages/public/signup"

export const PublicRoutes = [
  { path: "/auth/login", exact: true, component: Login },
  { path: "/auth/signup", exact: true, component: SignUp },
  { path: "/DMV/auth/login", exact: true, component: DMVLogin },
  {
    path: "/privacy-policy",
    exact: true,
    name: "Privacy Policy",
    component: PrivacyPolicy
  }
]
