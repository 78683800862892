import React, { useEffect, useState } from "react"
import loadingImage from "assets/images/imagePlaceholder.png"
import styles from "./menuDisplayer.module.scss"

function MenuImageDisplayer({ image }) {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
  }, [image])

  return (
    <div className={styles.imageWrapper}>
      <div
        className={`${styles.imageContainer} ${styles.loading} ${
          isLoading ? styles.top : styles.bottom
        }`}>
        <img className={styles.actualImage} src={loadingImage} alt="MenuImage" />
      </div>
      <div
        className={`${styles.imageContainer} ${styles.actual} ${
          isLoading ? styles.bottom : styles.top
        }`}>
        <img
          className={styles.actualImage}
          src={image}
          alt="MenuImage"
          onLoad={() => setIsLoading(false)}
          style={{ display: isLoading ? "none" : "block" }}
        />
      </div>
    </div>
  )
}

export default MenuImageDisplayer
