import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
// import { WINE_OF_THE_MONTH_DATA } from "assets/json/WineOfTheMonth";
// import MonthlyCard from "components/WineOfTheMonthComponents/MonthlyCard";
// import { Box } from "@mui/system";
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
// import ButtonContainer from "components/WineOfTheMonthComponents/ButtonContainer";
import { useDispatch } from "react-redux"
import { getWineMonthAction } from "redux/marketing/actions"
import { useSelector } from "react-redux"
import { appActions } from "redux/app/slice/app"
import { Typography } from "@mui/material"
import { getLastDayOfMonth } from "helpers/functions"
import WineOfTheMonthScroller from "components/WineOfTheMonthScroller"
import DetailFeatureItemOfTheMonth from "components/DetailFeatureItemOfMonth"
import { marketingActions } from "redux/marketing/slice/user"
import DinnerDiningIcon from "@mui/icons-material/DinnerDining"
import { useTheme } from "@mui/system"

function WineOfTheMonth() {
  const { listWineMonthData, getSingleWineMonthData } = useSelector(
    (store) => store?.marketing?.wineOfTheMonth || {}
  )
  const theme = useTheme()
  const divisionNumber = 3
  const [index, setIndex] = React.useState(0)
  const [slicedArrays, setSlicedArrays] = useState([])
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const { date } = useSelector((store) => store?.app?.filters ?? {})
  const { featureItemId } = useSelector((store) => store?.marketing?.featureItemMonth || {})

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Feature Items of the Month"
    return () => {
      dispatch(marketingActions.setFeatureItemId(""))
      dispatch(marketingActions.setGetSingleWineMonthData({}))
    }
  }, [])

  function sliceArray() {
    const result = listWineMonthData.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / divisionNumber)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
    setSlicedArrays(result)
  }

  useEffect(() => {
    sliceArray()
  }, [listWineMonthData])

  useEffect(() => {
    const newDate = getLastDayOfMonth(date)
    dispatch(getWineMonthAction(newDate))
  }, [date])

  useEffect(() => {
    dispatch(appActions.initFilters())
  }, [])

  const componentArrays = slicedArrays?.map((array, index) => {
    return (
      <WineOfTheMonthScroller
        key={index}
        isBottomDisabled={index > slicedArrays.length - 2}
        index={index}
        isTopDisabled={index === 0}
        onPrev={() => setIndex(index - 1)}
        onNext={() => setIndex(index + 1)}
        details={array}></WineOfTheMonthScroller>
    )
  })

  return (
    <>
      <Container fluid className="ps-2 pe-2 ms-1 me-1">
        <Row>
          <Col lg="12" className="ps-0">
            <Header title="Featured Item Of The Month" breadCrumbs={breadCrumbs}></Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="mt-4">
            {listWineMonthData.length === 0 ? (
              <Typography variant="body1Regular" sx={{ color: "#FFFFFF" }}>
                No Records Found
              </Typography>
            ) : (
              <>
                <Row>
                  <Col lg="5" md="6" xl="4" xxl="4">
                    {componentArrays[index]}
                  </Col>
                  <Col lg="7" md="6" xl="8" xxl="8" className="ps-5">
                    {featureItemId ? (
                      <DetailFeatureItemOfTheMonth
                        setIsEditModal={false}
                        isEdit={false}
                        data={getSingleWineMonthData}></DetailFeatureItemOfTheMonth>
                    ) : (
                      <div
                        className="text-center d-flex flex-column justify-content-center align-items-center"
                        style={{ marginTop: 160 }}>
                        <DinnerDiningIcon
                          className="mb-4 loadingImage"
                          sx={{
                            color: theme.palette.secondary.main,
                            fontSize: 48
                          }}></DinnerDiningIcon>
                        <Typography variant="body1Regular" sx={{ color: "#FFFFFF" }}>
                          Please select any item
                        </Typography>
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        {/* Modal */}
      </Container>
    </>
  )
}

export default WineOfTheMonth
