import React, { Fragment, useState, useEffect } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { Container, Row, Col } from "react-bootstrap"
import Header from "components/Header/Header"
import FullCalendar from "components/TriviaCalendarComponents/FullCalendar"
import { INITIAL_EVENTS } from "assets/json/CalendarEvents"
import SignUpSummary from "components/TriviaCalendarComponents/SignUpSummary/index"
import UpgradeSummary from "components/TriviaCalendarComponents/upgradeSummary/index"
import { Box, Typography } from "@mui/material"
import LeaderBoardTable from "components/TriviaCalendarComponents/LeaderBoardTable/LeaderBoardTable"
import TriviaAnalyticsTable from "components/TriviaCalendarComponents/TriviaAnalyticsTable/TriviaAnalyticsTable"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
function AreaDirectorTriviaCalendar() {
  const history = useHistory()
  const [breadCrumbs, setBreadcrumbs] = useState([])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Trivia Calendar"
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header title="Trivia Calendar & Leaderboard" breadCrumbs={breadCrumbs}>
              <AppButton
                onClick={() =>
                  history.push("/area-director/dashboard/membership=sign-up-performance")
                }>
                Membership Sign-up Performance
              </AppButton>
            </Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="12" className="mb-4">
                <FullCalendar events={INITIAL_EVENTS} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="6">
                <SignUpSummary />
              </Col>
              <Col lg="6">
                <UpgradeSummary />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0 mt-5 ">
        <Typography variant="h2" className="mb-2">
          Leaderboard
        </Typography>
        <Row>
          <Col lg="12">
            <LeaderBoardTable
              columns={LeaderBoardColumns}
              rows={LeaderBoardRows}></LeaderBoardTable>
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0 mt-5">
        <Box className="d-flex justify-content-between">
          <Typography variant="h2" className="mb-2">
            Trivia Analytics
          </Typography>
        </Box>
        <Row>
          <Col lg="12">
            <TriviaAnalyticsTable columns={TriviaColumns} rows={TriviaRows}></TriviaAnalyticsTable>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default AreaDirectorTriviaCalendar

const TriviaColumns = [
  { id: "Rank", label: "Rank", minWidth: 50 },
  { id: "Questions", label: "Questions", minWidth: 170 },
  {
    id: "Trivia Category",
    label: "Trivia Category",
    align: "center",
    minWidth: 170
  },
  {
    id: "Difficulty Level",
    label: "Difficulty Level",
    align: "center",
    minWidth: 170
  },
  {
    id: "% Incorrect",
    label: "% Incorrect",
    align: "right",
    minWidth: 170
  }
]

const TriviaRows = [
  {
    id: 1,
    rank: 1,
    questions: "What is the retail wine discount for a WCM purchasing 12 or more bottles?",
    triviaCategory: "Wine Club",
    difficultyLevel: "Intermediate",
    inCorrect: "60%"
  },
  {
    id: 2,
    rank: 2,
    questions: "Which wine is not a Cooper's Hawk wine?",
    triviaCategory: "Grape Varietal",
    difficultyLevel: "Intermediate",
    inCorrect: "58%"
  },
  {
    id: 3,
    rank: 3,
    questions: "Which wine region is not in the United States?",
    triviaCategory: "Wine Region",
    difficultyLevel: "Intermediate",
    inCorrect: "56%"
  },
  {
    id: 4,
    rank: 4,
    questions: "What foods pairs best with the Luxe Pinot Noir?",
    triviaCategory: "Emily’s Wildcard",
    difficultyLevel: "Intermediate",
    inCorrect: "55%"
  },
  {
    id: 5,
    rank: 5,
    questions: "Which attribute is not a part of January's wine of the month tasting note?",
    triviaCategory: "Wine Of The Month",
    difficultyLevel: "Intermediate",
    inCorrect: "54%"
  }
]

const LeaderBoardColumns = [
  { id: "Rank", label: "Rank", minWidth: 50 },
  { id: "Name", label: "Name", minWidth: 170 },
  {
    id: "Trivia Questions Answered Correctly",
    label: "Trivia Questions Answered Correctly",
    align: "center",
    minWidth: 170
  },
  {
    id: "Signups / Upgrades",
    label: "Signups / Upgrades ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Coins Earned",
    label: "Coins Earned",
    align: "center",
    minWidth: 170
  }
]

const LeaderBoardRows = [
  {
    id: 1,
    rank: 1,
    name: "Scott Hatteberg",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    correctAnswers: "10",
    signUp_Upgrades: "8",
    coins: "80"
  },
  {
    id: 2,
    rank: 2,
    name: "Carlos Pena",
    correctAnswers: "8",
    image: "https://randomuser.me/api/portraits/men/77.jpg",
    signUp_Upgrades: "5",
    coins: "40"
  },
  {
    id: 3,
    rank: 3,
    name: "Marcella Ellis",
    correctAnswers: "6",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    signUp_Upgrades: "4",
    coins: "24"
  }
]
