import React, { useState } from "react"
import Card from "components/Card/Card"
import { Box, Typography, Dialog, styled, IconButton } from "@mui/material"
import CustomDivider from "components/StyledComponents/Divider"
import { useTheme } from "@mui/system"
import ImportTab from "./ImportTab"
import CloseIcon from "@mui/icons-material/Close"
import { Formik } from "formik"
import { validationSchemaWineOfTheMonth } from "helpers/validationSchema"
import { useDispatch, useSelector } from "react-redux"
import { formatDate } from "helpers/functions"
import { uploadWineOfTheMonthAction } from "redux/marketing/actions"
import { marketingActions } from "redux/marketing/slice/user"
import { DMVActions } from "redux/DMV/slice/user"
import moment from "moment"

const initialValues = {
  month: "",
  nameOfTheWine: "",
  videoThumbnail: "",
  videoURL: "",
  wineImageVideo: "",
  wineRegion: "",
  grapeVarietals: "",
  description: "",
  tastingNotes: [],
  menuPairing: [
    {
      image: null,
      title: ""
    }
  ],
  scheduleNotificationDate: "",
  scheduleNotificationTime: ""
}

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: "1100px"
  }
}))

function UploadWineOfTheMonthModal({ open, onClose, isEdit = false }) {
  const theme = useTheme()
  const [initialFormValue] = useState(initialValues)
  const dispatch = useDispatch()
  const { videoThumbnailImage, bottleimage, flavourImages } = useSelector(
    (store) => store?.marketing?.wineOfTheMonth || {}
  )
  const { alcoholType } = useSelector((state) => state?.dmv || {})
  const zone_name = moment.tz.guess()

  const styles = {
    card: {
      margin: 0
    },
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center"
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 3,
      opacity: 1,
      position: "relative",
      top: "12px",
      left: "-23%",
      width: "150%"
    },
    mainDivider: {
      backgroundColor: theme.palette.background.main,
      opacity: 1,
      marginTop: "10px"
    },
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  function convertDate(date, time) {
    const forDate = moment(new Date(date)).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    return newDate.toUTCString()
  }
  function payloadDate(dateArray = []) {
    return `${dateArray[3]}-${months[dateArray[2]]}-${dateArray[1]}`
  }

  function handleSubmit(values) {
    const NotificationTimeStamp = convertDate(
      values.scheduleNotificationDate,
      values.scheduleNotificationTime
    )
    const NotificationTimeSplit = NotificationTimeStamp.split(" ")
    const payloadStartDate = payloadDate(NotificationTimeSplit)
    const payload = {
      alcohol_type: alcoholType,
      month: formatDate(values?.month.toUTCString()),
      name: values?.nameOfTheWine,
      thumbnail: videoThumbnailImage,
      video_url: values?.videoURL,
      image: bottleimage,
      wine_region: values?.wineRegion,
      gape_variatals: values?.grapeVarietals,
      description: values?.description,
      menu_tasting_notes: values.tastingNotes,
      menu_pairing: values?.menuPairing
        ?.filter((el) => el?.title)
        ?.map((el, index) => {
          return { name: el?.title, image: flavourImages[index] }
        }),
      notification_date: payloadStartDate || "",
      notification_time: NotificationTimeSplit[4] || "",
      time_zone: zone_name
    }
    console.log("formik", values, payload)

    dispatch(uploadWineOfTheMonthAction(payload))
    dispatch(marketingActions.setWineMonthPDFThumbnail(null))
    dispatch(marketingActions.setWineMonthVideoThumbnail(null))
    onClose()
    dispatch(DMVActions.setAlcoholType(""))
  }

  return (
    <StyledDialog open={open} fullWidth>
      <Card style={styles.card}>
        <IconButton onClick={onClose} sx={styles.closeBtn}>
          <CloseIcon sx={{ height: "14px", width: "14px" }} />
        </IconButton>
        {/* Headings */}
        <Box sx={styles.headingContainer}>
          <Box sx={styles.heading}>
            <Typography variant="body1Regular" sx={{ color: theme.palette.secondary.main }}>
              Featured Item of The Month
            </Typography>
            {<CustomDivider sx={styles.divider} />}
          </Box>
        </Box>
        <CustomDivider sx={styles.mainDivider} />

        <Box>
          {/* Upload Tab */}
          <Formik
            initialValues={initialFormValue}
            enableReinitialize={true}
            validationSchema={validationSchemaWineOfTheMonth}
            onSubmit={handleSubmit}>
            {(formik) => (
              <>
                {/* Import Tab */}
                {<ImportTab isEdit={isEdit} formik={formik} />}
              </>
            )}
          </Formik>
        </Box>
      </Card>
    </StyledDialog>
  )
}

export default UploadWineOfTheMonthModal
const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12"
}
