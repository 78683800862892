import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React from "react"
import styles from "./performanceSummaryTable.module.scss"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: "transparent",
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid ${theme.palette.background.default}`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .rank": {
    height: 44,
    width: 29,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 4,
    borderColor: theme.palette.green.main
  }
}))

const PerformanceSummaryable = ({ columns = [], rows = [] }) => {
  return (
    <Box className={styles.container}>
      <TableContainer className={styles.table}>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <div className={`rank`}>{row.rank}</div>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2Regular">{row.restaurant}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.region}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.areaDirector}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.inviteRate.toFixed(1) + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.nonMembers}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.percentHasApp.toFixed(1) + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.members}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.upgradeRate.toFixed(1) + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.inviteUnderperformers}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.upgradeUnderPerformers}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.lostWcmOpportunity}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.lostUpgradeOpportunity}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.bottleSignups}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.upgrades}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{"$" + row.wcmAnnualOpportunity}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {"$" + row.upgradeAnnualOpportunity}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.zeroInvites}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.zeroUpgrades}</Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Box>
  )
}

export default PerformanceSummaryable
