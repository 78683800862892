import { Box, Grid, Typography } from "@mui/material"
import React, { useEffect } from "react"
import QuestionTable from "components/QuestionTable"
import Questions from "assets/json/triviaAnalytics.json"
import { useTheme } from "@mui/system"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { useState } from "react"

const AreaDirectorTriviaAnalytics = () => {
  const theme = useTheme()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Trivia Analytics"
  }, [])

  const styles = {
    gridContainer: {
      "&.MuiGrid-root": {
        paddingTop: "0"
      }
    },
    heading: {
      color: theme.palette.text.primary
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Header title="Trivia Analytics" breadCrumbs={breadCrumbs} />
      </Grid>
      <Grid sx={styles.gridContainer} item xs={12}>
        <Typography sx={styles.heading} variant="h2">
          Trivia Questions Answered Most Incorrectly
        </Typography>
      </Grid>
      <Grid sx={styles.gridContainer} item xs={12}>
        <QuestionTable
          isCorrectAnswer={false}
          rows={Questions.IncorrectAnsweredRows}
          columns={Questions.IncorrectAnsweredColumns}
        />
      </Grid>
      <Grid item xs={12}>
        <Box height={23} />
        <Typography sx={styles.heading} variant="h2">
          Trivia Questions Answered Most Correctly
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <QuestionTable
          isCorrectAnswer={true}
          rows={Questions.CorrectAnsweredRows}
          columns={Questions.CorrectAnsweredColumns}
        />
      </Grid>
    </Grid>
  )
}

export default AreaDirectorTriviaAnalytics
