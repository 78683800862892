import { List, ListItem, Typography } from "@mui/material"
import Card from "components/Card/Card"
import ListWith2Items from "components/List/ListBodyWith2Items"
import React from "react"

const AdditionalInfoCard = ({ data, heading }) => {
  return (
    <Card>
      <List sx={{ padding: 0 }}>
        <ListItem sx={{ padding: "0px 0px 12px" }}>
          <Typography variant="h2">{heading}</Typography>
        </ListItem>
        <ListWith2Items tableData={data} />
      </List>
    </Card>
  )
}

export default AdditionalInfoCard
