import React, { Fragment } from "react"
import { ListItem, Typography } from "@mui/material"
import Divider from "components/StyledComponents/Divider"
import { makeStyles } from "@material-ui/core"
import WineBottleIcon from "assets/icons/WineBottleIcon.svg"
import moment from "moment"

const useStyles = makeStyles(() => ({
  keyCtr: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: 14
    }
  },
  wineLable: {
    width: "40%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textAlign: "end",
    "white-space": "nowrap"
  }
}))

function ListItems({ tableData, marginRight = 0 }) {
  const classes = useStyles()

  return (
    <Fragment>
      {tableData.length ? (
        tableData?.slice(0, 4).map((item) => (
          <Fragment key={item.id}>
            <ListItem
              sx={{
                justifyContent: "space-between",
                padding: "18px 0 10px 0"
              }}>
              <div className={classes.keyCtr}>
                <img src={WineBottleIcon} />
                <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
                  {moment(item?.month).format("MMMM YYYY")}
                </Typography>
              </div>
              <div className={classes.wineLable}>
                <Typography variant="body1Regular" sx={{ marginRight }}>
                  {item.name}
                </Typography>
              </div>
            </ListItem>
            {tableData.length - 1 !== item.id && <Divider />}
          </Fragment>
        ))
      ) : (
        <div className="text-center mt-3">
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            No Record Found
          </Typography>
        </div>
      )}
    </Fragment>
  )
}

export default ListItems
