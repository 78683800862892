import { Box, TableCell, TableRow, Typography } from "@mui/material"
import React from "react"

const TableNoRecords = ({ label = "No Records Found", colSpan = 1 }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Box className="d-flex justify-content-center">
          <Typography variant="body1Regular">{label}</Typography>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default TableNoRecords
