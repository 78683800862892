import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React, { useState } from "react"
import styles from "./GamificationSummaryTable.module.scss"
import avatar from "assets/images/user/avatarUser.svg"
import AppPagination from "components/StyledComponents/AppPagination"
import TableNoRecords from "components/TableNoRecords"
import { useDispatch } from "react-redux"
import { appActions } from "redux/app/slice/app"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { useEffect } from "react"
import { getRankColor } from "helpers/functions"
import ProfileDisplayer from "components/ProfileDisplayer"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid ${theme.palette.background.default}`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    padding: "24px 24px 12px 0px",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const GamificationSummaryTable = ({ columns = [], rows = [], setPage, currentPage, totalPage }) => {
  const dispatch = useDispatch()
  const [sort, setSort] = useState("")
  const [sortDirection, setSortDirection] = useState("")

  const handleSortDirection = (dir, id) => {
    setSortDirection(dir)
    handleSort(dir, id)
  }

  const handleSort = (dir, id) => {
    if (dir === "asc") {
      setSort(id)
      dispatch(appActions.setOrderBy(id))
    } else {
      setSort(`-${id}`)
      dispatch(appActions.setOrderBy(`-${id}`))
    }
  }

  useEffect(() => {}, [sort, sortDirection])

  return (
    <>
      <Paper className={styles.tableContainer}>
        <TableContainer>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography
                      variant="body2Regular"
                      style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ opacity: 0.6 }}>{column.label}</span>
                      {column.icon && (
                        <img className={styles.columnIcon} src={column.icon} alt="" />
                      )}
                      {column?.isSorting && (
                        <span style={{ cursor: "pointer", opacity: 0.6 }}>
                          {sortDirection === "desc" && sort === `-${column?.sortId}` ? (
                            <ArrowDownwardIcon
                              onClick={() => handleSortDirection("asc", column?.sortId)}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowUpwardIcon
                              onClick={() => handleSortDirection("desc", column?.sortId)}
                              fontSize="small"
                            />
                          )}
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!rows?.length ? (
                <TableNoRecords colSpan={9} label="No Data Found" />
              ) : (
                rows?.map((row, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>
                        <div className={styles.rank} style={{ borderColor: getRankColor(row) }}>
                          {row.rank}
                        </div>
                      </TableCell>
                      <TableCell sx={{ cursor: "pointer" }}>
                        <div className={`${styles.tableAvatar}`}>
                          <div
                            className={`${styles.tableAvatar__img} ${
                              row.rank === 1 && styles.borderImg
                            }`}>
                            {row?.image_url !== null ? (
                              <ProfileDisplayer image={row?.image_url} />
                            ) : (
                              <img src={avatar} />
                            )}
                          </div>
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                              <Typography className="me-2" variant="body2Regular">
                                {row?.full_name}
                              </Typography>
                              {row.isBiggestOpportunityServer ? <span role="button"></span> : null}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.profile_picture_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.featured_item_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.tasting_notes_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.trivia_awards_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.flash_cards_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.event_rvsp_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.review_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.positive_review_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.bonus_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.trivia_coins}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.total_coins ?? 0}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
      </Paper>
      <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
    </>
  )
}

export default GamificationSummaryTable
