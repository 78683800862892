import React from "react"
import { Container } from "react-bootstrap"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import Header from "components/Header/Header"

import icon1 from "assets/icons/PositiveAlert/icon1.svg"
import icon2 from "assets/icons/PositiveAlert/icon2.svg"
import icon3 from "assets/icons/PositiveAlert/icon3.svg"
import icon4 from "assets/icons/PositiveAlert/icon4.svg"
import iconNegative1 from "assets/icons/negativeAlert/icon1.svg"
import iconNegative2 from "assets/icons/negativeAlert/icon2.svg"
import iconNegative3 from "assets/icons/negativeAlert/icon3.svg"
import iconNegative4 from "assets/icons/negativeAlert/icon4.svg"
import alertTraingle from "assets/icons/alertTraingle.svg"
import AlertCardView from "./alertCardView/index"
import MetricsView from "./metricsView/index"
import AppButton from "components/StyledComponents/AppButton"
import { Link } from "react-router-dom"

function DVMDashboard() {
  return (
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <Header
            title="DVM Ventures"
            back={false}
            // notificationIcon="/dmv/dashboard/notifications"
            // isNotify={true}
            isSettings={true}
            isEnterprise={true}
            enterpriseRoute={"/dmv/dashboard/client-information"}
            settingsRoute={"/dmv/dashboard"}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Row>
            <Col lg="12">
              <AlertCardView
                key="1"
                heading="Positive Alert"
                listData={positiveData}
                variantBg="rgba(58, 219, 118, 0.08);"></AlertCardView>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <MetricsView
                redirect="/dmv/dashboard/usage-dashboard"
                dataView={userMetrics}
                heading="Weekly User Metrics"></MetricsView>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <MetricsView
                redirect="/dmv/dashboard/4-month-tr-trend"
                dataView={inviteRate}
                heading="Invite Rate"></MetricsView>
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <Col lg="12">
              <AlertCardView
                key="2"
                heading="Negative Alert"
                listData={negativeData}
                variantBg="#CC4B371A"></AlertCardView>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <AlertCardView
                key="3"
                heading="Data Alert"
                listData={dataLaertData}
                variantBg="rgba(170, 219, 246, 0.1);"></AlertCardView>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <MetricsView
                redirect="/dmv/dashboard/base-period-graph"
                dataView={[]}
                heading="Performance Management"></MetricsView>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-3">
              <Link to="/dmv/dashboard/performance-management-content">
                <AppButton className="w-100" variant="outlined">
                  Video Gallery
                </AppButton>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default DVMDashboard
const positiveData = [
  {
    id: 0,
    icon: icon1,
    title: "40 of 50 restaurants hit 100% of goal in week 4 of round 1"
  },
  {
    id: 1,
    icon: icon2,
    title: "Invite rate increased from 10% to 15% month over month"
  },
  {
    id: 2,
    icon: icon3,
    title: "Number of people playing increased 2,000-3,000 month over month"
  },
  {
    id: 3,
    icon: icon4,
    title: "Number of active users has grown up to 50% month over month"
  }
]
const negativeData = [
  {
    id: 0,
    icon: iconNegative1,
    title: "Jacksonville is under 50% of goal in week 4 of round 1 "
  },
  {
    id: 1,
    icon: iconNegative2,
    title: "Number of mobile users decreased by 20% month over month"
  },
  {
    id: 2,
    icon: iconNegative3,
    title: "Virginia Beach sales went down by 10% month over month View More"
  },
  {
    id: 3,
    icon: iconNegative4,
    title: "Ryan, GM of tampa hasn't opened up the email in 8 days View More"
  }
]

const dataLaertData = [
  {
    id: 0,
    icon: alertTraingle,
    title: "Jacksonville does not sent data since April 1st 2022"
  },
  {
    id: 1,
    icon: alertTraingle,
    title: "Naples has not sent data since March 30th"
  }
]

const userMetrics = [
  {
    id: 0,
    key: "Incremental Memberships",
    field: "1,000"
  },
  {
    id: 1,
    key: "Weekly Downloads",
    field: "500"
  },
  {
    id: 2,
    key: "% Of Goal Achieved",
    field: "120%"
  },
  {
    id: 3,
    key: "Most Used Mobile Feature",
    field: "Membership Invite Rate"
  },
  {
    id: 4,
    key: "Active Users",
    field: "5,000"
  },
  {
    id: 5,
    key: "Most Used Web Feature",
    field: "Signup Performance"
  }
]

const inviteRate = [
  {
    id: 0,
    key: "October",
    field: "12.5%"
  },
  {
    id: 1,
    key: "November",
    field: "10.6%"
  },
  {
    id: 2,
    key: "December",
    field: "6.7%"
  },
  {
    id: 3,
    key: "January",
    field: "8.5%"
  }
]
