import React from "react"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Typography } from "@mui/material"
import Card from "components/Card/Card"
import NavHeader from "../navHeader/index"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { useState } from "react"
import { useEffect } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useHistory } from "react-router-dom"
import { Formik } from "formik"
import { Container } from "react-bootstrap"
import AppTextField from "components/StyledComponents/AppTextField"
import AppSelect from "components/StyledComponents/AppSelect"
import AppButtonFilled from "components/StyledComponents/AppButtonFilled"

function AddClient() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const history = useHistory()
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Add Client"
  }, [])

  return (
    <Formik
      initialValues={{
        clientName: "",
        area: "",
        pointofcontact: "",
        code: "",
        contactNumber: ""
      }}>
      {(formik) => (
        <>
          <NavHeader isClientActive={true} breadCrumbs={breadCrumbs}></NavHeader>
          <Card
            style={{
              borderTopLeftRadius: "0px",
              marginTop: "0px"
            }}>
            <Row>
              <Col lg="12" className="d-flex mt-2">
                <ArrowBackIcon
                  role="button"
                  className="me-3 cursor-pointer"
                  onClick={() => history.goBack()}
                  fontSize="medium"
                />
                <Typography variant="h2" className="d-flex align-items-center">
                  Add Client
                </Typography>
              </Col>
            </Row>

            <Container fluid className="mt-5">
              <Row>
                <Col lg="4">
                  <AppTextField
                    id="clientName"
                    name="clientName"
                    fullWidth
                    label="Client Name"
                    isRequired={false}
                    placeholder="Enter Client Name"
                    value={formik.values.clientName}
                    onChange={formik.handleChange}
                    error={formik.touched.clientName && Boolean(formik.errors.clientName)}
                    helperText={formik.touched.clientName && formik.errors.clientName}
                  />
                </Col>
                <Col lg="4">
                  <AppSelect
                    id="area"
                    name="area"
                    label="Area"
                    value={formik.values.area}
                    onChange={formik.handleChange}
                    error={formik.errors.area}
                    touched={formik.touched.area}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="pointofcontact"
                    name="pointofcontact"
                    fullWidth
                    label="Point of Contact"
                    isRequired={false}
                    placeholder="Enter  Name"
                    value={formik.values.pointofcontact}
                    onChange={formik.handleChange}
                    error={formik.touched.pointofcontact && Boolean(formik.errors.pointofcontact)}
                    helperText={formik.touched.pointofcontact && formik.errors.pointofcontact}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg="2">
                  <AppSelect
                    id="code"
                    name="code"
                    fullWidth
                    label="Contact Name"
                    isRequired={true}
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                  />
                </Col>
                <Col lg="2">
                  <AppTextField
                    id="contactNumber"
                    name="contactNumber"
                    label="_"
                    fullWidth
                    isRequired={false}
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactNumber &&
                      Boolean(formik.errors.pointcontactNumberofcontact)
                    }
                    helperText={formik.touched.contactNumber && formik.errors.contactNumber}
                  />
                </Col>
              </Row>
            </Container>
            <Container fluid className="mt-5 mb-4">
              <Row>
                <Col lg="12">
                  <AppButtonFilled style={{ height: "50px" }}>Submit</AppButtonFilled>
                </Col>
              </Row>
            </Container>
          </Card>
        </>
      )}
    </Formik>
  )
}

export default AddClient
