import React, { useEffect, useState } from "react"
import Card from "components/Card/Card"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import AppSelect from "components/StyledComponents/AppSelect"
import { Box, Typography } from "@mui/material"
import QuestionAccordion from "./flaggedQuestions"
import styles from "../flaggedQuestion/flaggedQuestion.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { getFlaggedQuestionsAction, getReasonCodesAction } from "redux/L&D/actions/user"

function FlaggedQuestion() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [selectedReason, setSelectedReason] = useState("All")
  const [selectedType, setSelectedType] = useState("All")
  const [selectedCategory, setSelectedCategory] = useState("All")
  const { flaggedQuestions, reasonCodes } = useSelector((state) => state?.learningDevelopment)
  const [page, setPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [reasons, setReasons] = useState([])
  const [disable, setDisable] = useState(true)
  const dispatch = useDispatch()

  const handleChange = (e, filter) => {
    const value = e.target.value
    if (filter === "reasonCode") {
      setSelectedReason(value)
      if (value === "All") {
        dispatch(
          getFlaggedQuestionsAction({
            page: currentPage,
            type: selectedType,
            category: selectedCategory
          })
        )
      } else {
        dispatch(getFlaggedQuestionsAction({ page: currentPage, reason: value }))
      }
    } else if (filter === "category") {
      setSelectedCategory(value)
      setSelectedReason("All")
      setSelectedType("All")
      if (value === "All") {
        setDisable(true)
        dispatch(
          getFlaggedQuestionsAction({
            page: currentPage
          })
        )
      } else {
        setDisable(false)
        dispatch(
          getFlaggedQuestionsAction({
            page: currentPage,
            category: value
          })
        )
      }
    } else {
      setSelectedType(value)
      setSelectedReason("All")
      if (value === "All") {
        dispatch(
          getFlaggedQuestionsAction({
            page: currentPage
          })
        )
      } else {
        dispatch(
          getFlaggedQuestionsAction({
            page: currentPage,
            type: value,
            category: selectedCategory
          })
        )
        dispatch(getReasonCodesAction({ value, category: selectedCategory }))
      }
    }
  }

  useEffect(() => {
    const updatedReasons =
      reasonCodes?.map((item) => ({
        label: item.reason,
        value: item.id
      })) || []

    updatedReasons.unshift({
      label: "All",
      value: "All"
    })
    setReasons(updatedReasons)
  }, [reasonCodes])

  useEffect(() => {
    dispatch(
      getFlaggedQuestionsAction({ page: currentPage, reason: selectedReason, type: selectedType })
    )
  }, [currentPage])

  useEffect(() => {
    const totalPages = flaggedQuestions?.pages
    setPage(totalPages)
  }, [flaggedQuestions])

  useEffect(() => {
    const updatedReasons =
      reasonCodes?.map((item) => ({
        label: item.reason,
        value: item.id
      })) || []

    updatedReasons.unshift({
      label: "All",
      value: "All"
    })
    setReasons(updatedReasons)
  }, [reasonCodes])

  useEffect(() => {
    dispatch(
      getFlaggedQuestionsAction({ page: currentPage, reason: selectedReason, type: selectedType })
    )
  }, [currentPage])

  useEffect(() => {
    const totalPages = flaggedQuestions?.pages
    setPage(totalPages)
  }, [flaggedQuestions])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    setCurrentPage(1)
  }, [])

  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.headerContainer}>
          <Header title="Flagged Questions" breadCrumbs={breadCrumbs} />
        </Box>

        <Box
          className={
            selectedType !== "All" && selectedCategory !== "All"
              ? styles.selectBox2
              : styles.selectBox
          }>
          <Box>
            {!!flaggedQuestions?.count && (
              <span className={styles.questionCount}>
                Total Flagged Questions: {flaggedQuestions?.count}
              </span>
            )}
          </Box>
          {selectedType !== "All" && selectedCategory !== "All" && reasons?.length > 0 && (
            <Box>
              <AppSelect
                id="Reason Code"
                name="reasonCode"
                menuItems={reasons}
                value={selectedReason}
                onChange={(e) => handleChange(e, "reasonCode")}
              />
            </Box>
          )}

          <Box>
            <AppSelect
              id="category"
              name="category"
              menuItems={categories}
              value={selectedCategory}
              onChange={(e) => handleChange(e, "category")}
            />
          </Box>

          <Box>
            <AppSelect
              id="QuestionType"
              name="questionType"
              menuItems={Type}
              disabled={disable}
              value={selectedType}
              onChange={(e) => handleChange(e, "type")}
            />
          </Box>
        </Box>
        <Card className={styles.cardContainer}>
          {flaggedQuestions && flaggedQuestions?.results?.length > 0 ? (
            <QuestionAccordion
              questions={flaggedQuestions.results}
              pages={page}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <Typography variant="h2" className="d-flex justify-content-center">
              No Flagged Questions Found
            </Typography>
          )}
        </Card>
      </Box>
    </>
  )
}

export default FlaggedQuestion

const Type = [
  { label: "Question Type: All", value: "All" },
  {
    label: "Good",
    value: "GOOD"
  },
  {
    label: "Bad",
    value: "BAD"
  }
]
const categories = [
  { label: "All", value: "All" },
  {
    label: "Food",
    value: "FOOD"
  },
  {
    label: "Bar",
    value: "BAR"
  },
  {
    label: "Spirit",
    value: "SPIRIT"
  },
  {
    label: "Wine by glass",
    value: "WINE_BY_GLASS"
  }
]
