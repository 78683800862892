import React from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import { Formik } from "formik"
import { validationSchemaRoleModal } from "helpers/validationSchema"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import { addRoleDetailsAction, editRoleDetailsAction } from "redux/superAdmin/actions"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: 580,
    padding: 35,
    boxSizing: "border-box"
  }
}))

function RoleModal({ open = false, onClose = () => {}, isEdit = false }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { roleDetails } = useSelector((store) => store?.superAdmin ?? [])

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  function handleRole(values) {
    const payload = {
      name: values?.role
    }
    if (isEdit) {
      dispatch(editRoleDetailsAction({ payload }))
    } else {
      dispatch(addRoleDetailsAction({ payload }))
    }
    onClose()
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2">{isEdit ? "Edit" : "Add New"} Role</Typography>
        </Box>
        <Formik
          initialValues={{
            role: roleDetails?.name ?? ""
          }}
          enableReinitialize={true}
          onSubmit={handleRole}
          validationSchema={validationSchemaRoleModal}>
          {(formik) => (
            <>
              <Box flexDirection="column" className="mt-4">
                <React.Fragment>
                  <Box className="d-flex justify-content-between w-100 py-2">
                    <AppTextField
                      id="role"
                      name="role"
                      fullWidth
                      isUpperCase={!false}
                      label={`Role Name`}
                      isRequired={true}
                      placeholder="Enter role name"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      helperText={formik.errors.role}
                    />
                  </Box>
                </React.Fragment>
              </Box>
              <Box className="mt-5 d-flex justify-content-around">
                <AppButton
                  onClick={() => onClose()}
                  sx={{ height: "48px", width: 150 }}
                  variant="outlined">
                  Cancel
                </AppButton>
                <AppButton
                  disabled={Boolean(formik?.errors.role) || formik?.values.role?.length === 0}
                  onClick={() => handleRole(formik?.values)}
                  sx={() => ({
                    height: "48px",
                    padding: "10px 50px"
                  })}>
                  {isEdit ? "Update" : "Add"}
                </AppButton>
              </Box>
            </>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  )
}

export default RoleModal
