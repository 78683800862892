import { Box, Pagination, styled } from "@mui/material"
import React from "react"

const StyledPagination = styled(Pagination, {
  shouldForwardProp: (prop) => prop !== "primaryColor"
})(({ theme, primaryColor }) => ({
  "& .MuiPaginationItem-page": {
    ...theme.typography.body2Regular,
    lineHeight: "0px",
    "&.Mui-selected": {
      border: `1px solid ${primaryColor || theme.palette.secondary.main}`,
      color: primaryColor || theme.palette.secondary.main
    }
  },
  "& .MuiPaginationItem-previousNext": {
    color: primaryColor || theme.palette.secondary.main,
    opacity: 1,
    "&.Mui-disabled": {
      color: "#BCBCBC"
    }
  }
}))

const AppPagination = ({ currentPage = 1, totalPages = 1, onPageChange = () => {}, ...props }) => {
  return (
    <Box className="d-flex justify-content-end mt-4">
      <StyledPagination
        page={currentPage}
        count={totalPages}
        onChange={(e, page) => onPageChange(page)}
        shape="rounded"
        {...props}
      />
    </Box>
  )
}

export default AppPagination
