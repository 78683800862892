import Card from "components/Card/Card"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import InvitationIcon from "assets/icons/InvitationIcon.svg"
import { Box, Typography } from "@mui/material"
import classes from "./notifications.module.scss"
import { useHistory } from "react-router-dom"

const DMVNotifications = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const history = useHistory()

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Notifications"
  }, [])

  const onNotificationClick = (link) => {
    if (link) {
      history.push(link)
    }
  }

  return (
    <Container fluid className="p-0  ">
      <Row>
        <Col lg="12">
          <Header title="Notifications" breadCrumbs={breadCrumbs} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {notifications?.length ? (
            <>
              {notifications.map((notification) => (
                <Card
                  key={notification.id}
                  className="d-flex justify-content-between align-items-center">
                  <Box className="d-flex align-items-center">
                    <Box className={classes.icon}>
                      <img src={InvitationIcon} alt="" />
                    </Box>
                    <Box
                      onClick={() => onNotificationClick(notification.link)}
                      style={{ cursor: notification.link ? "pointer" : "" }}>
                      <Typography variant="caption5" className="ms-2">
                        {notification.title}
                      </Typography>
                      <Typography variant="caption5" className={classes.title}>
                        Click here
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2Regular" className={classes.date}>
                    {notification.date}
                  </Typography>
                </Card>
              ))}
            </>
          ) : (
            <Box className="d-flex w-100 justify-content-center">
              <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
                No new Notifications
              </Typography>
            </Box>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default DMVNotifications

const notifications = []
