import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { styled, TextField, Typography } from "@mui/material"
import crown from "assets/icons/crown.svg"
import styles from "./PiggyBankTable.module.scss"
import TableNoRecords from "components/TableNoRecords"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.main}`,
  "& .MuiPaper-root": {
    backgroundColor: "red"
  },
  "& .MuiTableRow-hover": {
    backgroundColor: "red"
  },
  "& .MuiTableCell-root": {
    padding: "16px 16px",
    backgroundColor: `${theme.palette.background.main}`,
    borderBottom: "1px solid #353535"
  }
}))

const StyledTextField = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    textAlign: "center"
  }
}))

const PiggyBankTable = ({ columns, rows, isEditMode, formik }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <StyledTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                    {column.icon && <img className={styles.columnIcon} src={column.icon} alt="" />}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableNoRecords colSpan={9} label="No Contests Found" />
            ) : (
              rows.map((row, index) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <span
                        style={{
                          border: "2px solid #3ADB76",
                          borderRadius: 6,
                          padding: "12px 10px"
                        }}>
                        {row.rank}
                      </span>
                    </TableCell>
                    <TableCell>
                      <div className={`${styles.tableAvatar}`}>
                        <div
                          className={`${styles.tableAvatar__img} ${
                            row.rank === 1 && styles.borderImg
                          }`}>
                          <img src={row.image} />
                        </div>
                        <div className="d-flex flex-column">
                          <Typography variant="body2Regular">{row.name}</Typography>
                          {row.rank === 1 && (
                            <div className="mt-1 d-flex" style={{ color: "#E67C1B" }}>
                              <img width={12} className="me-2" height={12} src={crown} alt="" />
                              <Typography variant="tag1">Highest Ranked</Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">{row.employeeClass}</TableCell>
                    {isEditMode ? (
                      <TableCell align="center">
                        <StyledTextField
                          placeholder="Enter Questions Answered Correctly"
                          type="number"
                          value={formik.values?.userSet?.[index]?.correctAnswers}
                          onChange={formik.handleChange(`userSet.[${index}].correctAnswers`)}
                        />
                      </TableCell>
                    ) : (
                      <TableCell align="center">{row.correctAnswers}</TableCell>
                    )}
                    {isEditMode ? (
                      <TableCell align="center">
                        <StyledTextField
                          placeholder="Enter Signups / Upgrades"
                          type="number"
                          value={formik.values?.userSet?.[index]?.signUp_Upgrades}
                          onChange={formik.handleChange(`userSet.[${index}].signUp_Upgrades`)}
                        />
                      </TableCell>
                    ) : (
                      <TableCell align="center">{row.signUp_Upgrades}</TableCell>
                    )}
                    <TableCell align="center">{row.fairnessFactor}</TableCell>
                    <TableCell align="center">{row.coins}</TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Paper>
  )
}

export default PiggyBankTable
