import React, { useEffect, useState } from "react"
import { styled, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { CalendarPicker, LocalizationProvider, PickersDay } from "@mui/x-date-pickers"
import AppTextField from "components/StyledComponents/AppTextField"
import classes from "./dmvDatePicker.module.scss"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import moment from "moment"
import InfoIcon from "@mui/icons-material/Info"
import { useDispatch, useSelector } from "react-redux"
import { getHolidaysAction } from "redux/DMV/actions"

const StyledDatePicker = styled(CalendarPicker)(({ theme }) => ({
  maxWidth: "100%",
  "& .css-1dozdou": {
    padding: 0,
    height: 26,
    position: "relative",
    margin: 0,
    marginBottom: 12,
    "& .css-l0iinn": {
      margin: 0,
      width: "100%",
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      ...theme.typography.h1,
      fontSize: 20
    },
    "& .MuiPickersArrowSwitcher-root": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      height: "100%",
      alignItems: "center",
      top: 0,
      bottom: 0
    }
  },
  "& .PrivatePickersSlideTransition-root": {
    minHeight: "232px !important",
    height: "auto !important"
  }
}))

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" &&
    prop !== "isFirstDay" &&
    prop !== "isLastDay" &&
    prop !== "isRowStart" &&
    prop !== "isRowEnd"
})(({ dayIsBetween, isFirstDay, isLastDay, isRowStart, isRowEnd }) => ({
  borderRadius: 0,
  backgroundColor: "transparent !important",
  width: 46,
  overflow: "visible",
  "&.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root": {
    color: "#C4C4C4"
  },
  ...(dayIsBetween && {
    backgroundColor: "#23262F !important"
  }),
  ...((dayIsBetween || isLastDay) &&
    isRowStart && {
      backgroundColor: "#23262F !important",
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4
    }),
  ...((dayIsBetween || isFirstDay) &&
    isRowEnd && {
      backgroundColor: "#23262F !important",
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    }),
  ...((isFirstDay || isLastDay) && {
    backgroundColor: "#4FACE9 !important",
    border: "none !important",
    borderRadius: 4
  })
}))

const DMVDatePicker = ({
  title = "",
  tooltip = "",
  value = new Date(),
  start = new Date(),
  end = new Date(),
  onChange = () => {},
  readOnly,
  ...props
}) => {
  const dispatch = useDispatch()
  const [monthViewing, setMonthViewing] = useState(moment(value).format("YYYY-MM") + "-01")
  const holidays = useSelector((store) => store?.dmv?.holidayCalendar?.data)

  const renderWeekPickerDay = (date, pickersDayProps, holidays) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />
    }

    const formattedDate = moment(date).format("YYYY-MM-DD")
    const isEventDate = Boolean(holidays.find((el) => el?.holiday_date === formattedDate))

    const checkRowStart = () => {
      let momentObj = moment(date)
      return (
        momentObj.format("dddd") === "Sunday" ||
        momentObj.startOf("month").isSame(moment(date), "date")
      )
    }

    const checkRowEnd = () => {
      let momentObj = moment(date)
      return (
        momentObj.format("dddd") === "Saturday" ||
        momentObj.endOf("month").isSame(moment(date), "date")
      )
    }

    const getEventStyles = () => {
      if (isEventDate) {
        if (readOnly) {
          return classes.holiday
        }
        return classes.holiday
      }
    }

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        isRowStart={checkRowStart()}
        isRowEnd={checkRowEnd()}
        dayIsBetween={moment(date).isBetween(start, end)}
        isFirstDay={moment(start).isSame(moment(date), "date")}
        isLastDay={moment(end).isSame(moment(date), "date")}>
        <Box className={classes.pickerDateCtr}>
          {readOnly ? (
            <Box
              className={`${classes.readOnlyDateCtr} ${isEventDate ? classes.readOnlyEvent : ""}`}>
              <Box className={classes.eventDot} />
              <Typography variant="body1Medium" className={classes.calendarDateText}>
                {date.getDate()}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="body1Medium" className={classes.calendarDateText}>
                {date.getDate()}
              </Typography>
              <Box className={`${classes.event} ${getEventStyles()}`} />
            </>
          )}
        </Box>
      </CustomPickersDay>
    )
  }

  useEffect(() => {
    dispatch(getHolidaysAction(moment(monthViewing).format("YYYY-MM-DD")))
  }, [monthViewing])

  return (
    <Box className={classes.container}>
      <Box className={classes.TitleCtr}>
        <Typography className="me-2" variant="h3">
          {title}
        </Typography>
        {tooltip ? (
          <Tooltip title={tooltip}>
            <InfoIcon role="button" sx={{ width: "15px" }} />
          </Tooltip>
        ) : null}
      </Box>
      <Box className={classes.calendarCtr}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledDatePicker
            onChange={onChange}
            renderDay={(date, selectedDates, pickersDayProps) =>
              renderWeekPickerDay(
                date,
                pickersDayProps,
                holidays?.[moment(monthViewing).format("YYYY-MM-DD")] || []
              )
            }
            onMonthChange={setMonthViewing}
            renderInput={(params) => <AppTextField {...params} />}
            {...props}
            {...(readOnly ? {} : { date: value })}
          />
        </LocalizationProvider>
      </Box>
      <Box className={classes.holidaysCtr}>
        {holidays?.[moment(monthViewing).format("YYYY-MM-DD")]?.map((holiday, index) => (
          <Box key={index} className={classes.holidayCtr}>
            <Box className={classes.dateCtr}>
              <Typography variant="caption3">
                {moment(holiday.holiday_date).format("dddd").substring(0, 3)}
              </Typography>
              <Typography variant="caption2">{moment(holiday.holiday_date).get("date")}</Typography>
            </Box>
            <Box className={classes.holidayNameCard}>
              <Typography variant="body2Regular">{holiday.title}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default DMVDatePicker
