import React, { useEffect, useState } from "react"
import Card from "components/Card/Card"
import { Grid, List, ListItem, Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import { useHistory } from "react-router-dom"
import { getDynamicMenuFieldsAction } from "redux/superAdmin/actions"
import image from "assets/images/imagePlaceholder.png"
import MenuImageDisplayer from "components/Menu"

function MenuSnapShot({ path = "/marketing/dashboard/explore-menu", subCategory }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const { menuImageList } = useSelector((store) => store.superAdmin)
  const [menuSnapshotData, setMenuSnapshotData] = useState([])
  const mediaMatch = window.matchMedia("(max-width: 2000px) and (min-width: 1700px)")
  const classes = {
    featureItemContainer: {
      border: `1px solid `,
      borderColor: theme.palette.secondary.main,
      display: "flex",
      position: "relative",
      margin: 16,
      borderRadius: 12,
      height: 120,
      cursor: "pointer"
    },
    wineMonth: {
      textTransform: "uppercase",
      color: theme.palette.secondary.main
    },
    wineBottle: {
      height: "100%",
      width: `${mediaMatch.matches ? "calc(100% - 120px)" : "calc(100% - 80px)"}`
    },
    wineImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12
    },
    wineDetail: {
      padding: 16,
      display: "flex",
      alignItems: "end"
    }
  }

  function handleMenuItem(items, name) {
    const linkItem = {
      item: items,
      category: subCategory || "",
      list: "Summary View"
    }
    dispatch(marketingActions.setMenuLink(linkItem))
    history.push(path)
    dispatch(getDynamicMenuFieldsAction({ category: name }))
  }

  useEffect(() => {
    const newDate = menuImageList.slice(0, 4).map((item) => {
      return {
        image: item.icon,
        name: item.name,
        link: "/marketing/dashboard/explore-menu",
        value: item.name.toLowerCase()
      }
    })

    setMenuSnapshotData(newDate)
  }, [menuImageList])

  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem sx={{ justifyContent: "space-between", padding: "0 0 12px 0" }}>
          <Typography variant="h2">Menu</Typography>
        </ListItem>
      </List>
      {menuSnapshotData?.length ? (
        <Grid
          spacing={{ xs: 2, md: 3, lg: 4 }}
          container
          style={classes.mainContainer}
          className="p-4 ">
          {menuSnapshotData?.map((items, idx) => {
            return (
              <Grid key={idx} item xs={12} sm={12} md={4} lg={3} xl={3} className="p-0">
                <div
                  style={classes.featureItemContainer}
                  onClick={() => handleMenuItem(items?.value, items?.name)}>
                  <div style={classes.wineBottle}>
                    {items?.image !== null ? (
                      <MenuImageDisplayer image={items?.image} />
                    ) : (
                      <img src={image} style={classes.wineImage} />
                    )}
                  </div>
                  <div style={classes.wineDetail}>
                    <div style={classes.nameWine}>
                      <Typography variant="caption2" style={classes.wineMonth}>
                        {items?.name}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Typography variant="caption2" style={{ opacity: 0.6 }}>
          No data Found
        </Typography>
      )}
    </Card>
  )
}

export default MenuSnapShot
