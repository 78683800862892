import React from "react"
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from "chart.js"
import { Bar, BarChart, LabelList, XAxis, YAxis } from "recharts"
import { CartesianGrid } from "recharts"
import { ResponsiveContainer } from "recharts"
import { Typography } from "@mui/material"
import { ListItem } from "@mui/material"
import { List } from "@mui/material"

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
)

function DMVBarChart(props) {
  const data = [
    {
      name: "Round 1",
      improvedCandidates: 5000
    },
    {
      name: "Round 2",
      improvedCandidates: 3000
    },
    {
      name: "Round 3",
      improvedCandidates: 5000
    }
  ]

  return (
    <>
      {props.isHeading && (
        <List>
          <ListItem sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
            <Typography variant="h2">{props.heading}</Typography>
          </ListItem>
        </List>
      )}
      <ResponsiveContainer width="100%" height={props.height}>
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 20,
            bottom: 0
          }}>
          <CartesianGrid strokeDasharray="1 0" strokeOpacity={0.2} vertical={false} />
          <XAxis dataKey="name" />
          <YAxis
            tickLine={false}
            axisLine={false}
            label={{
              value: "CANDIDATES IMPROVED",
              angle: -90,
              position: "insideBottomLeft",
              strokeOpacity: "0.2",
              fill: "#a4a5a6"
            }}
          />
          <LabelList dataKey="improvedCandidates" />
          <Bar barSize={50} dataKey="improvedCandidates" fill="#4FACE9" />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default DMVBarChart
