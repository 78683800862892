import React from "react"
import { Container, Row } from "react-bootstrap"
import Header from "components/Header/Header"
import { Col } from "react-bootstrap"
import ButtonNav from "components/ButtonNav"
import { useHistory } from "react-router-dom"

function NavHeader({ breadCrumbs, isClientActive = false, isRestaurantActive = false }) {
  const history = useHistory()
  return (
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <Header title="Client Onboarding" breadCrumbs={breadCrumbs} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <ButtonNav
            isActive={isClientActive}
            onPress={() => history.push("dmvDMV/dashboard/client-information")}>
            Client
          </ButtonNav>
          <ButtonNav
            isActive={isRestaurantActive}
            onPress={() => history.push("/dmv/dashboard/restaurant-information")}>
            Restaurant
          </ButtonNav>
        </Col>
      </Row>
    </Container>
  )
}

export default NavHeader
