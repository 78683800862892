import React, { useState } from "react"
import { useEffect } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import Card from "components/Card/Card"
import { Col, Container, Row } from "react-bootstrap"
import { Typography } from "@mui/material"
import AppButtonFilled from "components/StyledComponents/AppButtonFilled"
import BasePeriodNav from "../BasePeriodNavBar"
import classes from "./BasePeriodTable.module.scss"
import AttendantsTable from "./attendantsTable"
import ExcludedTable from "./excludedTable"

function BasePeriodTable() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  // const history = useHistory();
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Base Period Graph"
  }, [])

  return (
    <>
      <BasePeriodNav breadCrumbs={breadCrumbs} isPeriodTableActive={true}></BasePeriodNav>
      <Card
        style={{
          borderTopLeftRadius: "0px",
          marginTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
        <Row className="m-0 px-4">
          <Col
            lg="12"
            className="d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}>
            <Typography variant="h2">Base Period Statistics</Typography>
          </Col>
        </Row>
        <Row className="m-0 mt-3 px-4 d-flex justify-content-between">
          <Col lg="4">
            <div className={classes.statsBox}>
              <Typography variant="body2regular" style={{ opacity: "0.6" }}>
                Invites
              </Typography>
              <Typography variant="body2regular">644</Typography>
            </div>
          </Col>
          <Col lg="4">
            <div className={classes.statsBox}>
              <Typography variant="body2regular" style={{ opacity: "0.6" }}>
                Non - WCM Guests
              </Typography>
              <Typography variant="body2regular">7829</Typography>
            </div>
          </Col>
          <Col lg="4">
            <div className={classes.statsBox}>
              <Typography variant="body2regular" style={{ opacity: "0.6" }}>
                Invite Rate
              </Typography>
              <Typography variant="body2regular">8.9%</Typography>
            </div>
          </Col>
        </Row>
      </Card>
      <Container fluid className="mt-5">
        <Row className="m-0">
          <Col
            lg="12"
            className="d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}>
            <Typography variant="h2">Tampa TR Attendants</Typography>
            <AppButtonFilled onClick={() => history.push("/dmv/dashboard/")}>
              Preview candidate List
            </AppButtonFilled>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3" style={{ minWidth: "100%" }}>
        <AttendantsTable rows={attendantsRows} columns={attendantsColumns}></AttendantsTable>
      </Container>
      <Container fluid className="mt-5">
        <Row className="m-0">
          <Col
            lg="12"
            className="d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}>
            <Typography variant="h2">Excluded Employees</Typography>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3" style={{ minWidth: "100%" }}>
        <ExcludedTable rows={excludedRows} columns={excludedColumns}></ExcludedTable>
      </Container>
    </>
  )
}

export default BasePeriodTable

const attendantsColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Name",
    label: " Name",
    minWidth: 200
  },
  {
    id: "Lost Opportunity",
    label: "Lost Opportunity",
    align: "center",
    minWidth: 150
  },
  {
    id: "Has App ",
    label: "Has App ",
    align: "center",
    minWidth: 150
  },
  {
    id: "Bottles",
    label: "Bottles ",
    align: "center",
    minWidth: 150
  },
  {
    id: "% of Total",
    label: "% of Total ",
    align: "center",
    minWidth: 150
  },
  {
    id: "Non Members",
    label: "Non Members ",
    align: "center",
    minWidth: 150
  },
  {
    id: "Invite Rate",
    label: "Invite Rate ",
    align: "center",
    minWidth: 150
  },
  {
    id: "Invites",
    label: "Invites ",
    align: "center",
    minWidth: 150
  },
  {
    id: "Wine Club Members",
    label: "Wine Club Members ",
    align: "center",
    minWidth: 150
  },
  {
    id: "Non WCM Guests",
    label: "Non WCM Guests",
    align: "center",
    minWidth: 150
  },
  {
    id: "Upgrade Rate",
    label: "Upgrade Rate",
    align: "center",
    minWidth: 150
  },
  {
    id: "Performance Gap",
    label: "Performance Gap",
    align: "center",
    minWidth: 150
  },
  {
    id: "Membership Opportunity",
    label: "Membership Opportunity",
    align: "center",
    minWidth: 150
  },
  {
    id: "Membership Annualized",
    label: "Membership Annualized",
    align: "center",
    minWidth: 150
  },
  {
    id: "Tenure",
    label: "Tenure",
    align: "center",
    minWidth: 150
  },
  {
    id: "Beverage Average Check",
    label: "Beverage Average Check",
    align: "center",
    minWidth: 150
  },
  {
    id: "Wine Average Check",
    label: "Wine Average Check",
    align: "center",
    minWidth: 150
  },
  {
    id: "Total Average Check",
    label: "Total Average Check",
    align: "center",
    minWidth: 150
  }
]

const attendantsRows = [
  {
    id: 1,
    rank: 1,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 54,
    percentOfTotal: 15.0,
    nonMembers: 300,
    inviteRate: 10.0,
    invites: 52,
    wineClubMembers: 230,
    nonWCM: 199,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 0,
    membershipAnnualized: 0,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  },
  {
    id: 2,
    rank: 2,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 94,
    percentOfTotal: 13.0,
    nonMembers: 500,
    inviteRate: 60.0,
    invites: 52,
    wineClubMembers: 530,
    nonWCM: 569,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 0,
    membershipAnnualized: 0,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  },
  {
    id: 3,
    rank: 3,
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 54,
    percentOfTotal: 15.0,
    nonMembers: 300,
    inviteRate: 10.0,
    invites: 52,
    wineClubMembers: 230,
    nonWCM: 199,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 0,
    membershipAnnualized: 0,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  },
  {
    id: 4,
    rank: 4,
    name: "Robert Fox",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 94,
    percentOfTotal: 13.0,
    nonMembers: 500,
    inviteRate: 60.0,
    invites: 52,
    wineClubMembers: 530,
    nonWCM: 569,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 0,
    membershipAnnualized: 0,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  },
  {
    id: 5,
    rank: 5,
    name: "Marvin McKinney",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 54,
    percentOfTotal: 15.0,
    nonMembers: 300,
    inviteRate: 10.0,
    invites: 52,
    wineClubMembers: 230,
    nonWCM: 199,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 0,
    membershipAnnualized: 0,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  },
  {
    id: 6,
    rank: 6,
    name: "Jade Cooper",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 94,
    percentOfTotal: 13.0,
    nonMembers: 500,
    inviteRate: 60.0,
    invites: 52,
    wineClubMembers: 530,
    nonWCM: 569,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 34,
    membershipAnnualized: 54,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  },
  {
    id: 7,
    rank: 7,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 54,
    percentOfTotal: 15.0,
    nonMembers: 300,
    inviteRate: 10.0,
    invites: 52,
    wineClubMembers: 230,
    nonWCM: 199,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 23,
    membershipAnnualized: 67,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  },
  {
    id: 8,
    rank: 8,
    name: "Marcella Ellis",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 94,
    percentOfTotal: 13.0,
    nonMembers: 500,
    inviteRate: 60.0,
    invites: 52,
    wineClubMembers: 530,
    nonWCM: 569,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 45,
    membershipAnnualized: 98,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  },
  {
    id: 9,
    rank: 9,
    name: "Albert Flores",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 54,
    percentOfTotal: 15.0,
    nonMembers: 300,
    inviteRate: 10.0,
    invites: 52,
    wineClubMembers: 230,
    nonWCM: 199,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 23,
    membershipAnnualized: 65,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  },
  {
    id: 10,
    rank: 10,
    name: "Devon Lane",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    lostOpportunity: 0,
    hasApp: "-",
    bottles: 94,
    percentOfTotal: 13.0,
    nonMembers: 500,
    inviteRate: 60.0,
    invites: 52,
    wineClubMembers: 530,
    nonWCM: 569,
    upgradeRate: 6.7,
    performanceGap: "-",
    membershipOpp: 34,
    membershipAnnualized: 34,
    tenure: "-",
    beverageAverageCheck: "-",
    wineAverageCheck: "-",
    totalAverageCheck: "-"
  }
]

const excludedColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 200
  },
  {
    id: "Invite Rate",
    label: "Invite Rate",
    align: "center",
    minWidth: 150
  },
  {
    id: "Invites",
    label: "Invites",
    minWidth: 150,
    align: "center"
  },
  {
    id: "Non-WCM Guests",
    label: "Non-WCM Guests",
    align: "center",
    minWidth: 150
  }
]

const excludedRows = [
  {
    id: 1,
    rank: 1,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    inviteRate: 50.0,
    invites: 2,
    nonWCM: 4
  },
  {
    id: 2,
    rank: 2,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    inviteRate: 0.0,
    invites: 0,
    nonWCM: 4
  }
]
