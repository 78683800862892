import React, { useState } from "react"
import { List, ListItem, Typography } from "@mui/material"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Card from "components/Card/Card"
import ListWith2Items from "components/List/ListBodyWith2Items"
import ListHeaderFor2Items from "components/List/ListHeaderFor2Items"
import { useHistory } from "react-router-dom"

function Top5LeadersUI({ routeNext, tableData = [], date = "", style = {} }) {
  const [tableHeading] = useState(TABLE_HEADING)
  const history = useHistory()

  const styles = {
    userBoxList: {
      overflow: "auto",
      maxHeight: "200px",
      height: "100%"
    }
  }

  return (
    <Card style={{ paddingBottom: 12, ...style }}>
      <List>
        <ListItem sx={{ justifyContent: "space-between", padding: "0 0 12px 0" }}>
          <Typography variant="h2">Leaderboard {date ? `for ${date} ` : ""}</Typography>
          <span onClick={() => history.push(routeNext)}>
            <IconButtonWithBorder aria-label="Go to Leaderboard">
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>
        <ListHeaderFor2Items heading={tableHeading} />
        {tableData.length === 0 ? (
          <div className="text-center mt-3">
            <Typography variant="body2regular" sx={{ opacity: 0.6 }}>
              No Records Found
            </Typography>
          </div>
        ) : (
          <div style={styles.userBoxList}>
            <ListWith2Items tableData={tableData} marginRight={"28px"} />
          </div>
        )}
      </List>
    </Card>
  )
}

export default Top5LeadersUI

// const TABLE_DATA = [
//   {
//     id: 0,
//     key: "Scott Hatteberg",
//     field: "80"
//   },
//   {
//     id: 1,
//     key: "Carlos Pena",
//     field: "72"
//   },
//   {
//     id: 2,
//     key: "Marcella Ellis",
//     field: "72"
//   },
//   {
//     id: 3,
//     key: "Albert Flores",
//     field: "40"
//   },
//   {
//     id: 4,
//     key: "Devon Lane",
//     field: "30"
//   }
// ];

const TABLE_HEADING = {
  key: "Employee Name",
  field: "Coins Earned"
}
