import { createAction } from "@reduxjs/toolkit"

export const suggestQuestionAction = createAction("suggestQuestionAction")

export const getTriviaCategoriesAction = createAction("getTriviaCategoriesActiond")

export const uploadSuggestedQuestionImageAction = createAction("uploadSuggestedQuestionImageAction")

export const getNextContestListAction = createAction("getNextContestListAction")
export const getLeaderBoardGMListAction = createAction("getLeaderBoardGMListAction")
export const getTriviaDashboardAnalyticsAction = createAction("getTriviaDashboardAnalyticsAction")

export const getGMCalendarDataAction = createAction("getGMCalendarDataAction")

export const getGMCorrectAnswersAction = createAction("getGMCorrectAnswersAction")

export const getGMInCorrectAnswersAction = createAction("getGMInCorrectAnswersAction")

export const getTop5RanksActions = createAction("getTop5RanksActions")

export const revertAllGM = createAction("revertAllGM")

export const getSuggestedQuestionDataAction = createAction("getSuggestedQuestionDataAction")
