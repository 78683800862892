import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import LeaderBoardList from "components/LeaderboardComponents/LeaderBoardList/LeaderBoardList"

import Header from "components/Header/Header"
import alert from "assets/icons/alert.svg"
import {} from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { useDispatch, useSelector } from "react-redux"
import { appActions } from "redux/app/slice/app"
import { getLeaderBoardUserListAction, getWinnerListMerchantAction } from "redux/L&D/actions"
import moment from "moment"
import Card from "components/Card/Card"
import MonthlySwag from "components/LeaderboardComponents/MonthlySwag/MonthlySwag"

function LeaderBoard() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [leaderBoardRowsData, setLeaderBoardRowsData] = useState([])
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const merchantData = useSelector((store) => store?.learningDevelopment?.leaderBoardMerchant)
  const { leaderBoardUserList } = useSelector((store) => store?.learningDevelopment)

  const { date, employeeClass } = useSelector((state) => state?.app?.filters || "")

  function createLeaderBoardListData() {
    setLeaderBoardRowsData(
      leaderBoardUserList?.data?.map((res) => ({
        id: res.user.id,
        rank: res.rank,
        name: res.user.name,
        image: res.user.image_url,
        employeeClass: res.user.job_class ?? "-",
        correctAnswers: res.correct_answer ?? "-",
        signUp_Upgrades: "-",
        coins: res.cooper_coins ?? "-",
        fairnessFactor: res.fairness_factor ?? "-",
        restaurantName: res.user.rest_name
      }))
    )
  }

  useEffect(() => {
    createLeaderBoardListData()
  }, [leaderBoardUserList])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Top 5 Leaderboard"
  }, [])

  useEffect(() => {
    dispatch(
      getLeaderBoardUserListAction({ date: date, employeeClass: employeeClass, page: currentPage })
    )
    dispatch(getWinnerListMerchantAction({ date }))
  }, [date, employeeClass, currentPage])
  useEffect(() => {
    setCurrentPage(1)
  }, [date, employeeClass])

  useEffect(() => {
    dispatch(appActions.initFilters())
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header
              title={`Leaderboard For ${moment(date).format("MMMM'YY")}`}
              breadCrumbs={breadCrumbs}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <LeaderBoardList
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={leaderBoardUserList.totalPages}
              rowsData={leaderBoardRowsData}
              columns={LeaderBoardColumns}></LeaderBoardList>
          </Col>
        </Row>
      </Container>
      {/* remove for pilot */}

      <Container fluid className="p-0 mt-4  ">
        <Row>
          <Col lg="12">
            <Card>
              <MonthlySwag merchantWinnerList={merchantData}></MonthlySwag>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default LeaderBoard

const LeaderBoardColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 170
  },
  {
    id: "Employee Class",
    label: "Employee Class  ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Restaurant",
    label: "Restaurant",
    align: "center",
    minWidth: 170
  },
  {
    id: "Trivia Questions Answered Correctly",
    label: "Trivia Questions Answered Correctly  ",
    align: "center",
    minWidth: 150
  },
  {
    id: "Cooper Coins Earned",
    label: "Cooper Coins Earned",
    align: "center",
    tooltip: "Trivia Questions Answered Correctly * 5 = Cooper Coins Earned",
    icon: alert,
    minWidth: 150
  }
]
