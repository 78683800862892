import React, { Fragment } from "react"
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  DialogContent,
  List,
  ListItem
} from "@mui/material"
import DailyStatsTableHeader from "./DailyStatsTableHeader"
import DailyStatsTableBody from "./DailyStatsTableBody"
import { useTheme } from "@mui/system"
import CustomDivider from "components/StyledComponents/Divider"

function DailyStatsBody({ content }) {
  const theme = useTheme()
  const TABLE_HEADERS = [
    {
      id: "Employee Job Class",
      label: "Employee Job Class",
      minWidth: "270px",
      paddingLeft: "0",
      paddingRight: "0"
    },
    {
      id: "Invites",
      label: "Invites",
      minWidth: "90px",
      align: "center"
    },
    {
      id: "Upgrades",
      label: "Upgrades",
      minWidth: "90px",
      align: "center"
    },
    {
      id: "Total",
      label: "Total",
      minWidth: "60px",
      align: "right",
      paddingRight: "0",
      paddingLeft: "0"
    }
  ]

  const styles = {
    list: {
      marginTop: "16px",
      marginBottom: "20px",
      paddingBottom: "0"
    },
    listItemStyle: {
      justifyContent: "space-between",
      paddingLeft: "24px",
      paddingRight: "24px",
      paddingTop: "12px",
      paddingBottom: "12px",
      marginTop: "0",
      zIndex: "2"
    },
    list2: {
      marginTop: "0",
      marginBottom: "2px"
    }
  }

  return (
    <DialogContent sx={{ padding: "0" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <DailyStatsTableHeader tableHeaders={TABLE_HEADERS} />
            </TableRow>
          </TableHead>
          <TableBody>
            <DailyStatsTableBody content={content} />
          </TableBody>
        </Table>
      </TableContainer>

      {/* WCM and Non-WCM guests */}
      <List sx={styles.list}>
        {content.stats.guestStats.map((item) => (
          <Fragment key={item.key}>
            <ListItem
              sx={[
                styles.listItemStyle,
                item.key === "Total" && {
                  backgroundColor: theme.palette.background.secondary,
                  borderRadius: "6px"
                }
              ]}>
              <Typography variant="body1Regular" sx={{ opacity: "0.6", zIndex: "5" }}>
                {item.key}
              </Typography>
              <Typography variant="body1Regular" sx={{ opacity: 1, zIndex: "5" }}>
                {item.field}
              </Typography>
            </ListItem>
          </Fragment>
        ))}
      </List>

      <CustomDivider />

      {/* Invite and upgrade rates */}
      <List sx={styles.list2}>
        {content.stats.inviteStats.map((item) => (
          <Fragment key={item.key}>
            <ListItem sx={styles.listItemStyle}>
              <Typography
                variant="body1Regular"
                sx={{
                  opacity: "0.6"
                }}>
                {item.key}
              </Typography>
              <Typography
                variant="body1Regular"
                sx={{
                  opacity: 1
                }}>
                {item.field}
              </Typography>
            </ListItem>
          </Fragment>
        ))}
      </List>
    </DialogContent>
  )
}

export default DailyStatsBody
