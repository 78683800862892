import React from "react"
import { Typography } from "@mui/material"
import classes from "../../pages/private/marketing/menu/menu.module.scss"

const AddMenuItemButton = ({ onAddItems, text, isLongCategory = false }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      onClick={onAddItems}
      style={{ cursor: "pointer" }}>
      <div className={`${isLongCategory ? classes.addIconSecond : classes.addIcon}`}>+</div>
      <Typography className={`${isLongCategory ? classes.addButtonSecond : classes.addButton}`}>
        {text}
      </Typography>
    </div>
  )
}

export default AddMenuItemButton
