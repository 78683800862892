import React, { useState } from "react"
import { Grid, styled, TextField, Typography } from "@mui/material"
import classes from "./InputCounter.module.scss"

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root:before": {
    borderBottom: "none !important"
  },
  "& .MuiInput-root:before": {
    borderBottom: "none !important"
  },
  "& .Mui-focused:after": {
    transform: "none",
    border: `1px solid ${theme.palette.text.primary}`
  },
  "& .MuiInputBase-input": {
    padding: "12px 16px",
    color: "#FFFFFF",
    border: `1px solid #212121`,
    background: `#212121`,
    textAlign: "center",
    borderRadius: 6,
    ...theme.typography.body1Regular,
    lineHeight: "24px"
  },
  "& .Mui-error": {
    "& .MuiInputBase-input": {
      border: `1px solid ${theme.palette.error.main}`
    },
    "& .MuiIconButton-root": {
      border: `1px solid ${theme.palette.error.main}`,
      borderLeft: "none"
    }
  },
  "& .MuiFilledInput-root": {
    "& .Mui-focused": {
      borderRadius: 6,
      backgroundColor: theme.palette.background.default
    },
    "&:hover": {
      backgroundColor: `${theme.palette.background.default} !important`
    }
  },
  "& .Mui-disabled": {
    "& .MuiInputBase-input": {
      WebkitTextFillColor: "#969698"
    }
  }
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

function InputCounter({
  label,
  isDisabled,
  isRequired,
  style = {},
  className = "",
  placeholder,
  formStyle = {},
  onChange = () => {},
  value = 0,
  ...props
}) {
  const [counter, setCounter] = useState(value)

  function incrementValue() {
    setCounter(counter + 1)
  }

  function decrementValue() {
    setCounter(counter - 1)
  }

  return (
    <Grid container style={style} className={className}>
      {label ? (
        <StyledLabel variant="body1Regular">
          {label}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12} className={classes.inputContainer}>
        <div onClick={decrementValue} className={classes.iconCounter}>
          -
        </div>
        <StyledTextField
          InputLabelProps={{
            shrink: true
          }}
          type="number"
          InputProps={{ disableUnderline: true }}
          variant="filled"
          disabled={isDisabled}
          onChange={onChange}
          placeholder={placeholder}
          sx={(theme) => ({
            "& .MuiFilledInput-root": {
              backgroundColor: value ? theme.palette.background.default : "transparent"
            }
          })}
          style={formStyle}
          value={counter}
          {...props}
        />
        <div onClick={incrementValue} className={classes.iconCounter}>
          +
        </div>
      </Grid>
    </Grid>
  )
}

export default InputCounter
