export function createBreadcrumbs() {
  let breadcrumbs = []
  const label = location.pathname
    .split("/")
    .filter((x) => x)
    .splice(0)
  const href = location.pathname.split("/").filter((x) => x)
  let URl = `/${label[0]}`
  for (let i = 0; i < label.length - 1; i++) {
    URl = `${URl}/${href[i + 1]}`
    let newLabel = `${label[i + 1]}`
    let objectData = {
      id: i,
      label: `${newLabel
        .toLowerCase()
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
        .join(" ")}`,
      href: URl
    }
    breadcrumbs.push(objectData)
  }
  return breadcrumbs
}
