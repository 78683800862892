import React, { useState } from "react"
import classes from "pages/private/marketing/menu/menu.module.scss"
import { Typography, useMediaQuery } from "@mui/material"
import ImageDisplay from "components/ImageDisplayer"
import { useTheme } from "@mui/system"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import {
  deleteWineItemAction,
  deleteWineMenuCategoryAction,
  getWineItemsAction,
  updateWineItemsAction
} from "redux/marketing/actions"
import { useEffect } from "react"
import Paragraph from "components/ReadMoreParagraph"
import AddMenuItems from "pages/private/marketing/menu/AddMenuItems"
import { Col, Row } from "react-bootstrap"
import trashIcon from "assets/icons/trash.svg"
import Card from "components/Card/Card"
import editIcon from "assets/icons/edit.svg"
import archiveIcon from "assets/icons/UnarchiveItem.svg"
import AddMenuItemButton from "components/StyledComponents/addNewItemButton"

function WineBottleListItems({
  data,
  isEdit,
  setCategoryModal = () => {},
  setCurrentCategoryData = () => {},
  setIsEdit = () => {}
}) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const [modalCategory, setDeleteCategoryModal] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [ArchiveModal, setArchiveItemModal] = useState(false)
  const dispatch = useDispatch()
  const categoryLength = data?.food_category?.length || 0
  const isSmall = useMediaQuery("(max-width:1440px)")

  const handleClick = (type, id, data) => {
    dispatch(marketingActions.setMenuItemId(id))
    dispatch(marketingActions.setMenuCategoryId(data?.food_category_id))
    dispatch(getWineItemsAction())
    if (type == "edit") {
      handleEditMenuItemModal()
    } else if (type == "archive") {
      handleArchiveItem()
    } else {
      handleMenuDeleteModal()
    }
  }

  function handleMenuItemDelete() {
    dispatch(deleteWineItemAction())
  }

  function handleMenuDeleteModal() {
    setDeleteModal(true)
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  function handleEditMenuItemModal() {
    setIsEditModalItem(true)
    setMenuItemsModal(true)
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  function handleArchiveItem() {
    setArchiveItemModal(true)
  }

  function handleCloseArchiveModal() {
    setArchiveItemModal(false)
  }

  function handleArchiveMenuItem() {
    const payload = {
      item_state: "archived"
    }
    dispatch(updateWineItemsAction({ payload: payload, onClose: handleCloseArchiveModal }))
  }

  function handleMenuCategoryDeleteModal(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    setDeleteCategoryModal(true)
  }

  function EditCategoryModal(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    setCategoryModal(true)
    setIsEdit(true)
    setCurrentCategoryData(data)
  }

  function handleMenuPeriodDelete(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    dispatch(deleteWineMenuCategoryAction({ mealCategoryId: id }))
  }

  function handleAddItems(id, data) {
    dispatch(marketingActions.setBarCategory(data?.food_category))
    setIsEditModalItem(false)
    dispatch(marketingActions.setMenuCategoryId(id))
    setMenuItemsModal(true)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  useEffect(() => {}, [])

  return (
    <>
      <AddMenuItems
        isEdit={isEditModalItem}
        open={menuItemsModal}
        onClose={handleCloseMenuItems}></AddMenuItems>
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this wine ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are you sure you want to archive this wine?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleArchiveMenuItem()}
        open={ArchiveModal}
        btnText="Archive"
        type="warning"
      />
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete “${data?.food_category ?? ""}”?`}
        description={`"${data?.food_category ?? ""}" has ${data?.food_list?.length ?? ""} ${
          data?.food_list?.length <= 1 ? "dish" : "dishes"
        }`}
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteCategoryModal(false)}
        onDelete={() => handleMenuPeriodDelete(data?.food_category_id)}
        open={modalCategory}
      />
      <Card style={{ height: "fit-content" }}>
        <Row>
          <Col lg="12">
            <div
              className="d-flex justify-content-between align-item-center"
              style={{
                backgroundColor: isEdit && theme.palette.background.default,
                padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                borderRadius: 6
              }}>
              <div className="d-flex positon-absolute">
                <Typography variant="h2">
                  {data?.food_category}
                  {isEdit && (
                    <img
                      className={classes.edit}
                      src={editIcon}
                      onClick={() => EditCategoryModal(data?.food_category_id)}
                    />
                  )}
                </Typography>
              </div>
              {isEdit && (
                <div className="d-flex align-items-center">
                  <AddMenuItemButton
                    onAddItems={() => handleAddItems(data?.food_category_id, data)}
                    text="Add Menu Item"
                    typographyClass={classes.addButton}
                    {...(isSmall && categoryLength > 23 ? { isLongCategory: true } : {})}
                  />
                  <img
                    className={classes.trashIcon}
                    onClick={() => handleMenuCategoryDeleteModal(data?.food_category_id)}
                    src={trashIcon}
                    alt=""
                  />
                </div>
              )}
            </div>
          </Col>
          <Col lg="12">
            {data?.food_list?.length ? (
              data?.food_list?.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className={classes.itemContainer}
                    style={{
                      border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
                      borderBottom: `${
                        !isEdit ? `1px solid #605e5e` : `.5px solid ${theme.palette.secondary.main}`
                      }`,
                      borderRadius: `${isEdit ? "6px" : "0px"}`,
                      padding: `${isEdit ? "8px" : "5px"}`
                    }}>
                    <div className={classes.imageContainer} style={{ height: 250 }}>
                      <ImageDisplay data={item?.image_url}></ImageDisplay>
                    </div>
                    <div
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        maxWidth: 375,
                        overflowWrap: "break-word"
                      }}>
                      <div key={item.id}>
                        <Typography variant="h4">{item?.name ?? "-"}</Typography>
                      </div>

                      <div>
                        {item?.data_to_display?.map((data, index) => {
                          return (
                            <div key={index}>
                              {data.filedName === "Tasting Notes"
                                ? (data?.value?.length > 0 || isEdit) && (
                                    <div className="mt-2 d-flex flex-column">
                                      <Typography variant="menuLabel">{data.filedName}</Typography>
                                      <Typography variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                                        {data?.value?.length > 0
                                          ? data?.value?.map((item, index) => {
                                              return data?.value?.length - 1 > index
                                                ? `${item?.name}, `
                                                : `${item?.name}`
                                            })
                                          : ""}
                                      </Typography>
                                    </div>
                                  )
                                : data.filedName === "Notes"
                                ? (data?.value || isEdit) && (
                                    <div className="mt-2 d-flex flex-column">
                                      <Typography variant="menuLabel">{"Notes:"}</Typography>
                                      <Typography
                                        component="div"
                                        variant="menuLabelItalic"
                                        sx={{ opacity: 0.6 }}>
                                        {
                                          <Paragraph
                                            text={data?.value ?? ""}
                                            maxCharCount={70}></Paragraph>
                                        }
                                      </Typography>
                                    </div>
                                  )
                                : data.filedName === "Ingredients"
                                ? (data?.value?.length > 0 || isEdit) && (
                                    <div className="mt-2">
                                      <Typography variant="tag1">{data.filedName}</Typography>
                                      <br></br>

                                      {typeof data?.value !== "string" ? (
                                        data?.value?.map((item, index) => (
                                          <Typography
                                            key={index}
                                            sx={{
                                              opacity: 0.6,
                                              fontSize: 12,
                                              fontWeight: "400",
                                              fontStyle: "italic",
                                              display: item?.name ? "list-item" : "",
                                              marginLeft: 2
                                            }}>
                                            {`${item?.name ?? ""}`}
                                            {item?.measurement
                                              ? data?.value?.length - 1 > index
                                                ? ` (${item?.measurement})`
                                                : item?.measurement
                                                ? ` (${item?.measurement}) `
                                                : ""
                                              : data?.value?.length - 1 > index
                                              ? ", "
                                              : ""}
                                          </Typography>
                                        ))
                                      ) : (
                                        <div>
                                          {data.value.split(",").map((ingredient, index) => (
                                            <Typography
                                              key={index}
                                              sx={{
                                                opacity: 0.6,
                                                fontSize: 12,
                                                fontWeight: "400",
                                                fontStyle: "italic",
                                                display: ingredient ? "list-item" : "",
                                                marginLeft: 2
                                              }}>
                                              {data.value.split(",")?.length - 1 > index
                                                ? `${ingredient.trim()},`
                                                : ingredient.trim()}
                                            </Typography>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  )
                                : (data?.value || isEdit) && (
                                    <div className="mt-2 d-flex flex-column">
                                      <Typography variant="menuLabel">{data.filedName}</Typography>
                                      <Typography
                                        component="div"
                                        variant="menuLabelItalic"
                                        sx={{ opacity: 0.6 }}>
                                        {typeof data?.value === "string" &&
                                        data.value.includes("\n") ? (
                                          data.value
                                            .split("\n")
                                            .map((line, index) =>
                                              line.length > 70 ? (
                                                <Paragraph
                                                  key={index}
                                                  text={line}
                                                  maxCharCount={70}
                                                  component="span"
                                                />
                                              ) : (
                                                <div key={index}>{line}</div>
                                              )
                                            )
                                        ) : typeof data?.value === "string" &&
                                          data.value.length > 70 ? (
                                          <Paragraph text={data.value} maxCharCount={70} />
                                        ) : (
                                          data?.value || "-"
                                        )}
                                      </Typography>
                                    </div>
                                  )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        {item?.price > 0 && <Typography variant="bold">${item?.price}</Typography>}
                      </div>
                      {isEdit && (
                        <div className={classes.actionButtonContainer}>
                          <div className="d-grid justify-content-center mt-1">
                            <img
                              src={editIcon}
                              alt="edit"
                              onClick={() => handleClick("edit", item?.id, data)}
                              className={classes.menu_action_icon}
                            />
                          </div>
                          <div className="d-grid justify-content-center mt-1">
                            <img
                              src={archiveIcon}
                              alt="unarchive"
                              className={classes.menu_action_icon}
                              onClick={() => handleClick("archive", item?.id, data)}
                            />
                          </div>
                          <div className="d-grid justify-content-center mt-1">
                            <img
                              onClick={() => handleClick("delete", item?.id, data)}
                              className={classes.menu_action_icon}
                              src={trashIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })
            ) : (
              <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                No Items Found
              </Typography>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default WineBottleListItems
