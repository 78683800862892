import { Box, Grid } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import RoleListTable from "./roleListTable"
import { useDispatch, useSelector } from "react-redux"
import { getUserRoleAction } from "redux/superAdmin/actions"
import RoleModal from "./AddRole"
import { superAdminAction } from "redux/superAdmin/slice/user"
import SearchComponent from "components/SearchComponent"
import { useDebounce } from "hooks/utils"

const RoleManagement = () => {
  const dispatch = useDispatch()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [page, setPage] = useState(1)
  const [roleList, setRoleList] = useState([])
  const { userRoleList } = useSelector((store) => store?.superAdmin ?? [])
  const { searchField } = useSelector((store) => store?.app?.filters || "")
  const debouncedSearchTerm = useDebounce(searchField, 500)

  const handleCreateRole = () => {
    setIsEdit(false)
    setOpenModal(true)
  }

  function handleEditRole() {
    setIsEdit(true)
    setOpenModal(true)
  }

  function handleCloseModal() {
    setIsEdit(false)
    setOpenModal(false)
    dispatch(superAdminAction.setRoleDetails({}))
  }

  function filterResults(results, searchTerm) {
    const trimmedQuery = searchTerm.trim().toLowerCase()
    if (!trimmedQuery) {
      return results
    }

    // Check if search term is provided
    if (searchTerm.trim() !== "") {
      const filteredResults = results.filter((item) => {
        const nameMatch = item?.name?.toLowerCase().includes(trimmedQuery)
        return nameMatch
      })

      return filteredResults
    }
  }

  useEffect(() => {
    const filteredResults = filterResults(userRoleList, debouncedSearchTerm)
    const itemsPerPage = 12
    const totalItems = filteredResults?.length || 0
    const totalPages = Math.ceil(totalItems / itemsPerPage)
    setPage(totalPages)
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems)
    const roleList = filteredResults?.slice(startIndex, endIndex)
    setRoleList(roleList)
  }, [currentPage, userRoleList, debouncedSearchTerm])

  useEffect(() => {
    setCurrentPage(1)
  }, [debouncedSearchTerm])

  useEffect(() => {
    dispatch(getUserRoleAction())
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Role Management"
  }, [])
  return (
    <>
      <RoleModal isEdit={isEdit} open={openModal} onClose={handleCloseModal} />
      <Grid container>
        <Grid item xs={12}>
          <Header title="Role Management" breadCrumbs={breadCrumbs}>
            <Box sx={{ marginRight: "20px" }}>
              <SearchComponent />
            </Box>
            <AppButton
              onClick={() => handleCreateRole()}
              sx={{ padding: "13px 36px", minWidth: "200px" }}>
              + Add New Role
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Container style={{ minWidth: "100%" }}>
        <Row style={{ width: "100%", overflow: "auto" }}>
          <Col lg="12" className="p-0">
            <RoleListTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={page}
              rows={roleList}
              columns={columns}
              handleEditRole={handleEditRole}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default RoleManagement

const columns = [
  {
    id: "Role Name",
    label: "Role Name",
    minWidth: 160
  },
  {
    id: "count",
    label: "No. of Person",
    minWidth: 80,
    align: "center"
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 120
  },
  {
    id: "Actions",
    label: "Actions",
    align: "center",
    minWidth: 80
  }
]
