import React, { useState, useRef } from "react"
import { TextField, InputAdornment } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import "./Search.scss"
import { appActions } from "redux/app/slice/app"
import { useDispatch } from "react-redux"

const SearchComponent = () => {
  const [searchValue, setSearchValue] = useState("")
  const [expanded, setExpanded] = useState(false)
  const dispatch = useDispatch()
  const searchInputRef = useRef(null)

  const handleFocus = () => {
    setExpanded(true)
  }

  const handleBlur = () => {
    if (!searchValue) {
      setExpanded(false)
    }
  }

  const handleSearchField = (e) => {
    const searchText = e.target.value
    setSearchValue(searchText)
    dispatch(appActions.setSearchField(searchText))
  }

  return (
    <div className="box">
      <TextField
        type="search"
        autoComplete="off"
        value={searchValue}
        onChange={handleSearchField}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder="Search"
        inputRef={searchInputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: "30px" }} style={{ cursor: "pointer" }} />
            </InputAdornment>
          ),
          className: `input ${expanded ? "expanded" : ""}`
        }}
        fullWidth
      />
    </div>
  )
}

export default SearchComponent
