import { Grid, Typography, useTheme } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import Wine_by_glass from "assets/images/WineByGlass.png"
import styles from "./menuImage.module.scss"
import { getMenuImageAction } from "redux/superAdmin/actions"
import { useDispatch, useSelector } from "react-redux"

// const menuImageList = [
//   { image: Wine_by_glass, name: "Dinner" },
//   { image: Wine_by_glass, name: "Lunch" },
//   { image: Wine_by_glass, name: "Bar Menu" }
// ]

const MenuImage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const { menuImageList } = useSelector((store) => store.superAdmin)

  const handleEditMenuImage = () => {
    history.push("/super-admin/dashboard/menu-image/edit-menu-image")
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Menu Image"
    dispatch(getMenuImageAction())
  }, [])
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Menu Image" breadCrumbs={breadCrumbs}>
            <AppButton
              onClick={() => handleEditMenuImage()}
              sx={{ padding: "13px 36px", minWidth: "200px" }}>
              Edit
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Grid container item ml={4} xs={12} lg={11}>
        {menuImageList.slice(0, 4)?.map((menu, index) => (
          <Grid item key={index} xs={6} mb={8} sx={{ maxWidth: "fit-content" }}>
            <Grid item className={styles.category}>
              <Typography
                variant="body1Regular"
                sx={{ fontWeight: "600" }}
                color={theme.palette.secondary.main}>
                Category {index + 1}
              </Typography>
            </Grid>
            <Grid item mt={4} mb={3}>
              <img
                src={menu?.icon || Wine_by_glass}
                alt="i"
                loading="eager"
                className={styles.image}
              />
            </Grid>
            <Grid item>
              <Grid item>
                <Typography variant="body1Regular" color={theme.palette.text.secondary}>
                  Category Name
                </Typography>
              </Grid>
              <Grid item mt={1}>
                <Typography variant="body1Regular">{menu?.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  )
}

export default MenuImage
