import React, { useState } from "react"
import avatar from "assets/images/user/avatarUser.svg"
import styles from "./adminUserList.module.scss"

function ProfileDisplayer({ image, isPopupAvatar }) {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div className={isPopupAvatar ? styles.popUpAvatar : styles.tableAvatar}>
      {isLoading && <img src={avatar} alt="Avatar" className={styles.imgActual} />}

      <img
        src={image}
        onLoad={() => setIsLoading(false)}
        alt="Avatar"
        className={styles.imgActual}
        style={{ display: isLoading ? "none" : "block" }}
      />
    </div>
  )
}

export default ProfileDisplayer
