import { Box, List, ListItem, styled, Typography } from "@mui/material"
import Card from "components/Card/Card"
import ListWith2Items from "components/List/ListBodyWith2Items"
import React from "react"

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: "22px 22px 12px",
  backgroundColor: theme.palette.background.main,
  borderRadius: "0px 0px 12px 12px"
}))

const DataCard = ({ data }) => {
  return (
    <Card sx={{ padding: "2px" }}>
      <Box sx={{ padding: "22px 22px 12px" }}>
        <List sx={{ padding: 0 }}>
          <ListItem sx={{ padding: "0px 0px 12px" }}>
            <Typography variant="h2">{data.heading}</Typography>
          </ListItem>
          <ListWith2Items tableData={data.list} />
        </List>
      </Box>
      {data?.subData ? (
        <StyledContainer>
          <List sx={{ padding: 0 }}>
            <ListItem sx={{ padding: "0px 0px 12px" }}>
              <Typography variant="h2">{data?.subData?.heading}</Typography>
            </ListItem>
            <ListWith2Items tableData={data?.subData?.list} />
          </List>
        </StyledContainer>
      ) : (
        <Box height={44} />
      )}
    </Card>
  )
}

export default DataCard
