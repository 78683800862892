import React, { useEffect, useState } from "react"
import { FormControl, Grid } from "@mui/material"
import { useFormik } from "formik"
import { validationSchemaCSV } from "helpers/validationSchema"
import AppButton from "components/StyledComponents/AppButton"
import DragAndDropMenu from "./DragAndDropMenu"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { uploadMenuBulkAction } from "redux/marketing/actions"
import { desiredMenuOrder } from "helpers/functions"

function UploadMenu({ onClose }) {
  const [allFilesSelected, setAllFilesSelected] = useState({
    csvUploaded: false
  })
  const [isDisabled, setDisable] = useState(false)
  const { menuImageList } = useSelector((store) => store?.superAdmin || [])
  const [menuCategoryList, setMenuCategoryList] = useState([])
  const [menuCategory, setMenuCategory] = useState("Dinner")
  const dispatch = useDispatch()

  const styles = {
    container: {
      marginTop: "5px"
    },
    uploadBtnContainer: {
      padding: "12px 79px"
    }
  }

  const formik = useFormik({
    initialValues: {
      csvUrl: ""
    },
    validationSchema: validationSchemaCSV,

    onSubmit: (values) => {
      dispatch(
        uploadMenuBulkAction({
          csvUrl: values.csvUrl,
          menuCategory: menuCategory,
          onClose: onClose
        })
      )
    }
  })

  useEffect(() => {
    const menuCategories = [...menuImageList]
      ?.sort((a, b) => desiredMenuOrder.indexOf(a.name) - desiredMenuOrder.indexOf(b.name))
      .map((item) => {
        return {
          label: item.name,
          value: item.name
        }
      })
    const filteredMenuImageList = menuCategories?.filter((item) => item.label !== "Spirits")
    setMenuCategoryList(filteredMenuImageList)
  }, [menuImageList])

  return (
    <FormControl className="mt-4" style={{ width: "100%" }}>
      <Grid container sx={styles.container}>
        {/* Drag and Drop PDF */}
        <Grid item sm={12}>
          <DragAndDropMenu
            setDisable={setDisable}
            type="CSV"
            setAllFilesSelected={setAllFilesSelected}
            onChange={(url) => formik.setFieldValue("csvUrl", url)}
            menuCategory={menuCategory}
            setMenuCategory={setMenuCategory}
            menuCategoryList={menuCategoryList}
          />
        </Grid>

        {/* Upload Button */}
        {allFilesSelected.csvUploaded && (
          <Grid
            item
            sm={12}
            className="d-flex justify-content-center align-items-center"
            style={{ paddingTop: "0px" }}>
            {isDisabled && (
              <AppButton onClick={formik.handleSubmit} className={styles.uploadBtn}>
                Upload
              </AppButton>
            )}
          </Grid>
        )}
      </Grid>
    </FormControl>
  )
}

export default UploadMenu
