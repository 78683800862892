import React from "react"
import { Grid, styled, TextField, Typography } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import Tooltip from "@mui/material/Tooltip"

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root:before": {
    borderBottom: "none !important"
  },
  "& .MuiInput-root:before": {
    borderBottom: "none !important"
  },
  "& .Mui-focused:after": {
    transform: "none",
    border: `1px solid ${theme.palette.text.primary}`
  },
  "& .MuiInputBase-input": {
    padding: "12px 16px",
    color: "#FFFFFF",
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 6,
    ...theme.typography.body1Regular,
    lineHeight: "24px",
    "&[type='time']": {
      cursor: "text",
      "&::-webkit-calendar-picker-indicator": {
        display: "none"
      }
    },
    "&[type='search']": {
      cursor: "text",
      border: "none",
      padding: "12px 36px",
      background: theme.palette.drawer.main,
      borderBottom: "2px solid #212121",
      borderRadius: 0,
      "&::-webkit-calendar-picker-indicator": {
        display: "none"
      },
      "&::-webkit-search-cancel-button": {
        appearance: "none",
        backgroundImage:
          "url(https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-rouge-1.png)",
        backgroundSize: "cover",
        height: "14px",
        width: "14px"
      }
    }
  },
  "& .Mui-error": {
    "& .MuiInputBase-input": {
      border: `1px solid ${theme.palette.error.main}`
    },
    "& .MuiIconButton-root": {
      border: `1px solid ${theme.palette.error.main}`,
      borderLeft: "none"
    }
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
    marginLeft: 0
  },
  "& .MuiFilledInput-root": {
    "& .Mui-focused": {
      borderRadius: 6,
      backgroundColor: theme.palette.background.default
    },
    "&:hover": {
      backgroundColor: `${theme.palette.background.default} !important`
    }
  },
  "& .Mui-disabled": {
    "& .MuiInputBase-input": {
      WebkitTextFillColor: "#969698"
    }
  }
}))

function tootTileString(renderString) {
  return <p dangerouslySetInnerHTML={{ __html: renderString }}></p>
}

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

const AppTextField = ({
  label,
  isDisabled,
  isToolTip = false,
  isRequired,
  style = {},
  renderToolTip,
  className = "",
  placeholder,
  formStyle = {},
  isUpperCase = false,
  onChange = () => {},
  onBlur = () => {},
  value = "",
  isNumber,
  backgroundColor,
  minRows = 1,
  maxRows = 5,
  ...props
}) => {
  const { type } = props
  const isMultiline =
    type !== "search" && type !== "time" && type !== "password" && type !== "email" // Apply multiline based on the type

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value.trim() === "") {
      e.preventDefault()
    }
  }

  return (
    <Grid container style={style} className={className}>
      {label ? (
        <StyledLabel variant="body1Regular">
          {!isUpperCase ? (label ?? "-").toUpperCase() : label ?? "-"}
          {isToolTip && (
            <Tooltip title={tootTileString(renderToolTip)}>
              <InfoIcon className="ms-1" fontSize="small"></InfoIcon>
            </Tooltip>
          )}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12}>
        <StyledTextField
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{ disableUnderline: true }}
          variant="filled"
          disabled={isDisabled}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          multiline={isMultiline} // Apply multiline conditionally
          minRows={isMultiline ? minRows : undefined} // Only apply minRows if multiline is true
          maxRows={isMultiline ? maxRows : undefined} // Only apply maxRows if multiline is true
          sx={(theme) => ({
            "& .MuiFilledInput-root": {
              backgroundColor: value ? theme.palette.background.default : "transparent",
              padding: 0
            },
            "& MuiInputBase-input": {
              background: backgroundColor ?? theme.palette.background.default
            }
          })}
          style={formStyle}
          value={value}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) => {
            handleKeyDown(e)
            if (isNumber) {
              // eslint-disable-next-line no-extra-semi
              ;["e", "E", "+", "-", ".", "ArrowDown", "ArrowUp"].includes(e.key) &&
                e.preventDefault()
            }
          }}
          {...props}
        />
      </Grid>
    </Grid>
  )
}

export default AppTextField
