import React from "react"
import { Box, List, ListItem, Typography } from "@mui/material"
import Card from "components/Card/Card"

import CustomDivider from "components/StyledComponents/Divider"

function AlertCardView({ variantBg, listData, heading }) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: "15px",
      width: "100%"
    },
    listContainer: {
      width: "100%",
      cursor: "pointer"
    },
    listItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: "10px",
      marginBottom: "10px"
    },
    listMain: {
      padding: "0px"
    },
    title: {
      marginLeft: "10px"
    },
    text: {
      display: "flex",
      flexWrap: "wrap"
    },
    imageBg: {
      width: "40px",
      height: "40px",
      backgroundColor: `${variantBg}`,
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }

  return (
    <Card>
      <List>
        <ListItem sx={{ justifyContent: "space-between", padding: "0 0 12px 0" }}>
          <Typography variant="h2">{heading}</Typography>
        </ListItem>
        <ListItem sx={styles.listMain}>
          <Box sx={styles.container}>
            {listData.map((data) => (
              <Box sx={styles.listContainer} key={data.id}>
                <Box sx={styles.listItem}>
                  <Box sx={styles.imageBg}>
                    <img src={data.icon} alt="" />
                  </Box>
                  <Box sx={styles.text}>
                    <Typography sx={styles.title} variant="body1Regular">
                      {data.title}
                    </Typography>
                  </Box>
                </Box>
                {listData.length - 1 !== data.id && <CustomDivider />}
              </Box>
            ))}
          </Box>
        </ListItem>
      </List>
    </Card>
  )
}

export default AlertCardView
