import { Box, Grid, styled, Typography } from "@mui/material"
import DetailField from "components/DetailField"
import { splitAndMergeString } from "helpers/functions"
import React from "react"
import { toTitleCase } from "../../../../../helpers/functions"
import moment from "moment"

const ImageContainer = styled(Box)(() => ({
  "& img": {
    maxHeight: 200,
    maxWidth: 500
  }
}))

function getString(string) {
  if (string) {
    var newStr = string?.replace(/_/g, " ")
    return toTitleCase(newStr)
  }
}
const BasicDetails = ({ data }) => {
  return (
    <Grid container spacing={4}>
      <Grid item md={3} sm={4}>
        <DetailField
          label="Start Date"
          value={data?.start_date ? moment(new Date(data.start_date)).format("MMM DD, YYYY") : "-"}
        />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField
          label="End Date"
          value={data?.end_date ? moment(new Date(data?.end_date)).format("MMM DD, YYYY") : "-"}
        />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField
          label={data?.alcohol_type === "wine" ? "Wine" : "Spirit"}
          value={data?.name ?? "-"}
        />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField label="Origin" value={data?.origin ?? "-"} />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField label="Personality" value={data?.personality ?? "-"} />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField
          label={data?.alcohol_type === "wine" ? "Grape Varietals" : "Ingredients"}
          value={data?.grape_varietal ?? "-"}
        />
      </Grid>
      <Grid item md={3} sm={4}>
        <DetailField
          label={data?.alcohol_type === "wine" ? "Wine Type" : "Spirit Type"}
          value={getString(data?.wine_type ?? "-")}
        />
      </Grid>
      {data?.wine_type === "transparent" ? null : (
        <Grid item md={3} sm={4}>
          <DetailField
            label={data?.alcohol_type === "wine" ? "Wine Color" : "Spirit Color"}
            value={data?.wine_color ? splitAndMergeString(data?.wine_color) : "-"}
          />
        </Grid>
      )}
      <Grid item lg={12}>
        <Box className="w-100 mb-2">
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            {data?.alcohol_type === "wine" ? "WINE IMAGE" : "SPIRIT IMAGE"}
          </Typography>
        </Box>
        {data.image ? (
          <ImageContainer>
            <img src={data.image} alt="" />
          </ImageContainer>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default BasicDetails
