import { Box, Grid, Typography } from "@mui/material"
import AppButtonGradient from "components/StyledComponents/AppButtonGradient"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { updateGrandCruGoalsAction } from "redux/DMV/actions"
import { DMVActions } from "redux/DMV/slice/user"
import IncDecField from "../../../../../components/IncDecField/incDecField"

const GoalsForm = ({ setIsFormSubmitting }) => {
  const { goalsData, isGoalsSubmitted } = useSelector((store) => store?.dmv?.grandCru || {})
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    setIsFormSubmitting(true)
    dispatch(
      updateGrandCruGoalsAction({
        id: values.id,
        server_goal: values.server,
        bartender_goal: values.bartender,
        tasting_room_attendant_goal: values.tr
      })
    )
  }
  const [initialData, setInitialData] = useState({
    id: "",
    bartender: 1,
    server: 1,
    tr: 1
  })

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    if (goalsData.id) {
      setInitialData({
        ...goalsData
      })
    }
  }, [goalsData])

  useEffect(() => {
    if (isGoalsSubmitted) {
      dispatch(DMVActions.setGrandCruGoalsSubmitted(false))
      history.push("/dmv/dashboard")
    }
  }, [isGoalsSubmitted])

  return (
    <>
      <Typography variant="body1Medium">Grand Cru Settings</Typography>
      <Box className="w-100 d-flex justify-content-start mt-4 mb-5">
        <Box sx={{ width: "205px", marginRight: "30px" }}>
          <IncDecField
            label="Bartenders"
            isRequired={true}
            count={formik.values.bartender}
            onChange={(val) => formik.setFieldValue("bartender", val)}
          />
        </Box>
        <Box sx={{ width: "205px", marginRight: "30px" }}>
          <IncDecField
            label="Server"
            isRequired={true}
            count={formik.values.server}
            onChange={(val) => formik.setFieldValue("server", val)}
          />
        </Box>
        <Box sx={{ width: "205px", marginRight: "30px" }}>
          <IncDecField
            label="TRs"
            isRequired={true}
            count={formik.values.tr}
            onChange={(val) => formik.setFieldValue("tr", val)}
          />
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={2}>
          <AppButtonGradient
            onClick={formik.handleSubmit}
            sx={{ height: "48px", width: "100%" }}
            variant="filled">
            Submit
          </AppButtonGradient>
        </Grid>
      </Grid>
    </>
  )
}

export default GoalsForm
