import { Backdrop, Grid, List, Typography } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { useTheme } from "@mui/system"
import avatar from "assets/images/user/avatarUser.svg"
import { useDispatch } from "react-redux"
import { getFrontlineUsersDataAction, updateUserStatusAction } from "redux/superAdmin/actions"
import { useSelector } from "react-redux"
import { toTitleCase, splitAndMergeString } from "helpers/functions"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import moment from "moment"

const userStatus = {
  activated: "Activated",
  deactivated: "Deactivated"
}

const ViewFrontLineUser = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(false)
  const open = Boolean(anchorEl)
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)

  const { frontlineUserData } = useSelector((store) => store?.superAdmin?.frontlineUser ?? {})
  const [buttonState, setButtonState] = useState("")

  const styles = {
    list: {
      display: "flex",
      flexDirection: "column",
      marginTop: "14px",
      marginBottom: "18px"
    },
    label: {
      color: theme.palette.text.secondary,
      opacity: 0.6,
      marginBottom: "4px"
    },
    userImage: {
      width: "120px",
      height: "120px",
      objectFit: "cover",
      borderRadius: "50%"
    }
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(getFrontlineUsersDataAction())
  }, [])

  function handleEditContest() {
    history.push("/super-admin/dashboard/frontline-user-list/edit-frontline-user")
  }

  function getButtonStatus(status) {
    if (!status) return
    if (status === userStatus?.activated) {
      return "Deactivate"
    } else {
      return "Activate"
    }
  }

  function handleActivate() {
    const source = "view"
    if (buttonState === userStatus.activated) {
      dispatch(updateUserStatusAction({ activate: false, source }))
      setButtonState("Deactivate")
    } else {
      dispatch(updateUserStatusAction({ activate: true, source }))
      setButtonState("Activated")
    }
  }

  useEffect(() => {
    getButtonStatus()
    if (!frontlineUserData?.status) return
    setButtonState(frontlineUserData?.status)
  }, [buttonState, frontlineUserData])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const toggleActivePopup = () => {
    handleClose()
    setIsDeletePopupOpen(!isDeletePopupOpen)
  }

  function handleDelete() {
    handleActivate()
  }

  const status = frontlineUserData?.status
  const value =
    status !== undefined && status.toLowerCase() === "activated" ? "deactivate" : "activate"

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={open} onClick={handleClose}></Backdrop>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Change Status"
        description={`Are you sure you want to ${value} this account?`}
        btnText={`${value}`}
        onDelete={handleDelete}
        onClose={toggleActivePopup}
      />
      <Grid container>
        <Grid item xs={12}>
          <Header title="View Frontline User" breadCrumbs={breadCrumbs}>
            <AppButton onClick={handleEditContest} styles={{ padding: "14px 70px" }}>
              Edit User
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Container fluid className="mt-4 mb-4 ps-0">
        <Row>
          <Col lg="4">
            <img
              src={frontlineUserData?.image_url ? frontlineUserData?.image_url : avatar}
              style={styles.userImage}
              alt=""
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                User Status
              </Typography>
              <Typography variant="body1Regular">
                {frontlineUserData?.status ? frontlineUserData.status : "N/A"}
              </Typography>
            </List>
          </Col>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                User Type
              </Typography>
              <Typography variant="body1Regular">
                {frontlineUserData?.role_name ? toTitleCase(frontlineUserData.role_name) : "N/A"}
              </Typography>
            </List>
          </Col>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Role
              </Typography>
              <Typography variant="body1Regular">
                {frontlineUserData?.job_class === "PRESIDENT_N_FOUNDER"
                  ? "President & Founder"
                  : frontlineUserData?.job_class
                  ? splitAndMergeString(frontlineUserData.job_class)
                  : "N/A"}
              </Typography>
            </List>
          </Col>
        </Row>
        <Row>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Full Name
              </Typography>
              <Typography variant="body1Regular">
                {frontlineUserData?.full_name ? toTitleCase(frontlineUserData.full_name) : "N/A"}
              </Typography>
            </List>
          </Col>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Contact Numner
              </Typography>
              <Typography variant="body1Regular">
                {frontlineUserData?.mobile ? `+${frontlineUserData.mobile}` : "N/A"}
              </Typography>
            </List>
          </Col>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Date Of Hire
              </Typography>
              <Typography variant="body1Regular">
                {frontlineUserData?.hired_date
                  ? moment(new Date(frontlineUserData?.hire_date)).format("MMM DD, YYYY")
                  : "N/A"}
              </Typography>
            </List>
          </Col>
        </Row>
        <Row>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Address
              </Typography>
              <Typography variant="body1Regular">
                {frontlineUserData?.address ? frontlineUserData.address : "N/A"}
              </Typography>
            </List>
          </Col>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Email
              </Typography>
              <Typography variant="body1Regular">
                {frontlineUserData?.email ? frontlineUserData.email : "N/A"}
              </Typography>
            </List>
          </Col>
        </Row>
        <Row>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Access To CMS
              </Typography>
              <Typography variant="body1Regular">
                {toTitleCase(frontlineUserData?.cms_access ? "Yes" : "No")}
              </Typography>
            </List>
          </Col>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Access To Analytics
              </Typography>
              <Typography variant="body1Regular">
                {toTitleCase(frontlineUserData?.analytics_access ? "Yes" : "No")}
              </Typography>
            </List>
          </Col>
          <Col lg="4" className="ps-0">
            <List sx={styles.list}>
              <Typography sx={styles.label} variant="caption3">
                Access To Raffle
              </Typography>
              <Typography variant="body1Regular">
                {frontlineUserData?.raffle_access ? "Yes" : "No"}
              </Typography>
            </List>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col lg="12" className="d-flex justify-content-start ps-0 mt-4">
            <AppButton
              onClick={toggleActivePopup}
              styles={{
                padding: "14px 70px",
                backgroundColor: `${
                  frontlineUserData?.status === "Activated"
                    ? "transparent"
                    : theme.palette.secondary.main
                }`,
                borderColor: `${theme.palette.secondary.main}`
              }}>
              {`${getButtonStatus(frontlineUserData?.status ?? "-") ?? "-"} `}
            </AppButton>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ViewFrontLineUser
