import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React from "react"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid ${theme.palette.background.default}`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .rank": {
    height: 44,
    width: 29,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 4,
    borderColor: theme.palette.green.main
  }
}))

const PercentGoalTable = ({ columns = [], rows = [] }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <div className={`rank`}>{row.rank}</div>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2Regular">{row.restaurant}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.percentGoal.toFixed(1) + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.percentImproved.toFixed(1) + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.improvedCandidates}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.totalCandidates}</Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Paper>
  )
}

export default PercentGoalTable
