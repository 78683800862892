import React from "react"
import Header from "components/Header/Header"
import { Col, Container, Row } from "react-bootstrap"
import AdminCard from "./components/AdminCard"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { appActions } from "redux/app/slice/app"
import {
  getDashboardStatsAction,
  getGamificationDataAction,
  getMenuImageAction,
  getUserRoleAction
} from "redux/superAdmin/actions"
import { useSelector } from "react-redux"

const adminList = {
  total_super_admin_count: "Total Admin Users"
}

function SuperAdminDashboard() {
  const dispatch = useDispatch()
  const { dashboardStats } = useSelector((store) => store?.superAdmin || {})
  const { gamificationData, menuImageList } = useSelector((store) => store.superAdmin)

  const formatKey = (key) => {
    return key
      .replace(/_count/g, "")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (val) => val.toUpperCase())
  }

  function getFrontlineStats() {
    if (!dashboardStats?.frontline_data) return
    let frontlineData = []
    Object.keys(dashboardStats?.frontline_data).forEach((key, index) => {
      frontlineData?.push({
        id: index,
        key: formatKey(key),
        field: dashboardStats?.frontline_data[key]
      })
    })
    return frontlineData
  }

  function getAdminStats() {
    if (!dashboardStats?.super_admin_data) return
    let AdminStats = []
    Object.keys(adminList).forEach((key, index) => {
      AdminStats?.push({
        id: index,
        key: adminList[key],
        field: dashboardStats?.super_admin_data[key]
      })
    })
    return AdminStats
  }

  useEffect(() => {
    dispatch(getDashboardStatsAction())
    dispatch(getGamificationDataAction())
    dispatch(getMenuImageAction())
    dispatch(getUserRoleAction())
    dispatch(appActions.initFilters())
    document.title = "Super Admin"
  }, [])
  return (
    <Container fluid className="p-0">
      <Row>
        <Header title="Super Admin Dashboard" back={false}></Header>
      </Row>
      <Row>
        <Col lg="6" md="12">
          <Row>
            <Col lg="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/frontline-user-list"
                heading="Frontline Users"
                data={getFrontlineStats() || []}></AdminCard>
            </Col>
          </Row>
        </Col>
        <Col lg="6" md="12">
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/admin-user-list"
                heading="Admin Users"
                data={getAdminStats() || []}></AdminCard>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/notification"
                heading="Notification Engine"
                data={[]}></AdminCard>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/tasting-notes-list"
                heading="Tasting Notes Config"
                data={[]}></AdminCard>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/gift-card-list"
                heading="Monthly & Quarterly Prizes"
                data={[]}></AdminCard>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo={
                  gamificationData?.length
                    ? "/super-admin/dashboard/gamification"
                    : "/super-admin/dashboard/gamification/edit-gamification"
                }
                heading="Gamification"
                data={[]}></AdminCard>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/event-listing"
                heading="Event Management"
                data={[]}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo={
                  menuImageList?.length
                    ? "/super-admin/dashboard/menu-image"
                    : "/super-admin/dashboard/menu-image/edit-menu-image"
                }
                heading="Menu Image"
                data={[]}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo={"/super-admin/dashboard/menu-management"}
                heading="Menu Management"
                data={[]}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo={"/super-admin/dashboard/role-management"}
                heading="Role Management"
                data={[]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default SuperAdminDashboard

// const adminUsersCard = [
//   {
//     id: 1,
//     key: "Total Admin Users",
//     field: 1000
//   },
//   {
//     id: 2,
//     key: "General Managers ",
//     field: 340
//   },
//   {
//     id: 3,
//     key: "Office Managers",
//     field: 180
//   },
//   {
//     id: 4,
//     key: "Marketing Managers",
//     field: 180
//   },
//   {
//     id: 5,
//     key: "L&D Trainers",
//     field: 180
//   }
// ];

// const frontLineUserCard = [
//   {
//     id: 1,
//     key: "Total Frontline Users",
//     field: 1500
//   },
//   {
//     id: 2,
//     key: "Servers ",
//     field: 340
//   },
//   {
//     id: 3,
//     key: "Bartenders",
//     field: 180
//   },
//   {
//     id: 4,
//     key: "Tasting Room Attendants ",
//     field: 180
//   }
// ];
