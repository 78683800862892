import { Grid } from "@mui/material"
import React, { useRef, useState } from "react"
import TransparentImageIcon from "assets/icons/TransparentImageIcon.svg"
import { bytesToSize, removeDecimalUpto1 } from "helpers/functions"
import AppButtonGradient from "components/StyledComponents/AppButtonGradient"
import AppButton from "components/StyledComponents/AppButton"
import styles from "../menuImage.module.scss"

const UploadMenuImage = ({
  error,
  touched,
  value,
  onChange = () => {},
  aspectWidth,
  aspectHeight,
  isVariant = false,
  imageSizeInBytes,
  disabled,
  ...props
}) => {
  const inputRef = useRef(null)
  const [isInternalError, setIsInternalError] = useState(false)

  const isError = !value && (isInternalError || (touched === true && error))

  const checkSize = ({ size }) => {
    if (!imageSizeInBytes || size < imageSizeInBytes) {
      return true
    }
    setIsInternalError(`Image Size Exceeded ${bytesToSize(imageSizeInBytes)}`)
  }

  const checkAspectRatio = (file) => {
    let img = new Image()
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth
        const height = img.naturalHeight
        const ratio = width / height
        if (removeDecimalUpto1(ratio) !== removeDecimalUpto1(aspectWidth / aspectHeight)) {
          setIsInternalError(`Please Upload Image with ${aspectWidth}:${aspectHeight} aspect ratio`)
          return resolve(false)
        }
        resolve(true)
      }
      img.onerror = reject
    })
    img.src = window.URL.createObjectURL(file)

    return promise
  }

  const checkFileType = (file) => {
    const types = ["image/png", "image/jpeg", "image/jpg"]
    if (types.includes(file.type)) {
      return true
    }
    setIsInternalError(`Unsupported File Type`)
    return false
  }

  const checkFileName = (file) => {
    if (file?.name?.length <= 200) {
      return true
    }
    setIsInternalError(`File name exceeded 200 characters`)
    return false
  }

  const onImageUploadClick = async (e) => {
    setIsInternalError(false)
    const { files } = e.target
    if (files?.[0] && checkFileType(files[0]) && checkFileName(files[0]) && checkSize(files[0])) {
      if (!aspectWidth || !aspectHeight) {
        return onChange(files[0])
      }
      const isAspectRatioCorrect = await checkAspectRatio(files[0])
      if (isAspectRatioCorrect) onChange(files[0])
    }
  }

  const showImage = () => {
    let image = {}
    if (value?.url) {
      image.name = decodeURIComponent(value.url)?.split("/")?.pop()
      image.url = value.url
    } else if (value?.name) {
      image.name = value.name
      image.size = value.size
      image.url = URL.createObjectURL(value)
    }

    return (
      <Grid item mb={3}>
        <img
          src={image.url ?? TransparentImageIcon}
          alt="i"
          loading="eager"
          className={styles.image}
        />
      </Grid>
    )
  }

  return (
    <Grid container {...props}>
      <Grid item xs={12}>
        <div
          className={isError ? "error" : disabled ? "disabled" : ""}
          onClick={() => !disabled && inputRef.current?.click()}>
          {value && showImage()}
          {isVariant ? (
            <AppButton disabled={disabled}>Upload Image</AppButton>
          ) : (
            <AppButtonGradient variant="filled" disabled={disabled}>
              Upload Image
            </AppButtonGradient>
          )}
          <input
            ref={inputRef}
            type="file"
            onChange={onImageUploadClick}
            onClick={(event) => {
              event.target.value = null
            }}
            style={{ display: "none" }}
            accept="image/png, image/jpeg, image/jpg"
          />
        </div>
      </Grid>
      {isError ? (
        <div style={{ color: "#A9402F", fontSize: "0.75rem", marginTop: 3 }}>
          {isInternalError ? isInternalError : error}
        </div>
      ) : null}
    </Grid>
  )
}

export default UploadMenuImage
