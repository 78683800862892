import { Box, Dialog, DialogContent, Grid, IconButton, styled, Typography } from "@mui/material"
import React, { useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import styles from "./VideoUploadPopup.module.scss"
import DashedBorder from "assets/icons/DashedBorderBlue.svg"
import UploadIcon from "assets/icons/UploadIconBlue.svg"
import RedCloseIcon from "assets/icons/RedCloseIcon.svg"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 12,
    border: "none",
    width: 740,
    padding: 35,
    boxSizing: "border-box"
  },
  "& .closeButton": {
    height: 32,
    width: 32,
    borderRadius: "50%",
    backgroundColor: theme.palette.background.main,
    position: "absolute",
    top: 35,
    right: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}))

const VideoUploadPopup = ({ onVideoUpload, onUrlUpload, onClose, importOnly, uploadOnly }) => {
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [isUploadActive, setIsUploadActive] = useState(!importOnly)
  const [url, setUrl] = useState("")
  const inputFile = useRef(null)

  const isError = !url?.trim()?.length

  const onBrowseClick = () => {
    inputFile.current.click()
  }

  const onFilesSelected = (e) => {
    const { files } = e.target
    if (files && files.length) {
      setSelectedVideo(files[0])
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onVideoDelete = () => {
    setSelectedVideo(null)
  }

  const handleUrlUpload = () => {
    if (!url) {
      return
    }
    onUrlUpload(url)
    onClose()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { files } = e.dataTransfer

    if (files && files.length) {
      setSelectedVideo(files[0])
    }
  }

  const onUploadClick = () => {
    onVideoUpload(selectedVideo)
    onClose()
  }

  return (
    <StyledDialog open={true} onClose={onClose} maxWidth="">
      <DialogContent>
        <Box className="closeButton">
          <span role="button" onClick={onClose}>
            <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
              <CloseIcon sx={{ height: "14px", width: "14px" }} />
            </IconButton>
          </span>
        </Box>
        <Box className="w-100 mt-2">
          <Box className={styles.tabNavigationCtr}>
            {uploadOnly ? null : (
              <Box
                onClick={() => setIsUploadActive(false)}
                className={`${styles.navItem} ${!isUploadActive ? styles.navItemActive : ""}`}>
                <Typography sx={{ opacity: 0.6 }} variant="body1Regular">
                  Import
                </Typography>
              </Box>
            )}
            {importOnly ? null : (
              <Box
                onClick={() => setIsUploadActive(true)}
                className={`${styles.navItem} ${isUploadActive ? styles.navItemActive : ""}`}>
                <Typography sx={{ opacity: 0.6 }} variant="body1Regular">
                  Upload
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {isUploadActive ? (
          <Grid container className="mt-2" spacing={4}>
            <Grid item xs={12}>
              {selectedVideo ? (
                <Box>
                  <Box
                    className={styles.uploadCtr}
                    style={{ height: "auto", margin: "0px 0px 50px" }}>
                    <Box className={styles.imageListItem}>
                      <Box className={styles.imageItemInnerCtr}>
                        <Typography className="ms-1 me-3" variant="body1Regular">
                          {selectedVideo?.name}
                        </Typography>
                      </Box>
                      <img
                        className={styles.deleteBtn}
                        src={RedCloseIcon}
                        onClick={onVideoDelete}
                        alt="delete"
                      />
                    </Box>
                  </Box>
                  <Box className={styles.divider} />
                  <Box className="w-100 text-center">
                    <AppButton
                      onClick={onUploadClick}
                      sx={{ margin: "0 !important", borderColor: "#4FACE9" }}
                      className={styles.uploadBtn}>
                      Upload
                    </AppButton>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <div
                    className={styles.uploadCtr}
                    onDragOver={handleDragOver}
                    style={{ margin: 0 }}
                    onDrop={handleDrop}>
                    <img src={DashedBorder} alt="" className={styles.borderImage} />
                    <Box className={styles.innerUploadCtr}>
                      <img src={UploadIcon} alt="" />
                      <Typography variant="h3Italic">Drag And Drop</Typography>
                      <Typography className={styles.imgUploadHelperText} variant="body1Regular">
                        or select files from device
                      </Typography>
                      <AppButton
                        className={styles.browseBtn}
                        styles={{ borderColor: "#4FACE9" }}
                        onClick={onBrowseClick}>
                        Browse
                      </AppButton>
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        onChange={onFilesSelected}
                        accept="video/mp4,video/x-m4v,video/*"
                      />
                    </Box>
                  </div>
                </Box>
              )}
            </Grid>
          </Grid>
        ) : (
          <Box className="mt-4">
            <AppTextField
              fullWidth={true}
              label="Video URL"
              isRequired={true}
              placeholder="Enter Video URL"
              value={url}
              error={isError}
              helperText={isError ? "URL is Required" : ""}
              onChange={(e) => setUrl(e.target.value)}
            />
            <Box className="w-100 d-flex justify-content-center mt-4">
              <AppButton
                disabled={isError}
                onClick={handleUrlUpload}
                styles={{ borderColor: "#4FACE9", padding: "12px 69px" }}>
                Upload
              </AppButton>
            </Box>
          </Box>
        )}
      </DialogContent>
    </StyledDialog>
  )
}

export default VideoUploadPopup
