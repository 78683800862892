const AlcoholTypeFilterList = [
  {
    label: "All",
    value: "All"
  },
  {
    label: "Wine",
    value: "wine"
  },
  {
    label: "Spirit",
    value: "spirit"
  }
]

const GiftCardTypeFilterList = [
  {
    label: "All",
    value: "All"
  },
  {
    label: "Monthly Prize",
    value: "MONTHLY"
  },
  {
    label: "Quarterly Prize",
    value: "QUARTERLY"
  }
]

const RankFilter = [
  {
    label: 1,
    value: 1
  },
  {
    label: 2,
    value: 2
  },
  {
    label: 3,
    value: 3
  },
  {
    label: "Unranked",
    value: null
  }
]

const CategoryTypeFilter = [
  {
    label: "Lunch",
    value: "Lunch"
  },
  {
    label: "Dinner",
    value: "Dinner"
  },
  {
    label: "Breakfast",
    value: "Breakfast"
  },
  {
    label: "Brunch",
    value: "Brunch"
  },
  {
    label: "Bar",
    value: "Bar"
  },
  {
    label: "Spirits",
    value: "Spirits"
  },
  {
    label: "Wine By Glass",
    value: "Wine By Glass"
  },
  {
    label: "Wine By Bottle",
    value: "Wine By Bottle"
  },
  {
    label: "Wine Region",
    value: "Wine Region"
  },
  {
    label: "Special",
    value: "Special"
  }
]

export const FiltersData = [
  {
    path: "/learning_development/dashboard/trivia-calendar",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      }
      // {
      //   title: "Restaurant",
      //   key: "restaurant",
      //   default: {
      //     label: "All",
      //     value: "All"
      //   },
      //   menu: restaurantFilterList
      // }
      // {
      //   title: "Performance",
      //   name: "LOW"
      // },
      // {
      //   title: "Restaurant",
      //   name: "TAMPA"
      // }
    ]
  },
  {
    path: "/learning_development/dashboard/trivia-contest/trivia-analytics",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true,
        isCalenderDisabled: true
      }

      // {
      //   title: "Restaurant",
      //   key: "restaurant",
      //   default: {
      //     label: "All",
      //     value: "All"
      //   },
      //   menu: restaurantFilterList
      // },
      // {
      //   title: "Employee Class",
      //   key: "employeeClass",
      //   default: {
      //     label: "All",
      //     value: "all"
      //   },
      //   menu: [
      //     {
      //       label: "All",
      //       value: "All"
      //     },
      //     {
      //       label: "Server",
      //       value: "Server"
      //     },
      //     {
      //       label: "Bartender",
      //       value: "Bartender"
      //     },
      //     {
      //       label: "TR",
      //       value: "Tasting Room Attendant"
      //     }
      //   ]
      // }
    ]
  },

  // {
  //   path: "dashboard/calendar",
  //   filters: [
  //     {
  //       title: "Month",
  //       name: "MARCH",
  //       isCalender: true
  //     },
  //     {
  //       title: "Performance",
  //       name: "LOW"
  //     },
  //     {
  //       title: "Restaurant",
  //       name: "TAMPA"
  //     }
  //   ]
  // },
  {
    path: "/gm/dashboard/calendar",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      }
      // {
      //   title: "Restaurant",
      //   name: "TAMPA"
      // }
    ]
  },
  {
    path: "/gm/dashboard/trivia-analytics",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      }
      // {
      //   title: "Restaurant",
      //   name: "TAMPA"
      // }
    ]
  },
  {
    path: "dashboard/notifications/performance-management",
    filters: [
      {
        title: "Date Range",
        name: "10.05.22 - 10.12.22",
        name2: "Week 6 of 8"
      }
    ]
  },
  {
    path: "dashboard/performance-management-progress",
    filters: [
      {
        title: "Date Range",
        name: "10.05.22 - 10.12.22",
        name2: "Week 6 of 8"
      }
    ]
  },
  {
    path: "/dashboard/featured-Items-of-the-Month",
    filters: [
      {
        title: "Month Posted",
        name: "MARCH 2022",
        isCalender: true
      },
      {
        title: "Alcohol Type",
        key: "alcohol_type",
        default: {
          label: "All",
          value: "All"
        },
        menu: AlcoholTypeFilterList
      }
      // {
      //   title: "Posted By",
      //   name: "ALL"
      // }
    ]
  },
  {
    path: "/dashboard/performance-summary/tracking",
    filters: [
      {
        title: "Performance Management",
        name: "1o.1.21 - 11.30.22",
        isCalender: true
      },
      {
        title: "Employee Class",
        name: "TR ATTENDANTS"
      },
      {
        title: "Meal Period",
        name: "DINNER"
      }
    ]
  },
  {
    path: "/dashboard/performance-summary",
    filters: [
      {
        title: "Date Type",
        name: "Month"
      },
      {
        title: "Date Range",
        name: "12.1.21 - 12.31.21",
        isCalender: true
      },
      {
        title: "Meal Period",
        name: "Dinner"
      },
      {
        title: "Employee Class",
        name: "Server"
      },
      {
        title: "Revenue Center",
        name: "ALL"
      }
    ]
  },
  {
    path: "/gm/dashboard/leaderboard",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      },
      {
        title: "Employee Class",
        key: "employeeClass",
        default: {
          label: "All",
          value: "all"
        }
      }
    ]
  },
  {
    path: "marketing/dashboard/leaderboard/quiz-leaderboard",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      },
      {
        title: "Employee Class",
        key: "employeeClass",
        default: {
          label: "All",
          value: "all"
        }
      },
      {
        title: "Quiz Week",
        isWeek: true,
        key: "weeks"
      }
    ]
  },
  {
    path: "/learning_development/dashboard/leaderboard",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      },
      {
        title: "Employee Class",
        key: "employeeClass",
        default: {
          label: "All",
          value: "all"
        }
      }
    ]
  },
  {
    path: "/learning_development/dashboard",
    filters: [
      {
        name: "Super Admin",
        link: "/super-admin/dashboard",
        isRedirect: true
      },

      {
        name: "Featured Item Of The Month",
        link: "/marketing/dashboard",
        isRedirect: true
      },
      {
        name: "Wine Dinner",
        link: "/marketing/dashboard/contest-configuration",
        isRedirect: true
      }
    ]
  },
  {
    path: "/learning_development/dashboard/trivia-contest",
    filters: [
      {
        name: "Super Admin",
        link: "/super-admin/dashboard",
        isRedirect: true
      },
      {
        name: "Featured Item Of The Month",
        link: "/marketing/dashboard",
        isRedirect: true
      },
      {
        name: "Wine Dinner",
        link: "/marketing/dashboard/contest-configuration",
        isRedirect: true
      }

      // {
      //   title: "Employee Class",
      //   key: "employeeClass",
      //   default: {
      //     label: "All",
      //     value: "all"
      //   },
      //   menu: [
      //     {
      //       label: "All",
      //       value: "All"
      //     },
      //     {
      //       label: "Server",
      //       value: "Server"
      //     },
      //     {
      //       label: "Bartender",
      //       value: "Bartender"
      //     },
      //     {
      //       label: "TR",
      //       value: "Tasting Room Attendant"
      //     }
      //   ]
      // }
    ]
  },
  {
    path: "/learning_development/dashboard/trivia-analytics",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      }
    ]
  },
  {
    path: "/dashboard/trivia-contest/trivia-analytics",
    filters: [
      {
        title: "Trivia Month",
        name: "MARCH",
        isCalender: true
      },
      {
        title: "Restaurants",
        name: "ALL"
      },
      {
        title: "Employee Class",
        name: "ALL"
      }
    ]
  },
  {
    path: "/dmv/dashboard"
    // filters: [
    //   {
    //     title: "Date Range",
    //     name: "12.1.21 - 12.31.21",
    //     isCalender: true
    //   }
    // ]
  },
  {
    path: "/dmv/dashboard/usage-dashboard",
    filters: [
      {
        title: "Date Range",
        name: "12.1.21 - 12.31.21",
        isCalender: true
      },
      {
        title: "Restaurants",
        name: "TAMPA"
      },
      {
        title: "Employee Class",
        name: "ALL"
      }
    ]
  },
  {
    path: "/dmv/dashboard/video-analytics",
    filters: [
      {
        title: "Date Range",
        name: "12.1.21 - 12.31.21",
        isCalender: true
      },
      {
        title: "Restaurants",
        name: "TAMPA"
      },
      {
        title: "Employee Class",
        name: "ALL"
      }
    ]
  },
  {
    path: "/dmv/dashboard/performance-management",
    filters: [
      {
        title: "Date Range",
        name: "12.1.21 - 12.31.21",
        isCalender: true
      },
      {
        title: "Restaurants",
        name: "TAMPA"
      },
      {
        title: "Employee Class",
        name: "ALL"
      }
    ]
  },
  {
    path: "/dmv/dashboard/top-users",
    filters: [
      {
        title: "Time Period",
        name: "LAST 7 DAYS"
      },
      {
        title: "Users",
        name: "ACTIVE"
      }
    ]
  },

  //MarketingConsole Filters
  {
    path: "/marketing/dashboard/calendar",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      }

      // {
      //   title: "Performance",
      //   name: "LOW"
      // },
      // {
      //   title: "Restaurant",
      //   name: "TAMPA"
      // }
    ]
  },
  {
    path: "/marketing/dashboard",
    filters: [
      {
        name: "Trivia Management",
        link: "/learning_development/dashboard/trivia-contest",
        isRedirect: true
      },
      {
        name: "Super Admin",
        link: "/super-admin/dashboard",
        isRedirect: true
      },
      {
        name: "Wine Dinner",
        link: "/marketing/dashboard/contest-configuration",
        isRedirect: true
      }
    ]
  },
  {
    path: "/marketing/dashboard/explore-menu",
    filters: [
      {
        name: "Trivia Management",
        link: "/learning_development/dashboard/trivia-contest",
        isRedirect: true
      },
      {
        name: "Super Admin",
        link: "/super-admin/dashboard",
        isRedirect: true
      },
      {
        name: "Wine Dinner",
        link: "/marketing/dashboard/contest-configuration",
        isRedirect: true
      }
    ]
  },
  {
    path: "/marketing/dashboard/contest-configuration",
    filters: [
      {
        name: "Trivia Management",
        link: "/learning_development/dashboard/trivia-contest",
        isRedirect: true
      },
      {
        name: "Featured Item Of The Month",
        link: "/marketing/dashboard",
        isRedirect: true
      },
      {
        name: "Super Admin",
        link: "/super-admin/dashboard",
        isRedirect: true
      }
    ]
  },
  {
    path: "/marketing/dashboard/leaderboard",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      },
      {
        title: "Employee Class",
        key: "employeeClass",
        default: {
          label: "All",
          value: "all"
        }
      }
    ]
  },
  {
    path: "/marketing/dashboard/leaderboard/gamification-leaderboard",
    filters: [
      {
        title: "Month",
        name: "MARCH",
        isCalender: true
      },
      {
        title: "Employee Class",
        key: "employeeClass",
        default: {
          label: "All",
          value: "all"
        }
      }
    ]
  },
  // Super Admin Filters
  {
    path: "/super-admin/dashboard/frontline-user-list",
    filters: [
      {
        title: "Search",
        name: "Search",
        isSearch: true
      },
      {
        title: "Employee Class",
        key: "employeeClass",
        default: {
          label: "All",
          value: "All"
        }
      },
      {
        title: "User Status",
        key: "userStatus",
        default: {
          label: "All",
          value: "All"
        },
        menu: [
          {
            label: "All",
            value: "All"
          },
          {
            label: "ACTIVATED",
            value: "ACTIVATED"
          },
          {
            label: "DEACTIVATED",
            value: "DEACTIVATED"
          }
        ]
      },
      {
        title: "Raffle Access",
        key: "raffleAccess",
        default: {
          label: "All",
          value: "All"
        },
        menu: [
          {
            label: "All",
            value: "All"
          },
          {
            label: "Enabled",
            value: "Enabled"
          },
          {
            label: "Disabled",
            value: "Disabled"
          }
        ]
      }
    ]
  },
  {
    path: "/super-admin/dashboard/admin-user-list",
    filters: [
      {
        title: "Search",
        name: "Search",
        isSearch: true
      },
      {
        title: "User Status",
        key: "userStatus",
        default: {
          label: "All",
          value: "All"
        },
        menu: [
          {
            label: "All",
            value: "All"
          },
          {
            label: "ACTIVATED",
            value: "ACTIVATED"
          },
          {
            label: "DEACTIVATED",
            value: "DEACTIVATED"
          }
        ]
      }
    ]
  },
  {
    path: "/super-admin/dashboard",
    filters: [
      {
        name: "Trivia Management",
        link: "/learning_development/dashboard/trivia-contest",
        isRedirect: true
      },
      {
        name: "Featured Item Of The Month",
        link: "/marketing/dashboard",
        isRedirect: true
      },
      {
        name: "Wine Dinner",
        link: "/marketing/dashboard/contest-configuration",
        isRedirect: true
      }
    ]
  },
  {
    path: "/super-admin/dashboard/gift-card-list",
    filters: [
      {
        title: "Prize Type",
        key: "giftCard_type",
        default: {
          label: "All",
          value: "All"
        },
        menu: GiftCardTypeFilterList
      }
    ]
  },
  {
    path: "learning_development/dashboard/question-bank",
    filters: [
      {
        title: "Rank",
        key: "rank",
        menu: RankFilter,
        isCheckBox: true
      },
      {
        title: "Category",
        key: "category",
        menu: CategoryTypeFilter,
        isCheckBox: true
      }
    ]
  }
]
