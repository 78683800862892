import React, { useState } from "react"

import UserMetricsNavBar from "../userMetricsNavBar"
import { useEffect } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import Card from "components/Card/Card"
import { Container } from "react-bootstrap"
import { Row } from "react-bootstrap"
import { Typography } from "@mui/material"
import { Col } from "react-bootstrap"
import GaugeChart from "components/GaugeChart/gaugeChart.js"
import DMVLineCharts from "components/DMVLineCharts"
import DMVPieChart from "components/DMVPieCharts"
import DMVStackedBarChart from "components/DMVStackedBarCharts"
import DMVBarChart from "components/DMVBarChart"

function UsageDashboard() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  // const history = useHistory();
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Client Information"
  }, [])

  return (
    <>
      <UserMetricsNavBar breadCrumbs={breadCrumbs} isUsageActive={true}></UserMetricsNavBar>
      <Card
        style={{
          borderTopLeftRadius: "0px",
          marginTop: "0px",
          background: "linear-gradient(180deg, #1C1F21 0.23%, #0F1214 6.3%)",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
        <Container fluid className="mt-3">
          <Row>
            <Col lg="6">
              <Typography className="ms-3" variant="h2">
                Performance Management
              </Typography>
            </Col>
            <Col lg="6">
              <Typography variant="h2" className="ms-3">
                Platform Usage
              </Typography>
            </Col>
          </Row>
        </Container>
        <Container fluid className="mt-3">
          <Row>
            <Col lg="6" className="ps-0">
              <Card>
                <GaugeChart headingTitle="Incremental Membership" isHeading={true}></GaugeChart>
              </Card>
            </Col>
            <Col lg="6" className="pe-0">
              <Card>
                <DMVLineCharts
                  height={300}
                  isHeading={true}
                  heading="Weekly Downloads"></DMVLineCharts>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="ps-0">
              <Card>
                <GaugeChart headingTitle="% of Goal Achieve" isHeading={true}></GaugeChart>
              </Card>
            </Col>
            <Col lg="6" className="pe-0">
              <Card>
                <DMVLineCharts height={300} isHeading={true} heading="Active Users"></DMVLineCharts>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="ps-0">
              <Card>
                <DMVLineCharts
                  height={300}
                  isHeading={true}
                  heading="% of Goal Achieved"></DMVLineCharts>
              </Card>
            </Col>
            <Col lg="6" className="pe-0">
              <Card>
                <DMVPieChart
                  height={300}
                  data={webData}
                  isHeading={true}
                  heading="Most Used Mobile Feature"></DMVPieChart>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="ps-0">
              <Card>
                <GaugeChart headingTitle="Incremental Memberships" isHeading={true}></GaugeChart>
              </Card>
            </Col>
            <Col lg="6" className="pe-0">
              <Card>
                <DMVPieChart
                  height={300}
                  data={mobileData}
                  isHeading={true}
                  heading="Most Used Web Feature"></DMVPieChart>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="ps-0">
              <Card>
                <DMVStackedBarChart
                  heading="Performance Management Candidates"
                  height={300}
                  isHeading={true}></DMVStackedBarChart>
              </Card>
            </Col>
            <Col lg="6" className="pe-0">
              <Card>
                <DMVPieChart
                  height={300}
                  data={mobileData}
                  isHeading={true}
                  heading="Most Used Web Feature"></DMVPieChart>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="ps-0">
              <Card>
                <DMVBarChart
                  heading="Performance Management Candidates"
                  height={300}
                  isHeading={true}></DMVBarChart>
              </Card>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  )
}

export default UsageDashboard

const mobileData = [
  { name: "Membership invite rate & rankings", value: 1000 },
  { name: "Grand Cru video", value: 250 },
  { name: "Trivia", value: 250 },
  { name: "Membership statistics", value: 250 },
  { name: "Wine of the month video & tasting note", value: 250 }
]

const webData = [
  { name: "Signup performance", value: 90 },
  { name: "Score cards", value: 30 },
  { name: "Calendar view", value: 30 },
  { name: "Performance management", value: 30 },
  { name: "Suggest a trivia", value: 30 }
]
