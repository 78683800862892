import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React from "react"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderCollapse: "collapse !important",

  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    color: "white",
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid rgba(255, 255, 255, 0.1)`,
    "&:nth-of-type(3)": {
      borderBottom: `none`
    },
    "&:last-child": {
      borderBottom: `none`
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const TableFooter = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.default,
  borderRadius: 6,
  marginBottom: 10,
  "& .MuiTableCell-root": {
    backgroundColor: "transparent",
    "&:first-of-type": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6
    },
    "&:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      borderBottom: "none"
    }
  }
}))
const SalesTable = ({ food = [], columns = [], rows = [], inviteRows = [] }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography variant="body2Regular">{row.employeeClass}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date1}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date2}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date3}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date4}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date5}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.date1 + row.date2 + row.date3 + row.date4 + row.date5}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableFooter>
              <TableCell>
                <Typography className="fw-bold" variant="body2Regular">
                  Guests Checks
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date1, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date2, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date3, 0)}
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date4, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date5, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date1, 0) +
                    rows.reduce((prev, el) => prev + el.date2, 0) +
                    rows.reduce((prev, el) => prev + el.date3, 0) +
                    rows.reduce((prev, el) => prev + el.date4, 0) +
                    rows.reduce((prev, el) => prev + el.date5, 0)}
                </Typography>
              </TableCell>
            </TableFooter>
            {inviteRows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography variant="body2Regular">{row.invitations}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.Invitedate1 +
                        (row.invitations == "Invite Rate" || row.invitations == "Upgrade Rate"
                          ? "%"
                          : " ")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.Invitedate2 +
                        (row.invitations == "Invite Rate" || row.invitations == "Upgrade Rate"
                          ? "%"
                          : " ")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.Invitedate3 +
                        (row.invitations == "Invite Rate" || row.invitations == "Upgrade Rate"
                          ? "%"
                          : " ")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.Invitedate4 +
                        (row.invitations == "Invite Rate" || row.invitations == "Upgrade Rate"
                          ? "%"
                          : " ")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.Invitedate5 +
                        (row.invitations == "Invite Rate" || row.invitations == "Upgrade Rate"
                          ? "%"
                          : " ")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.Invitedate1 +
                        row.Invitedate2 +
                        row.Invitedate3 +
                        row.Invitedate4 +
                        row.Invitedate5 +
                        (row.invitations == "Invite Rate" || row.invitations == "Upgrade Rate"
                          ? "%"
                          : " ")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableFooter>
              <TableCell>
                <Typography className="fw-bold" variant="body2Regular">
                  Guest Covers
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date1, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date2, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date3, 0)}
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date4, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date5, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.date1, 0) +
                    rows.reduce((prev, el) => prev + el.date2, 0) +
                    rows.reduce((prev, el) => prev + el.date3, 0) +
                    rows.reduce((prev, el) => prev + el.date4, 0) +
                    rows.reduce((prev, el) => prev + el.date5, 0)}
                </Typography>
              </TableCell>
            </TableFooter>

            {food.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography variant="body2Regular">{row.employeeClass}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date1}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date2}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date3}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date4}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.date5}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.date1 + row.date2 + row.date3 + row.date4 + row.date5}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableFooter>
              <TableCell>
                <Typography className="fw-bold" variant="body2Regular">
                  Total Sales
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {food.reduce((prev, el) => prev + el.date1, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {food.reduce((prev, el) => prev + el.date2, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {food.reduce((prev, el) => prev + el.date3, 0)}
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {food.reduce((prev, el) => prev + el.date4, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {food.reduce((prev, el) => prev + el.date5, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className="fw-bold" variant="body2Regular">
                  {food.reduce((prev, el) => prev + el.date1, 0) +
                    food.reduce((prev, el) => prev + el.date2, 0) +
                    food.reduce((prev, el) => prev + el.date3, 0) +
                    food.reduce((prev, el) => prev + el.date4, 0) +
                    food.reduce((prev, el) => prev + el.date5, 0)}
                </Typography>
              </TableCell>
            </TableFooter>
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Paper>
  )
}

export default SalesTable
