import React, { useEffect, useState } from "react"
import {
  Box,
  Dialog,
  DialogContent,
  styled,
  Typography,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import CloseIcon from "@mui/icons-material/Close"
import AppTextArea from "components/StyledComponents/AppTextArea"
import AppSelect from "components/StyledComponents/AppSelect"
import styles from "../../pages/private/learning_development/QuestionBank/flaggedQuestion/flaggedQuestion.module.scss"
import { useTheme } from "@emotion/react"
import StyledLabel from "components/StyledComponents/StyledLabel"
import { useDispatch, useSelector } from "react-redux"
import { getReasonCodesAction } from "redux/L&D/actions/user"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 814,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 24,
    boxSizing: "border-box"
  }
}))

const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  "& .MuiTypography-root": {
    fontFamily: "Inter-Regular",
    fontSize: 18,
    lineHeight: "140%",
    fontWeight: 400,
    fontStyle: "normal",
    color: "#ffff"
  }
}))
function ReasonsForm({ formik, open = false, onClose = () => {} }) {
  const theme = useTheme()
  const [reasons, setReasons] = useState([])
  const [disable, setDisable] = useState(true)
  const { reasonCodes, questionCategory } = useSelector((state) => state?.learningDevelopment)
  const dispatch = useDispatch()

  const style = {
    closeBtnContainer: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "27px",
      right: "15px",
      backgroundColor: theme.palette.background.main
    },
    closeBtn: {
      width: "14px",
      height: "14px"
    },
    saveBtn: {
      height: "48px",
      width: "181px"
    },
    radioBtn: {
      marginRight: "35px"
    }
  }

  const handleRadioChange = (e) => {
    const value = e.target.value
    formik.setFieldValue("questionType", value)
    formik.setFieldValue("reasonCodes", "")
    setDisable(false)
    dispatch(getReasonCodesAction({ value, category: questionCategory }))
  }

  useEffect(() => {
    const updatedReasons = reasonCodes?.map((item) => ({
      label: item.reason,
      value: item.id
    }))
    setReasons(updatedReasons)
  }, [reasonCodes])

  return (
    <StyledDialog open={open}>
      <DialogContent>
        <Box className="d-flex">
          <Box className="d-flex justify-content-end">
            <IconButton onClick={() => onClose()} sx={style.closeBtnContainer}>
              <CloseIcon sx={style.closeBtn} />
            </IconButton>
          </Box>
          <Box className="w-100 d-flex flex-column">
            <Typography variant="h2">Select Reason Codes</Typography>
            <Typography className={styles.headerText}>
              Please select the reason for flagging this question from the dropdown below
            </Typography>
          </Box>
        </Box>

        <Box className={`${styles.selectContainer}`}>
          <StyledLabel variant="body1Regular">
            {"Question Type"}
            <span>*</span>
          </StyledLabel>
          <RadioGroup
            id="questionType"
            name="questionType"
            value={formik.values.questionType}
            onChange={(e) => handleRadioChange(e)}
            error={formik.touched.questionType && Boolean(formik.errors.questionType)}>
            <Box className={styles.radio}>
              <CustomFormControlLabel
                value="GOOD"
                sx={style.radioBtn}
                control={<Radio color="secondary" />}
                label="Good"
              />
              <CustomFormControlLabel
                value="BAD"
                control={<Radio color="secondary" />}
                label="Bad"
              />
            </Box>
            {formik.touched.questionType && formik.errors.questionType && (
              <FormHelperText error={true}>{formik.errors.questionType}</FormHelperText>
            )}
          </RadioGroup>
        </Box>

        <Box>
          <Box className={`${styles.selectContainer}`}>
            <AppSelect
              id="reason_codes"
              name="reasonCodes"
              menuItems={reasons}
              label="Reason Codes"
              isRequired={true}
              disabled={disable}
              value={formik.values.reasonCodes}
              onChange={(e) => formik.setFieldValue("reasonCodes", e.target.value)}
              error={formik.touched.reasonCodes && formik.errors.reasonCodes}
            />
          </Box>

          <Box className={`${styles.selectContainer}`}>
            <AppTextArea
              label="Describe the reason"
              minRows={5}
              maxRows={15}
              isRequired={true}
              placeholder="Type your answer here..."
              value={formik.values.description}
              onChange={formik.handleChange("description")}
              error={formik.errors?.description}
              touched={formik.touched?.description}
              charLimit={500}
            />
          </Box>
        </Box>

        <Box className={styles.saveButtonContainer}>
          <AppButton onClick={formik.handleSubmit} styles={style.saveBtn}>
            Save
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default ReasonsForm
