import React, { useState } from "react"
import classes from "pages/private/marketing/menu/menu.module.scss"
import Typography from "@mui/material/Typography"
import ImageDisplay from "components/ImageDisplayer"
import { useTheme } from "@mui/system"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { deleteMenuItemAction } from "redux/marketing/actions"
import { useEffect } from "react"
import Paragraph from "components/ReadMoreParagraph"
import unSelect from "assets/icons/unSelectedRectange.svg"
import selectedItem from "assets/icons/SelectedRectange.svg"
import AddMenuItems from "pages/private/marketing/menu/AddMenuItems"
import { getFoodItemsAction, updateFoodItemsAction } from "redux/marketing/actions/user"
import UnarchiveItem from "assets/icons/UnarchiveItem.svg"
import trashIcon from "assets/icons/trash.svg"
import editIcon from "assets/icons/edit.svg"

function ArchivedMenuListItems({ foodList, isEdit, selectedItems, handleSelectItem }) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const { archived } = useSelector((store) => store?.marketing?.menu ?? {})
  const dispatch = useDispatch()
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const [unarchiveModal, setUnarchiveItemModal] = useState(false)

  const checkSelectedItems = () => {
    const isItemSelected = selectedItems?.food_list?.filter((item) => item?.id === foodList?.id)
    if (isItemSelected?.length > 0) {
      return true
    } else {
      return false
    }
  }

  function handleMenuItemDelete() {
    dispatch(deleteMenuItemAction())
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  useEffect(() => {}, [])

  const handleClick = (type, id, categoryId) => {
    dispatch(marketingActions.setMenuItemId(id))
    dispatch(marketingActions.setMenuCategoryId(categoryId))
    dispatch(getFoodItemsAction())
    if (type == "edit") {
      handleEditMenuItemModal()
    } else if (type == "archive") {
      handleArchiveItem()
    } else {
      handleMenuDeleteModal()
    }
  }

  function handleMenuDeleteModal() {
    setDeleteModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleEditMenuItemModal() {
    setIsEditModalItem(true)
    setMenuItemsModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  function handleArchiveItem() {
    dispatch(marketingActions.setisArchive(true))
    setUnarchiveItemModal(true)
  }

  function handleCloseArchiveModal() {
    setUnarchiveItemModal(false)
  }

  function handleUnarchiveMenuItem() {
    const payload = {
      item_state: "available"
    }
    dispatch(updateFoodItemsAction({ payload: payload, onClose: handleCloseArchiveModal }))
  }

  return (
    <>
      <AddMenuItems isEdit={isEditModalItem} open={menuItemsModal} onClose={handleCloseMenuItems} />
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this dish ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are you sure you want to unarchive this dish?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleUnarchiveMenuItem()}
        open={unarchiveModal}
        btnText="Unarchive"
        type="warning"
      />
      <div
        className={archived ? classes.archivedItemContainer : classes.itemContainer}
        style={{
          border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
          borderRadius: `${isEdit ? "6px" : "0px"}`,
          padding: `${isEdit ? "8px" : "0px"}`
        }}>
        <div className={classes.checkbox}>
          {checkSelectedItems() ? (
            <img
              src={selectedItem}
              alt="select"
              style={{ cursor: "pointer" }}
              onClick={() => handleSelectItem(foodList?.id)}
            />
          ) : (
            <img
              src={unSelect}
              alt="select"
              style={{ cursor: "pointer" }}
              onClick={() => handleSelectItem(foodList?.id)}
            />
          )}
        </div>
        <div className={classes.imageContainer}>
          <ImageDisplay data={foodList?.image}></ImageDisplay>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: 375,
            overflowWrap: "break-word"
          }}>
          <div key={foodList?.id}>
            <Typography variant="h4">{foodList?.name ?? "-"}</Typography>
          </div>
          <div>
            {foodList?.data_to_display?.map((data, index) => {
              return (
                <div key={index}>
                  {data.filedName === "Allergens"
                    ? (data?.value?.length || isEdit) && (
                        <div className="mt-2">
                          <Typography sx={{ fontSize: 12, fontWeight: "400" }}>
                            {"Allergens:"}
                          </Typography>
                          <Typography
                            sx={{
                              opacity: 0.6,
                              fontSize: 12,
                              fontWeight: "400",
                              fontStyle: "italic"
                            }}>
                            {data?.value?.length > 0
                              ? data?.value?.map((item, index) => {
                                  return data?.value?.length - 1 > index
                                    ? `${item?.name}, `
                                    : `${item?.name}`
                                })
                              : ""}
                          </Typography>
                        </div>
                      )
                    : data.filedName === "Unique Facts" || data.filedName === "Glossary"
                    ? (data?.value?.length || isEdit) && (
                        <div className="mt-2">
                          <Typography variant="tag1">{data.filedName}</Typography>
                          <br></br>
                          {data?.value?.length === 1
                            ? data?.value?.map((item, index) =>
                                item?.fact !== "" ? (
                                  <Typography
                                    component="div"
                                    style={{
                                      display: item?.fact ? "list-item" : "",
                                      marginLeft: 15
                                    }}
                                    key={index}
                                    variant="tagItalic"
                                    sx={{ opacity: 0.6 }}>
                                    <Paragraph
                                      text={item?.fact ?? ""}
                                      maxCharCount={70}
                                      key={index}
                                      variant="tagItalic"
                                      sx={{ opacity: 0.6 }}></Paragraph>
                                  </Typography>
                                ) : null
                              )
                            : data?.value?.map((item, index) => (
                                <Typography
                                  component="div"
                                  style={{
                                    display: item?.fact ? "list-item" : "",
                                    marginLeft: 15
                                  }}
                                  key={index}
                                  variant="tagItalic"
                                  sx={{ opacity: 0.6 }}>
                                  <Paragraph
                                    text={item?.fact ?? ""}
                                    maxCharCount={70}
                                    key={index}
                                    variant="tagItalic"
                                    sx={{ opacity: 0.6 }}></Paragraph>
                                </Typography>
                              ))}
                        </div>
                      )
                    : data.filedName === "Chef's Description"
                    ? (data?.value || isEdit) && (
                        <div className="mt-2">
                          <Typography variant="tag1">{data.filedName}</Typography>
                          <br></br>
                          <Typography component="div" variant="tagItalic" sx={{ opacity: 0.6 }}>
                            {<Paragraph text={data?.value ?? ""} maxCharCount={70}></Paragraph>}
                          </Typography>
                        </div>
                      )
                    : data.filedName === "Ingredients"
                    ? (data?.value || isEdit) && (
                        <div className="mt-2">
                          <Typography sx={{ fontSize: 12, fontWeight: "400" }}>
                            {data.filedName}
                          </Typography>

                          {typeof data?.value !== "string" ? (
                            data?.value?.map((item, index) => (
                              <Typography
                                key={index}
                                sx={{
                                  opacity: 0.6,
                                  fontSize: 12,
                                  fontWeight: "400",
                                  fontStyle: "italic",
                                  display: item?.name ? "list-item" : "",
                                  marginLeft: 2
                                }}>
                                {`${item?.name ?? ""}`}
                                {item?.measurement
                                  ? data?.value?.length - 1 > index
                                    ? ` (${item?.measurement})`
                                    : item?.measurement
                                    ? ` (${item?.measurement}) `
                                    : ""
                                  : data?.value?.length - 1 > index
                                  ? ", "
                                  : ""}
                              </Typography>
                            ))
                          ) : (
                            <div>
                              {data.value.split(",").map((ingredient, index) => (
                                <Typography
                                  key={index}
                                  sx={{
                                    opacity: 0.6,
                                    fontSize: 12,
                                    fontWeight: "400",
                                    fontStyle: "italic",
                                    display: ingredient ? "list-item" : "",
                                    marginLeft: 2
                                  }}>
                                  {data.value.split(",")?.length - 1 > index
                                    ? `${ingredient.trim()},`
                                    : ingredient.trim()}
                                </Typography>
                              ))}
                            </div>
                          )}
                        </div>
                      )
                    : (data?.value || isEdit) && (
                        <div className="mt-2">
                          <Typography sx={{ fontSize: 12, fontWeight: "400" }}>
                            {data?.filedName}
                          </Typography>
                          <Typography
                            component="div"
                            sx={{
                              opacity: 0.6,
                              fontSize: 12,
                              fontWeight: "400",
                              fontStyle: "italic"
                            }}>
                            {typeof data?.value === "string" && data.value.includes("\n") ? (
                              data.value
                                .split("\n")
                                .map((line, index) =>
                                  line.length > 70 ? (
                                    <Paragraph key={index} text={line} maxCharCount={70} />
                                  ) : (
                                    <div key={index}>{line}</div>
                                  )
                                )
                            ) : typeof data?.value === "string" && data.value.length > 70 ? (
                              <Paragraph text={data.value} maxCharCount={70} />
                            ) : (
                              data?.value || "-"
                            )}
                          </Typography>
                        </div>
                      )}
                </div>
              )
            })}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            {foodList?.price > 0 && <Typography variant="bold">${foodList?.price}</Typography>}
          </div>
          <div className={classes.actionButtonContainer}>
            <div className="d-grid justify-content-center mt-1">
              <img
                src={editIcon}
                alt="edit"
                onClick={() => handleClick("edit", foodList?.id)}
                className={classes.menu_action_icon}
              />
            </div>
            <div className="d-grid justify-content-center mt-1">
              <img
                src={UnarchiveItem}
                alt="unarchive"
                className={classes.menu_action_icon}
                onClick={() => handleClick("archive", foodList?.id)}
              />
            </div>
            <div className="d-grid justify-content-center mt-1">
              <img
                onClick={() => handleClick("delete", foodList?.id)}
                className={classes.menu_action_icon}
                src={trashIcon}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ArchivedMenuListItems
