import React, { useState } from "react"

import UserMetricsNavBar from "../userMetricsNavBar"
import { useEffect } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import Card from "components/Card/Card"
import { Col, Container, Row } from "react-bootstrap"
import { Typography } from "@mui/material"
import AppButtonFilled from "components/StyledComponents/AppButtonFilled"
import UserPerformanceTable from "./userPerformanceTable"

function videoAnalytics() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  // const history = useHistory();
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Client Information"
  }, [])

  return (
    <>
      <UserMetricsNavBar breadCrumbs={breadCrumbs} isPerformanceActive={true}></UserMetricsNavBar>
      <Card
        style={{
          borderTopLeftRadius: "0px",
          marginTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
        <Container fluid className="mt-3">
          <Row className="m-0">
            <Col
              lg="12"
              className="d-flex align-items-center justify-content-between"
              style={{ height: "50px" }}>
              <Typography className="ms-3" variant="h2">
                Area Director
              </Typography>
              <AppButtonFilled
                onClick={() => history.push("/dmv/dashboard/performance-management")}>
                Track Program
              </AppButtonFilled>
            </Col>
          </Row>
          <Row className="m-0 mt-3">
            <Col lg="12">
              <UserPerformanceTable rows={rows} columns={columns}></UserPerformanceTable>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  )
}

export default videoAnalytics

const columns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 220
  },
  {
    id: "% Of Goal",
    label: "% Of Goal",
    align: "center",
    minWidth: 170
  },
  {
    id: "% improved ",
    label: "% improved ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Improved Team Members",
    label: "Improved Team Members",
    align: "center",
    minWidth: 170
  },
  {
    id: "Total Team Members",
    label: "Total Team Members",
    align: "center",
    minWidth: 100
  },
  {
    id: "Incremental Memberships",
    label: "Incremental Memberships",
    align: "center",
    minWidth: 170
  },
  {
    id: "Annualized",
    label: "Annualized",
    align: "center",
    minWidth: 170
  }
]

const rows = [
  {
    id: 1,
    rank: 1,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    percentGoal: 133.3,
    improvedTeam: 84,
    totalTeam: 45,
    incrementalMembers: 160,
    percentImproved: 13.3,
    annualized: 654
  },
  {
    id: 2,
    rank: 2,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    percentGoal: 120.0,
    improvedTeam: 99,
    totalTeam: 56,
    incrementalMembers: 250,
    percentImproved: 12.0,
    annualized: 970
  },
  {
    id: 3,
    rank: 3,
    name: "Robert Fox",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    percentGoal: 100.0,
    improvedTeam: 56,
    totalTeam: 23,
    incrementalMembers: 550,
    percentImproved: 2.0,
    annualized: 960
  },
  {
    id: 4,
    rank: 4,
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    percentGoal: 90.9,
    improvedTeam: 65,
    totalTeam: 78,
    incrementalMembers: 110,
    percentImproved: 1.8,
    annualized: 450
  },
  {
    id: 5,
    rank: 5,
    name: "Marvin McKinney",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    percentGoal: 87.5,
    improvedTeam: 63,
    totalTeam: 65,
    incrementalMembers: 680,
    percentImproved: 8.8,
    annualized: 760
  },
  {
    id: 6,
    rank: 6,
    name: "Jade Cooper",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    percentGoal: 71.4,
    improvedTeam: 75,
    totalTeam: 57,
    incrementalMembers: 570,
    percentImproved: 17.1,
    annualized: 960
  }
]
