import React, { Fragment } from "react"
import { ListItem, Typography } from "@mui/material"
import Divider from "components/StyledComponents/Divider"

function ListWithStatus({ data = [], marginRight = 0 }) {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
      case "done":
        return "#3ADB76"
      case "draft":
      case "pending":
        return "#FFAE00"
      case "published":
      case "publish":
        return "#9CC43B"
      default:
        return "#FFFFFF"
    }
  }

  return (
    <Fragment>
      {data?.map((item, index) => (
        <Fragment key={`${item?.id}-${index}`}>
          <ListItem sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
            <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
              {item?.name ?? "-"}
            </Typography>
            <Typography
              variant="body1Regular"
              style={{ color: getStatusColor(item?.status ?? "-") }}
              sx={{ marginRight }}>
              {item?.status ?? ""}
            </Typography>
          </ListItem>
          {data?.length - 1 !== index && <Divider />}
        </Fragment>
      ))}
    </Fragment>
  )
}

export default ListWithStatus
