import React from "react"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Typography } from "@mui/material"
import Card from "components/Card/Card"
import NavHeader from "../navHeader/index"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { useState } from "react"
import { useEffect } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useHistory } from "react-router-dom"
import { Formik } from "formik"
import { Container } from "react-bootstrap"
import AppTextField from "components/StyledComponents/AppTextField"
import AppSelect from "components/StyledComponents/AppSelect"
import AppButtonFilled from "components/StyledComponents/AppButtonFilled"
import Divider from "components/StyledComponents/Divider"
import InputCounter from "components/InputCounter"

function AddRestaurant() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const history = useHistory()
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Add Restaurant"
  }, [])

  return (
    <Formik
      initialValues={{
        client: "",
        channelName: "",
        channelType: "",
        generalManager: "",
        areaDirector: "",
        superAdmin: "",
        addressLine1: "",
        addressLine2: "",
        country: "",
        city: "",
        stateCode: "",
        postalCode: "",
        countryCode: "",
        totalSeats: 0,
        interiorSeats: 0,
        exteriorSeats: 0,
        squareFootSeats: 0,
        outsideSeats: 0,
        insideSeats: 0
      }}>
      {(formik) => (
        <>
          <NavHeader isRestaurantActive={true} breadCrumbs={breadCrumbs}></NavHeader>
          <Card
            style={{
              borderTopLeftRadius: "0px",
              marginTop: "0px"
            }}>
            <Row>
              <Col lg="12" className="d-flex mt-2">
                <ArrowBackIcon
                  role="button"
                  className="me-3 cursor-pointer"
                  onClick={() => history.goBack()}
                  fontSize="medium"
                />
                <Typography variant="h2" className="d-flex align-items-center">
                  Add Restaurant
                </Typography>
              </Col>
            </Row>
            <Container fluid className="mt-5">
              <Row>
                <Col lg="4">
                  <AppSelect
                    id="client"
                    name="client"
                    label="Client"
                    value={formik.values.client}
                    onChange={formik.handleChange}
                    error={formik.errors.client}
                    touched={formik.touched.client}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="channelName"
                    name="channelName"
                    fullWidth
                    label="Channel Name"
                    isRequired={false}
                    placeholder="Enter Channel  Name"
                    value={formik.values.channelName}
                    onChange={formik.handleChange}
                    error={formik.touched.channelName && Boolean(formik.errors.channelName)}
                    helperText={formik.touched.channelName && formik.errors.channelName}
                  />
                </Col>
                <Col lg="4">
                  <AppSelect
                    id="channelType"
                    name="channelType"
                    label="Channel Type"
                    value={formik.values.channelType}
                    onChange={formik.handleChange}
                    error={formik.errors.channelType}
                    touched={formik.touched.channelType}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg="4">
                  <AppTextField
                    id="generalManager"
                    name="generalManager"
                    fullWidth
                    label="General Manager"
                    isRequired={false}
                    placeholder="Enter General Manager"
                    value={formik.values.generalManager}
                    onChange={formik.handleChange}
                    error={formik.touched.generalManager && Boolean(formik.errors.generalManager)}
                    helperText={formik.touched.generalManager && formik.errors.generalManager}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="areaDirector"
                    name="areaDirector"
                    fullWidth
                    label="Area Director"
                    isRequired={false}
                    placeholder="Enter Area Director"
                    value={formik.values.areaDirector}
                    onChange={formik.handleChange}
                    error={formik.touched.areaDirector && Boolean(formik.errors.areaDirector)}
                    helperText={formik.touched.areaDirector && formik.errors.areaDirector}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="superAdmin"
                    name="superAdmin"
                    fullWidth
                    label="Client Super Admin"
                    isRequired={false}
                    placeholder="Enter Client Super Admin"
                    value={formik.values.superAdmin}
                    onChange={formik.handleChange}
                    error={formik.touched.superAdmin && Boolean(formik.errors.superAdmin)}
                    helperText={formik.touched.superAdmin && formik.errors.superAdmin}
                  />
                </Col>
              </Row>
              <Divider className="mt-4 mb-2" />
              <Row>
                <Typography className="mt-3 mb-3" variant="h4">
                  Address Details
                </Typography>
              </Row>
              <Row className="mt-2">
                <Col lg="4">
                  <AppTextField
                    id="addressLine1"
                    name="addressLine1"
                    fullWidth
                    label="Address Line 1"
                    isRequired={false}
                    placeholder="Enter Address Line 1"
                    value={formik.values.addressLine1}
                    onChange={formik.handleChange}
                    error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
                    helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="addressLine2"
                    name="addressLine2"
                    fullWidth
                    label="Address Line 2"
                    isRequired={false}
                    placeholder="Enter Address Line 2"
                    value={formik.values.addressLine2}
                    onChange={formik.handleChange}
                    error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
                    helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
                  />
                </Col>
                <Col lg="4">
                  <AppSelect
                    id="country"
                    name="country"
                    label="Country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={formik.errors.country}
                    touched={formik.touched.country}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg="4">
                  <AppSelect
                    id="city"
                    name="city"
                    label="City"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.errors.v}
                    touched={formik.touched.city}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="stateCode"
                    name="stateCode"
                    fullWidth
                    label="State Code"
                    isRequired={false}
                    placeholder="Enter State Code"
                    value={formik.values.stateCode}
                    onChange={formik.handleChange}
                    error={formik.touched.stateCode && Boolean(formik.errors.stateCode)}
                    helperText={formik.touched.stateCode && formik.errors.stateCode}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="postalCode"
                    name="postalCode"
                    fullWidth
                    label="Postal Code"
                    isRequired={false}
                    placeholder="Enter Postal Code"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg="4">
                  <AppTextField
                    id="countryCode"
                    name="countryCode"
                    fullWidth
                    label="Country Code"
                    isRequired={false}
                    placeholder="Enter Country Code"
                    value={formik.values.countryCode}
                    onChange={formik.handleChange}
                    error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                    helperText={formik.touched.countryCode && formik.errors.countryCode}
                  />
                </Col>
              </Row>
              <Divider className="mt-4 mb-2" />
              <Row>
                <Typography className="mt-3 mb-3" variant="h4">
                  Channel Details
                </Typography>
              </Row>
              <Row className="mt-4">
                <Col lg="2">
                  <InputCounter
                    id="totalSeats"
                    name="totalSeats"
                    fullWidth
                    label="Total Seats"
                    isRequired={false}
                    value={formik.values.totalSeats}
                    onChange={formik.handleChange}
                    error={formik.touched.totalSeats && Boolean(formik.errors.totalSeats)}
                    helperText={
                      formik.touched.totalSeats && formik.errors.totalSeats
                    }></InputCounter>
                </Col>
                <Col lg="2">
                  <InputCounter
                    id="interiorSeats"
                    name="interiorSeats"
                    fullWidth
                    label="Interior Seats"
                    isRequired={false}
                    value={formik.values.interiorSeats}
                    onChange={formik.handleChange}
                    error={formik.touched.interiorSeats && Boolean(formik.errors.interiorSeats)}
                    helperText={
                      formik.touched.interiorSeats && formik.errors.interiorSeats
                    }></InputCounter>
                </Col>
                <Col lg="2">
                  <InputCounter
                    id="exteriorSeats"
                    name="exteriorSeats"
                    fullWidth
                    label="Exterior Seats"
                    isRequired={false}
                    value={formik.values.exteriorSeats}
                    onChange={formik.handleChange}
                    error={formik.touched.exteriorSeats && Boolean(formik.errors.exteriorSeats)}
                    helperText={
                      formik.touched.exteriorSeats && formik.errors.exteriorSeats
                    }></InputCounter>
                </Col>
                <Col lg="2">
                  <InputCounter
                    id="squareFootSeats"
                    name="squareFootSeats"
                    fullWidth
                    label="Square Footage"
                    isRequired={false}
                    value={formik.values.squareFootSeats}
                    onChange={formik.handleChange}
                    error={formik.touched.squareFootSeats && Boolean(formik.errors.squareFootSeats)}
                    helperText={
                      formik.touched.squareFootSeats && formik.errors.squareFootSeats
                    }></InputCounter>
                </Col>
                <Col lg="2">
                  <InputCounter
                    id="outsideSeats"
                    name="outsideSeats"
                    fullWidth
                    label="Outside Seats"
                    isRequired={false}
                    value={formik.values.outsideSeats}
                    onChange={formik.handleChange}
                    error={formik.touched.outsideSeats && Boolean(formik.errors.outsideSeats)}
                    helperText={
                      formik.touched.outsideSeats && formik.errors.outsideSeats
                    }></InputCounter>
                </Col>
                <Col lg="2">
                  <InputCounter
                    id="insideSeats"
                    name="insideSeats"
                    fullWidth
                    label="Inside Seats"
                    isRequired={false}
                    value={formik.values.insideSeats}
                    onChange={formik.handleChange}
                    error={formik.touched.insideSeats && Boolean(formik.errors.insideSeats)}
                    helperText={
                      formik.touched.insideSeats && formik.errors.insideSeats
                    }></InputCounter>
                </Col>
              </Row>
            </Container>
            <Container fluid className="mt-5 mb-4">
              <Row>
                <Col lg="12">
                  <AppButtonFilled style={{ height: "50px" }}>Submit</AppButtonFilled>
                </Col>
              </Row>
            </Container>
          </Card>
        </>
      )}
    </Formik>
  )
}

export default AddRestaurant
