import { Box, styled, Typography } from "@mui/material"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import React, { useEffect, useState } from "react"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Card from "components/Card/Card"
import TopRankersTable from "./TopRankersTable"
import { useHistory } from "react-router-dom"

const InfoCard = styled("span")(({ theme }) => ({
  ...theme.typography.tag1,
  padding: "8px 12px",
  height: "fit-content",
  backgroundColor: "#3E341C",
  borderRadius: 4
}))

const TopRankersCard = ({ routeNext, type = "" }) => {
  const [data, setData] = useState([])
  const [average] = useState("1.8%")
  const history = useHistory()

  useEffect(() => {
    switch (type) {
      case "Server":
        return setData(TopServers)
      case "TR":
        return setData(TopTRs)
      case "Bartender":
        return setData(TopBartenders)
    }
  }, [type])
  return (
    <Card sx={{ padding: "12px 12px 24px" }}>
      <Box className="w-100 d-flex justify-content-between" sx={{ padding: "12px" }}>
        <Typography variant="h2">{`Top ${type}s`}</Typography>
        <Box className="d-flex align-items-center">
          <InfoCard>Ranking out of 1,392 team members</InfoCard>
          <span onClick={() => history.push(routeNext)} className="ms-3">
            <IconButtonWithBorder>
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </Box>
      </Box>
      <TopRankersTable {...{ data, type, average }} />
      <Box className="d-flex justify-content-center mt-3">
        <Typography
          variant="body2Regular"
          sx={(theme) => ({ color: theme.palette.orange.secondary })}>
          *200 Non-Member Opportunities to Qualify
        </Typography>
      </Box>
    </Card>
  )
}

export default TopRankersCard

const TopServers = [
  {
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    inviteRate: "19.9%",
    restaurant: "Morton Grove"
  },
  {
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    inviteRate: "14.3%",
    restaurant: "Doral"
  },
  {
    name: "Robert Fox",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    inviteRate: "13.0%",
    restaurant: "Burr Ridge"
  },
  {
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    inviteRate: "12.0%",
    restaurant: "Doral"
  },
  {
    name: "Marvin McKinney",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    inviteRate: "11.6%",
    restaurant: "Doral"
  }
]

const TopTRs = [
  {
    name: "Jade Cooper",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    inviteRate: "39.1%",
    restaurant: "Naples"
  },
  {
    name: "Kristin Watson",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    inviteRate: "36.7%",
    restaurant: "Virginia Beach"
  },
  {
    name: "Esther Howard",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    inviteRate: "34.6%",
    restaurant: "Ashburn"
  },
  {
    name: "Dianne Russell",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    inviteRate: "33.5%",
    restaurant: "Downers Grove"
  },
  {
    name: "Ralph Edwards",
    image: "https://randomuser.me/api/portraits/men/33.jpg",
    inviteRate: "32.4%",
    restaurant: "Rockville"
  }
]

const TopBartenders = [
  {
    name: "Guy Hawkins",
    image: "https://randomuser.me/api/portraits/men/34.jpg",
    inviteRate: "12.4%",
    restaurant: "Avon"
  },
  {
    name: "Leslie Alexander",
    image: "https://randomuser.me/api/portraits/women/41.jpg",
    inviteRate: "11.5%",
    restaurant: "Centerville"
  },
  {
    name: "Maegan Martin",
    image: "https://randomuser.me/api/portraits/women/42.jpg",
    inviteRate: "11.0%",
    restaurant: "Burr Ridge"
  },
  {
    name: "Katherin Talento",
    image: "https://randomuser.me/api/portraits/women/43.jpg",
    inviteRate: "8.1%",
    restaurant: "Oak Park"
  },
  {
    name: "Eric Smith",
    image: "https://randomuser.me/api/portraits/men/35.jpg",
    inviteRate: "8.0%",
    restaurant: "Reston"
  }
]
