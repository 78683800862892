import React from "react"
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from "chart.js"
import { Line, LineChart, XAxis, YAxis } from "recharts"
import { CartesianGrid } from "recharts"
import { useTheme } from "@mui/material"
import { ResponsiveContainer } from "recharts"
import { Typography } from "@mui/material"
import { ListItem } from "@mui/material"
import { List } from "@mui/material"

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
)

function DMVLineCharts(props) {
  const themes = useTheme()
  const data = [
    {
      date: "03 Oct",
      data: 50
    },
    {
      date: "10 Oct",
      data: 100
    },
    {
      date: "17 Oct",
      data: 200
    },
    {
      date: "24 Oct",
      data: 280
    },
    {
      date: "31 Oct",
      data: 320
    },
    {
      date: "07 Nov",
      data: 430
    },
    {
      date: "14 Nov",
      data: 500
    }
  ]
  return (
    <>
      {props.isHeading && (
        <List>
          <ListItem sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
            <Typography variant="h2">{props.heading}</Typography>
          </ListItem>
        </List>
      )}
      <ResponsiveContainer width="100%" height={props.height}>
        <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray="1 0" strokeOpacity={0.2} vertical={false} />
          <XAxis dataKey="date"></XAxis>
          <YAxis tickLine={false} axisLine={false} padding={{ bottom: 40 }} />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            strokeWidth={3}
            dataKey="data"
            fill={`${themes.palette.secondary.default}`}
            stroke={`${themes.palette.secondary.default}`}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

export default DMVLineCharts
