import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"

import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { styled, Typography } from "@mui/material"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    color: "white",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    borderBottom: `2px solid #272a2c`,
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

function createData(name, calories, fat, carbs, protein, detail) {
  return { name, calories, fat, carbs, protein, detail }
}

const rows = [
  createData("Food", 159, 6.0, 24, 4.0, "Lorem ipsum dolor sit amet, consectetur adipiscing elit"),
  createData(
    "Beverage",
    237,
    9.0,
    37,
    4.3,
    "Maecenas rutrum urna vel lacus viverra, id ultrices dui rutrum"
  )
]

const TableFooter = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 6,
  marginBottom: 10,
  "& .MuiTableCell-root": {
    backgroundColor: "transparent",
    "&:first-of-type": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6
    },
    "&:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      borderBottom: "none"
    }
  }
}))

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? (
              <KeyboardArrowUpIcon style={{ color: "white" }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: "white" }} />
            )}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  )
}

export default function AverageCheckTable() {
  return (
    <Paper>
      <CustomTable aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <ExpandableTableRow
              key={row.name}
              expandComponent={<TableCell colSpan="5">{row.detail}</TableCell>}>
              <TableCell component="td" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{"$" + row.calories}</TableCell>
              <TableCell align="center">{"$" + row.fat}</TableCell>
              <TableCell align="center">{"$" + row.carbs}</TableCell>
              <TableCell align="center">{"$" + row.protein}</TableCell>
            </ExpandableTableRow>
          ))}
          <TableFooter>
            <TableCell />
            <TableCell>
              <Typography className="fw-bold" variant="body2Regular">
                Total
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography className="fw-bold" variant="body2Regular">
                {"$" + rows.reduce((prev, el) => prev + el.calories, 0)}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography className="fw-bold" variant="body2Regular">
                {"$" + rows.reduce((prev, el) => prev + el.fat, 0)}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography className="fw-bold" variant="body2Regular">
                {"$" + rows.reduce((prev, el) => prev + el.carbs, 0)}
              </Typography>
            </TableCell>

            <TableCell align="center">
              <Typography className="fw-bold" variant="body2Regular">
                {"$" + rows.reduce((prev, el) => prev + el.protein, 0)}
              </Typography>
            </TableCell>
          </TableFooter>
        </TableBody>
      </CustomTable>
    </Paper>
  )
}
