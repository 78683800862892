import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@mui/material"
import { Link } from "react-router-dom"
import TableNoRecords from "components/TableNoRecords"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    "& .MuiPaper-root": {
      backgroundColor: "red"
    },
    "& .MuiTableRow-hover": {
      backgroundColor: "red"
    },
    "& .MuiTableCell-root": {
      padding: "24px 16px",
      backgroundColor: theme.palette.background.main,
      borderBottom: "1px solid #353535",
      "&:first-child": {
        paddingLeft: 0
      }
    }
  }
}))

const RestaurantTable = ({ columns, rows }) => {
  const classes = useStyles()

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table className={classes.root} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length ? (
              <>
                {rows?.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Link color="primary" to="#">
                          <Typography variant="body1Regular">{row?.restaurant ?? "-"}</Typography>
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1Regular">{row?.active_players ?? "-"}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1Regular">{`${row?.percentage ?? 0}%`}</Typography>
                      </TableCell>
                      {/* <TableCell align="right">
                        <Typography className="me-3" variant="body1Regular">
                          {row.inviteRate ?? "-"}
                        </Typography>
                      </TableCell> */}
                    </TableRow>
                  )
                })}
              </>
            ) : (
              <TableNoRecords colSpan={4} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default RestaurantTable
