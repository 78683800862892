import React, { useState } from "react"
import { Box, Typography, Collapse } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import styles from "./flaggedQuestion.module.scss"
import AppPagination from "components/StyledComponents/AppPagination"
import ImageDisplay from "components/ImageDisplayer"

const QuestionAccordion = ({
  questions,
  showPagination = true,
  pages = 0,
  currentPage,
  setCurrentPage
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null)
  const itemsPerPage = 12

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "..."
    }
    return text
  }

  const handleExpandClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index)
  }

  return (
    <>
      <Box className={styles.accordionHeader}>
        <Box></Box>
        <Box className={styles.headContainer}>
          <Typography variant="h6">Questions</Typography>
        </Box>
        <Box></Box>
        <Box>
          <Typography variant="h6">Reason Code</Typography>
        </Box>
        <Box>
          <Typography variant="h6">Remark</Typography>
        </Box>
      </Box>

      {questions.map((question, index) => {
        const isExpanded = expandedIndex === index

        return (
          <Box key={index} className={styles.accordionGrid}>
            <Box className={styles.accordionItem}>
              <Box
                className={`${styles.questionNumber} ${
                  question?.reason_type === "GOOD"
                    ? styles.questionNumberGood
                    : styles.questionNumberBad
                } `}>
                {" "}
                {currentPage * itemsPerPage - (itemsPerPage - 1) + index}
              </Box>
            </Box>
            <Box className={styles.questionContent}>
              <ImageDisplay data={question.image} keyProp={"image"} />
            </Box>
            <Box>
              <Box>
                <Typography variant="caption7">{`${
                  question?.food_item_name ? question?.food_item_name : question?.food_name
                } | ${question?.question_platform ?? ""}`}</Typography>
                <Box>
                  <Typography variant="caption6">
                    {" "}
                    {isExpanded ? question.title : truncateText(question.title, 50)}
                  </Typography>
                  <Collapse in={isExpanded}>
                    <Box>
                      <Box>
                        {question.answer.map((choice, index) => (
                          <Box
                            key={index}
                            className={`${styles.question_bank_option} ${
                              choice?.is_correct ? styles.optionCorrect : ""
                            }`}>
                            <Box className={styles.optionNumber}>
                              <Typography variant="tag1">
                                {(index + 10).toString(36).toUpperCase()}
                              </Typography>
                            </Box>
                            <Typography className={styles.optionName} variant="body2Regular">
                              {choice?.answer}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography variant="h5">
                {isExpanded ? question?.reason.reason : truncateText(question.reason.reason, 50)}
              </Typography>
            </Box>

            <Box>
              <Typography variant="h5" className={styles.remark}>
                {isExpanded ? question.remark : truncateText(question.remark, 50)}
              </Typography>
            </Box>

            <Box className={styles.accordionItem}>
              <Box className={styles.expandButton} onClick={() => handleExpandClick(index)}>
                {isExpanded ? (
                  <KeyboardArrowUpIcon className={styles.accordion} />
                ) : (
                  <KeyboardArrowDownIcon className={styles.accordion} />
                )}
              </Box>
            </Box>
          </Box>
        )
      })}
      {showPagination && pages > 0 && (
        <AppPagination onPageChange={setCurrentPage} totalPages={pages} currentPage={currentPage} />
      )}
    </>
  )
}

export default QuestionAccordion
