import { Box, styled } from "@mui/material"
import Shimmer from "components/Shimmer/Shimmer"
import YoutubeFrame from "components/YoutubeFrame/YoutubeFrame"
import { getYoutubeIdByUrl } from "helpers/functions"
import React, { useState } from "react"
import PlayIcon from "assets/icons/PlayIcon.svg"

const StyledContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  boxSizing: "border-box",
  "& .thumbnailCtr": {
    height: 250,
    width: "100%",
    position: "absolute",
    cursor: "pointer",
    "& .thumbnail": {
      height: 250,
      width: "100%",
      borderRadius: 12,
      objectFit: "cover"
    },
    "& .playIcon": {
      position: "absolute",
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
}))

const VideoPlayer = ({ video }) => {
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [isVideoPlayed, setIsVideoPlayed] = useState(false)

  return (
    <StyledContainer>
      {!videoLoaded ? (
        <Shimmer type="rounded" height="250px" styles={{ marginLeft: 0, marginRight: 0 }} />
      ) : (
        <>
          {isVideoPlayed ? null : (
            <Box className="thumbnailCtr" onClick={() => setIsVideoPlayed(true)}>
              <Box className="playIcon">
                <img src={PlayIcon} alt="Play" />
              </Box>
              <img className="thumbnail" src={video.thumbnail_url} alt="none" />
            </Box>
          )}
        </>
      )}
      <YoutubeFrame
        {...{
          videoLoaded,
          setVideoLoaded,
          url: `https://www.youtube.com/embed/${getYoutubeIdByUrl(video?.video_url)}${
            isVideoPlayed ? "?autoplay=1&loop=1&rel=0&wmode=transparent" : ""
          }`,
          style: {
            width: "100%",
            visibility: isVideoPlayed ? "visible" : "hidden"
          }
        }}
      />
    </StyledContainer>
  )
}

export default VideoPlayer
