import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
// import TriviaAnalyticsSnapshot from "components/TriviaAnalyticsSnapshot/TriviaAnalyticsSnapshot"
import Top5LeadersUI from "components/DashboardCards/Top5LeadersUI/Top5LeadersUI"
// import PendingApprovals from "./components/PendingApprovals"
import "./dashboard.scss"
import TriviaContest from "./components/TriviaContest"
import MiniCalendar from "../../../../components/DashboardCards/MiniCalendar"
import Header from "components/Header/Header"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  getLeaderBoardUserListAction,
  getPendingQuestionsAction,
  getRestaurantsListAction,
  getTriviaContestListAction
} from "redux/L&D/actions"
import { formatDate, getLastDayOfMonth } from "helpers/functions"
import { getLeaderboardDataAction, getWineMonthAction } from "redux/marketing/actions"
import moment from "moment"
import { appActions } from "redux/app/slice/app"
import FeatureItemOfMonthSnapShot from "components/FeatureItemOfMonthSnapShot"
import Card from "components/Card/Card"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import { Typography } from "@mui/material"

function Dashboard() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState([])

  const { date } = useSelector((store) => store?.app?.filters || {})

  const { leaderBoardUserList } = useSelector((store) => store?.learningDevelopment || [])
  const { results } = useSelector((store) => store?.marketing?.leaderboard?.data || {})

  const { listWineMonthData } = useSelector((store) => store?.marketing?.wineOfTheMonth || [])

  // function TriviaCalendarSnapshotIcon() {
  //   return (
  //     <ArrowForwardIosIcon
  //       style={{
  //         color: "white",
  //         fontSize: "small"
  //       }}
  //     />
  //   )
  // }

  function createTableData() {
    setTableData(
      results?.map((res, index) => ({
        id: index,
        key: res?.user?.name,
        field: res?.coins_earned,
        restaurantName: res?.user?.rest_name ?? ""
      }))
    )
  }

  useEffect(() => {
    createTableData()
  }, [leaderBoardUserList])

  useEffect(() => {
    const currentDate = formatDate(new Date())
    dispatch(getLeaderboardDataAction({ date: currentDate, page: 1 }))
    dispatch(getWineMonthAction(getLastDayOfMonth(currentDate)))
  }, [])

  useEffect(() => {
    document.title = "Dashboard - L&D"
    dispatch(getTriviaContestListAction({ page: 1 }))
    dispatch(getLeaderBoardUserListAction({ date: formatDate(new Date()), page: 1 }))
    dispatch(getPendingQuestionsAction())
  }, [])

  useEffect(() => {
    dispatch(appActions.initFilters())
    dispatch(getRestaurantsListAction())
  }, [])

  return (
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <Header
            title="Learning and Development Dashboard"
            back={false}
            // notificationIcon={"/learning_development/dashboard/notifications"}
            // isNotify={true}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FeatureItemOfMonthSnapShot
            nextRoute="/learning_development/dashboard/featured-Items-of-the-Month"
            listData={listWineMonthData}></FeatureItemOfMonthSnapShot>
        </Col>
      </Row>
      <Row>
        <Col lg="4" md="6">
          <Row>
            <Col lg="12" className="calendarCtr">
              <MiniCalendar
                onClick={() => history.push("/learning_development/dashboard/trivia-calendar")}
              />
            </Col>
          </Row>
        </Col>
        <Col lg="4" md="6">
          <Row>
            <Col lg="12">
              <TriviaContest style={{ height: 395 }} />
            </Col>
          </Row>
        </Col>
        <Col lg="4" md="6">
          <Row>
            <Col lg="12">
              <Top5LeadersUI
                style={{ height: 395 }}
                date={moment(date).format("MMM'YY")}
                tableData={tableData}
                routeNext={"/learning_development/dashboard/leaderboard"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <Row>
              <Col lg="8">
                <Typography variant="h2">Trivia Question Bank</Typography>
              </Col>
              <Col lg="4" className="d-flex justify-content-end">
                <span onClick={() => history.push("/learning_development/dashboard/question-bank")}>
                  <IconButtonWithBorder aria-label="Wine Of The Month">
                    <ArrowForwardIosIcon
                      sx={{
                        color: "white",
                        fontSize: "small"
                      }}
                    />
                  </IconButtonWithBorder>
                </span>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* <Col lg="6">
          <Row>
            <Col lg="12">
              <PendingApprovals style={{ height: 395 }} />
            </Col>
          </Row>
        </Col> */}
        {/* <Col lg="6">
          <Row>
            <Col lg="12">
              <TriviaAnalyticsSnapshot
                style={{ height: 400 }}
                date={moment(date).format("MMM'YY")}
                {...{ TriviaCalendarSnapshotIcon }}
                routeNext={"/learning_development/dashboard/trivia-analytics"}
              />
            </Col>
          </Row>
        </Col> */}
      </Row>
    </Container>
  )
}

export default Dashboard
