import { Box, styled, Typography, useTheme } from "@mui/material"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import React from "react"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Card from "components/Card/Card"
import AppSelect from "components/StyledComponents/AppSelect"
import CustomDivider from "components/StyledComponents/Divider"
import { useHistory } from "react-router-dom"

const StyledSelect = styled(AppSelect)(() => ({
  height: 40,
  width: "fit-content",
  "& .MuiSvgIcon-root": {
    marginRight: 0
  }
}))

const StyledContainer = styled(Box)(() => ({
  marginTop: 12,
  "& .listItem": {
    marginBottom: 12
  },
  "& .rank": {
    height: 30,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 4
  }
}))

const RankList = ({ list = [], rankColor }) => {
  return (
    <StyledContainer>
      {list.map((item, index) => (
        <Box
          key={`${rankColor}-${index}`}
          className="d-flex justify-content-between align-items-center listItem">
          <Box className="d-flex align-items-center">
            <Box className="rank me-1" sx={{ borderColor: rankColor }}>
              {index + 1}
            </Box>
            <Typography variant="body1Regular" className="ms-2" sx={{ opacity: 0.6 }}>
              {item.label}
            </Typography>
          </Box>
          <Typography variant="body1Regular">{item.value}</Typography>
        </Box>
      ))}
    </StyledContainer>
  )
}

const InviteRateRankingsCard = ({ routeNext }) => {
  const theme = useTheme()
  const history = useHistory()

  return (
    <Card className="d-flex flex-column">
      <Box className="w-100 d-flex justify-content-between">
        <Typography variant="h2">Invite Rate Rankings </Typography>
        <Box className="d-flex">
          <StyledSelect menuItems={[{ label: "TRs", value: "TRs" }]} value="TRs" />
          <span onClick={() => history.push(routeNext)} className="ms-3">
            <IconButtonWithBorder>
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </Box>
      </Box>
      <Typography variant="caption1" sx={{ fontSize: 12 }}>{`Rank (Out of 50)`}</Typography>
      <Typography variant="caption1" sx={{ color: theme.palette.green.main, fontSize: 12, mt: 3 }}>
        YOUR TOP
      </Typography>
      <RankList list={topList} rankColor={theme.palette.green.main} />
      <CustomDivider className="mb-1" />
      <Typography variant="caption1" sx={{ color: theme.palette.red.main, fontSize: 12, mt: 1 }}>
        YOUR BOTTOM
      </Typography>
      <RankList list={bottomList} rankColor={theme.palette.red.main} />
    </Card>
  )
}

export default InviteRateRankingsCard

const topList = [
  {
    label: "Rockville",
    value: "14.3%"
  },
  {
    label: "Chandler",
    value: "14.2%"
  },
  {
    label: "Virginia Beach",
    value: "13.6%"
  },
  {
    label: "Fort Lauderdale",
    value: "13.1%"
  },
  {
    label: "Ashburn",
    value: "12.8%"
  }
]

const bottomList = [
  {
    label: "New Lenox",
    value: "5.1%"
  },
  {
    label: "Coconut Creek",
    value: "5.0%"
  },
  {
    label: "Brookfield",
    value: "4.9%"
  },
  {
    label: "South Barrington",
    value: "4.4%"
  },
  {
    label: "Esquire Chicago",
    value: "3.4%"
  }
]
