import React, { Fragment, useEffect, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import QuestionTable from "components/QuestionTable"
import Header from "components/Header/Header"
import Modal from "components/Modal/Modal"
import ModalContent from "components/CorrectAnswerModal/CorrectAnswerModal"
import { useDispatch, useSelector } from "react-redux"
import { getTriviaCorrectAnswersAction, getTriviaIncorrectAnswersAction } from "redux/L&D/actions"
import { getMonthYearFromDate } from "helpers/functions"
import { appActions } from "redux/app/slice/app"

const TriviaAnalytics = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { correctAnswers, incorrectAnswers } = useSelector(
    (store) => store?.learningDevelopment?.triviaAnalytics
  )
  const { date } = useSelector((store) => store?.app?.filters ?? "")
  const styles = {
    gridContainer: {
      "&.MuiGrid-root": {
        paddingTop: "0"
      }
    },
    heading: {
      color: theme.palette.text.primary
    }
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [breadCrumbs, setBreadcrumbs] = useState([])

  const handleClose = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Trivia Analytics"
  }, [])

  useEffect(() => {
    dispatch(appActions.initFilters())
  }, [])

  useEffect(() => {
    dispatch(getTriviaCorrectAnswersAction(date))
    dispatch(getTriviaIncorrectAnswersAction({ date }))
  }, [date])

  return (
    <Fragment>
      <Modal open={modalOpen} onClose={handleClose}>
        <ModalContent modalContent={modalContent} />
      </Modal>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header
            title={`Trivia Analytics For ${getMonthYearFromDate()}`}
            breadCrumbs={breadCrumbs}
          />
        </Grid>
        <Grid sx={styles.gridContainer} item xs={12}>
          <Typography sx={styles.heading} variant="h2">
            Trivia Questions Answered Most Correctly
          </Typography>
        </Grid>
        <Grid sx={styles.gridContainer} item xs={12}>
          <QuestionTable
            isCorrectAnswer={true}
            rows={correctAnswers ?? []}
            columns={CorrectAnsweredColumns}
            setModalOpen={setModalOpen}
            setModalContent={setModalContent}
          />
        </Grid>
        <Grid item xs={12}>
          <Box height={23} />

          <Typography sx={styles.heading} variant="h2">
            Trivia Questions Answered Most Incorrectly
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <QuestionTable
            isCorrectAnswer={false}
            rows={incorrectAnswers ?? []}
            columns={IncorrectAnsweredColumns}
            setModalOpen={setModalOpen}
            setModalContent={setModalContent}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default TriviaAnalytics

const CorrectAnsweredColumns = [
  { id: "Rank", label: "Rank", minWidth: 55 },
  { id: "Questions", label: "Questions", minWidth: 170 },
  {
    id: "Correct Answer",
    label: "Correct Answer",
    align: "center",
    minWidth: 170
  },
  {
    id: "Correct Answers",
    label: "Correct Answers ",
    align: "center",
    minWidth: "fit-content"
  },
  {
    id: "Players Answered",
    label: "Players Answered",
    align: "center",
    minWidth: "fit-content"
  },
  {
    id: "% Correct Answered",
    label: "% Correct Answered",
    align: "center",
    minWidth: "fit-content"
  }
]

const IncorrectAnsweredColumns = [
  { id: "Rank", label: "Rank", minWidth: 55 },
  { id: "Questions", label: "Questions", minWidth: 170 },
  {
    id: "Correct Answer",
    label: "Correct Answer",
    align: "center",
    minWidth: 170
  },
  {
    id: "Incorrect Answers",
    label: "Incorrect Answers",
    align: "center",
    minWidth: "fit-content"
  },
  {
    id: "Players Answered",
    label: "Players Answered",
    align: "center",
    minWidth: "fit-content"
  },
  {
    id: "% Incorrect Answered",
    label: "% Incorrect Answered",
    align: "center",
    minWidth: "fit-content"
  }
]
