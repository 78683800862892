import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Formik } from "formik"
import Card from "components/Card/Card"
import Grid from "@mui/material/Grid"
import AppTextField from "components/StyledComponents/AppTextField"
import AppSelect from "components/StyledComponents/AppSelect"
import { useDispatch } from "react-redux"
import {
  editWineBenefitsAction,
  getWineBenefitDataAction,
  uploadBenefitIconImageAction
} from "redux/DMV/actions"
import { useSelector } from "react-redux"
import { validationSchemaWineClubMembershipEdit } from "helpers/validationSchema"
import AppButtonGradient from "components/StyledComponents/AppButtonGradient"
import { DMVActions } from "redux/DMV/slice/user"
import { Prompt, useHistory } from "react-router-dom"
import AppTextArea from "components/StyledComponents/AppTextArea"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"

const EditWeekWineClub = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialWineBenefitValues, setInitialWineBenefitValues] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const { wineBenefitData } = useSelector((state) => state.dmv.wineBenefits)
  const { isSubmitted } = useSelector((state) => state.dmv.wineBenefits)
  const { benefitImageIcon } = useSelector((state) => state.dmv.wineBenefits)
  const [openCrop, setCrop] = useState(false)

  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const initialFormData = () => {
    setInitialWineBenefitValues({
      benefitName: wineBenefitData?.benefit ?? "",
      benefitDetail: wineBenefitData?.detail ?? "",
      imageIcon: wineBenefitData?.image
        ? {
            id: "",
            url: wineBenefitData?.image ?? ""
          }
        : null,
      tooltip: wineBenefitData?.tooltip ?? "",
      membership: wineBenefitData?.available_with ?? ""
    })
  }
  const onImageUpload = (image) => {
    if (image) {
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  const handleImageDelete = (formik) => {
    dispatch(DMVActions.setBenefitImageIcon(null))
    formik.setFieldValue(`imageIcon`, null)
  }

  const handleSubmitData = (values) => {
    setIsFormSubmitting(true)
    const payload = {
      ...(benefitImageIcon ? { image: benefitImageIcon } : {}),
      detail: values.benefitDetail,
      benefit: values.benefitName,
      tooltip: values.tooltip,
      available_with: values.membership
    }
    dispatch(editWineBenefitsAction(payload))
  }

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  }, [])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Wine Of The Month - DMV"
    dispatch(getWineBenefitDataAction())
    dispatch(DMVActions.initWineBenefits())
  }, [])

  useEffect(() => {
    if (wineBenefitData) {
      initialFormData()
    }
  }, [wineBenefitData])
  useEffect(() => {
    if (isSubmitted) {
      dispatch(DMVActions.setIsBenefitSubmitted(false))
      history.push("/dmv/dashboard/wine-club-memberships/list")
    }
  }, [isSubmitted])

  useEffect(() => {}, [benefitImageIcon])

  function handleCroppedImage(image, formik) {
    let data = new FormData()
    data.append("media", image)
    data.append("media_type", "IMAGE")
    data.append("content_type", image.type)
    dispatch(uploadBenefitIconImageAction(data))
    formik.setFieldValue("imageIcon", image)
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={0}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <Container fluid className="p-0  ">
      <Row>
        <Col lg="12">
          <Header title="Wine Club Memberships" breadCrumbs={breadCrumbs} back={true}></Header>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Formik
            initialValues={initialWineBenefitValues}
            enableReinitialize={true}
            validationSchema={validationSchemaWineClubMembershipEdit}
            onSubmit={handleSubmitData}>
            {(formik) => (
              <>
                <Prompt
                  when={formik.dirty && !isFormSubmitting}
                  message={
                    "Changes you made not be saved. Are you sure you want to leave this page ?"
                  }
                />
                <Card className="mb-4">
                  {showCropperModal(imageObjectURL, fileDetails, formik)}
                  <Grid container className="mt-0" spacing={4}>
                    <Grid item sm={12} md={6} lg={3}>
                      <AppTextField
                        placeholder="Enter Benefit Name"
                        label="Benefit Name"
                        isRequired={true}
                        value={formik?.values?.benefitName}
                        fullWidth
                        onChange={formik.handleChange("benefitName")}
                        error={formik.touched.benefitName && Boolean(formik.errors?.benefitName)}
                        helperText={formik.touched.benefitName && formik.errors.benefitName}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={3}>
                      <AppTextField
                        placeholder="Enter Benefit Details"
                        label="Benefit Details"
                        isRequired={true}
                        value={formik?.values?.benefitDetail}
                        fullWidth
                        onChange={formik.handleChange("benefitDetail")}
                        error={
                          formik.touched.benefitDetail && Boolean(formik.errors?.benefitDetail)
                        }
                        helperText={formik.touched.benefitDetail && formik.errors.benefitDetail}
                      />
                    </Grid>

                    <Grid item sm={12} md={6} lg={3}>
                      <ImageUploadField
                        label="Benefit Icon"
                        isRequired={true}
                        placeholder="Upload Icon"
                        value={formik?.values?.imageIcon}
                        onChange={(e) => onImageUpload(e)}
                        onDelete={() => handleImageDelete(formik)}
                        error={formik.errors?.imageIcon}
                        touched={formik.touched?.imageIcon}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={3}>
                      <AppSelect
                        label="Memberships"
                        isRequired={true}
                        onChange={(e) => formik.setFieldValue("membership", e.target.value)}
                        value={formik?.values?.membership}
                        menuItems={membershipList}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={3}>
                      <AppTextArea
                        placeholder="Enter Benefit Tooltip"
                        label="Benefit Tooltip"
                        value={formik?.values?.tooltip}
                        onChange={formik.handleChange("tooltip")}
                        error={formik.errors?.tooltip}
                        touched={formik.touched?.tooltip}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <Row>
                  <Col lg="2" className="mt-5">
                    <AppButtonGradient
                      onClick={formik.resetForm}
                      sx={{ height: "48px", width: "100%" }}
                      variant="outlined">
                      Reset
                    </AppButtonGradient>
                  </Col>
                  <Col lg="2" className="mt-5">
                    <AppButtonGradient
                      onClick={formik.handleSubmit}
                      sx={{ height: "48px", width: "100%" }}
                      variant="filled">
                      Submit
                    </AppButtonGradient>
                  </Col>
                </Row>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  )
}

export default EditWeekWineClub
const membershipList = [
  {
    label: "1 bottle",
    value: 1
  },
  {
    label: "2 bottle",
    value: 2
  },
  {
    label: "3 bottle",
    value: 3
  }
]
