import { Typography } from "@mui/material"
import Card from "components/Card/Card"
import GaugeChart from "components/GaugeChart/gaugeChart"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import PerformanceManagementTable from "./components/Table/performanceManagementTable"
import classes from "./performanceManagement.module.scss"

const GMPerformanceManagement = () => {
  const history = useHistory()
  const [breadCrumbs, setBreadcrumbs] = useState([])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Performance Management"
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title="Performance Management Week 6 of 8" breadCrumbs={breadCrumbs}>
              <AppButton
                onClick={() =>
                  history.push(
                    "/gm/dashboard/notifications/performance-management/additional-information"
                  )
                }>
                Additional Information
              </AppButton>
            </Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className={classes.gaugeBackground}>
              <GaugeChart headingTitle="% of improved" isHeading={false}></GaugeChart>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card className={classes.heading}>
              <Typography variant="h2">% Of Goal Achieved </Typography>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <PerformanceManagementTable rows={rows} columns={columns} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default GMPerformanceManagement

const columns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 220
  },
  {
    id: "% Of Goal",
    label: "% Of Goal",
    align: "center",
    minWidth: 170
  },
  {
    id: "Job Class",
    label: "Job Class",
    align: "center",
    minWidth: 170
  },
  {
    id: "Invites",
    label: "Invites",
    align: "center",
    minWidth: 170
  },
  {
    id: "Non WCM",
    label: "Non WCM",
    align: "center",
    minWidth: 100
  },
  {
    id: "Invite Rate %",
    label: "Invite Rate %",
    align: "center",
    minWidth: 170
  },
  {
    id: "Employee Class Benchmark Invite Rate %",
    label: "Employee Class Benchmark Invite Rate %",
    align: "center",
    minWidth: 170
  }
]

const rows = [
  {
    id: 1,
    rank: 1,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    percentGoal: 133.3,
    jobClass: "TR",
    Invites: 8,
    nonWCM: 60,
    inviteRate: 13.3,
    employeeBenchmark: 10.0
  },
  {
    id: 2,
    rank: 2,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    percentGoal: 120.0,
    jobClass: "TR",
    Invites: 6,
    nonWCM: 50,
    inviteRate: 12.0,
    employeeBenchmark: 10.0
  },
  {
    id: 3,
    rank: 3,
    name: "Robert Fox",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    percentGoal: 100.0,
    jobClass: "Server",
    Invites: 1,
    nonWCM: 50,
    inviteRate: 2.0,
    employeeBenchmark: 2.0
  },
  {
    id: 4,
    rank: 4,
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    percentGoal: 90.9,
    jobClass: "Server",
    Invites: 2,
    nonWCM: 110,
    inviteRate: 1.8,
    employeeBenchmark: 2.0
  },
  {
    id: 5,
    rank: 5,
    name: "Marvin McKinney",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    percentGoal: 87.5,
    jobClass: "TR",
    Invites: 7,
    nonWCM: 80,
    inviteRate: 8.8,
    employeeBenchmark: 10.0
  },
  {
    id: 6,
    rank: 6,
    name: "Jade Cooper",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    percentGoal: 71.4,
    jobClass: "TR",
    Invites: 5,
    nonWCM: 70,
    inviteRate: 17.1,
    employeeBenchmark: 10.0
  },
  {
    id: 7,
    rank: 7,
    name: "Kristin Watson",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    percentGoal: 58.8,
    jobClass: "Server",
    Invites: 2,
    nonWCM: 170,
    inviteRate: 1.2,
    employeeBenchmark: 2.0
  },
  {
    id: 8,
    rank: 8,
    name: "Esther Howard",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    percentGoal: 50.0,
    jobClass: "Server",
    Invites: 1,
    nonWCM: 100,
    inviteRate: 1.0,
    employeeBenchmark: 2.0
  },
  {
    id: 9,
    rank: 9,
    name: "Dianne Russell",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    percentGoal: 44.0,
    jobClass: "Bartender",
    Invites: 1,
    nonWCM: 150,
    inviteRate: 0.7,
    employeeBenchmark: 1.5
  },
  {
    id: 10,
    rank: 10,
    name: "Ralph Edwards",
    image: "https://randomuser.me/api/portraits/men/33.jpg",
    percentGoal: 40.0,
    jobClass: "TR",
    Invites: 4,
    nonWCM: 100,
    inviteRate: 4.0,
    employeeBenchmark: 10.0
  },
  {
    id: 11,
    rank: 11,
    name: "Guy Hawkins",
    image: "https://randomuser.me/api/portraits/men/34.jpg",
    percentGoal: 0.0,
    jobClass: "Bartender",
    Invites: 0,
    nonWCM: 50,
    inviteRate: 0.0,
    employeeBenchmark: 1.5
  },
  {
    id: 12,
    rank: 12,
    name: "Leslie Alexander",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    percentGoal: 0.0,
    jobClass: "Server",
    Invites: 0,
    nonWCM: 50,
    inviteRate: 0.0,
    employeeBenchmark: 2.0
  }
]
