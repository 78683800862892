import React, { Fragment, useState } from "react"
import { Box, Typography } from "@mui/material"
import CustomDivider from "components/StyledComponents/Divider"
import Modal from "components/Modal/Modal"
import ModalContent from "./ModalContent"
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined"

function ListItems({ data }) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: "15px"
    },
    listContainer: {
      width: "100%",
      cursor: "pointer"
    },
    listItem: {
      marginBottom: "10px",
      marginTop: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    title: {
      opacity: 0.6,
      marginLeft: "10px"
    },
    text: {
      display: "flex",
      flexWrap: "wrap"
    },
    iconCtr: {
      height: 44,
      width: 44,
      position: "relative"
    },
    playIcon: {
      position: "absolute",
      top: 10,
      left: 10
    }
  }

  const [modalActive, setModalActive] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleClose = () => {
    setModalActive(false)
  }

  const populateModal = (item) => {
    setModalData(item)
    setModalActive(true)
  }

  return (
    <Fragment>
      <Modal open={modalActive}>
        <ModalContent handleClose={handleClose} data={modalData} />
      </Modal>
      <Box sx={styles.container}>
        {data.map((item, index) => (
          <Box sx={styles.listContainer} key={index}>
            <Box onClick={() => populateModal(item)} sx={styles.listItem}>
              <Box style={styles.iconCtr}>
                <img src={item.icon} style={styles.iconCtr} />
                {item.type === "video" ? <PlayArrowOutlinedIcon sx={styles.playIcon} /> : null}
              </Box>
              <Box sx={styles.text}>
                <Typography sx={styles.title} variant="body1Regular">
                  {item.title}
                </Typography>
              </Box>
            </Box>
            {data.length - 1 !== item.id && <CustomDivider />}
          </Box>
        ))}
      </Box>
    </Fragment>
  )
}

export default ListItems
