import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import MembershipsCards from "./components/membershipsCards"
import PerformanceSummaryable from "./components/performanceSummaryTable"
import WineClubPerformanceCard from "./components/wineClubPerformanceCard"

const AreaDirectorPerformanceSummary = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Performance Summary"
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header
              title="Frontline Wine Club Membership Sign-up Performance"
              breadCrumbs={breadCrumbs}></Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <PerformanceSummaryable {...{ rows, columns }} />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="7">
            <WineClubPerformanceCard />
          </Col>
          <Col lg="5">
            <MembershipsCards />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default AreaDirectorPerformanceSummary

const columns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Restaurant",
    label: "Restaurant",
    minWidth: 140
  },
  {
    id: "Invite Rate",
    label: "Invite Rate",
    align: "center",
    minWidth: 170
  },
  {
    id: "Non Members",
    label: "Non Members",
    align: "center",
    minWidth: 170
  },
  {
    id: "% of Team Has App",
    label: "% of Team Has App",
    align: "center",
    minWidth: 170
  },
  {
    id: "Members",
    label: "Members",
    align: "center",
    minWidth: 100
  },
  {
    id: "Upgrade Rate",
    label: "Upgrade Rate",
    align: "center",
    minWidth: 100
  },
  {
    id: "# of Invite Underperformers",
    label: "# of Invite Underperformers",
    align: "center",
    minWidth: 170
  },
  {
    id: "# of Upgrade Underperformers",
    label: "# of Upgrade Underperformers",
    align: "center",
    minWidth: 170
  },
  {
    id: "Lost WCM Opportunity",
    label: "Lost WCM Opportunity",
    align: "center",
    minWidth: 170
  },
  {
    id: "Lost Upgrade Opportunity",
    label: "Lost Upgrade Opportunity",
    align: "center",
    minWidth: 170
  },
  {
    id: "Bottle Signups",
    label: "Bottle Signups",
    align: "center",
    minWidth: 170
  },
  {
    id: "Upgrades",
    label: "Upgrades",
    align: "center",
    minWidth: 170
  },
  {
    id: "WCM Annual Opportunity",
    label: "WCM Annual Opportunity",
    align: "center",
    minWidth: 170
  },
  {
    id: "Upgrade Annual Opportunity",
    label: "Upgrade Annual Opportunity",
    align: "center",
    minWidth: 170
  },
  {
    id: "Employees w/ Zero Invites",
    label: "Employees w/ Zero Invites",
    align: "center",
    minWidth: 170
  },
  {
    id: "Employees w/ Zero Upgrades",
    label: "Employees w/ Zero Upgrades",
    align: "center",
    minWidth: 170
  }
]

const rows = [
  {
    id: 1,
    rank: 1,
    restaurant: "Tampa",
    areaDirector: "Jay Lee",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 22,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  },
  {
    id: 2,
    rank: 2,
    restaurant: "Waterford Lakes",
    areaDirector: "Jay Lee",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 22,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  },
  {
    id: 3,
    rank: 3,
    restaurant: "Naples",
    areaDirector: "Jay Lee",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 22,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  },
  {
    id: 4,
    rank: 4,
    restaurant: "Sarasota",
    areaDirector: "Jay Lee",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 22,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  },
  {
    id: 5,
    rank: 5,
    restaurant: "Doral",
    areaDirector: "Jay Lee",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 22,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  },
  {
    id: 6,
    rank: 6,
    restaurant: "Jacksonville",
    areaDirector: "Eric Fox",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 2,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  },
  {
    id: 7,
    rank: 7,
    restaurant: "St. Peters",
    areaDirector: "Eric Fox",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 22,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  },
  {
    id: 8,
    rank: 8,
    restaurant: "Coconut Creek",
    areaDirector: "Eric Fox",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 22,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  },
  {
    id: 9,
    rank: 9,
    restaurant: "Orlando",
    areaDirector: "Eric Fox",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 22,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  },
  {
    id: 10,
    rank: 10,
    restaurant: "Indianapolis",
    areaDirector: "Matteo Cerniglia",
    region: "Atlantic",
    inviteRate: 14.3,
    nonMembers: 59000,
    percentHasApp: 71.4,
    members: 2950,
    upgradeRate: 2.5,
    inviteUnderperformers: 18,
    upgradeUnderPerformers: 2,
    lostWcmOpportunity: 56,
    lostUpgradeOpportunity: 25,
    bottleSignups: 669,
    upgrades: 74,
    wcmAnnualOpportunity: 537600,
    upgradeAnnualOpportunity: 120960,
    zeroInvites: 6,
    zeroUpgrades: 15
  }
]
