import { Box, IconButton, styled, Typography } from "@mui/material"
import Card from "components/Card/Card"
import Modal from "components/Modal/Modal"
import React from "react"
import CloseIcon from "@mui/icons-material/Close"

const StyledCard = styled(Card)(({ theme }) => ({
  paddingLeft: "36px",
  paddingRight: "36px",
  paddingTop: "36px",
  position: "relative",
  margin: 0,
  minWidth: 250,
  minHeight: 250,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "& .closeBtn": {
    color: theme.palette.text.primary,
    position: "absolute",
    top: "5px",
    right: "5px",
    backgroundColor: theme.palette.background.main
  }
}))

const ShowImagePopup = ({ open, onClose, data }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledCard>
        <IconButton onClick={onClose} className="closeBtn">
          <CloseIcon sx={{ height: "14px", width: "14px" }} />
        </IconButton>
        <img
          style={{ borderRadius: 12, minHeight: 200 }}
          src={data.image}
          alt="Wine club member dinner"
        />
        <Box className="d-flex justify-content-center align-items-center mt-3">
          <Typography variant="body1Regular">{data.title}</Typography>
        </Box>
      </StyledCard>
    </Modal>
  )
}

export default ShowImagePopup
