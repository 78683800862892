import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import AdditionalInfoCard from "./components/AdditionalInfoCard"
import GMAdditionalInfoTable from "./components/AdditionalInfoTable"

const GMAdditionalInformation = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Performance Management"
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title="Additional Information" breadCrumbs={breadCrumbs} />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <GMAdditionalInfoTable {...{ rows, columns }} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="4">
            <AdditionalInfoCard
              heading="Benchmarks Invites"
              data={benchmarksInviteData?.map((el) => ({
                ...el,
                field: el.field.toFixed(1)
              }))}
            />
          </Col>
          <Col lg="4">
            <AdditionalInfoCard
              heading="Actual Invites"
              data={actualInvitesData?.map((el) => ({
                ...el,
                field: el.field.toFixed(1)
              }))}
            />
          </Col>
          <Col lg="4">
            <AdditionalInfoCard heading="% Of Goal" data={percentGoalData} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default GMAdditionalInformation

const benchmarksInviteData = [
  {
    key: "Server",
    field: 9.6
  },
  {
    key: "TR",
    field: 36.0
  },
  {
    key: "Bartender",
    field: 3.0
  },
  {
    key: "Total",
    field: 48.6
  }
]

const actualInvitesData = [
  {
    key: "Server",
    field: 6.0
  },
  {
    key: "TR",
    field: 30.0
  },
  {
    key: "Bartender",
    field: 1.0
  },
  {
    key: "Total",
    field: 37.0
  }
]

const percentGoalData = [
  {
    key: "Server",
    field: "63%"
  },
  {
    key: "TR",
    field: "83%"
  },
  {
    key: "Bartender",
    field: "33%"
  },
  {
    key: "Total",
    field: "76.1%"
  }
]

const columns = [
  {
    id: "PM Candidates by Employee Class",
    label: "PM Candidates by Employee Class",
    minWidth: 150
  },
  {
    id: "% Of Goal",
    label: "% Of Goal",
    align: "center",
    minWidth: 100
  },
  {
    id: "Employees",
    label: "Employees",
    align: "center",
    minWidth: 100
  },
  {
    id: "Invites",
    label: "Invites",
    align: "center",
    minWidth: 70
  },
  {
    id: "Non WCM Guests",
    label: "Non WCM Guests",
    align: "center",
    minWidth: 100
  },
  {
    id: "Invite Rate %",
    label: "Invite Rate %",
    align: "center",
    minWidth: 80
  },
  {
    id: "Employee Class Invites",
    label: "Employee Class Invites",
    align: "center",
    minWidth: 80
  },
  {
    id: "Employee Class Group Non-WCM",
    label: "Employee Class Group Non-WCM",
    align: "center",
    minWidth: 70
  },
  {
    id: "Employee Class Benchmark Invite Rate %",
    label: "Employee Class Benchmark Invite Rate %",
    align: "center",
    minWidth: 80
  }
]

const rows = [
  {
    id: 1,
    employeeClass: "Servers",
    percentGoal: 63,
    employees: 5,
    invites: 6,
    nonWcmGuests: 480,
    inviteRate: 1.3,
    employeeClassInvites: 25,
    employeeClassGroupNonWcm: 1250,
    employeeClassBenchmark: 2.0
  },
  {
    id: 2,
    employeeClass: "TR",
    percentGoal: 83,
    employees: 5,
    invites: 30,
    nonWcmGuests: 360,
    inviteRate: 8.3,
    employeeClassInvites: 70,
    employeeClassGroupNonWcm: 700,
    employeeClassBenchmark: 10.0
  },
  {
    id: 3,
    employeeClass: "Bartenders",
    percentGoal: 33,
    employees: 2,
    invites: 1,
    nonWcmGuests: 200,
    inviteRate: 0.5,
    employeeClassInvites: 5,
    employeeClassGroupNonWcm: 330,
    employeeClassBenchmark: 1.5
  }
]
