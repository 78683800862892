import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import Card from "components/Card/Card"
import React from "react"

const Container = styled(Card)(({ theme }) => ({
  padding: 0,
  overflow: "hidden",
  backgroundColor: "transparent",
  "& .ctr1, .ctr2, .ctr3, .ctr4": {
    width: "100%",
    padding: 24
  },
  "& .ctr1": {
    backgroundColor: theme.palette.background.default
  },
  "& .ctr2": {
    backgroundColor: theme.palette.drawer.main
  },
  "& .ctr3": {
    border: `2px solid ${theme.palette.drawer.main}`,
    borderBottom: 0,
    paddingBottom: 0
  },
  "& .ctr4": {
    border: `2px solid ${theme.palette.drawer.main}`,
    borderTop: 0,
    borderBottomLeftRadius: "24px",
    borderBottomRightRadius: "24px"
  }
}))

const ListContainer = styled(Box)(() => ({
  width: "100%",
  marginTop: 24,
  "& .innerCtr": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 0px",
    borderBottom: "2px solid #3c3c3c",
    "& span": {
      opacity: 0.6
    }
  }
}))

const StyledTable = styled(Table)(() => ({
  "& .MuiTableCell-root": {
    backgroundColor: "transparent",
    "&.MuiTableCell-head": {
      border: 0
    }
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      borderBottom: "2px solid #323232"
    }
  }
}))

const CustomTable = ({ columns = [], rows = [] }) => {
  return (
    <TableContainer>
      <StyledTable stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                <Typography variant="body1Regular" style={{ opacity: 0.6 }}>
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {columns.map((column) => (
                <TableCell key={`innerColumn-${column.id}`} align={column.align}>
                  <Typography
                    variant="body1Regular"
                    style={{
                      opacity: column.opacity ?? 1,
                      color: column.color
                    }}>
                    {row[column.accessKey]}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

const WineClubPerformanceCard = () => {
  return (
    <Container>
      <Box className="ctr1">
        <Typography variant="h2">Regional Wine Club Performance</Typography>
        <ListContainer>
          {list1.map((item, index) => (
            <Box className="innerCtr" key={`${item.label + index}`}>
              <Typography variant="body1Regular">{item.label}</Typography>
              <Typography variant="body1Regular">{item.value}</Typography>
            </Box>
          ))}
        </ListContainer>
      </Box>
      <Box className="ctr2">
        <CustomTable {...list2} />
      </Box>
      <Box className="ctr3">
        <CustomTable {...list3} />
      </Box>
      <Box className="ctr4">
        <CustomTable {...list4} />
      </Box>
    </Container>
  )
}

export default WineClubPerformanceCard

const list1 = [
  {
    label: "Signups",
    value: "3223"
  },
  {
    label: "Upgrades",
    value: "280"
  }
]

const list2 = {
  rows: [
    {
      title: "Invite Rate",
      areaAverage: "8.2%",
      companyAverage: "9.8%",
      variance: "-16.7%"
    },
    {
      title: "Upgrade Rate",
      areaAverage: "1.4%",
      companyAverage: "1.6%",
      variance: "-12.3%"
    }
  ],
  columns: [
    {
      id: "0",
      minWidth: "200",
      accessKey: "title",
      label: "",
      opacity: 0.6
    },
    {
      id: "1",
      minWidth: "140",
      align: "right",
      accessKey: "areaAverage",
      label: "Area Average"
    },
    {
      id: "2",
      align: "right",
      minWidth: "140",
      accessKey: "companyAverage",
      label: "Company Average"
    },
    {
      id: "3",
      align: "right",
      minWidth: "140",
      accessKey: "variance",
      label: "Variance",
      color: "#CC4B37"
    }
  ]
}

const list3 = {
  rows: [
    {
      title: "Tampa Invite Rate",
      yourTop: "10.2%",
      rank: "5 of 50"
    },
    {
      title: "Tampa Upgrade Rate",
      yourTop: "2.5%",
      rank: "4 of 50"
    }
  ],
  columns: [
    {
      id: "0",
      minWidth: "200",
      accessKey: "title",
      label: "",
      opacity: 0.6
    },
    {
      id: "1",
      minWidth: "140",
      align: "right",
      accessKey: "yourTop",
      label: "Your Top"
    },
    {
      id: "2",
      align: "right",
      minWidth: "140",
      accessKey: "rank",
      label: "Rank"
    }
  ]
}

const list4 = {
  rows: [
    {
      title: "Tampa Invite Rate",
      yourBottom: "4.9%",
      rank: "48 of 50"
    },
    {
      title: "Tampa Upgrade Rate",
      yourBottom: "0.1%",
      rank: "50 of 50"
    }
  ],
  columns: [
    {
      id: "0",
      minWidth: "200",
      accessKey: "title",
      label: "",
      opacity: 0.6
    },
    {
      id: "1",
      minWidth: "140",
      align: "right",
      accessKey: "yourBottom",
      label: "Your Bottom"
    },
    {
      id: "2",
      align: "right",
      minWidth: "140",
      accessKey: "rank",
      label: "Rank"
    }
  ]
}
