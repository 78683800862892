import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import MonthlyCard from "components/WineOfTheMonthComponents/MonthlyCard"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { useDispatch, useSelector } from "react-redux"
import { appActions } from "redux/app/slice/app"
import { getWineMonthAction } from "redux/marketing/actions"
import AppButton from "components/StyledComponents/AppButton"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { useTheme } from "@mui/system"
import { Typography } from "@mui/material"

function AreaDirectorWineOfTheMonth() {
  const divisionNumber = 3
  const dispatch = useDispatch()
  const [index, setIndex] = React.useState(0)
  const [slicedArrays, setSlicedArrays] = useState([])
  const [breadCrumbs, setBreadcrumbs] = useState([])

  const theme = useTheme()

  const { listWineMonthData } = useSelector((store) => store?.marketing?.wineOfTheMonth || {})
  const { date } = useSelector((store) => store?.app?.filters ?? {})

  const styles = {
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    navigationBtn: {
      padding: "4px 4px",
      background: theme.palette.secondary.main,
      minWidth: "0px",
      marginRight: 10
    }
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Wine of the Month"
  }, [])

  function sliceArray() {
    const result = listWineMonthData.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / divisionNumber)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
    setSlicedArrays(result)
  }

  useEffect(() => {
    sliceArray()
  }, [listWineMonthData])

  useEffect(() => {
    dispatch(getWineMonthAction(date))
  }, [date])

  useEffect(() => {
    dispatch(appActions.initFilters())
  }, [])

  const componentArrays = slicedArrays.map((array, index) => {
    return (
      <Col lg="12" key={index}>
        <Row>
          {array.map((item, index) => {
            return (
              <Col xxl="4" xl="4" md="12" key={index} className="ps-0 pe-3">
                <MonthlyCard details={item} editable={false} />
              </Col>
            )
          })}
        </Row>
      </Col>
    )
  })

  return (
    <Container fluid className="ps-2 pe-2 ms-1 me-1">
      <Row>
        <Col lg="12" className="ps-0">
          <Header title="Wine Of The Month" breadCrumbs={breadCrumbs} />
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="ps-0 d-flex justify-content-end">
          <AppButton
            disabled={index === 0}
            onClick={() => setIndex(index - 1)}
            styles={styles.navigationBtn}>
            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
          </AppButton>
          <AppButton
            disabled={index > componentArrays.length - 2}
            onClick={() => setIndex(index + 1)}
            styles={styles.navigationBtn}>
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </AppButton>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mt-4">
          {listWineMonthData.length === 0 ? (
            <Typography variant="body1Regular" sx={{ color: "#FFFFFF" }}>
              No Records Found
            </Typography>
          ) : (
            <>{componentArrays[index]}</>
          )}
        </Col>
      </Row>
      {/* <Row>
        <Box sx={styles.btnContainer}>
          <ButtonContainer {...{ index, setIndex, componentArrays }} />
        </Box>
      </Row> */}
    </Container>
  )
}

export default AreaDirectorWineOfTheMonth
