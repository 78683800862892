import React, { useEffect, useRef, useState } from "react"
import {
  Backdrop,
  Chip,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from "@mui/material"
import AppPagination from "components/StyledComponents/AppPagination"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import TableNoRecords from "components/TableNoRecords"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { useTheme } from "@mui/system"
import { useDispatch } from "react-redux"
import { appActions } from "redux/app/slice/app"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { superAdminAction } from "redux/superAdmin/slice/user"
import { deleteRoleDetailsAction, getRoleDetailsAction } from "redux/superAdmin/actions"

const RoleListTable = ({
  columns = [],
  rows = [],
  setPage,
  currentPage,
  totalPage,
  handleEditRole
}) => {
  const themes = useTheme()
  const [order, setOrder] = useState(orderStat.DESC)
  const [anchorEl, setAnchorEl] = useState(false)
  const openMenu = Boolean(anchorEl)
  const dispatch = useDispatch()
  const rowId = useRef(null)
  const [deleteRole, setDeleteRole] = useState(false)

  const onOptionsClick = (e, id) => {
    e.stopPropagation()
    rowId.current = id
    setAnchorEl(e.currentTarget)
    dispatch(superAdminAction.setUserRoleID(id))
    dispatch(getRoleDetailsAction())
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  function handleSort() {
    if (order === orderStat.DESC) {
      setOrder("name")
      dispatch(appActions.setOrderBy("name"))
    } else {
      setOrder("-name")
      dispatch(appActions.setOrderBy("-name"))
    }
  }

  useEffect(() => {
    setOrder("-name")
    dispatch(appActions.setOrderBy("-name"))
  }, [])

  function handleDelete() {
    setDeleteRole(true)
  }

  function handleDeleteRole() {
    dispatch(deleteRoleDetailsAction())
    rowId.current = null
  }

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this role?`}
        description="  "
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteRole(false)}
        onDelete={() => handleDeleteRole()}
        open={deleteRole}
      />
      <Paper>
        <TableContainer sx={{ maxHeight: 700 }}>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}{" "}
                      {column?.isSorting && (
                        <span onClick={handleSort} style={{ cursor: "pointer" }}>
                          {order === "-name" ? (
                            <ArrowDownwardIcon fontSize="small"></ArrowDownwardIcon>
                          ) : (
                            <ArrowUpwardIcon fontSize="small"></ArrowUpwardIcon>
                          )}
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows?.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        <div className="d-flex flex-column">
                          <Typography variant="body1Regular">{row?.name ?? "-"}</Typography>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1Regular">{row?.user_count ?? 0}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          variant="filled"
                          color="secondary"
                          label={`${row?.is_active ? "Activated" : "Deactivated"}`}
                        />
                      </TableCell>
                      <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                        <span onClick={(e) => onOptionsClick(e, row?.id)}>
                          <MoreHorizIcon
                            sx={() => ({
                              height: "24px",
                              width: "24px"
                            })}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableNoRecords colSpan={8} label="No New Role" />
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
        <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={openMenu} onClick={handleMenuClose}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{
              sx: {
                marginTop: "15px",
                backgroundColor: themes.palette.background.default,
                borderRadius: "12px",
                "& .MuiList-root": {
                  backgroundColor: themes.palette.background.default,
                  width: "225px",
                  boxSizing: "border-box",
                  padding: "10px 0"
                }
              }
            }}>
            <StyledMenuItem onClick={() => handleEditRole()}>
              <Typography variant="body1Regular">Edit</Typography>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleDelete}>
              <Typography sx={{ color: themes.palette.red.main }} variant="body1Regular">
                Delete
              </Typography>
            </StyledMenuItem>
          </Menu>
        </Backdrop>
      </Paper>
      {rows?.length ? (
        <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
      ) : (
        ""
      )}
    </>
  )
}

export default RoleListTable

const orderStat = {
  ASC: "name",
  DESC: "-name"
}

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "sticky",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}))
