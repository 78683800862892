import React from "react"
import { Button } from "@mui/material"
import { useTheme } from "@mui/system"

function ButtonNav({ children, isActive = false, onPress = () => {}, style = {} }) {
  const themes = useTheme()

  return (
    <Button
      onClick={onPress}
      style={{
        background: `${
          isActive ? themes.palette.linearGradient.main : themes.palette.primary.main
        }`,
        opacity: `${isActive ? "1" : "0.5"}`,
        padding: "12px 26px 12px 26px",
        marginRight: "6px",
        textTransform: "none",
        ...style
      }}
      variant="contained">
      {children}
    </Button>
  )
}

export default ButtonNav
