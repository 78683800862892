import React from "react"

function YoutubeFrame({ videoLoaded, setVideoLoaded, url, style }) {
  const styles = {
    youtube: {
      borderRadius: "12px",
      height: "250px",
      display: videoLoaded ? "block" : "none",
      ...style
    }
  }
  return (
    <iframe
      src={`${url}`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      onLoad={() => setVideoLoaded(true)}
      style={styles.youtube}
    />
  )
}

export default YoutubeFrame
