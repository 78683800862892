import React, { Fragment, useState } from "react"
import { Box, IconButton, Typography, useTheme } from "@mui/material"
import styles from "../../trivia-contest/components/PendingQuestionTable/PendingQuestionTable.module.scss"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import EditIcon from "@mui/icons-material/Edit"
import EditSuggestedQuestion from "../../trivia-contest/components/EditSuggestedQuestion"
import { useDispatch } from "react-redux"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import { getViewTriviaQuestionsAction } from "redux/L&D/actions"

function Question({ question, status, index, isIncorrect = true }) {
  const boxStyling = { marginTop: "18px", marginBottom: "18px" }
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const dispatch = useDispatch()
  const theme = useTheme()

  const handleEditDialogOpen = () => {
    dispatch(learningDevelopmentActions.setSuggestedQuestionEdited(false))
    setOpenEditDialog(true)
  }

  const handleEditDialogClose = () => {
    setOpenEditDialog(false)
  }

  const onQuestionEdited = () => {
    dispatch(getViewTriviaQuestionsAction())
  }

  return (
    <Fragment>
      {openEditDialog ? (
        <EditSuggestedQuestion
          onClose={handleEditDialogClose}
          data={question}
          onQuestionEdited={onQuestionEdited}
        />
      ) : null}
      <Box className={styles.question} sx={boxStyling}>
        <Typography
          sx={{ margin: "0px 0px 4px 52px" }}
          color={theme.palette.secondary.main}
          variant="caption3"
          style={{ opacity: 1 }}
          className={styles.questionHeader}>
          {`${question?.category ?? "-"} | ${question?.level ?? "-"} | Duration - ${
            question?.duration + " seconds" ?? "-"
          } `}
          {!!(isIncorrect && (question?.incorrect_percentage > 0 || question?.incorrect_count)) && (
            <Typography
              variant="caption3"
              style={{ opacity: 1 }}
              className={styles.incorrect_analytics}
              pl={0.5}>
              {`${
                question?.incorrect_percentage > 0
                  ? `incorrect %: ${question?.incorrect_percentage}%`
                  : ""
              }${question?.incorrect_count ? ` | count: ${question?.incorrect_count} ` : ""}`}
            </Typography>
          )}
        </Typography>
        <Box className={styles.questionTitle}>
          <Typography variant="body1Regular">{index + 1}</Typography>
          <ArrowForwardIcon sx={{ marginLeft: "14px" }} />
          <Typography variant="h4" sx={{ marginLeft: "6px" }}>
            {question?.title}
          </Typography>
          {question?.is_suggested && status?.toLowerCase() === "pending" ? (
            <span className="ms-1" onClick={handleEditDialogOpen}>
              <IconButton
                sx={(theme) => ({
                  color: theme.palette.secondary.main
                })}>
                <EditIcon
                  sx={{
                    height: 20,
                    width: 20
                  }}
                />
              </IconButton>
            </span>
          ) : null}
        </Box>
        {question?.answer?.map((option, index) => (
          <Box
            key={option.id}
            className={`${styles.option} ${option?.is_correct ? styles.optionCorrect : ""}`}>
            <div className={styles.optionNumber}>
              <Typography variant="tag1">{(index + 10).toString(36).toUpperCase()}</Typography>
            </div>
            <Typography
              className={styles.optionName}
              sx={{ marginLeft: "10px" }}
              variant="body2Regular">
              {option?.answer}
            </Typography>
          </Box>
        ))}
      </Box>
    </Fragment>
  )
}

export default Question
