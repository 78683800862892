import Card from "components/Card/Card"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import ApprovePerformanceTable from "./components/Table/ApprovePerformanceTable"

const GMApprovePerformanceManagement = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [selectedEmployeesCount, setSelectedEmployeesCount] = useState(0)

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Approve Performance Management"
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title="Approve Performance Management Candidates" breadCrumbs={breadCrumbs} />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <ApprovePerformanceTable
                {...{
                  rows,
                  columns,
                  selectedEmployees,
                  setSelectedEmployees,
                  selectedEmployeesCount,
                  setSelectedEmployeesCount
                }}
                rows={rows}
                columns={columns}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default GMApprovePerformanceManagement

const columns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Employee Name",
    label: "Employee Name",
    minWidth: 220
  },
  {
    id: "% of Goal Achieved",
    label: "% of Goal Achieved",
    align: "center",
    minWidth: 75
  },
  {
    id: "Lost Opportunity",
    label: "Lost Opportunity",
    align: "center",
    minWidth: 75
  },
  {
    id: "Job Class",
    label: "Job Class",
    align: "center",
    minWidth: 90
  },
  {
    id: "Non WCM Guests",
    label: "Non WCM Guests",
    align: "center",
    minWidth: 80
  },
  {
    id: "Invite Rate",
    label: "Invite Rate",
    align: "center",
    minWidth: 90
  },
  {
    id: "Benchmark Invite Rate",
    label: "Benchmark Invite Rate",
    align: "center",
    minWidth: 80
  },
  {
    id: "Performance Gap",
    label: "Performance Gap",
    align: "center",
    minWidth: 80
  },
  {
    id: "Tenure (months)",
    label: "Tenure (months)",
    align: "center",
    minWidth: 80
  }
]

const rows = [
  {
    id: 1,
    rank: 1,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    percentGoal: 100,
    lostOpportunity: 1,
    jobClass: "Server",
    nonWCM: 100,
    inviteRate: 1.0,
    benchmarkInviteRate: 2.0,
    performanceGap: 1.0,
    tenure: 12
  },
  {
    id: 2,
    rank: 2,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    percentGoal: 90,
    lostOpportunity: 1,
    jobClass: "Server",
    nonWCM: 100,
    inviteRate: 1.0,
    benchmarkInviteRate: 2.0,
    performanceGap: 1.0,
    tenure: 12
  },
  {
    id: 3,
    rank: 3,
    name: "Robert Fox",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    percentGoal: 100,
    lostOpportunity: 1,
    jobClass: "Server",
    nonWCM: 100,
    inviteRate: 1.0,
    benchmarkInviteRate: 2.0,
    performanceGap: 1.0,
    tenure: 12
  },
  {
    id: 4,
    rank: 4,
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    percentGoal: 100,
    lostOpportunity: 1,
    jobClass: "Bartender",
    nonWCM: 100,
    inviteRate: 1.0,
    benchmarkInviteRate: 2.0,
    performanceGap: 1.0,
    tenure: 12
  },
  {
    id: 5,
    rank: 5,
    name: "Marvin McKinney",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    percentGoal: 100,
    lostOpportunity: 1,
    jobClass: "Server",
    nonWCM: 100,
    inviteRate: 1.0,
    benchmarkInviteRate: 2.0,
    performanceGap: 1.0,
    tenure: 12
  },
  {
    id: 6,
    rank: 6,
    name: "Jade Cooper",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    percentGoal: 100,
    lostOpportunity: 1,
    jobClass: "Server",
    nonWCM: 100,
    inviteRate: 1.0,
    benchmarkInviteRate: 2.0,
    performanceGap: 1.0,
    tenure: 12
  },
  {
    id: 7,
    rank: 7,
    name: "Kristin Watson",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    percentGoal: 0,
    lostOpportunity: 5,
    jobClass: "TR",
    nonWCM: 100,
    inviteRate: 5.0,
    benchmarkInviteRate: 10.0,
    performanceGap: 5.0,
    tenure: 12
  },
  {
    id: 8,
    rank: 8,
    name: "Esther Howard",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    percentGoal: 0,
    lostOpportunity: 5,
    jobClass: "TR",
    nonWCM: 100,
    inviteRate: 5.0,
    benchmarkInviteRate: 10.0,
    performanceGap: 5.0,
    tenure: 12
  },
  {
    id: 9,
    rank: 9,
    name: "Dianne Russell",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    percentGoal: 0,
    lostOpportunity: 5,
    jobClass: "TR",
    nonWCM: 100,
    inviteRate: 5.0,
    benchmarkInviteRate: 10.0,
    performanceGap: 5.0,
    tenure: 12
  },
  {
    id: 10,
    rank: 10,
    name: "Ralph Edwards",
    image: "https://randomuser.me/api/portraits/men/33.jpg",
    percentGoal: 0,
    lostOpportunity: 5,
    jobClass: "TR",
    nonWCM: 100,
    inviteRate: 5.0,
    benchmarkInviteRate: 10.0,
    performanceGap: 5.0,
    tenure: 12
  },
  {
    id: 11,
    rank: 11,
    name: "Guy Hawkins",
    image: "https://randomuser.me/api/portraits/men/34.jpg",
    percentGoal: 0,
    lostOpportunity: 5,
    jobClass: "TR",
    nonWCM: 100,
    inviteRate: 5.0,
    benchmarkInviteRate: 10.0,
    performanceGap: 5.0,
    tenure: 12
  },
  {
    id: 12,
    rank: 12,
    name: "Leslie Alexander",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    percentGoal: 0,
    lostOpportunity: 5,
    jobClass: "TR",
    nonWCM: 100,
    inviteRate: 5.0,
    benchmarkInviteRate: 10.0,
    performanceGap: 5.0,
    tenure: 12
  }
]
