import { useTheme } from "@mui/system"
import AppButton from "components/StyledComponents/AppButton"
import React from "react"
import { Container } from "react-bootstrap"
import "./error404.scss"
import ErrorImage from "../../assets/images/No-Data-vantahospitality.svg"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { userActions } from "redux/user/user"

function Error404() {
  const theme = useTheme()
  const history = useHistory()
  const userDate = location.pathname
  const dispatch = useDispatch()
  function handleHomepage() {
    if (userDate.includes(undefined)) {
      history.push("/auth/login/")
      dispatch(userActions.setUserData({}))
    } else {
      history.push("/")
    }
  }
  return (
    <div className="Container">
      <Container fluid>
        <div className="text-center logo">
          <img src={ErrorImage} alt="" />
        </div>
      </Container>
      <Container>
        <div className="text-center text-white pt-2">
          <h1 style={{ fontSize: 100 }}>404</h1>
          <h1>Page Not Found</h1>
        </div>
        <div className="text-center text-white pt-2">
          <p className=" mt-4 mb-4">Oops! We cannot find the page you are looking for. </p>
        </div>
      </Container>
      <Container>
        <div className="text-center text-white pt-4">
          <AppButton
            onClick={handleHomepage}
            styles={{
              border: "none",
              background: theme.palette.secondary.main,
              padding: "14px 40px"
            }}>
            Go Back To Homepage
          </AppButton>
        </div>
      </Container>
    </div>
  )
}

export default Error404
