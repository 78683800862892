import { createAction } from "@reduxjs/toolkit"

export const loginAction = createAction("createAction")
export const getRestaurantsAction = createAction("getRestaurantsAction")
export const getTriviaContestListAction = createAction("getTriviaContestListAction")
export const deleteTriviaContestItemAction = createAction("deleteTriviaContestItem")
export const createTriviaAction = createAction("createTrivia")
export const addTriviaQuestionsAction = createAction("addTriviaQuestionsAction")
export const uploadQuestionImageAction = createAction("uploadQuestionImage")
export const getTriviaCategoriesAction = createAction("getTriviaCategories")
export const getPendingQuestionsAction = createAction("getPendingQuestionsAction")
export const approveQuestionAction = createAction("approveQuestionAction")
export const getTriviaCorrectAnswersAction = createAction("getTriviaCorrectAnswersAction")
export const getTriviaIncorrectAnswersAction = createAction("getTriviaIncorrectAnswersAction")
export const editSuggestedQuestionAction = createAction("editSuggestedQuestionAction")

export const uploadSuggestedQuestionImageAction = createAction(
  "LD_uploadSuggestedQuestionImageAction"
)

export const getViewTriviaContestDetailsAction = createAction(
  "LD_getViewTriviaCOntestDetailsAction"
)

export const getViewTriviaQuestionsAction = createAction("getViewTriviaQuestionsAction")

export const setQuestionStatusViewTriviaAction = createAction("setQuestionStatusViewTriviaAction")

export const getEditTriviaContestDetailsAction = createAction(
  "LD_getEditTriviaCOntestDetailsAction"
)

export const getEditTriviaQuestionsAction = createAction("getEditTriviaQuestionsAction")
export const getLikeDislikeListAction = createAction("getLikeDislikeListAction")

export const setQuestionStatusEditTriviaAction = createAction("setQuestionStatusEditTriviaAction")

export const editTriviaContestAction = createAction("LD_editTriviaContestAction")

export const getLeaderBoardMerchantAction = createAction("getLeaderBoardMerchantAction")

export const getWinnerListMerchantAction = createAction("getWinnerListMerchantAction")
export const getLeaderBoardUserListAction = createAction("getLeaderBoardUserListAction")

export const getResturantListByTriviaAction = createAction("getResturantListByTriviaAction")

export const getCorrectAnswersByTriviaAction = createAction("getCorrectAnswersByTriviaAction")

export const getIncorrectAnswersByTriviaAction = createAction("getIncorrectAnswersByTriviaAction")

export const getTopSuggestorsByTriviaAction = createAction("getTopSuggestorsByTriviaAction")

export const getTopTriviaAnalyticsAction = createAction("getTopTriviaAnalyticsAction")

export const editTriviaDeleteQuestionAction = createAction("editTriviaDeleteQuestionAction")

export const getCalendarDataAction = createAction("getCalendarDataAction")

export const revertAllLnD = createAction("revertAllLnD")
export const getRestaurantsListAction = createAction("getRestaurantsListAction")
export const getTop5RanksTriviaAction = createAction("getTop5RanksTriviaAction")
export const uploadQuestionBulkAction = createAction("uploadQuestionBulkAction")
export const deleteMultipleTriviaContestAction = createAction("deleteMultipleTriviaContestAction")
export const getMenuDinnerLunchAction = createAction("getMenuDinnerLunchAction")
export const getWineMenuItemAction = createAction("getWineMenuItemAction")
export const getBarMenuItemAction = createAction("getBarMenuItemAction")
export const generateQuestionAction = createAction("generateQuestionAction")
export const getQuestionsBankAction = createAction("getQuestionsBankAction")
export const addToQuestionBankAction = createAction("addToQuestionBankAction")
export const addManualQuestionToBankAction = createAction("addManualQuestionToBankAction")
export const getTriviaMonthListAction = createAction("getTriviaMonthListAction")
export const addQuestionToTriviaAction = createAction("addQuestionToTriviaAction")
export const getAutoSuggestQuestionsAction = createAction("getAutoSuggestQuestionsAction")
export const flagQuestionAction = createAction("flagQuestionAction")
export const getFlaggedQuestionsAction = createAction("getFlaggedQuestionsAction")
export const getReasonCodesAction = createAction("getReasonCodesAction")
export const getMenuDinnerLunchSearchAction = createAction("getMenuDinnerLunchSearchAction")
export const deleteQuestionFromBankAction = createAction("deleteQuestionFromBankAction")
export const getGenerateQuestionsSpiritsAction = createAction("getGenerateQuestionsSpiritsAction")
export const getAllQuestionsByTriviaAction = createAction("getAllQuestionsByTriviaAction")
export const deleteQuestionFromAutoSuggestAction = createAction(
  "deleteQuestionFromAutoSuggestAction"
)
export const getIncorrectTriviaContestListAction = createAction(
  "getIncorrectTriviaContestListAction"
)
export const getIncorrectTriviaDurationAction = createAction("getIncorrectTriviaDurationAction")
export const getCategoryCountAction = createAction("getCategoryCountAction")
export const getWineBottleMenuItemAction = createAction("getWineBottleMenuItemAction")
