import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Formik } from "formik"

import { validationSchemaAdminUser } from "helpers/validationSchema"
import { formatDate } from "helpers/functions"
import { useDispatch } from "react-redux"
import { EditFrontlineUsersDataAction, getFrontlineUsersDataAction } from "redux/superAdmin/actions"
import { useSelector } from "react-redux"
import AdminUserForm from "../adminUserForm/index"
import { useHistory } from "react-router-dom"

const initData = {
  fullName: "",
  email: "",
  role: "",
  contactNumber: "",
  address: "",
  hire_date: null,
  reportingManager: ""
}

const EditAdminUser = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialFormValue, setInitialFormValue] = useState(initData)

  const { frontlineUserData: adminUserData } = useSelector(
    (store) => store?.superAdmin?.frontlineUser ?? {}
  )
  const history = useHistory()
  const dispatch = useDispatch()

  function initFormData() {
    const initData = {
      fullName: adminUserData?.full_name,
      email: adminUserData?.email,
      role: adminUserData?.emp_job_class,
      contactNumber: adminUserData?.phone_number || "",
      address: adminUserData?.address || "",
      hireDate: adminUserData?.hire_date ? new Date(adminUserData?.hire_date) : null,
      reportingManager: adminUserData?.reporting_manager ?? ""
    }
    setInitialFormValue(initData)
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    initFormData()
  }, [adminUserData])

  function handleSubmit(values) {
    const payload = {
      full_name: values?.fullName,
      email: values?.email,
      mobile: values?.contactNumber,
      address: values?.address,
      emp_job_class: values?.role,
      hire_date: values?.hireDate ? formatDate(new Date(values?.hireDate)) : null,
      reporting_manager: values?.reportingManager,
      cms_access: true,
      analytics_access: true,
      raffle_access: adminUserData?.raffle_access ?? false
    }
    const handleRoute = () => {
      history.replace("/super-admin/dashboard/admin-user-list")
    }

    dispatch(EditFrontlineUsersDataAction({ payload, onClose: handleRoute }))
  }

  useEffect(() => {
    dispatch(getFrontlineUsersDataAction())
  }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Edit Admin User" breadCrumbs={breadCrumbs}></Header>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaAdminUser}
        initialValues={initialFormValue}>
        {(formik) => <AdminUserForm isEditContent={true} formik={formik}></AdminUserForm>}
      </Formik>
    </>
  )
}

export default EditAdminUser
