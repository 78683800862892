import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React from "react"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    borderBottom: `2px solid #373737`,
    backgroundColor: theme.palette.background.default,
    boxSizing: "border-box",
    "&:nth-last-of-type(2)": {
      paddingRight: 0
    },
    "&:first-of-type": {
      padding: "12px 12px 12px 0px"
    },
    "&:nth-last-of-type(1)": {
      padding: "12px 12px 12px 0px"
    }
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root:nth-last-of-type(-n+2)": {
      "& .MuiTableCell-root": {
        borderBottom: "none"
      }
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .rank": {
    height: 30,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${theme.palette.green.main}`,
    borderRadius: 4
  },
  "& .userImage": {
    height: 32,
    width: 32,
    borderRadius: "50%",
    margin: "0px 12px"
  },
  "& .spacer": {
    width: 0,
    borderBottom: "none"
  }
}))

const TableFooter = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 6,
  marginBottom: 10,
  "& .MuiTableCell-root": {
    backgroundColor: "transparent",
    "&:first-of-type": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6
    },
    "&:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6
    }
  }
}))

const TopRankersTable = ({ data = [], type, average }) => {
  return (
    <TableContainer>
      <CustomTable stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className="spacer" />

            <TableCell>
              <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                Employee Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                Restaurant
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                Invite Rate
              </Typography>
            </TableCell>
            <TableCell className="spacer" />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((employee, index) => (
            <TableRow key={index}>
              <TableCell className="spacer" />

              <TableCell>
                <Box className="d-flex align-items-center">
                  <Box className="rank">{index + 1}</Box>
                  <img src={employee.image} alt="" className="userImage" />
                  <Typography variant="body2Regular" sx={{ opacity: 0.6 }}>
                    {employee.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2Regular">{employee.restaurant}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2Regular">{employee.inviteRate}</Typography>
              </TableCell>
              <TableCell className="spacer" />
            </TableRow>
          ))}
          <TableFooter>
            <TableCell className="spacer" />

            <TableCell>
              <Typography variant="body2Regular" sx={{ opacity: 0.6 }}>
                {`Average ${type}s`}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2Regular">Nationwide</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2Regular">{average}</Typography>
            </TableCell>
            <TableCell className="spacer" />
          </TableFooter>
        </TableBody>
      </CustomTable>
    </TableContainer>
  )
}

export default TopRankersTable
