import React, { Fragment, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Header from "components/Header/Header"
import { useState } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
// import LineCharts from "components/LineCharts/LineCharts";
import PerformanceTrackingGraph from "components/PerformanceTrackingGraph"
import Card from "components/Card/Card"

const data2 = [
  {
    day: "Oct 01",
    TRTeam: 11.6,
    MentorTRTeam: 1.0
  },
  {
    day: "oct 02",
    TRTeam: 11.8,
    MentorTRTeam: 4.0
  },
  {
    day: "oct 15",
    TRTeam: 12.2,
    MentorTRTeam: 7.0
  },
  {
    day: "oct 22",
    TRTeam: 12.6,
    MentorTRTeam: 10.5
  },
  {
    day: "oct 29",
    TRTeam: 13,
    MentorTRTeam: 14
  },
  {
    day: "Nov 05",
    TRTeam: 13.0,
    MentorTRTeam: 15.0
  },
  {
    day: "Nov 12",
    TRTeam: 14.0,
    MentorTRTeam: 17.0
  },
  {
    day: "Nov 19",
    TRTeam: 15.0,
    MentorTRTeam: 18.5
  }
]

function tracking() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Performance tracking"
  }, [])

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title="Tracking" breadCrumbs={breadCrumbs} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Card>
              <PerformanceTrackingGraph
                position="top"
                height={650}
                alignLegend="end"
                data={data2}
                id={1}></PerformanceTrackingGraph>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default tracking
