import { createSlice } from "@reduxjs/toolkit"
import { revertAllSuperAdmin } from "../actions/user"

const initialState = {
  totalUserCount: 0,
  totalActiveUser: 0,
  frontlineUser: {
    id: null,
    frontlineUserData: {},
    frontlineUserList: {
      totalPages: 1,
      data: []
    }
  },
  notifications: {
    id: null,
    notificationData: {},
    notificationList: {
      totalPages: 1,
      data: []
    },
    notificationImageId: ""
  },
  adminUsers: {
    id: null,
    isFormSubmitted: false,
    adminUserData: {},
    adminUserList: {
      totalPages: 1,
      data: {}
    }
  },
  versionListData: {
    totalPages: 1,
    data: {}
  },
  versionData: {},
  versionId: null,
  dashboardStats: {},
  giftCards: {
    id: null,
    giftCardData: {},
    giftCardList: {
      totalPages: 1,
      data: []
    },
    giftCardImageId: ""
  },
  gamificationData: {},
  eventList: [],
  eventID: null,
  eventDetails: {},
  menuImageList: [],
  menuImages: [],
  downloadMenuTemplate: "",
  menuFields: [],
  userRole: [],
  userRoleList: [],
  userRoleId: null,
  roleDetails: {}
}

const superAdmin = createSlice({
  name: "superAdmin",
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(revertAllSuperAdmin, () => initialState),
  reducers: {
    setFrontlineUserID: (state, action) => {
      state.frontlineUser.id = action.payload
    },
    setAdminUserID: (state, action) => {
      state.adminUsers.id = action.payload
    },
    setFrontlineUserList: (state, action) => {
      state.frontlineUser.frontlineUserList = action.payload
    },
    setFrontlineUserData: (state, action) => {
      state.frontlineUser.frontlineUserData = action.payload
    },
    setTotalUserCount: (state, action) => {
      state.totalUserCount = action.payload
    },
    setTotalActiveUser: (state, action) => {
      state.totalActiveUser = action.payload
    },
    setAdminUserList: (state, action) => {
      state.adminUsers.adminUserList = action.payload
    },
    setAdminUserData: (state, action) => {
      state.adminUsers.adminUserData = action.payload
    },
    setDashboardStats: (state, action) => {
      state.dashboardStats = action.payload
    },
    setVersionListData: (state, action) => {
      state.versionListData = action.payload
    },
    setVersionID: (state, action) => {
      state.versionId = action.payload
    },
    setVersiondata: (state, action) => {
      state.versionData = action.payload
    },
    setNotificationList: (state, action) => {
      state.notifications.notificationList = action.payload
    },
    setNotificationID: (state, action) => {
      state.notifications.id = action.payload
    },
    setNotificationData: (state, action) => {
      state.notifications.notificationData = action.payload
    },
    setNotificationImageId: (state, action) => {
      state.notifications.notificationImageId = action.payload
    },
    setGiftCardList: (state, action) => {
      state.giftCards.giftCardList = action.payload
    },
    setGiftCardID: (state, action) => {
      state.giftCards.id = action.payload
    },
    setGiftCardData: (state, action) => {
      state.giftCards.giftCardData = action.payload
    },
    setGiftCardImageId: (state, action) => {
      state.giftCards.giftCardImageId = action.payload
    },
    setGamificationData: (state, action) => {
      state.gamificationData = action.payload
    },
    setEventList: (state, action) => {
      state.eventList = action.payload
    },
    setEventID: (state, action) => {
      state.eventID = action.payload
    },
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload
    },
    setMenuImageList: (state, action) => {
      state.menuImageList = action.payload
    },
    setMenuImage: (state, action) => {
      state.menuImages[action.payload.index] = action.payload.id
    },
    setDownloadMenuTemplate: (state, action) => {
      state.downloadMenuTemplate = action.payload
    },
    setMenuFields: (state, action) => {
      state.menuFields = action.payload
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload
    },
    setUserRoleList: (state, action) => {
      state.userRoleList = action.payload
    },
    setUserRoleID: (state, action) => {
      state.userRoleId = action.payload
    },
    setRoleDetails: (state, action) => {
      state.roleDetails = action.payload
    }
  }
})

export default superAdmin
export const superAdminAction = superAdmin.actions
