import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import styles from "../menuImage.module.scss"
import { FieldArray, Formik } from "formik"
import { validationSchemaMenuImage } from "helpers/validationSchema"
import { addMenuImageAction } from "redux/superAdmin/actions"
import { useDispatch, useSelector } from "react-redux"
import MenuImageForm from "./menuImageForm"

const EditMenuImage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const { menuImageList, menuImages } = useSelector((store) => store.superAdmin)

  const handleCancel = () => {
    history.goBack()
  }

  const handleSubmit = (values) => {
    const payload = {
      menu_categories:
        values?.menuimage?.length === 0 || values?.menuimage?.[0].name === ""
          ? null
          : values?.menuimage?.map((el, index) => ({
              name: el.name,
              image: menuImages?.[index] ? menuImages?.[index] : el?.image ? el?.image?.id : null
            }))
    }

    const onClose = () => {
      history.replace("/super-admin/dashboard/menu-image")
    }
    dispatch(addMenuImageAction({ payload: payload, onClose: onClose }))
  }

  const resetFormikData = () => {
    if (menuImageList?.length < 1) {
      setInitialValues({
        menuimage: [
          {
            name: "",
            image: null
          },
          {
            name: "",
            image: null
          },
          {
            name: "",
            image: null
          },
          {
            name: "",
            image: null
          }
        ]
      })
    } else {
      setInitialValues({
        menuimage: menuImageList.slice(0, 4)?.map((el) => ({
          id: el.id,
          name: el.name,
          image: el.icon
            ? {
                id: el?.image,
                url: el.icon
              }
            : ""
        }))
      })
    }
  }

  useEffect(() => {
    resetFormikData()
  }, [menuImageList])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Edit Menu Image"
  }, [])
  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchemaMenuImage}
        onSubmit={handleSubmit}>
        {(formik) => (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Header title="Menu Image" breadCrumbs={breadCrumbs}>
                  <AppButton onClick={handleCancel} className={styles.cancelButton}>
                    Cancel
                  </AppButton>
                  <AppButton onClick={formik.handleSubmit} className={styles.saveButton}>
                    Save
                  </AppButton>
                </Header>
              </Grid>
            </Grid>
            <Grid container item xs={12} lg={11}>
              <FieldArray
                name={"menuimage"}
                render={() =>
                  formik.values?.menuimage?.map((category, index) => {
                    return <MenuImageForm formik={formik} index={index} key={`menuimage${index}`} />
                  })
                }
              />
            </Grid>
          </>
        )}
      </Formik>
    </Fragment>
  )
}

export default EditMenuImage
