import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useTheme
} from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import styles from "./menu.module.scss"
import AppButton from "components/StyledComponents/AppButton"
import trashIcon from "assets/icons/trash.svg"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpwardRounded"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownwardRounded"
import AddNewFields from "./components/AddNewFields"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteMenuFieldAction,
  downloadMenuTemplateAction,
  editDynamicMenuFieldsAction,
  getDynamicMenuFieldsAction,
  getMenuImageAction
} from "redux/superAdmin/actions"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"

const StyledFormLabel = styled(FormControlLabel)(() => ({
  "& span": {
    fontFamily: "Inter-Regular !important"
  }
}))

const MenuManagement = () => {
  const theme = useTheme()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const { downloadMenuTemplate, menuImageList, menuFields } = useSelector(
    (store) => store?.superAdmin || []
  )
  const [selectedMenu, setSelectedMenu] = useState("Dinner")
  const [selectedMenuId, setSelectedMenuId] = useState(menuImageList?.[0]?.id)
  const [menuField, setMenuField] = useState(menuFields)
  const [newField, setNewField] = useState(false)
  const [modal, setDeleteModal] = useState(false)
  const [fieldId, setfieldId] = useState()
  const [barCategory, setBarCategory] = useState("Cocktails")

  const handleMenuCategory = (name, id) => {
    setSelectedMenu(name)
    setSelectedMenuId(id)
    setMenuField(menuFields)
  }

  const handleDelete = (id) => {
    setfieldId(id)
    setDeleteModal(true)
  }

  const handleMoveUp = (index) => {
    const itemIndex = menuField.findIndex((item) => item.index === index)
    if (itemIndex > 0) {
      const newMenuField = [...menuField]
      const itemToMoveUp = { ...newMenuField[itemIndex], index: newMenuField[itemIndex].index - 1 }
      const itemToMoveDown = {
        ...newMenuField[itemIndex - 1],
        index: newMenuField[itemIndex - 1].index + 1
      }

      newMenuField[itemIndex - 1] = itemToMoveUp
      newMenuField[itemIndex] = itemToMoveDown

      const payload = {
        fields: newMenuField
      }
      dispatch(
        editDynamicMenuFieldsAction({ payload: payload, category: selectedMenu, barCategory })
      )
    }
  }

  const handleMoveDown = (index) => {
    const itemIndex = menuField.findIndex((item) => item.index === index)
    if (itemIndex < menuField.length - 1) {
      const newMenuField = [...menuField]
      const itemToMoveDown = {
        ...newMenuField[itemIndex],
        index: newMenuField[itemIndex].index + 1
      }
      const itemToMoveUp = {
        ...newMenuField[itemIndex + 1],
        index: newMenuField[itemIndex + 1].index - 1
      }
      newMenuField[itemIndex + 1] = itemToMoveDown
      newMenuField[itemIndex] = itemToMoveUp
      const payload = {
        fields: newMenuField
      }
      dispatch(
        editDynamicMenuFieldsAction({ payload: payload, category: selectedMenu, barCategory })
      )
    }
  }

  const handleAddField = () => {
    setNewField(true)
  }

  function handleCloseModal() {
    setDeleteModal(false)
  }

  function handleDeleteField() {
    dispatch(deleteMenuFieldAction({ id: fieldId, category: selectedMenu, barCategory }))
  }

  const handleChange = (event) => {
    setBarCategory(event.target.value)
  }

  useEffect(() => {
    dispatch(getMenuImageAction())
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Menu Management"
  }, [])

  useEffect(() => {
    setNewField(false)
    dispatch(downloadMenuTemplateAction({ selectedMenu, sub_category: barCategory }))
    dispatch(getDynamicMenuFieldsAction({ category: selectedMenu, sub_category: barCategory }))
  }, [selectedMenu, barCategory])

  useEffect(() => {
    setMenuField(menuFields)
  }, [menuFields, barCategory])

  // Filter out the 'Spirits' item
  const filteredMenuImageList = menuImageList?.filter((item) => item.name !== "Spirits")

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this field?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleDeleteField()}
        open={modal}
      />
      <Grid container>
        <Grid item xs={12}>
          <Header title="Menu Management" breadCrumbs={breadCrumbs} />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={4}>
          <Grid
            item
            sx={{ background: theme.palette.primary.main, borderRadius: "12px", padding: "24px" }}>
            <Typography variant="body1Regular" sx={{ color: theme.palette.text.secondary }}>
              Select Category
            </Typography>
            <Grid item mt={3}>
              {filteredMenuImageList?.map((itm, index) => (
                <Grid
                  item
                  key={index}
                  className={styles.category_grid}
                  sx={{
                    backgroundColor:
                      selectedMenu === itm?.name ? theme.palette.primary.hover : "transparent",
                    borderColor:
                      selectedMenu === itm?.name ? "transparent" : theme.palette.secondary.main
                  }}
                  onClick={() => handleMenuCategory(itm?.name, itm?.id)}>
                  <Typography>{itm?.name}</Typography>
                  {selectedMenu === "Bar Menu" && itm?.name === "Bar Menu" ? (
                    <Box className="dateFieldContainer w-100 mt-2">
                      <FormControl variant="filled">
                        <RadioGroup
                          className="d-flex flex-row px-4"
                          name="controlled-radio-buttons-group"
                          value={barCategory}
                          onChange={(e) => {
                            handleChange(e)
                          }}>
                          <StyledFormLabel
                            value="Cocktails"
                            control={<Radio color="secondary" />}
                            label="Cocktails"
                            sx={{ marginRight: "48px" }}
                          />
                          <StyledFormLabel
                            value="Beer"
                            control={<Radio color="secondary" />}
                            label="Beer"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ) : null}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid item className={styles.arrowMenu}>
            <Grid item className={styles.arrowMenuContent}>
              {menuField?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  key={index}
                  className={styles.itemLabel}>
                  <Grid item>
                    <Typography variant="body1Regular" sx={{ color: theme.palette.text.secondary }}>
                      {item?.fieldName}
                    </Typography>
                  </Grid>
                  {index > 2 && (
                    <Grid item xs={3} container justifyContent="flex-end" columnGap={1}>
                      <img
                        onClick={() => handleDelete(item.id)}
                        style={{ cursor: "pointer" }}
                        src={trashIcon}
                        alt=""
                      />
                      {index !== 3 && (
                        <ArrowUpwardIcon
                          fontSize="medium"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleMoveUp(item.index)}
                        />
                      )}
                      {index !== menuField?.length - 1 && (
                        <ArrowDownwardIcon
                          fontSize="medium"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleMoveDown(item.index)}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              ))}
              {!!newField && (
                <Grid item mt={2} className={styles.newField}>
                  <AddNewFields
                    currentIndex={menuField?.length}
                    categoryId={selectedMenuId}
                    category={selectedMenu}
                    setNewField={setNewField}
                    barCategory={barCategory}
                  />
                </Grid>
              )}
              <Grid item xs={12} mt={2}>
                {!newField && (
                  <Typography
                    variant="largeText"
                    onClick={handleAddField}
                    className={styles.addNewField}>
                    Add New Field
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container justifyContent="flex-end" mt={5}>
              <a href={downloadMenuTemplate} target="_blank" rel="noreferrer">
                <AppButton>Download Template</AppButton>
              </a>
            </Grid>
            <Grid item className={styles.arrow}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default MenuManagement
