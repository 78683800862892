import { Box, List, ListItem, Typography } from "@mui/material"
import Card from "components/Card/Card"
import ListWith2Items from "components/List/ListBodyWith2Items"
import React from "react"

const DataCard = ({ data }) => {
  return (
    <Card sx={{ padding: "2px" }}>
      <Box sx={{ padding: "22px 22px 12px" }}>
        <List sx={{ padding: 0 }}>
          <ListItem sx={{ padding: "0px 0px 12px" }}>
            <Typography variant="h2">{data.heading}</Typography>
          </ListItem>
          <ListWith2Items tableData={data.list} />
        </List>
      </Box>
    </Card>
  )
}

export default DataCard
