import React from "react"
import { Fragment } from "react"
import { Typography } from "@mui/material"
import { Col, Container } from "react-bootstrap"
import { Row } from "react-bootstrap"
import "./MonthlySwag.scss"
import WinnerList from "components/WinnerList/WinnerList"
import moment from "moment"
import avatar from "assets/images/user/avatarUser.svg"
function MonthlySwag({ merchantWinnerList = [] }) {
  const swagData = merchantWinnerList[0]?.monthly_swag_winner ?? []

  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <Typography className="text-center mb-4" variant="h2">
            Monthly Swag Winners
          </Typography>
        </Col>
      </Row>
      <Container fluid>
        <Row>
          {swagData.length <= 0 ? (
            <Col lg="12" className="swagBox d-flex align-items-center justify-content-center">
              <Typography
                variant="emphasisText"
                className="text-uppercase"
                style={{ opacity: 0.6 }}>
                No Records Found
              </Typography>
            </Col>
          ) : (
            swagData?.map((prizeData, index) => {
              if (!prizeData.month) {
                return (
                  <Col
                    lg="4"
                    className="swagBox d-flex align-items-center justify-content-center"
                    key={index}>
                    <Typography
                      variant="emphasisText"
                      className="text-uppercase"
                      style={{ opacity: 0.6 }}>
                      No Records Found
                    </Typography>
                  </Col>
                )
              }
              return (
                <Col key={prizeData?.id} lg="4" className="swagBox">
                  <Row className="text-center mb-4">
                    <Typography
                      variant="emphasisText"
                      className="mb-2 text-uppercase"
                      style={{ color: "#AD916F" }}>
                      {moment(prizeData?.month).format("MMMM")} Winners
                    </Typography>
                    <Typography
                      variant="emphasisText"
                      className="text-uppercase"
                      style={{ opacity: 0.6 }}>
                      Cooper’s Hawk Branded {prizeData?.prize}
                    </Typography>
                  </Row>
                  <div className=" winnerBox">
                    <div className="clothBox me-5">
                      {prizeData?.image !== null ? (
                        <img src={prizeData?.image} alt="" />
                      ) : (
                        <Typography
                          variant="emphasisText"
                          className="text-uppercase"
                          style={{ opacity: 0.6 }}>
                          No swag image found
                        </Typography>
                      )}
                    </div>
                    <div className="winners">
                      {merchantWinnerList?.map((user) => {
                        if (prizeData?.month || user[prizeData?.month]) {
                          return user[prizeData?.month]?.map((field) => {
                            return (
                              <WinnerList
                                key={field?.user?.id}
                                userImg={field?.image ? field?.image : avatar}
                                userName={field?.user?.name}></WinnerList>
                            )
                          })
                        }
                      })}
                    </div>
                  </div>
                </Col>
              )
            })
          )}
        </Row>
      </Container>
    </Fragment>
  )
}

export default MonthlySwag
