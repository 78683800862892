import React, { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col, Container, Row } from "react-bootstrap"
import { Grid, Typography, InputAdornment, useTheme } from "@mui/material"
import CreateIcon from "@mui/icons-material/Create"
import SearchIcon from "@mui/icons-material/Search"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import DMVNavBar from "components/DMVNavBar"
import DMVMenuListContainer from "components/DMVMenuListContainer"
import SpiritsSubMenuBar from "components/SpiritsSubMenuBar"
import DMVSpiritMenuListContainer from "components/DMVSpiritMenuListContainer"
import DMV_SpiritSummary from "components/DMVSpiritSummary"
import SearchTextField from "components/StyledComponents/SearchTextField"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import {
  getBeverageAction,
  getDMVWineRegionListAction,
  getSpiritAction
} from "redux/DMV/actions/user"
import classes from "./dmvmenu.module.scss"
import { useDebounce } from "hooks/utils"

const menuText = {
  WINE_REGION: "wine_region",
  BEVERAGE: "beverage",
  SPIRITS: "spirits"
}

const DMVMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const { dmvMenuLink, wineRegionData, beverageData, menuCategory, spiritData, SpiritSubMenu } =
    useSelector((state) => state.dmv)

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Explore Menu"
  }, [])

  useEffect(() => {
    const actions = {
      [menuText.WINE_REGION]: getDMVWineRegionListAction,
      [menuText.BEVERAGE]: getBeverageAction,
      [menuText.SPIRITS]: getSpiritAction
    }

    if (actions[dmvMenuLink]) {
      dispatch(actions[dmvMenuLink]())
    }
  }, [dmvMenuLink, dispatch])

  const handleRouting = () => {
    history.push("/dmv/dashboard/explore-menu/edit")
  }

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  const filterResults = (searchQuery, results, menuType) => {
    const trimmedQuery = searchQuery.trim().toLowerCase()
    if (!trimmedQuery) return results

    const isSpiritsMenu = menuType === menuText.SPIRITS
    const listKey = isSpiritsMenu ? "spirit_list" : "food_list"
    const categoryKey = isSpiritsMenu ? "spirit_category" : "food_category"

    const searchableFields = [
      "name",
      "place",
      "most_famous_wines",
      "white_grape_varietals",
      "red_grape_varietals",
      "terroir",
      "famous_vineyards",
      "description",
      "origin_name",
      "methodology",
      "taste_profile",
      "how_its_made",
      "unique_facts"
    ]

    return results
      ?.map((period) => {
        const filteredList = period[listKey]?.filter((item) => {
          const fieldMatches = searchableFields.some((field) => {
            return (
              item[field] &&
              typeof item[field] === "string" &&
              item[field].toLowerCase().includes(trimmedQuery)
            )
          })

          const uniqueFactsMatch =
            Array.isArray(item.unique_facts) &&
            item.unique_facts.some(
              (fact) =>
                typeof fact.fact === "string" && fact.fact.toLowerCase().includes(trimmedQuery)
            )

          const ingredientMatch =
            Array.isArray(item.ingredient) &&
            item.ingredient.some(
              (ingredient) =>
                typeof ingredient.name === "string" &&
                ingredient.name.toLowerCase().includes(trimmedQuery)
            )

          return fieldMatches || uniqueFactsMatch || ingredientMatch
        })

        return filteredList?.length > 0 ? { ...period, [listKey]: filteredList } : null
      })
      .filter(Boolean)
      .sort((a, b) => {
        const categoryA = a[categoryKey]?.toLowerCase() || ""
        const categoryB = b[categoryKey]?.toLowerCase() || ""

        if (categoryA === trimmedQuery) return -1
        if (categoryB === trimmedQuery) return 1
        if (categoryA.startsWith(trimmedQuery) && !categoryB.startsWith(trimmedQuery)) return -1
        if (!categoryA.startsWith(trimmedQuery) && categoryB.startsWith(trimmedQuery)) return 1
        return categoryA.localeCompare(categoryB)
      })
  }

  const filteredData = useMemo(() => {
    const dataMap = {
      [menuText.WINE_REGION]: wineRegionData,
      [menuText.BEVERAGE]: beverageData,
      [menuText.SPIRITS]: spiritData
    }
    return filterResults(debouncedSearchTerm, dataMap[dmvMenuLink], dmvMenuLink)
  }, [debouncedSearchTerm, dmvMenuLink, wineRegionData, beverageData, spiritData])

  const renderMenuContent = () => {
    if (filteredData?.length === 0) {
      return <Typography>No Data Found</Typography>
    }

    const MenuComponent =
      dmvMenuLink === menuText.SPIRITS ? DMVSpiritMenuListContainer : DMVMenuListContainer

    return filteredData.map((menuItem, idx) => <MenuComponent data={menuItem} key={idx} />)
  }

  return (
    <>
      <Row>
        <Col lg="12">
          <Header title="Menu" breadCrumbs={breadCrumbs} back={true}>
            <AppButton onClick={handleRouting}>
              <CreateIcon
                sx={(theme) => ({ color: theme.palette.secondary.main })}
                className="me-2"
              />
              Edit Menu
            </AppButton>
          </Header>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="12">
          <DMVNavBar activeTab={dmvMenuLink} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="4">
          <SearchTextField
            id="search"
            name="search"
            value={searchValue}
            onChange={handleSearchField}
            fullWidth
            type="search"
            placeholder="Search menu items"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" sx={{ backgroundColor: theme.palette.primary.main }}>
                  <SearchIcon htmlColor={theme.palette.text.secondary} />
                </InputAdornment>
              )
            }}
            style={{
              border: `1px solid ${theme.palette.text.secondary}`,
              backgroundColor: theme.palette.primary.main,
              borderRadius: 6
            }}
          />
        </Col>
      </Row>

      {dmvMenuLink === menuText.SPIRITS && (
        <Row className="mt-4">
          <Col lg={12}>
            <SpiritsSubMenuBar activeTab={menuCategory?.name} />
          </Col>
        </Row>
      )}

      {dmvMenuLink === menuText.SPIRITS && (
        <Grid item sx={{ maxWidth: "48%" }}>
          {SpiritSubMenu?.map(
            (foodList, i) =>
              foodList?.id === menuCategory?.name &&
              (foodList?.description ||
                foodList?.image_url ||
                foodList?.unique_facts?.length > 0) && (
                <DMV_SpiritSummary key={i} isEdit={false} foodList={foodList} />
              )
          )}
        </Grid>
      )}

      <Container fluid className="p-0 mt-4">
        <div className={classes.menuContainer}>{renderMenuContent()}</div>
      </Container>
    </>
  )
}

export default DMVMenu
